import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from 'shared/page-not-found/page-not-found.component';

export const appRoutes: Routes = [
    {
        path: 'lehrer',
        loadChildren: () => import('./teacher/teacher.module').then(m => m.TeacherModule)
    },
    {
        path: 'schueler',
        loadChildren: () => import('./student/student.module').then(m => m.StudentModule)
    },
    {
        path: '',
        loadChildren: () => import('./landingpage/landingpage.module').then(m => m.LandingpageModule)
    },
    
    {
        path: '**',
        component: PageNotFoundComponent,
        data: {
            title: 'Seite nicht gefunden - Leseo'
        }
    }
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
};

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
