export interface BookFilterModel {
    title: string;
    readingLevels: number[];
    genres: string[];
    statuses: FilterStatus[];
    progress: FilterProgress[];
}

export enum FilterStatus {
    Favorites = 'Favorit',
    New = 'Neuheit',
}

export enum FilterProgress {
    Read = 'Gelesen',
    Opened = 'Angefangen',
    Unopened = 'Ungelesen',
    All = 'Alle'
}

export enum FilterCategory {
    Title = 'Title',
    ReadingLevel = 'ReadingLevels',
    Genre = 'Genres',
    Status = 'Statuses',
    Serie = 'Series',
    Strategy = 'Strategies',
    Exercise = 'Exercises',
    Progress = 'Progress',
    Competence = 'Competences',
    TextbookPage = 'ConnectionToPrint',
}

export enum TaskTitle {
  WrongWord = 'Das falsche Wort',
  CorrectField = 'Das richtige Feld',
  GapText = 'Der Lückentext',
  FiveQuestions = 'Die fünf Fragen',
  MatchingWords = 'Die passenden Wörter',
  CorrectOrder = 'Die richtige Reihenfolge',
  CorrectAssignment = 'Die richtige Zuordnung',
  ReversedWordnsnake = 'Die verdrehte Wortschlange',
  Wordsnake = 'Die Wortschlange',
  TenQuestions = 'Die zehn Fragen'
}
