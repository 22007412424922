import { createAction, props } from '@ngrx/store';
import {
    AccountType,
    BackdropColor,
    CvPopupConfirmEvent,
    CvPopUpSettings,
    GlobalNotification,
    PopoverSettings,
    SettingsModel
} from 'shared/models';


export const openPopup = createAction(
    '[Layout] open popup',
    props<{ settings: CvPopUpSettings }>()
);

export const closePopup = createAction('[Layout] close popup');

export const confirmPopup = createAction(
    '[Layout] confirm Popup',
    props<{ payload: CvPopupConfirmEvent }>()
);

export const showBackdrop = createAction(
    '[Layout] show backdrop',
    (color: BackdropColor = BackdropColor.Black, closable: boolean = true) => ({ color, closable })
);

export const clickOnBackdrop = createAction('[Layout] click on backdrop');

export const hideBackdrop = createAction('[Layout] hide backdrop');

export const showPopover = createAction(
    '[Layout] Show Popover',
    props<{ settings: PopoverSettings }>()
);

export const hidePopover = createAction('[Layout] hide popover');

export const lockUi = createAction('[Layout] lock ui');

export const unlockUi = createAction('[Layout] unlock ui');

export const showLoadingIndicator = createAction('[Layout] show loading indicator',
    (accountType: AccountType, isTriggeredByRouter: boolean, message = 'Inhalte werden geladen') => ({
        accountType,
        isTriggeredByRouter,
        message
    })
);

export const hideLoadingIndicator = createAction('[Layout] hide loading indicator',
    props<{ isTriggeredByRouter: boolean }>()
);

export const loadingError = createAction('[Layout] loading error',
    props<{ error: unknown }>()
);

export const fetchSettings = createAction('[Layout] fetch settings');

export const fetchSettingsSuccess = createAction('[Layout] fetch settings success',
    props<{ settings: SettingsModel }>()
);

export const fetchSettingsFail = createAction('[Layout] fetch settings fail',
    props<{ error: unknown }>()
);

export const hideMaintenance = createAction('[Layout] hide maintenance');

export const getGlobalNotifications = createAction('[Layout] Get global notifications');

export const getGlobalNotificationsSuccess = createAction('[Layout] Get global notifications success', props<{ globalNotifications: GlobalNotification[] }>());

export const showGlobalNotifications = createAction('[GlobalNotifications Comp] show global notifications');

export const setGlobalNotificationViewedSuccess = createAction('[GlobalNotifications Comp] set global notification viewed success', props<{ notificationsIds: number[] }>());

export const setGlobalNotificationViewedFail = createAction('[GlobalNotifications Comp] set global notification viewed fail', props<{ error: unknown }>());

export const finishStudentOnboarding = createAction('[Onboarding Comp] finish student onboarding');

export const skipStudentOnboarding = createAction('[Onboarding Comp] skip student onboarding');

export const closeUserSidebar = createAction('[UserSidebar Comp] close user sidebar');

export const closeMenuSidebar = createAction('[MenuSidebar Comp] close menu sidebar');

export const toggleUserSidebar = createAction('[UserInfo Comp] open/close user sidebar');

export const toggleMenuSidebar = createAction('[Menu Comp] open/close menu sidebar');

export const setSubmenuTitle = createAction('[Submenu Comp] set submenu title', props<{ submenuTitle: string }>());
