import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';

import lottie from 'lottie-web';
import { AnimationItem, AnimationConfigWithPath } from 'lottie-web';

@Component({
    selector: 'app-lottie',
    template: '',
    styleUrls: ['./lottie.component.scss']
})
export class LottieComponent implements  OnChanges, OnDestroy {
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    readonly onCompleted = new EventEmitter();

    private animation: AnimationItem;

    @Input()
    animationName: string = null;

    @Input()
    animationPath: string = null;

    constructor(private elementRef: ElementRef<HTMLDivElement>) {}

    ngOnChanges(changes: SimpleChanges): void {
        const animationPathChange = changes.animationPath;

        if (
            animationPathChange != null &&
            animationPathChange.currentValue != null
        ) {
            if (this.animation != null) {
                this.animation.destroy();
            }

            this.animation = this.createAnimation(
                animationPathChange.currentValue
            );
        }
    }

    private createAnimation(animationPath: string): AnimationItem {
        const config: AnimationConfigWithPath = {
            container: this.elementRef.nativeElement,
            path: animationPath,
            // renderer: 'canvas',
            renderer: 'svg',
            loop: false,
            autoplay: true,
            name: this.animationName
        };

        const animation = lottie.loadAnimation(config);

        animation.addEventListener('error', e => {
            console.error('lottie', e);
        });

        animation.addEventListener('complete', () => {
            this.onCompleted.emit();
        });

        return animation;
    }

    ngOnDestroy(): void {
        if (this.animation != null) {
            this.animation.destroy();
        }
    }
}
