import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class UserSendMessage extends BaseTrackingEvent {
    constructor(messageType: string, messageTitle: string = '', messageId: number = 0) {
        super({
            eventId: 'd3550886-cdfa-4155-88c4-8eb8738d8317',
            eventVerb: TrackingVerbs.SEND,
            eventObject: TrackingObjects.MESSAGE,
            eventPayload: {
                messageTitle: messageTitle,
                messageId: messageId,
                messageType: messageType
            },
        });
    }
}
