import { Injectable } from '@angular/core';
import { SignoutRequest, SilentRenewErrorCallback, User, UserLoadedCallback, UserManager, UserManagerEvents, UserManagerSettings, UserUnloadedCallback, WebStorageStateStore } from 'oidc-client-ts';
import { from, Observable, of } from 'rxjs';
import { LoginProvider } from 'shared/models';
import { EnvironmentService } from './environment.service';

@Injectable({ providedIn: 'root' })
export class OidcUserService {
    private userManager: UserManager | null = null;

    get currentLoginProvider(): LoginProvider | null {
        const provider = localStorage.getItem('provider');
        return provider !== null ? LoginProvider[provider] : null;
    }

    constructor() {
        const provider = this.currentLoginProvider;

        if (provider !== null) {
            this.useProvider(provider);
        }
    }

    useProvider(provider: LoginProvider): UserManager {
        localStorage.setItem('provider', provider + '');
        let userManager: UserManager;

        switch (provider) {
            case LoginProvider.berlin: {
                userManager = this.useBerlinPortal();
                break;
            }
            case LoginProvider.edupool: {
                userManager = this.useEdupool();
                break;
            }
            default: {
                userManager = this.useCornelsen();
                break;
            }
        }

        return userManager;
    }

    private useCornelsen(): UserManager {
        const config = EnvironmentService.authService.authConfig;
        const settings: UserManagerSettings = {
            authority: config.authority,
            client_id: config.client_id,
            redirect_uri: window.location.origin + config.redirect_uri,
            response_type: config.response_type,
            scope: config.scope,
            loadUserInfo: config.loadUserInfo,
            post_logout_redirect_uri: window.location.origin + config.post_logout_redirect_uri,
            userStore: new WebStorageStateStore({ store: window.localStorage })
        };

        this.userManager = new UserManager(settings);

        return this.userManager;
    }

    private useBerlinPortal(): UserManager {
        const config = EnvironmentService.authService.berlinConfig;
        const settings: UserManagerSettings = {
            authority: config.authority,
            client_id: config.client_id,
            client_secret: config.client_secret,
            redirect_uri: window.location.origin + config.redirect_uri,
            response_type: config.response_type,
            scope: config.scope,
            loadUserInfo: config.loadUserInfo,
            post_logout_redirect_uri: window.location.origin + config.post_logout_redirect_uri,
            userStore: new WebStorageStateStore({ store: window.localStorage })
        };

        this.userManager = new UserManager(settings);

        return this.userManager;
    }

    private useEdupool(): UserManager {
        const config = EnvironmentService.authService.eduPoolConfig;
        const settings: UserManagerSettings = {
            authority: config.authority,
            client_id: config.client_id,
            client_secret: config.client_secret,
            redirect_uri: window.location.origin + config.redirect_uri,
            response_type: config.response_type,
            scope: config.scope,
            loadUserInfo: false, // config.loadUserInfo,
            post_logout_redirect_uri: window.location.origin + config.post_logout_redirect_uri,
            userStore: new WebStorageStateStore({ store: window.localStorage })
        };

        this.userManager = new UserManager(settings);

        return this.userManager;
    }

    isCornelsenUser(): boolean {
        return this.currentLoginProvider === LoginProvider.cornelsen;
    }

    isBerlinUser(): boolean {
        return this.currentLoginProvider === LoginProvider.berlin;
    }

    getUser(): Observable<User | null> {
        if (this.userManager) {
            return from(this.userManager.getUser());
        }

        return of(null);
    }

    addUserLoaded(callback: UserLoadedCallback): void {
        this.userManager?.events.addUserLoaded(callback);
    }

    addUserUnloaded(callback: UserUnloadedCallback): void {
        this.userManager?.events.addUserUnloaded(callback);
    }

    addAccessTokenExpiring(callback: (...ev: any[]) => void): void {
        this.userManager?.events.addAccessTokenExpiring(callback);
    }

    addAccessTokenExpired(callback: (...ev: any[]) => void): void {
        this.userManager?.events.addAccessTokenExpired(callback);
    }

    addSilentRenewError(callback: SilentRenewErrorCallback): void {
        this.userManager?.events.addSilentRenewError(callback);
    }

    signinRedirect(): Observable<void> {
        return from(this.userManager.signinRedirect({ state: { provider: this.currentLoginProvider } }));
    }

    signinRedirectCallback(): Observable<User> {
        return from(this.userManager.signinRedirectCallback());
    }

    signoutRedirect(args?: any): Promise<void> {
        if (this.userManager) {
            return this.userManager.signoutRedirect(args);
        }
    }

    createSignoutRequest(): Promise<void> {
        if (this.userManager) {
            return this.userManager.signoutRedirect();
        }
    }

    removeUser(): Promise<void> {
        if (this.userManager) {
            return this.userManager.removeUser();
        }
    }
}
