import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-cv-monsters-message',
    templateUrl: './cv-monsters-message.component.html',
    styleUrls: ['./cv-monsters-message.component.scss']
})
export class CvMonstersMessageComponent {
    @Input()
    message = '';

    constructor() {}
}
