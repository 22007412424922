import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import {
    AssignmentStatus,
    MonsterModel,
    StudentAssignmentModel
} from 'shared/models';
import { UserData } from 'shared/models/user-data';

@Injectable()
export class StudentService {
    constructor(private httpClient: HttpClient) {}

    getStudentAssignments(status = AssignmentStatus.Open, skip = 0, limit = 0): Observable<StudentAssignmentModel[]> {
        return this.httpClient.get<StudentAssignmentModel[]>(
            `${EnvironmentService.baseApiUrl}/assignments?status=${status}&skip=${skip}&limit=${limit}`
        );
    }

    setMonster(monster: MonsterModel): Observable<UserData> {
        return this.httpClient.put<UserData>(
            `${EnvironmentService.baseApiUrl}/user/monster`,
            monster
        );
    }
}
