import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 510_Editieren_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-pencil',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M15.556,51.024a1.418,1.418,0,0,1-1.418-1.417V41.1a1.424,1.424,0,0,1,.19-.709L34.741,5.038a1.417,1.417,0,0,1,1.936-.518l7.364,4.252a1.416,1.416,0,0,1,.519,1.936L24.148,46.064a1.417,1.417,0,0,1-.519.518l-7.365,4.252A1.408,1.408,0,0,1,15.556,51.024Zm1.417-8.286v4.414L20.8,44.945Zm.873-2.768L22.757,42.8l15.1-26.149L32.944,13.82Zm16.515-28.6L39.27,14.2,41.4,10.518l-4.91-2.834Z" />
    </svg>
  `
})
export class SvgIconPencilComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'pencil';
    }
}
