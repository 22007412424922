import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SvgIconsModule } from 'app/svg-icons/svg-icons.module';
import { BackToComponent } from './back-to/back-to.component';
import { BookCoverComponent } from './book-cover/book-cover.component';
import { BookRecommendationHeaderComponent } from './book-recommendation-header/book-recommendation-header.component';
import { CollapseComponent } from './collapse/collapse.component';
import { CtaBoxComponent } from './cta-box/cta-box.component';
import { CvCalendarComponent } from './cv-calendar/cv-calendar.component';
import { CvCheckboxComponent } from './cv-checkbox/cv-checkbox.component';
import { CvFilterComponent } from './cv-filter/cv-filter.component';
import { CvFooterComponent } from './cv-footer/cv-footer.component';
import { CvInlineTextEditComponent } from './cv-inline-text-edit/cv-inline-text-edit.component';
import { CvInputComponent } from './cv-input/cv-input.component';
import { CvMonstersMessageComponent } from './cv-monsters-message/cv-monsters-message.component';
import { CvNavComponent } from './cv-nav/cv-nav.component';
import { CvPopupComponent } from './cv-popup/cv-popup.component';
import { CvSelectableItemComponent } from './cv-selectable-item/cv-selectable-item.component';
import { CvSwitchComponent } from './cv-switch/cv-switch.component';
import { DateComponent } from './date/date.component';
import { AutofocusDirective } from './directives';
import { SortableDirective } from './directives/sortable.directive';
import { IconLinkComponent } from './icon-link/icon-link.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ReadingLevelBookmarkComponent } from './reading-level-bookmark/reading-level-bookmark.component';
import { ReadingLevelDistributionChartComponent } from './reading-level-distribution-chart/reading-level-distribution-chart.component';

import { SaleBannerComponent } from './sale-banner/sale-banner.component';
import { ChristmasBannerComponent } from './banner/christmas/christmas-banner.component';
import { LicenceBannerComponent } from './banner/licence-banner/licence-banner.component';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { CvStepperComponent } from './cv-stepper/cv-stepper.component';
import { CvStepComponent } from './cv-stepper/cv-step/cv-step.component';
import { LoadMoreComponent } from './load-more/load-more.component';
import { CvCommonButtonComponent, CvCommonIconComponent } from '@cornelsen/cv-common';
import { CvGroupLimitInfoComponent } from './cv-group-limit-info/cv-group-limit-info.component';
import { A11yModule } from '@angular/cdk/a11y';
import { ClickOutsideDirective } from './directives/click-outside.directive';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SvgIconsModule,
        RouterModule,
        NgxFloatUiModule,
        CvCommonButtonComponent,
        CvCommonIconComponent,
        A11yModule,
    ],
    declarations: [
        AutofocusDirective,
        BackToComponent,
        BookCoverComponent,
        BookRecommendationHeaderComponent,
        ChristmasBannerComponent,
        CollapseComponent,
        CtaBoxComponent,
        CvCalendarComponent,
        CvCheckboxComponent,
        CvFilterComponent,
        CvFooterComponent,
        CvInlineTextEditComponent,
        CvInputComponent,
        CvMonstersMessageComponent,
        CvNavComponent,
        CvPopupComponent,
        CvSelectableItemComponent,
        CvSwitchComponent,
        DateComponent,
        IconLinkComponent,
        LicenceBannerComponent,
        LoadingSpinnerComponent,
        ReadingLevelBookmarkComponent,
        ReadingLevelDistributionChartComponent,
        SaleBannerComponent,
        SortableDirective,
        CvStepperComponent,
        CvStepComponent,
        LoadMoreComponent,
        CvGroupLimitInfoComponent,
        ClickOutsideDirective,
    ],
    exports: [
        AutofocusDirective,
        BackToComponent,
        BookCoverComponent,
        BookRecommendationHeaderComponent,
        CollapseComponent,
        CtaBoxComponent,
        CvCalendarComponent,
        CvCheckboxComponent,
        CvFilterComponent,
        CvFooterComponent,
        CvInlineTextEditComponent,
        CvInputComponent,
        CvMonstersMessageComponent,
        CvNavComponent,
        CvPopupComponent,
        CvSelectableItemComponent,
        CvSwitchComponent,
        DateComponent,
        IconLinkComponent,
        LoadingSpinnerComponent,
        ReadingLevelBookmarkComponent,
        ReadingLevelDistributionChartComponent,
        SortableDirective,
        SvgIconsModule,
        CvStepperComponent,
        CvStepComponent,
        LoadMoreComponent,
        ClickOutsideDirective,
    ],
})
export class WidgetsModule { }
