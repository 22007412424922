import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

@Component({
    selector: 'app-svg-icon-three-filled-dots',
    template: `
    <!-- 557_Mehr-Menu_linear_CV_Uni_Icon -->
    <svg [attr.height]="height" [attr.width]="width" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path [attr.fill]="color" d="M9.923,35.432a7.086,7.086,0,1,1,7.085-7.086A7.094,7.094,0,0,1,9.923,35.432Zm0-11.338a4.252,4.252,0,1,0,4.252,4.252A4.256,4.256,0,0,0,9.923,24.094ZM28.347,35.432a7.086,7.086,0,1,1,7.087-7.086A7.094,7.094,0,0,1,28.347,35.432Zm0-11.338A4.252,4.252,0,1,0,32.6,28.346,4.257,4.257,0,0,0,28.347,24.094ZM46.773,35.432a7.086,7.086,0,1,1,7.086-7.086A7.094,7.094,0,0,1,46.773,35.432Zm0-11.338a4.252,4.252,0,1,0,4.252,4.252A4.256,4.256,0,0,0,46.773,24.094Z" />
    </svg>
  `
})
export class SvgIconThreeFilledDotsComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'three-filled-dots';
    }
}
