import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LayoutState } from 'store/layout';

const getLayoutState = createFeatureSelector<LayoutState>('layout');

export const getPopup = createSelector(getLayoutState, state => state.popup);

export const getIsPopupVisible = createSelector(
    getPopup,
    popup => popup.isVisible
);

export const getPopupSettings = createSelector(
    getPopup,
    popup => popup.settings
);

export const getBackdrop = createSelector(
    getLayoutState,
    state => state.backdrop
);

export const getIsBackdropVisible = createSelector(
    getBackdrop,
    state => state.isVisible
);

export const getPopover = createSelector(
    getLayoutState,
    (state: LayoutState) => state.popover
);

export const getIsUiLocked = createSelector(
    getLayoutState,
    state => state.uiLocked
);

export const getLoadingIndicator = createSelector(getLayoutState, state => state.loadingIndicator);

export const getShowLoadingIndicator = createSelector(getLoadingIndicator, state => {
    if (state == null) {
        return false;
    }

    return state.isVisible;
});

export const getLoadingIndicatorMessage = createSelector(
    getLoadingIndicator,
    state => {
        if (state == null) {
            return null;
        }

        return state.message;
    }
);

export const getSettings = createSelector(getLayoutState, state => state.settings);

export const getMaintenance = createSelector(getSettings, settings => {
    if (!settings.loaded) {
        return null;
    }

    return settings.maintenance;
});

export const selectGlobalNotifications = createSelector(getLayoutState, state => {
    if (state.globalNotifications.loaded) {
        return state.globalNotifications.notifications;
    }

    return [];
});

export const isGlobalNotificationNew = createSelector(getLayoutState, state => {
    if (state.globalNotifications.loaded && state.globalNotifications.notifications.length > 0) {
        if (state.globalNotifications.viewedAt) {
            const latestNotification = state.globalNotifications.notifications[0];

            return state.globalNotifications.viewedAt < latestNotification.publicationDate;
        }

        return true;
    }

    return false;
});

export const selectShowStudentOnboarding = createSelector(getLayoutState, state => {
    return state.showStudentOnboarding;
});

export const selectIsUserSidebarOpen = createSelector(getLayoutState, state => state.isUserSidebarOpen);

export const selectIsMenuSidebarOpen = createSelector(getLayoutState, state => state.isMenuSidebarOpen);

export const selectSubmenuTitle = createSelector(getLayoutState, state => state.submenuTitle);
