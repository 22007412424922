import { Component, Input, HostBinding } from '@angular/core';

export enum HeadImage {
    Gromo = 'gromo',
    MonsterSchoolClass = 'monster2',
    RedMonster = 'red-monster'
}

@Component({
    selector: 'app-cta-box',
    templateUrl: './cta-box.component.html',
    styleUrls: ['./cta-box.component.scss']
})
export class CtaBoxComponent {
    readonly HeadImages = HeadImage;

    @Input()
    @HostBinding('class')
    headImage: HeadImage = HeadImage.Gromo;

    constructor() {}
}
