import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreHelper } from 'shared/helper/store.helper';
import { BookDetailModel, BookModel, FilterCategory, FilterProgress, FilterStatus, PrintedBook } from 'shared/models';
import { getRouteBookId } from 'store/router/router.selector';
import { getStudentReadingLevel } from 'store/user/user.selector';
import { AllBooksState, BookSearchState, BooksState } from './books.reducer';

export const getBooksState = createFeatureSelector<BooksState>('books');

export const getAllBooksDict = createSelector(
    getBooksState,
    (state: BooksState) => state.all
);

export const selectAllBooksLoaded = createSelector(
    getAllBooksDict,
    (allBooks: AllBooksState) => allBooks.loaded
);


export const getAllBooksList = createSelector(getAllBooksDict, data => {
    const booksList = StoreHelper.dictToArray(data.dict).sort(
        sortBooksByReadingLevel
    );

    return {
        list: booksList,
        loaded: data.loaded
    };
});

export const getNewBooksList = createSelector(getAllBooksDict, data => {
    const booksList = StoreHelper.dictToArray(data.dict)
        .sort(sortBooksByCreatedAtDate)
        .slice(0, 10);

    return {
        list: booksList,
        loaded: data.loaded
    };
});

export const selectAllCompletedBooks = createSelector(
    getAllBooksList,
    (allBooksList) => {
        if (!allBooksList.loaded) {
            return [];
        }

        return allBooksList.list
            .filter(b => b.lastReadAt && b.isCompleted)
            .sort(sortByMostRecentReadAt);
    }
);

export const selectAllOpenedBooks = createSelector(
    getAllBooksList,
    (allBooksList) => {
        if (!allBooksList.loaded) {
            return [];
        }

        return allBooksList.list
            .filter(b => b.lastReadAt && !b.isCompleted)
            .sort(sortByMostRecentReadAt);
    }
);

const sortByMostRecentReadAt = (a: BookModel, b: BookModel) => {
    return a.lastReadAt > b.lastReadAt ? -1 : 1;
};

export const selectLastOpenedBooks = createSelector(
    selectAllOpenedBooks,
    (allReadBooks) => allReadBooks.slice(0, 6)
);

/**
 * use `selectShuffledBooks` as default for getting shuffled books
 * if you need a different shuffling logic, use this one (or for testing)
 *
 * @param shuffleFunc array of type T
 * @returns new shuffled array type T
 */
export const shuffledBooksSelectorFunc = (shuffleFunc: <T>(array: T[]) => T[]) => createSelector(
    getAllBooksList,
    (allBooks) => {
        if (allBooks.loaded) {
            return shuffleFunc(allBooks.list);
        }

        return [];
    }
);

const shuffle = <T>(array: T[]) => {
    return array
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
};

export const selectShuffledBooks = shuffledBooksSelectorFunc(shuffle);

export const selectDiscoverBooks = (bookCount: number) => createSelector(
    selectShuffledBooks,
    getStudentReadingLevel,
    (allBooks, readingLevel) => {
        let list: BookModel[] = [];

        if (allBooks.length > 0) {
            // remove 'Lehrwerke' (i.e. exercise-only books)
            const books = allBooks.filter(b => !b.genres.includes('Lehrwerk'));

            // remove completed books
            const unreadBooks = books.filter(b => !b.isCompleted);

            if (unreadBooks.length === 0) {
                return books.slice(0, bookCount);
            }

            // group unread books by reading level
            const groupedBooks = groupBy(unreadBooks, b => b.readingLevel);

            // get unread books from same or higher reading levels
            const HIGHEST_READING_LEVEL = 5;
            for (let i = readingLevel; i <= HIGHEST_READING_LEVEL; i++) {
                const booksFromGroup = groupedBooks[i.toString()];
                if (booksFromGroup) {
                    list = list.concat(booksFromGroup);
                }

                if (list.length >= bookCount) {
                    break;
                }
            }

            // if not enough get books from next lowest reading level
            if (list.length < bookCount) {
                const LOWEST_READING_LEVEL = 0;
                for (let i = readingLevel - 1; i >= LOWEST_READING_LEVEL; i--) {
                    const booksFromGroup = groupedBooks[i.toString()];
                    if (booksFromGroup) {
                        list = list.concat(booksFromGroup);
                    }

                    if (list.length >= bookCount) {
                        break;
                    }
                }
            }

            // if still not enough fill up with random read books
            if (list.length < bookCount) {
                const readBooks = books.filter(b => b.isCompleted).slice(list.length, bookCount);
                list = list.concat(readBooks);
            }

        }

        return list.slice(0, bookCount);
    }
);

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((dict, currentItem) => {
        const groupKey = getKey(currentItem);
        if (!dict[groupKey]) dict[groupKey] = [];
        dict[groupKey].push(currentItem);
        return dict;
    }, {} as Record<K, T[]>);

export const getSelectedBook = createSelector(
    getBooksState,
    getRouteBookId,
    (booksState, bookId): BookDetailModel => {
        const { booksDetails } = booksState;

        if (booksDetails[bookId]) {
            return booksState.booksDetails[bookId];
        }

        return;
    }
);

export const getBookById = (bookId: number) => createSelector(
    getAllBooksDict,
    (allBooks): BookModel => {
        if (!allBooks.loaded) {
            return;
        }

        const { dict } = allBooks;

        return dict[bookId];
    }
);


export const getAllGenres = createSelector(
    getAllBooksList,
    (allBooks): string[] => {
        if (!allBooks.loaded) {
            return [];
        }

        const genres = [];

        allBooks.list.forEach(book => {
            if (book.genres) {
                book.genres.forEach(genre => {
                    if (!genres.includes(genre) && genre.length > 0) {
                        genres.push(genre);
                    }
                });
            }
        });

        return genres.sort((a, b) => a < b ? -1 : 1);
    }
);

const getRecommendedBookState = createSelector(
    getBooksState,
    state => state.recommended
);

export const getRecommendedBook = createSelector(
    getRecommendedBookState,
    (state) => {
        if (state.loaded) {
            return state.book;
        }

        return;
    }
);

export const getFavorites = createSelector(getAllBooksList, books => {
    if (!books.loaded) {
        return [];
    }

    return books.list.filter(b => b.isFavorite);
});

export const selectVolumesInSeriesFromCurrentBook = createSelector(getAllBooksList, getSelectedBook,
    (books, currentSelectedBook) => {
        if (currentSelectedBook) {
            return books.list.filter(b => b.id !== currentSelectedBook.id && b.series === currentSelectedBook.series);
        }

        return books.list;
    });

export const selectFilterOptions = createSelector(getBooksState, data => {
    return data.filterOptions;
});

export const selectPrintedBooksFromFilter = createSelector(getBooksState, data => {
    return data.filterOptions.filter(res => res.name === FilterCategory.TextbookPage).map(res => res.values.map(res => {
        const bookInfo = JSON.parse(res.value);
        return {
            cover: bookInfo?.CoverUrl,
            name: bookInfo?.Name,
            bookId: parseInt(res.key)
        } satisfies PrintedBook;
    })
    )[0];
});

export const hasPrintedBookFilter = createSelector(selectPrintedBooksFromFilter, printedBooks => {
  return printedBooks?.length > 0;
});

export const selectBookSearch = createSelector(getBooksState, data => {
    return data.search;
});

export const selectReadingLevels = createSelector(getBooksState, data => {
    return data.search.readingLevels;
});

// todo: change syntax
export const selectHasFilterChecked = createSelector(
    selectBookSearch,
    (search: BookSearchState, { filterCategory, filterValue }) => {
        // needs to be camelCase for store's search properties
        const cat = filterCategory[0].toLowerCase() + filterCategory.slice(1);

        if (search[cat] && search[cat].length > 0) {
            return search[cat].includes(filterValue);
        }

        return false;
    });

export const getFilteredBooks = createSelector(getAllBooksList, selectBookSearch, (allBooks, search) => {
    const books = allBooks.list;

    if (books == null) {
        return [];
    }

    let filteredBooks = [...books];

    const { title, readingLevels, genres, statuses, exercises, strategies, series, progress, competences, textbookPage } = search;

    if (title.length > 0) {
        filteredBooks = filterByTitle(filteredBooks, title);
    }

    if (readingLevels.length > 0) {
        filteredBooks = filteredBooks.filter(b =>
            readingLevels.some((i: number) => i.toString() === b.readingLevel.toString())
        );
    }

    if (genres.length > 0) {
        filteredBooks = filteredBooks.filter(b =>
            genres.some((i: string) => b.genres.includes(i))
        );
    }

    // AND
    if (exercises.length > 0) {
        filteredBooks = filterByExercise(exercises, filteredBooks);
    }

    // OR
    if (strategies.length > 0) {
        filteredBooks = filteredBooks.filter(b =>
            strategies.some((i) => b.strategies.map(x => x.title).includes(i))
        );
    }

    // OR
    if (series.length > 0) {
        filteredBooks = filteredBooks.filter(b =>
            series.some((i: string) => b.series === i)
        );
    }

    // AND
    if (competences.length > 0) {
        filteredBooks = filteredBooks.filter(b => {
            const competencesInBook = b.competences.map(e => e.title);
            return competences.every(e => competencesInBook.includes(e));
        });
    }

    if (statuses.length > 0) {
        filteredBooks = filterByStatus(statuses, filteredBooks);
    }

    if (textbookPage) {
        filteredBooks = filterByTextbookPage(textbookPage, filteredBooks);
    }

    filteredBooks = filterByProgess(progress, filteredBooks);

    return filteredBooks;
});

const sortBooksByReadingLevel = (book1: BookModel, book2: BookModel) => {
    if (book1.readingLevel === book2.readingLevel) {
        return book1.title < book2.title ? -1 : 1;
    } else {
        return book1.readingLevel < book2.readingLevel ? -1 : 1;
    }
};

const sortBooksByCreatedAtDate = (book1: BookModel, book2: BookModel) => {
    if (book1.createdAt === book2.createdAt) {
        return 0;
    } else {
        return book1.createdAt > book2.createdAt ? -1 : 1;
    }
};

const filterByStatus = (bookStatuses: string[], filteredBooks: BookModel[]) => {
    if (bookStatuses.includes(FilterStatus.Favorites)) {
        filteredBooks = filteredBooks.filter(b => bookStatuses.some(i => b.isFavorite));
    }
    if (bookStatuses.includes(FilterStatus.New)) {
        filteredBooks = filteredBooks.filter(b => bookStatuses.some(i => b.isNew));
    }
    return filteredBooks;
};

const filterByExercise = (exercises: string[], filteredBooks: BookModel[]) => {
    filteredBooks = filteredBooks.filter(b => {
        const exerciseTitlesInBook = b.exercises.map(e => e.title);
        return exercises.every(e => exerciseTitlesInBook.includes(e));

    });

    return filteredBooks;
};

const filterByTitle = (books: BookModel[], title: string): BookModel[] => {
    return books.filter(b =>
        b.title.toLowerCase().includes(title.toLowerCase())
    );
};

const filterByTextbookPage = (textbookPage, filteredBooks: BookModel[]) => {
    return filteredBooks.filter(book => {
        return textbookPage.relatedBookIds.includes(book.id);
    });
};

function filterByProgess(progress: FilterProgress, books: BookModel[]): BookModel[] {
    return books.filter(b => {
        switch (progress) {
            case FilterProgress.Unopened:
                return b.lastReadAt == null;

            case FilterProgress.Read:
                return b.lastReadAt && b.isCompleted;

            case FilterProgress.Opened:
                return b.lastReadAt !== null && !b.isCompleted;

            default:
                return b;
        }
    });
}
