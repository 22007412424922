import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { environment } from 'environments/environment';
import { WindowService } from 'shared/services';

@Injectable({
    providedIn: 'root'
})
export class ConsentService {

    constructor(private windowService: WindowService) {
    }

    show(): void {
        if (EnvironmentService.enabledCvConsentManager) {
            this.windowService.nativeWindow.ccm?.show();
        } else {
            console.info('cvConsentManager is disabled for this environment.');
        }
    }

    isConsentGiven(): boolean {
        return !!this.windowService.nativeWindow.ccm?.getManager().savedConsents?.cornelsenProductAnalytics;
    }

    isConsentConfirmed(): boolean {
        return this.windowService.nativeWindow.ccm?.getManager().confirmed;
    }
}
