import { Pipe, PipeTransform } from '@angular/core';

import { Gender } from '../models';

@Pipe({ name: 'gender' })
export class GenderPipe implements PipeTransform {
    transform(gender: Gender): string {
        if (gender == null) {
            return null;
        }

        switch (gender) {
            case Gender.Male:
                return 'Herr';
            case Gender.Female:
                return 'Frau';
            default:
                throw new Error(`UserGender "${gender}" not supported`);
        }
    }
}
