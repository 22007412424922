import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { EnvironmentService } from 'app/core/services/environment.service';
import {
    ResetStudentCodesResultModel,
    SchoolClassDetailsModel,
    SchoolClassModel,
    StudentCreateModel,
    StudentModel,
    StudentReadingConfigurationEnum
} from 'shared/models';

@Injectable()
export class TeacherService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;
    private static readonly activeSchoolClassIdKey = 'active-school-class-id';

    static get activeSchoolClassId(): number {
        return +localStorage.getItem(TeacherService.activeSchoolClassIdKey);
    }

    static set activeSchoolClassId(value: number) {
        value ? localStorage.setItem(TeacherService.activeSchoolClassIdKey, value.toString()) : localStorage.removeItem(TeacherService.activeSchoolClassIdKey);
    }

    constructor(private http: HttpClient) { }

    getSchoolClass(): Observable<SchoolClassModel> {
        return this.getSchoolClasses().pipe(
            map((schoolClasses: SchoolClassModel[]) => {
                if (schoolClasses && schoolClasses.length > 0) {
                    return schoolClasses[0];
                }

                return null;
            })
        );
    }

    getSchoolClasses(): Observable<SchoolClassModel[]> {
        return this.http.get<SchoolClassModel[]>(
            `${this.baseApiUrl}/schoolclasses`
        );
    }

    getSchoolClassDetails(
        schoolClassId: number
    ): Observable<SchoolClassDetailsModel> {
        return this.http.get<SchoolClassDetailsModel>(
            `${this.baseApiUrl}/schoolclasses/${schoolClassId}/details`
        );
    }

    addSchoolClass(schoolClassName: string): Observable<SchoolClassModel> {
        return this.http.post<SchoolClassModel>(
            `${this.baseApiUrl}/schoolclasses`,
            { name: schoolClassName }
        );
    }

    deleteSchoolClass(schoolClassId: number): Observable<void> {
        return this.http.delete<void>(
            `${this.baseApiUrl}/schoolclasses/${schoolClassId}`
        );
    }

    changeSchoolClass(
        schoolClassId: number,
        changedFields: any
    ): Observable<SchoolClassModel> {
        return this.http.patch<SchoolClassModel>(
            `${this.baseApiUrl}/schoolclasses/${schoolClassId}`,
            changedFields
        );
    }

    getStudentsBySchoolClassId(
        schoolClassId: number
    ): Observable<StudentModel[]> {
        return this.http.get<StudentModel[]>(
            `${this.baseApiUrl}/schoolclasses/${schoolClassId}/Students`
        );
    }

    addStudents(
        schoolClassId: number,
        students: StudentCreateModel[]
    ): Observable<StudentModel[]> {
        return this.http.post<StudentModel[]>(
            `${this.baseApiUrl}/schoolclasses/${schoolClassId}/Students`,
            students
        );
    }

    changeStudent(
        studentId: number,
        changeFields: any
    ): Observable<StudentModel> {
        return this.http.patch<StudentModel>(
            `${this.baseApiUrl}/Students/${studentId}`,
            changeFields
        );
    }

    deleteStudent(studentId: number): Observable<void> {
        return this.http.delete<void>(
            `${this.baseApiUrl}/Students/${studentId}`
        );
    }

    resetStudentCode(studentId: number): Observable<string> {
        return this.http.post(
            `${this.baseApiUrl}/Students/${studentId}/reset_code`,
            {}, { responseType: 'text' }
        );
    }

    resetAllStudentCodes(
        schoolClassId: number
    ): Observable<ResetStudentCodesResultModel> {
        return this.http.post<ResetStudentCodesResultModel>(
            `${this.baseApiUrl}/schoolclasses/${schoolClassId}/resetstudentcodes`,
            {}
        );
    }

    changeStudentReadingConfiguration(student: StudentModel, isHidden: boolean, studentReadingConfiguration: StudentReadingConfigurationEnum): Observable<void> {
        return this.http.put<void>(
            `${this.baseApiUrl}/Students/${student.id}/readingconfiguration`,
            {
                ReadingConfiguration: studentReadingConfiguration,
                Value: isHidden
            }
        );
    }

    changeSchoolClassStudentReadingConfiguration(schoolClassId: number, isHidden: boolean, studentReadingConfiguration: StudentReadingConfigurationEnum): Observable<void> {
        return this.http.put<void>(
            `${this.baseApiUrl}/SchoolClasses/${schoolClassId}/students/readingconfigurations`,
            {
                ReadingConfiguration: studentReadingConfiguration,
                Value: isHidden
            }
        );
    }

    getStudentDetailRoute(student: StudentModel): Array<string | number> {
        const route = [
            '/lehrer',
            'lerngruppe',
            'entwicklung',
            student.id,
            student.userName
                .toLowerCase()
                .replace(' ', '-')
                .replace('ä', 'ae')
                .replace('ö', 'oe')
                .replace('ü', 'ue')
                .replace('ß', 'ss')
        ];

        return route;
    }
}
