import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss']
})
export class LoadMoreComponent implements OnInit {

    /**
     * total length of the array to show
     */
    @Input()
    length = 0;

    /**
     * count of how many elements added per new section
     */
    @Input()
    pageSteps: number;

    /**
     * total elements currently visible
     */
    @Input()
    pageSize: number;

    @Output()
    page = new EventEmitter<{ length: number, pageSize: number }>();

    showLoadMore: boolean = false;

    get showLess(): boolean {
        return this.length <= this.pageSize;
    }

    constructor() { }

    ngOnInit(): void {
        const lessThanMinimumElements = this.length < this.pageSize;
        this.showLoadMore = !lessThanMinimumElements;
    }

    loadMore(): void {
        this.setPageSize();
        this.page.emit({ length: this.length, pageSize: this.pageSize });
    }

    /**
     * either resets pageSize if we can't load more
     * or increments pageSize by pageSteps
     */
    private setPageSize() {
        if (this.showLess) {
            this.pageSize = this.pageSteps;
        } else {
            this.pageSize += this.pageSteps;
        }
    }
}
