import {
    Component, EventEmitter, Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from 'shared/services';
import { logout } from 'store/user';

@Component({
    selector: 'app-signed-out-popup',
    templateUrl: './signed-out-popup.component.html',
    styleUrls: ['./signed-out-popup.component.scss']
})
export class SignedOutPopupComponent {

    @Output()
    readonly dismissPopup: EventEmitter<void> = new EventEmitter();

    constructor(private authServive: AuthService, private store: Store) { }

    signIn(): void {
        this.authServive.oidcUserLogin(this.authServive.currentLoginProvider, null);
        this.dismissPopup.emit();
    }

    signOut(): void {
        this.store.dispatch(logout({redirectUri: 'login'}));
        this.dismissPopup.emit();
    }
}
