import { Component, Input } from '@angular/core';
import { Colors } from 'shared/helper';

@Component({
    selector: 'app-donut-progress',
    templateUrl: './donut-progress.component.html',
    styleUrls: ['./donut-progress.component.scss']
})
export class DonutProgressComponent {
    readonly colors = Colors;

    @Input()
    percentValue = 0;

    @Input()
    strokeColor = this.colors.colorBlack;

    @Input()
    strokeBackgroundColor = this.colors.colorGraphiteLight;

    get blankValue(): number {
        if (this.isValueLoaded === false) {
            return 0;
        }

        return 100 - this.percentValue;
    }

    get isValueLoaded(): boolean {
        return this.percentValue != null;
    }

    constructor() {}
}
