export interface GlobalNotification {
    id: number;
    headline: string;
    text: string;
    publicationDate: Date;
    linkText: string;
    targetUrl: string;
    isNewWindow: boolean;
    isViewedByUser: boolean;
}
