import { createReducer, on } from '@ngrx/store';
import { AccountType, BackdropColor, CvPopUpSettings, defaultMonsterKey, MaintenanceModel, PopoverSettings, StudentModel } from 'shared/models';
import { GlobalNotification } from 'shared/models/global-notification.model';
import * as userActions from 'store/user/user.actions';
import * as layoutActions from './layout.actions';

export interface LayoutPopupState {
    isVisible: boolean;
    settings: CvPopUpSettings;
}

export interface ContextMenuState {
    isVisible: boolean;
    channel: string;
}

export interface BackdropState {
    isVisible: boolean;
    color: BackdropColor;
    closable: boolean;
}

export interface PopoverState {
    isVisible: boolean;
    settings: PopoverSettings;
}

export interface LoadingIndicatorState {
    isVisible: boolean;
    isTriggeredByRouter: boolean;
    message: string | null;
}

interface SettingsStoreState {
    maintenance: MaintenanceModel;
    loading: boolean;
    loaded: boolean;
}

export interface LayoutState {
    backdrop: BackdropState;
    contextMenu: ContextMenuState;
    isUserSidebarOpen: boolean;
    isMenuSidebarOpen: boolean;
    submenuTitle: string;
    globalNotifications: {
        notifications: GlobalNotification[];
        viewedAt?: Date;
        loading: boolean;
        loaded: boolean;
    };
    loadingIndicator: LoadingIndicatorState;
    popover: PopoverState;
    popup: LayoutPopupState;
    settings: SettingsStoreState;
    showStudentOnboarding: boolean;
    uiLocked: boolean;
}

const initStatePopover: PopoverState = {
    isVisible: false,
    settings: { type: null }
};

const initStateLoadingIndicator: LoadingIndicatorState = {
    isVisible: false,
    isTriggeredByRouter: false,
    message: null
};

const initStateSettings: SettingsStoreState = {
    maintenance: {
        active: false,
        infotext: '',
        startDateTime: null,
        endDateTime: null
    },
    loading: false,
    loaded: false
};

export const initialState: LayoutState = {
    backdrop: {
        isVisible: false,
        color: null,
        closable: true
    },
    contextMenu: { isVisible: false, channel: null },
    isUserSidebarOpen: false,
    isMenuSidebarOpen: false,
    submenuTitle: '',
    globalNotifications: {
        notifications: [],
        viewedAt: null,
        loaded: false,
        loading: false
    },
    loadingIndicator: initStateLoadingIndicator,
    popover: initStatePopover,
    popup: {
        isVisible: false,
        settings: <any>{}
    },
    settings: initStateSettings,
    showStudentOnboarding: null,
    uiLocked: false,
};

const _layoutReducer = createReducer(
    initialState,
    on(userActions.loadProfileSuccess, (state, action) => {
        if (action.accountType === AccountType.Student) {
            const { profile } = action;
            const newState: LayoutState = {
                ...state,
                showStudentOnboarding: _showStudentOnboarding(profile as StudentModel)
            };

            return newState;
        }

        return state;
    }),
    on(layoutActions.toggleUserSidebar, (state) => ({...state, isUserSidebarOpen: !state.isUserSidebarOpen})),
    on(layoutActions.toggleMenuSidebar, (state) => ({...state, isMenuSidebarOpen: !state.isMenuSidebarOpen})),
    on(layoutActions.closeMenuSidebar, (state) => ({...state, isMenuSidebarOpen: false})),
    on(layoutActions.setSubmenuTitle, (state, action) => ({...state, submenuTitle: action.submenuTitle})),
    on(
        layoutActions.closeUserSidebar,
        layoutActions.showGlobalNotifications,
        (state) => ({...state, isUserSidebarOpen: false})
    ),
    on(layoutActions.finishStudentOnboarding, (state, action) => {
        const newState: LayoutState = {
            ...state,
            showStudentOnboarding: false
        };

        return newState;
    }),
    on(layoutActions.openPopup, (state, action) => {
        const newState: LayoutState = {
            ...state,

            popup: {
                ...state.popup,

                isVisible: true,
                settings: action.settings
            }
        };

        return newState;
    }),
    on(
        layoutActions.closePopup,
        layoutActions.toggleUserSidebar,
        (state, action) => {
        const newState: LayoutState = {
            ...state,

            popup: {
                ...state.popup,

                isVisible: false
            }
        };

        return newState;
    }),
    on(layoutActions.showBackdrop, (state, action) => {
        const newState: LayoutState = {
            ...state,

            backdrop: {
                isVisible: true,
                color: action.color,
                closable: action.closable
            }
        };

        return newState;
    }),
    on(layoutActions.hideBackdrop, (state, action) => {
        if (state.uiLocked === true) {
            // don't close UI-locked backdrop!
            return state;
        }

        const newState: LayoutState = {
            ...state,

            backdrop: {
                ...state.backdrop,

                isVisible: false,
                closable: true,
                color: null
            }
        };

        return newState;
    }),
    on(layoutActions.showPopover, (state, action) => {
        const newState: LayoutState = {
            ...state,

            popover: {
                ...state.popover,

                isVisible: true,
                settings: action.settings
            }
        };

        return newState;
    }),
    on(layoutActions.hidePopover, (state, action) => {
        const newState: LayoutState = {
            ...state,

            popover: initStatePopover
        };

        return newState;
    }),
    on(layoutActions.lockUi, (state, action) => {
        const newState: LayoutState = {
            ...state,

            uiLocked: true
        };

        return newState;
    }),
    on(layoutActions.unlockUi, userActions.activateLicenceSuccess, (state, action) => {
        const newState: LayoutState = {
            ...state,

            uiLocked: false
        };

        return newState;
    }),
    on(layoutActions.showLoadingIndicator, (state, action) => {
        const { loadingIndicator } = state;

        if (loadingIndicator.isVisible) {
            return state;
        }

        const newState: LayoutState = {
            ...state,

            loadingIndicator: {
                ...loadingIndicator,

                isVisible: true,
                isTriggeredByRouter: action.isTriggeredByRouter,
                message: action.message
            }
        };

        return newState;
    }),
    on(layoutActions.hideLoadingIndicator, (state, action) => {
        const { loadingIndicator } = state;

        if (!loadingIndicator.isVisible ||
            loadingIndicator.isTriggeredByRouter !== action.isTriggeredByRouter) {
            return state;
        }

        const newState: LayoutState = {
            ...state,

            loadingIndicator: initStateLoadingIndicator
        };

        return newState;
    }),
    on(layoutActions.fetchSettings, (state, action) => {
        const newState: LayoutState = {
            ...state,

            // reset settings
            settings: {
                ...initStateSettings,
                loading: true
            }
        };

        return newState;
    }),
    on(layoutActions.fetchSettingsSuccess, (state, action) => {
        const newState: LayoutState = {
            ...state,

            settings: {
                maintenance:
                    <MaintenanceModel>action.settings.maintenance ||
                    state.settings.maintenance,
                loading: false,
                loaded: true
            }
        };

        return newState;
    }),
    on(layoutActions.fetchSettingsFail, (state, action) => {
        const newState: LayoutState = {
            ...state,

            settings: {
                ...state.settings,
                loading: false,
                loaded: true
            }
        };

        return newState;
    }),
    on(layoutActions.hideMaintenance, (state, action) => {
        const newState: LayoutState = {
            ...state,

            settings: {
                ...state.settings,
                maintenance: {
                    ...state.settings.maintenance,
                    active: false
                }
            }
        };

        return newState;
    }),
    on(layoutActions.getGlobalNotifications, (state, action) => {
        return {
            ...state,
            globalNotifications: {
                ...state.globalNotifications,
                loading: true
            }
        };
    }),
    on(layoutActions.getGlobalNotificationsSuccess, (state, action) => {
        return {
            ...state,
            globalNotifications: {
                ...state.globalNotifications,
                notifications: action.globalNotifications,
                loading: false,
                loaded: true,
            }
        };
    }),
    on(layoutActions.setGlobalNotificationViewedSuccess, (state, { notificationsIds }) => {
        const notifications = state.globalNotifications.notifications.map(notification => {
            if (notificationsIds.includes(notification.id)) {
                return {
                    ...notification,
                    isViewedByUser: true
                };
            }

            return notification;
        });

        return {
            ...state,
            globalNotifications: {
                ...state.globalNotifications,
                notifications
            }
        };
    }),
    on(layoutActions.showGlobalNotifications, (state, action) => {
        return {
            ...state,
            globalNotifications: {
                ...state.globalNotifications,
                viewedAt: new Date() // todo: should be taken from action instead
            }
        };
    })
);

function _showStudentOnboarding(profile: StudentModel): boolean {
    return profile.avatarKey === null || profile.avatarKey === defaultMonsterKey;
}

export function layoutReducer(state, action) {
    return _layoutReducer(state, action);
}
