import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 036_Gruppenarbeit_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-group',
    template: `
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
    <path
        d="M36.844,12.756A7.087,7.087,0,1,0,43.93,5.669,7.095,7.095,0,0,0,36.844,12.756Zm11.338,0A4.252,4.252,0,1,1,43.93,8.5,4.256,4.256,0,0,1,48.182,12.756ZM21.253,18.425a7.087,7.087,0,1,0,7.087-7.087A7.095,7.095,0,0,0,21.253,18.425Zm11.339,0a4.252,4.252,0,1,1-4.252-4.252A4.257,4.257,0,0,1,32.592,18.425ZM12.749,5.669a7.087,7.087,0,1,0,7.087,7.087A7.095,7.095,0,0,0,12.749,5.669Zm0,11.339A4.252,4.252,0,1,1,17,12.756,4.256,4.256,0,0,1,12.749,17.008Zm35.433,5.668h-7.8a9.15,9.15,0,0,0-8.958,7.087H25.25a9.15,9.15,0,0,0-8.958-7.087H8.5a8.136,8.136,0,0,0-8.5,8.5V43.937a1.417,1.417,0,0,0,1.418,1.417H15.584v4.252A1.417,1.417,0,0,0,17,51.024H39.678A1.417,1.417,0,0,0,41.1,49.606V45.354H55.269a1.417,1.417,0,0,0,1.417-1.417V31.18A8.136,8.136,0,0,0,48.182,22.676Zm-32.6,15.591V42.52H8.5v-8.5a1.418,1.418,0,1,0-2.835,0v8.5H2.828V31.18A5.312,5.312,0,0,1,8.5,25.511h7.8a6.336,6.336,0,0,1,6.064,4.4A8.058,8.058,0,0,0,15.584,38.267Zm22.677,9.922H35.427v-8.5a1.418,1.418,0,0,0-2.835,0v8.5h-8.5v-8.5a1.417,1.417,0,1,0-2.834,0v8.5H18.418V38.267a5.313,5.313,0,0,1,5.67-5.669h8.5a5.312,5.312,0,0,1,5.669,5.669Zm15.59-5.669H51.016v-8.5a1.417,1.417,0,1,0-2.834,0v8.5H41.1V38.267a8.058,8.058,0,0,0-6.772-8.354,6.336,6.336,0,0,1,6.064-4.4h7.8a5.312,5.312,0,0,1,5.669,5.669Z" />
    </svg>
    `
})
export class SvgIconGroupComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'group';
    }
}
