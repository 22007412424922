import { createAction, props, Action } from '@ngrx/store';

export const startPolling = createAction(
    '[Polling] start polling',
    props<{ pollingAction: Action; interval: number; startImmediately: boolean }>()
);

export const failedPolling = createAction(
    '[Polling] failed polling'
);

export const stopPolling = createAction('[Polling] stop polling');

export const restartPolling = createAction(
    '[Polling] restart polling',
    props<{ pollingAction: Action; interval: number }>()
);

export const switchToErrorMode = createAction(
    '[Polling] switch to error-mode'
);

export const resetErrors = createAction('[Polling] reset errors');
