import { AssignmentStatus } from './assignment.model';
import { ExerciseModel } from './exercise.model';
import { StrategyModel } from './strategy.model';

export interface ReadingLevelDetails {
    readingLevel: number;
    averageScore: number;
}

export interface ReadingLevelDetailsDict {
    [id: number]: ReadingLevelDetails;
}

export interface ReadingLevelProgression {
    startDate: Date;
    startReadingLevel: number;
    data: { startDate: Date, level: number }[];
}

export interface StudentDetails {
    totalScore: number;
    maxScore: number;
    correctAnswersInPercent: number;
    readingLevels: ReadingLevelDetailsDict;
    assignments: StudentDetailAssignment[];
    readingLevelProgression: ReadingLevelProgression;
    teacherNote: string;
    readBooks: number[];
    openedBooks: number[];
}

export interface StudentDetailAssignment {
    assignmentId: number;
    assignedExercises: StudentDetailExercise[];
    strategies: StudentDetailStrategy[];
    bookId: number;
    dueDate: Date;
    doneDate: Date;
    status: AssignmentStatus;
    totalScore: number;
    maxScore: number;
    averageScore: number;
}

export interface StudentDetailExercise extends ExerciseModel {
    score: number;
    status: AssignmentStatus;

    isAssigned: boolean;

    doneDate: Date;
}

export interface StudentDetailStrategy extends StrategyModel {
    status: AssignmentStatus;
    doneDate: Date;
}

export interface ReadingLevelChartDetail {
    formatedData: string;
    gridLabelWidth: number;
    gridLabelHeight: number;
    hasYear: boolean;
    date: Date;
}

export interface ReadingLevelHistory {
    readingLevel: number;
    x: number;
    w: number;
    startDate: Date;
    endDate: Date;
}