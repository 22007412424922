import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
    transform(input: string, oldValue: string, newValue: string): string {
        if (!oldValue || !newValue) {
            return input;
        }

        return input.replace(oldValue, newValue);
    }
}
