import { Injectable } from '@angular/core';
import { WindowService } from './window.service';

/**This service is intended to communicate with Hotjar
 */
@Injectable()
export class HotjarService {
    constructor(private windowService: WindowService) { }

    /**This method sends private_user_logged_in event to Hotjar
     * and Hotjar will show our feedback widget for private users upon receiving it
     */
    sendPrivateUserLoginEvent() {
        this.windowService.nativeWindow.hj && this.windowService.nativeWindow.hj('event', 'private_user_logged_in');
    }

    /**This method sends teacher_logged_in event to Hotjar
     * and Hotjar will show our standard feedback widget for teachers upon receiving it
     */
    sendTeacherLoginEvent() {
        this.windowService.nativeWindow.hj && this.windowService.nativeWindow.hj('event', 'teacher_logged_in');
    }

    /**This method sends notification that url is changed to Hotjar
     * so that it can reevaluate if its needed to show the widget or not
     * @param redirectUri url to redirect to
     */
    sendUrlChangedEvent(redirectUri: string) {
        this.windowService.nativeWindow.hj && this.windowService.nativeWindow.hj('stateChange', redirectUri);
    }
}
