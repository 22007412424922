import { Injectable } from '@angular/core';
import { SortDirection } from 'shared/models';

@Injectable()
export class SortingService {
    constructor() {}

    sortBy<T>(list: T[], property: keyof T, direction: SortDirection): T[] {
        const sortedList = list.sort((a, b) => {
            if (direction === 'desc') {
                return a[property] > b[property] ? -1 : 1;
            }

            return a[property] < b[property] ? -1 : 1;
        });

        return sortedList;
    }
}
