import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { PopupChannels, RedirectionHelper } from 'shared/helper';
import {
    AccountType, AssignmentModel,
    BackdropColor,
    SchoolClassModel,
    StudentModel, TeacherLightModel
} from 'shared/models';
import { ExerciseService, GlobalNotificationService, SchoolService, SettingsService, TeacherService, TrackingSource } from 'shared/services';
import { TeacherUsedGlobalNotificationFeature } from 'shared/tracking/models/teacher-used-global-notification-feature.model';
import { DeleteAssignmentFail, DeleteAssignmentSuccess } from 'store/assignments/assignments.actions';
import * as BooksActions from 'store/books/books.actions';
import { RootState } from 'store/root.reducer';
import * as SchoolClassesActions from 'store/school-classes/school-classes.actions';
import { resetSchoolClassCodesFail, resetSchoolClassCodesSuccess } from 'store/school-classes/school-classes.actions';
import * as SchoolActions from 'store/school/school.actions';
import * as StudentsActions from 'store/students/students.actions';
import { selectAllStudents } from 'store/students/students.selector';
import { getAccountType } from 'store/user';
import { loadProfile, registerTeacher } from 'store/user/user.actions';
import * as TrackingActions from '../tracking/tracking.actions';
import * as layoutActions from './layout.actions';
import {
    getBackdrop,
    getIsPopupVisible, getIsUiLocked, getPopover
} from './layout.selector';

@Injectable()
export class LayoutEffects {
    clickOnBackdrop$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.clickOnBackdrop),
        withLatestFrom(
            this.store$.pipe(select(getBackdrop)),
            this.store$.pipe(select(getIsPopupVisible)),
            (action, backdrop, isPopupVisible) => ({
                backdrop,
                isPopupVisible
            })
        ),
        filter(
            ({ backdrop }) =>
                backdrop.closable === true && backdrop.isVisible === true
        ),
        switchMap(({ isPopupVisible }) => {
            const actions: Action[] = [layoutActions.hideBackdrop()];

            if (isPopupVisible === true) {
                actions.push(layoutActions.closePopup());
            }

            return actions;
        })
    ));

    hideBackdrop$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.hideBackdrop),
        withLatestFrom(
            this.store$.pipe(select(getIsUiLocked)),
            (action, isUiLocked) => isUiLocked
        ),
        filter(isUiLocked => isUiLocked === true),
        map(() => layoutActions.lockUi())
    ));

    openPopup$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.openPopup),
        map(({settings}) => layoutActions.showBackdrop(BackdropColor.Black, settings.closable))
    ));

    closePopup$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.closePopup, layoutActions.toggleUserSidebar),
        map(() => layoutActions.hideBackdrop())
    ));

    popupConfirmed$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.confirmPopup),
        switchMap(({ payload }) => {
            switch (payload.channel) {
                case PopupChannels.ResetAllCodesConfirmation: {
                    const schoolClass = payload.data as SchoolClassModel;

                    return this.teacherService
                        .resetAllStudentCodes(schoolClass.id)
                        .pipe(
                            map(
                                codes => resetSchoolClassCodesSuccess({ schoolClassId: schoolClass.id, result: codes })
                            ),
                            catchError((error: unknown) =>
                                of(resetSchoolClassCodesFail({ error }))
                            )
                        );
                }

                case PopupChannels.DeleteStudentConfirmation: {
                    const { student, source } = payload.data as {
                        student: StudentModel;
                        source: TrackingSource;
                    };

                    return this.teacherService.deleteStudent(student.id).pipe(
                        map(() =>
                            StudentsActions.deleteStudentSuccess({
                                student,
                                source
                            })
                        ),
                        catchError((error: unknown) =>
                            of(StudentsActions.deleteStudentFail({ error }))
                        )
                    );
                }

                case PopupChannels.DeleteSchoolClassConfirmation: {
                    const { schoolClass, source } = payload.data as {
                        source: TrackingSource;
                        schoolClass: SchoolClassModel;
                    };

                    return this.teacherService
                        .deleteSchoolClass(schoolClass.id)
                        .pipe(
                            tap(() => {
                                if (
                                    source ===
                                    TrackingSource.SchoolAdminDashboard
                                ) {
                                    // navigate back to teachers list
                                    this.router.navigate([
                                        '/lehrer/meine-schule/lehrer'
                                    ]);
                                }
                            }),
                            withLatestFrom(
                                this.store$.pipe(select(selectAllStudents)),
                                (_, students) => ({
                                    studentIds: students.map(s => s.id)
                                })
                            ),
                            map(({ studentIds }) =>
                                SchoolClassesActions.deleteSchoolClassSuccess({
                                    schoolClass,
                                    studentIds
                                })
                            ),
                            catchError((error: unknown) =>
                                of(
                                    SchoolClassesActions.deleteSchoolClassFail({
                                        error
                                    })
                                )
                            )
                        );
                }

                case PopupChannels.ResetStudentCodeConfirmation: {
                    const student = payload.data as StudentModel;

                    return this.teacherService
                        .resetStudentCode(student.id)
                        .pipe(
                            map(studentCode => StudentsActions.resetStudentCodeSuccess({ student, newCode: studentCode })),
                            catchError((error: unknown) => of(StudentsActions.resetStudentCodeFail({ error })))
                        );
                }

                case PopupChannels.DeleteAssignmentConfirmation: {
                    const assignment = payload.data as AssignmentModel;

                    return this.exerciseService
                        .deleteAssignment(assignment.id)
                        .pipe(
                            map(() => new DeleteAssignmentSuccess(assignment)),
                            catchError((error: unknown) =>
                                of(new DeleteAssignmentFail(error))
                            )
                        );
                }

                case PopupChannels.DeleteTeacherConfirmation: {
                    const { teacher } = payload.data as {
                        teacher: TeacherLightModel;
                        source: TrackingSource;
                    };

                    return this.schoolService.deleteTeacher(teacher.id).pipe(
                        map(() =>
                            SchoolActions.deleteTeacherSuccess({ teacher })
                        ),
                        catchError((error: unknown) =>
                            of(SchoolActions.deleteTeacherFail({ error }))
                        )
                    );
                }
            }
        })
    ));

    showPopover$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.showPopover),
        withLatestFrom(
            this.store$.pipe(select(getPopover)),
            ({settings}, inlineNotification) => ({
                settings,
                inlineNotification
            })
        ),
        filter(data => {
            return data.inlineNotification.settings.autoClose !== null;
        }),
        delay(3000),
        map(data => layoutActions.hidePopover())
    ));

    lockUi$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.lockUi),
        withLatestFrom(this.store$.pipe(select(getAccountType))),
        tap(([_, accountType]) => this.router.navigate([RedirectionHelper.getDashboardRoute(accountType)])),
        map(() => layoutActions.showBackdrop(BackdropColor.Gray, false)),
    ));

    unlockUi$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.unlockUi),
        map(() => layoutActions.hideBackdrop())
    ));

    loadProfile$ = createEffect(() => this.actions$.pipe(
        ofType(loadProfile),
        filter((action) => action.firstTime === true && action.accountType === AccountType.Teacher),
        map((action) => layoutActions.showLoadingIndicator(action.accountType, false))
    ));

    registerTeacher$ = createEffect(() => this.actions$.pipe(
        ofType(registerTeacher),
        map(_ => layoutActions.showLoadingIndicator(AccountType.Teacher, false)))
    );

    hideLoadingIndicatorTeacher$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.showLoadingIndicator),
        filter(({ accountType, isTriggeredByRouter }) => accountType === AccountType.Teacher && !isTriggeredByRouter),
        concatMap(() =>
            this.completedFillStoreActionsTeacher().pipe(
                map(() => layoutActions.hideLoadingIndicator({ isTriggeredByRouter: false })),
                catchError((error: unknown) => of(layoutActions.loadingError({error})))
            )
        )
    ));

    // // check error now
    // @Effect()
    // hideLoadingIndicatorStudent$ = this.actions$.pipe(
    //     ofType(LayoutActionTypes.ShowLoadingIndicator),
    //     filter(
    //         ({ accountType, isTriggeredByRouter }: ShowLoadingIndicator) =>
    //             accountType === AccountType.Student && !isTriggeredByRouter
    //     ),
    //     concatMap(() =>
    //         this.completedFillStoreActionsStudent().pipe(
    //             map(() => new HideLoadingIndicator(false)),
    //             catchError(err => of(new LoadingError(err)))
    //         )
    //     )
    // );

    loadingError$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.loadingError),
        map(() => layoutActions.hideLoadingIndicator({ isTriggeredByRouter: false })),
        tap(() => this.router.navigate(['profil-fehler']))
    ));

    fetchSettings$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.fetchSettings),
        switchMap(() =>
            this.settingsService.getSettings().pipe(
                map(settings => layoutActions.fetchSettingsSuccess({settings})),
                catchError((error: unknown) => of(layoutActions.fetchSettingsFail({error})))
            )
        )
    ));

    getGlobalNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.getGlobalNotifications),
        switchMap(() => this.globalNotificationsService.getGlobalNotifications().pipe(
            map(globalNotifications => layoutActions.getGlobalNotificationsSuccess({ globalNotifications }))
        ))
    ));

    getGlobalNotificationToken$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.getGlobalNotificationToken),
        map(() => {
            const viewedAt = this.globalNotificationsService.getGlobalNotificationToken();

            return layoutActions.getGlobalNotificationTokenSuccess({ viewedAt });
        })
    ));

    setGlobalNotificationToken$ = createEffect(() => this.actions$.pipe(
        ofType(layoutActions.showGlobalNotifications),
        tap(() => {
            this.globalNotificationsService.setGlobalNotificationToken();
        }),
        map((_) => TrackingActions.teacherUsedGlobalNotificationFeature({
                    event: new TeacherUsedGlobalNotificationFeature()
                })
            )
    ));

    hideStudentOnboarding$ = createEffect(() => this.actions$.pipe(
        ofType(
            layoutActions.finishStudentOnboarding,
            layoutActions.skipStudentOnboarding
        ),
        tap(() => this.router.navigate(['/', 'schueler']))
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private store$: Store<RootState>,
        private teacherService: TeacherService,
        private exerciseService: ExerciseService,
        private schoolService: SchoolService,
        private router: Router,
        private settingsService: SettingsService,
        private globalNotificationsService: GlobalNotificationService
    ) { }

    private completedFillStoreActionsTeacher(): Observable<any[]> {
        return this.joinActions([
            [
                BooksActions.fetchBooksSuccess.type,
                BooksActions.fetchBooksFail.type
            ],
            [
                SchoolClassesActions.fetchSchoolClassesSuccess.type,
                SchoolClassesActions.fetchSchoolClassesFail.type
            ]
        ]);
    }

    private joinActions(arr: string[][]): Observable<Action[]> {
        const completed = arr.map(a => {
            return this.actions$.pipe(ofType(a[0], a[1]), take(1));
        });

        return forkJoin(completed);
    }
}
