import { createAction, props } from '@ngrx/store';
import { Sort, TeacherNotificationModel, StudentNotificationModel } from 'shared/models';

export const fetchNotifications = createAction(
    '[Notifications] fetch notifications',
    props<{ cursor?: number, limit?: number, sort?: Sort }>()
);

export const fetchNotificationsSuccess = createAction(
    '[Notifications] fetch notifications success',
    props<{ notifications: StudentNotificationModel[] }>()
);

export const fetchNotificationsFail = createAction(
    '[Notifications] fetch notifications fail',
    props<{ error: any }>()
);

export const fetchTeacherNotifications = createAction(
    '[Notifications] fetch teacher notifications',
    props<{ cursor?: number, limit?: number, sort?: Sort }>()
);

export const fetchTeacherNotificationsSuccess = createAction(
    '[Notifications] fetch teacher notifications success',
    props<{ notifications: TeacherNotificationModel[] }>()
);

export const fetchTeacherNotificationsFail = createAction(
    '[Notifications] fetch teacher notifications fail',
    props<{ error: any }>()
);

export const markAsRead = createAction(
    '[Notifications] mark as read',
    props<{ id: number }>()
);

export const markAsReadSuccess = createAction(
    '[Notifications] mark as read success',
    props<{ id: number }>()
);

export const markAsReadFail = createAction(
    '[Notifications] mark as read fail',
    props<{ error: any }>()
);
