import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, filter, firstValueFrom, map, Subject, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import { ConversationService } from 'shared/services';
import { selectConversationsHasUnreadMessages } from 'store/conversations/conversations.selector';
import { RootState } from 'store/root.reducer';
import { getAllSchoolClassesList, getSchoolClass, selectSchoolClass } from 'store/school-classes';
import { hasSchoolClassManagementPermissions } from 'store/user';

@Component({
    selector: 'cv-study-group-selector',
    templateUrl: './cv-study-group-selector.component.html',
    styleUrls: ['./cv-study-group-selector.component.scss']
})
export class CvStudyGroupSelectorComponent implements OnInit, OnDestroy {
    private readonly ngUnsubscribe = new Subject<void>();

    @Output()
    clickedItem = new EventEmitter<void>();

    schoolClass$ = this.store.select(getSchoolClass);
    allSchoolClasses$ = this.store.select(getAllSchoolClassesList).pipe(
        tap(allSchoolClasses => allSchoolClasses.sort((groupA, groupB) => groupA.name.localeCompare(groupB.name))), // sort groups alphabetically
        takeUntil(this.ngUnsubscribe)
    );
    allGroupsNewMessages: { number: boolean } | {} = {};
    hasSchoolClassManagementpermissions$ = this.store.select(hasSchoolClassManagementPermissions);

    constructor(
        private store: Store<RootState>,
        private conversationService: ConversationService,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.allSchoolClasses$.pipe(
            withLatestFrom(this.store.select(selectConversationsHasUnreadMessages)),
            filter(([_, hasUnreadMessages]) => hasUnreadMessages),
            switchMap(([schoolClasses, _]) => {
                return combineLatest(
                    schoolClasses.map(schoolClass => {
                        return this.conversationService.getConversations(schoolClass.id).pipe(
                            map(conversations => {
                                this.allGroupsNewMessages[schoolClass.id] = conversations.some(conversation => conversation.isNew);
                            }),
                            takeUntil(this.ngUnsubscribe)
                        );
                    })
                );
            })).subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async selectSchoolClass(schoolClassId: number) {
        const { id } = await firstValueFrom(this.schoolClass$);
        if (id !== schoolClassId) {
            this.store.dispatch(selectSchoolClass({ schoolClassId }));
        }

        this.closeStudyGroupSelector();
    }

    closeStudyGroupSelector() {
        this.clickedItem.emit();
    }

}
