import { Component, OnInit, Input } from '@angular/core';
import { NotificationType } from 'shared/models';

@Component({
    selector: 'app-send-message-confirmation',
    templateUrl: './send-message-confirmation.component.html',
    styleUrls: ['./send-message-confirmation.component.scss']
})
export class SendMessageConfirmationComponent implements OnInit {

    @Input()
    userName: string;

    @Input()
    typeOfNotification: NotificationType;

    sticker: string;

    constructor() { }

    ngOnInit(): void {
        this.setSticker();
    }

    private setSticker() {
        switch (this.typeOfNotification) {
            case NotificationType.Expectations:
                this.sticker = 'leseerwartungen';
                break;

            case NotificationType.PriorKnowledge:
                this.sticker = 'vorwissen';
                break;

            case NotificationType.LevelUp:
                this.sticker = 'berg';
                break;

            case NotificationType.LevelDown:
                this.sticker = 'herz';
                break;

            case NotificationType.MaxScore:
            default:
                this.sticker = 'stern';
                break;
        }
    }

}
