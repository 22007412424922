import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherAuthenticatedIdentity extends BaseTrackingEvent{
    constructor(isLicenceActive: boolean, identityTitle: string = '', identityId: number = 0, schoolId: string = '') {
        super({
            eventId: '0b9aaa22-fde9-4bee-9151-13453a57ba7a',
            eventVerb: TrackingVerbs.AUTHENTICATED,
            eventObject: TrackingObjects.IDENTITY,
            eventPayload: {
                identityTitle: identityTitle,
                identityId: identityId,
                identityIsLicenceActive: isLicenceActive,
                identitySchoolId: schoolId
            },
        });
        this.sendUserId = true;
    }
}
