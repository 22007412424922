import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AccountType, BookModel, FilterCategory } from 'shared/models';
import { BooksService } from 'shared/services';
import { BookFilterTrackingPayload, UserUsedBookFilter } from 'shared/tracking/models/user-used-book-filter.model';
import { UserAddedFavorite } from 'shared/tracking/models/user-added-favorite.model';
import { UserDeletedFavorite } from 'shared/tracking/models/user-deleted-favorite.model';
import * as BooksActions from 'store/books/books.actions';
import { fillStore, getAccountType, getStudentReadingLevel } from 'store/user';
import { getAllBooksDict, selectBookSearch } from '.';
import * as TrackingActions from '../tracking/tracking.actions';
import { BookHelper } from 'shared/helper';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class BooksEffects {
    private static readonly recommendedBookKey = 'recommended-book';

    fetchBooksOnInitialPageLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fillStore),
            filter(({ firstTime }) => firstTime),
            map(_ => BooksActions.fetchBooks())
        )
    );

    fetchBooks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.fetchBooks),
            exhaustMap(() =>
                this.booksService.getBooks().pipe(
                    map(lib => {
                        return BooksActions.fetchBooksSuccess({
                            books: lib.books,
                            filterOptions: lib.filters,
                        });
                    }),
                    catchError((error: unknown) =>
                        of(BooksActions.fetchBooksFail({ error }))
                    )
                )
            )
        )
    );

    filterBooksForStudentByReadingLevel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.fetchBooksSuccess),
            withLatestFrom(this.store.pipe(select(getStudentReadingLevel)), this.store.pipe(select(getAccountType))),
            filter(([_, readingLevel, accountType]) => accountType === AccountType.Student),
            map(([{ filterOptions }, readingLevel]) => {
                const allReadingLevels = filterOptions[0] as {
                    name: string,
                    values: { key: number, value: string }[]
                };
                const readingLevels = allReadingLevels.values.filter(rl => Number(rl.value) >= readingLevel).map(rl => rl.value);
                return BooksActions.setDefaultFiltersForStudent({ readingLevels });
            })
        ));

    fetchBookDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                BooksActions.fetchBookDetails,
                BooksActions.fetchBookDetailsForAssignmentPage
            ),
            exhaustMap(({ bookId }) =>
                this.booksService.getBookDetail(bookId).pipe(
                    map(bookDetail =>
                        BooksActions.fetchBookDetailsSuccess({ bookDetail })
                    ),
                    catchError((error: unknown) =>
                        of(
                            BooksActions.fetchBookDetailsFail({
                                bookId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    fetchRecommendedBook$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.fetchRecommendedBook),
            switchMap(() => {
                const bookFromSessionStore =
                    this.getRecommendedBookFromSessionStore();

                if (!bookFromSessionStore) {
                    // load from backend
                    return this.booksService.getRecommendedBook().pipe(
                        tap(b =>
                            sessionStorage.setItem(
                                BooksEffects.recommendedBookKey,
                                JSON.stringify(b)
                            )
                        ),
                        map(b =>
                            BooksActions.fetchRecommendedBookSuccess({
                                book: b as BookModel,
                            })
                        ),
                        catchError((error: unknown) =>
                            of(BooksActions.fetchRecommendedBookFail({ error }))
                        )
                    );
                }

                // load from session-storage
                const result = BooksActions.fetchRecommendedBookSuccess({
                    book: bookFromSessionStore,
                });
                return of(result);
            })
        )
    );

    setFavorite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.setFavorite),
            switchMap(({ bookId }) =>
                this.booksService.setFavorite(bookId).pipe(
                    map(() => BooksActions.setFavoriteSuccess({ bookId })),
                    catchError((error: unknown) =>
                        of(BooksActions.setFavoriteFail({ error }))
                    )
                )
            )
        )
    );

    setFavoriteSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.setFavoriteSuccess),
            withLatestFrom(this.store.pipe(select(getAllBooksDict))),
            map(([{ bookId }, allBooks]) => allBooks.dict[bookId]),
            map((book) => {
                return TrackingActions.userAddedFavorite({
                    event: new UserAddedFavorite(book.title, book.isbn),
                });
            })
        )
    );

    removeFavorite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.removeFavorite),
            switchMap(({ bookId }) => {
                return this.booksService.removeFavorite(bookId).pipe(
                    map(() => BooksActions.removeFavoriteSuccess({ bookId })),
                    catchError((error: unknown) =>
                        of(BooksActions.removeFavoriteFail({ error }))
                    )
                );
            })
        )
    );

    removeFavoriteSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.removeFavoriteSuccess),
            withLatestFrom(this.store.pipe(select(getAllBooksDict))),
            map(([{ bookId }, allBooks]) => allBooks.dict[bookId]),
            map((book) => {
                return TrackingActions.userDeletedFavorite({
                    event: new UserDeletedFavorite(book.title, book.isbn),
                });
            })
        )
    );

    toggleFilterTracking$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.toggleFilter),
            filter(filter => filter.filterCategory != FilterCategory.Title),
            withLatestFrom(this.store.pipe(select(selectBookSearch))),
            map(([_, bookSearchState]) => {
                const trackingPayload: BookFilterTrackingPayload = {
                    filterReadingLevel: bookSearchState.readingLevels,
                    filterTextType: this.replaceUmlauteInArray(bookSearchState.genres),
                    filterSeries: this.replaceUmlauteInArray(bookSearchState.series),
                    filterBook: bookSearchState.statuses,
                    filterProgress: bookSearchState.progress.toString(),
                    filterCompetence: this.replaceUmlauteInArray(bookSearchState.competences),
                    filterReadingStrategy: this.replaceUmlauteInArray(bookSearchState.strategies),
                    filterTask: this.replaceUmlauteInArray(bookSearchState.exercises),
                    filterTextbookPage: bookSearchState.textbookPage ? [this.replaceUmlaute(bookSearchState.textbookPage.title), bookSearchState.textbookPage.page] : []
                };

                return TrackingActions.userUsedBookFilter({
                    event: new UserUsedBookFilter(trackingPayload),
                });
            })
        ));

    setFiltersAsUrlParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.toggleFilter),
            filter(filter => filter.filterCategory != FilterCategory.TextbookPage),
            withLatestFrom(this.store.pipe(select(selectBookSearch))),
            map(([_, bookSearchState]) => {
                let urlParams = {};
                for (const [key, value] of Object.entries(bookSearchState)) {
                    if (!value || key === 'progress' || key == 'textbookPage') continue;
                    const filterLabel = BookHelper.getFilterLabel(key.charAt(0).toUpperCase() + key.slice(1));
                    urlParams[filterLabel] = value;
                    this._router.navigate([], {
                        queryParams: urlParams,
                        queryParamsHandling: 'merge',
                    });
                }
            })
        ), { dispatch: false });

    removeFiltersFromUrlParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.resetAllFilters),
            map(() => {
                let urlParams = { ...this.route.snapshot.queryParams };
                for (const key of Object.keys(urlParams)) {
                    if (key === 'Titel') continue;
                    delete urlParams[key];
                }
                this._router.navigate([], {
                    queryParams: urlParams,
                });
            })
        ), { dispatch: false });

    removeTitleFromUrlParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BooksActions.resetTitleInput),
            map(() => {
                let urlParams = { ...this.route.snapshot.queryParams };
                delete urlParams['Titel'];

                this._router.navigate([], {
                    queryParams: urlParams,
                });
            })
        ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private booksService: BooksService,
        private store: Store,
        private route: ActivatedRoute,
        private _router: Router
    ) { }

    private getRecommendedBookFromSessionStore(): BookModel {
        const rawValue = sessionStorage.getItem(
            BooksEffects.recommendedBookKey
        );

        return JSON.parse(rawValue);
    }

    private replaceUmlauteInArray(stringArray): string[] {
        return stringArray.map(str => this.replaceUmlaute(str));
    }

    private replaceUmlaute(str): string {
        return str
            .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
                const big = this.umlautMap[a.slice(0, 1)];
                return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
            })
            .replace(new RegExp('[' + Object.keys(this.umlautMap).join('|') + ']', 'g'),
                (a) => this.umlautMap[a]
            );
    }

    umlautMap = {
        '\u00dc': 'UE',
        '\u00c4': 'AE',
        '\u00d6': 'OE',
        '\u00fc': 'ue',
        '\u00e4': 'ae',
        '\u00f6': 'oe',
        '\u00df': 'ss',
    };
}
