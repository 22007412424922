export enum CalendarSelectionDisabled {
    None,
    OnlyForThePast,
    Always
}

export interface CalendarSettings {
    monthsLabels?: string[];
    daysLabels?: string[];
    showTodaysDate: boolean;
    showOnFocus?: boolean;
    inputLabel?: string;
    selectionDisabled?: CalendarSelectionDisabled;
}

export interface SelectedDatesDays {
    days?: number[];
}
export interface SelectedDatesMonths {
    [month: number]: SelectedDatesDays;
}

export interface SelectedDates {
    [year: number]: SelectedDatesMonths;
}

export interface CalendarCurrentDate {
    month: number; // starting with 0
    year: number;
}
