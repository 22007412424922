import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsState } from './notifications.reducer';
import { StoreHelper } from 'shared/helper';
import { TeacherNotificationModel } from 'shared/models';
import { getStudentsState } from 'store/students/students.selector';

export const getNotificationsState = createFeatureSelector<NotificationsState>(
    'notifications'
);

export const selectIsLoadingNotifications = createSelector(
    getNotificationsState,
    (state) => state.isLoading
);

export const selectIsLoadedNotifications = createSelector(
    getNotificationsState,
    (state) => state.isLoaded
);

export const getTeacherNotifications = createSelector(
    getNotificationsState,
    getStudentsState,
    (notificationsState, students) => {
        if (notificationsState.isLoaded && students.loaded) {
            const notifications =
                StoreHelper.dictToArray(notificationsState.notifications)
                .filter(notification => {
                    const studentId = (notification as TeacherNotificationModel).data.studentId;
                    return students.allDict[studentId];
                })
                .sort((n1, n2) => n1.createdAt === n2.createdAt ? 0 : n1.createdAt < n2.createdAt ? 1 : -1)
                .map(notification => {
                    const studentId = (notification as TeacherNotificationModel).data.studentId;

                    return {
                        notification,
                        userName: students.allDict[studentId].userName
                    };
                });

                return notifications;
    }

    return [];
});

export const getTeacherNotificationsCount = createSelector(
    getTeacherNotifications,
    (notifications) => notifications.length
);

