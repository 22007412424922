import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { toggleUserSidebar } from 'store/layout/layout.actions';
import { selectIsUserSidebarOpen } from 'store/layout/layout.selector';
import { RootState } from 'store/root.reducer';
import { getTeacherProfile } from 'store/user';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {

    teacher$ = this.store.pipe(
        select(getTeacherProfile),
        filter(profile => Boolean(profile))
    );

    get userInitials$(): Observable<string> {
        return this.teacher$.pipe(
            map(teacher => {
                const { firstName, lastName } = teacher;

                if (
                    firstName == null ||
                    firstName.length < 1 ||
                    lastName == null ||
                    lastName.length < 1
                ) {
                    return '@';
                }

                return firstName[0] + lastName[0];
            })
        );
    }

    constructor(
        private store: Store<RootState>,
    ) {}

    togglePanel() {
        this.store.dispatch(toggleUserSidebar());
    }
}
