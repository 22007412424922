import { Component, Input } from '@angular/core';
import { TrophyFilling } from 'app/svg-icons/svg-icon-trophy.component';
import { Colors } from 'shared/helper';

@Component({
    selector: 'app-correct-answers',
    templateUrl: './correct-answers.component.html',
    styleUrls: ['./correct-answers.component.scss']
})
export class CorrectAnswersComponent {
    @Input()
    percentage: number;

    get trophyFilling(): TrophyFilling {
        if (!this.percentage || this.percentage === 0) {
            return TrophyFilling.Empty;
        }

        if (this.percentage < 30) {
            return TrophyFilling.Empty;
        }

        if (this.percentage >= 70) {
            return TrophyFilling.Full;
        }

        return TrophyFilling.HalfFull;
    }

    colors = Colors;

    constructor() { }
}
