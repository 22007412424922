import { BaseTrackingEvent } from 'shared/tracking/models/base-tracking-event.model';
import { TrackingObjects, TrackingVerbs } from 'shared/tracking/tracking';

export class UserViewedScreen extends BaseTrackingEvent {
    constructor(screenTitle: string, screenId: string) {
        super({
            eventId: '765b837a-9ed5-4dcb-bdf8-76d5c0d0f9f1',
            eventVerb: TrackingVerbs.VIEWED,
            eventObject: TrackingObjects.SCREEN,
            eventPayload: {
                screenTitle: screenTitle,
                screenId: screenId,
            },
        });
    }
}
