import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 527_Ausdrucken_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-print',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M49.594,19.843H42.53V7.086a1.416,1.416,0,0,0-1.417-1.417H15.579a1.416,1.416,0,0,0-1.417,1.417V19.843H7.076A1.416,1.416,0,0,0,5.659,21.26V43.937a1.416,1.416,0,0,0,1.417,1.417h7.086v4.252a1.416,1.416,0,0,0,1.417,1.417H41.113a1.416,1.416,0,0,0,1.417-1.417V45.354h7.064a1.416,1.416,0,0,0,1.417-1.417V21.26A1.416,1.416,0,0,0,49.594,19.843ZM17,8.5H39.7v11.34H17Zm0,39.686v-8.5H39.7v8.5ZM48.177,42.52H42.53V39.685h1.418a1.417,1.417,0,1,0,0-2.834h-31.2a1.417,1.417,0,1,0,0,2.834h1.417V42.52H8.493V22.677H48.177Z" />
        <circle cx="42.509" cy="28.346" r="2.126" />
    </svg>
    `
})
export class SvgIconPrintComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'print';
    }
}
