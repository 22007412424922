import { Component, Input } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

@Component({
    selector: 'app-svg-icon-invalid',
    template: `
    <svg [attr.height]="height" [attr.width]="width" viewBox="0 0 24 24">
        <defs>
            <circle id="icon-invalid-path-1" cx="12" cy="12" r="12"></circle>
        </defs>
        <g id="icon-invalid-Klickdummy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icon-invalid-Dashboard-Profil-Password-?-Error" transform="translate(-959.000000, -500.000000)">
                <g id="icon-invalid-Icon-(Validation)" transform="translate(959.000000, 500.000000)">
                    <g id="icon-invalid-Group-3-Copy-3">
                        <mask id="icon-invalid-mask-2" fill="white">
                            <use xlink:href="#icon-invalid-path-1"></use>
                        </mask>
                        <use id="icon-invalid-Mask" [attr.fill]="color" xlink:href="#icon-invalid-path-1"></use>
                        <g id="icon-invalid-Color/Validation/Falsch" mask="url(#icon-invalid-mask-2)" [attr.fill]="circleColor">
                            <rect id="icon-invalid-Shape" x="0" y="0" width="24" height="24"></rect>
                        </g>
                    </g>
                    <g id="icon-invalid-Group" transform="translate(7.000000, 7.000000)" stroke="#FFFFFF" stroke-linecap="round" stroke-width="3">
                        <path d="M9.44444444,0.555555556 L0.0163540286,9.98364597" id="icon-invalid-Line-3"></path>
                        <path d="M9.44444444,0.555555556 L0.0163540286,9.98364597" id="icon-invalid-Line-3" transform="translate(4.730399, 5.269601) scale(-1, 1) translate(-4.730399, -5.269601) "></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>`
})
export class SvgIconInvalidComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'invalid';
    }

    get circleVisibility(): string {
        if (Boolean(this.isCircleVisible) === false) {
            return 'hidden';
        }
    }

    private _circleColor = '#1A73E8';

    @Input()
    isCircleVisible = false;

    @Input()
    get circleColor(): string {
        if (this.isCircleVisible === false) {
            return;
        }

        return this._circleColor;
    }

    set circleColor(value: string) {
        this._circleColor = value;
    }
}
