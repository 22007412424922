import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountType, defaultMonsterKey, LicenceOwnerRole } from 'shared/models';
import { getRouteUrl } from 'store/router';
import { TeacherProfileState, UserState } from './user.reducer';

export const getUserState = createFeatureSelector<UserState>('user');

export const getErrorCode = createSelector(
    getUserState,
    (state: UserState) => {
        const { error } = state;
        return error?.errorCode || null;
    }
);

export const getIsAuthorized = createSelector(
    getUserState,
    (state: UserState) => state.isAuthorized
);

export const getAccountType = createSelector(
    getUserState,
    (state: UserState) => state.accountType
);

export const getRoles = createSelector(
    getUserState,
    (state: UserState) => state.roles
);

export const getTeacherProfile = createSelector(
    getUserState,
    (state: UserState) => state.teacher
);

export const getTutorLicenceRole = createSelector(
    getTeacherProfile,
    (tutor) => {
        return tutor?.licence?.ownerRole;
    }
);

export const getHasTutorRolePrivateUser = createSelector(
    getTutorLicenceRole,
    (role: LicenceOwnerRole) => role === LicenceOwnerRole.PrivateUser
);

export const getTeacherProfileLoading = createSelector(
    getTeacherProfile,
    (teacherState: TeacherProfileState) => teacherState.loading
);

export const getTeacherId = createSelector(
    getUserState,
    (state: UserState) => state?.teacher?.id
);

export const getIsAdmin = createSelector(
    getTeacherProfile,
    teacher => teacher ? teacher.isSchoolAdmin : false
);

export const getLicence = createSelector(
    getAccountType,
    getUserState,
    (accountType, userState) => {
        switch (accountType) {
            case AccountType.Teacher:
                if (userState == null || userState.teacher == null) {
                    return null;
                }

                return userState.teacher.licence;

            case AccountType.Student:
                if (userState == null || userState.student == null) {
                    return null;
                }

                return userState.student.licence;
        }
    }
);

export const getLicenceOwnerRole = createSelector(
    getLicence,
    (licence) => {
        if (licence) {
            return licence.ownerRole;
        }

        return null;
    }
);

/**
 * @deprecated use getHasTutorRolePrivateUser instead
 */
export const getIsPrivateUserOrChild = createSelector(
    getHasTutorRolePrivateUser,
    (isPrivateUser: boolean) => isPrivateUser
);

export const getIsOnboarded = createSelector(
    getTeacherProfile,
    getHasTutorRolePrivateUser,
    (profile, isPrivateUser) => isPrivateUser && profile.isOnboarded
);

export const isInPrivateUserOnboardingFlow = createSelector(
    getHasTutorRolePrivateUser,
    getRouteUrl,
    (isPrivateUser, routeUrl) => isPrivateUser && routeUrl.includes('leseo-starten')
);

export const selectIsLicenceExpired = createSelector(
    getLicence,
    (licence) => {
        return licence.isExpired;
    }
);

export const getTutorLeseoName = createSelector(
    getTeacherProfile,
    (profile) => profile?.leseoName
);

export const getStudentProfile = createSelector(
    getUserState,
    (state: UserState) => state.student
);

export const getUserName = createSelector(
    getStudentProfile,
    (studentProfile) => studentProfile ? studentProfile.userName : ''
);

export const getStudentReadingLevel = createSelector(
    getStudentProfile,
    state => state.readingLevel
);

export const getStudentReadBooks = createSelector(
    getStudentProfile,
    state => state.readBooks
);

export const getStudentOpenedBooks = createSelector(
    getStudentProfile,
    state => state.openedBooks
);

export const getStudentOpenAssignmentsCount = createSelector(
    getStudentProfile,
    state => state.openAssignmentsCount
);

export const getStudentTotalScore = createSelector(
    getStudentProfile,
    state => state.totalScore
);

export const getStudentMaxScore = createSelector(
    getStudentProfile,
    state => state.maxScore
);

export const getStudentAvatarKey = createSelector(
    getStudentProfile,
    state => state.avatarKey || defaultMonsterKey
);

export const selectStudentAvatarUri = createSelector(
    getStudentProfile,
    state => state.avatarUri || ''
);

export const getStudentHasLicence = createSelector(
    getStudentProfile,
    // if licence not loaded yet, assume we have one
    state => !state.loaded ? true : state.hasLicence
);

export const getReadingConfiguration = createSelector(
    getStudentProfile,
    profile => profile.loaded ? { hideAudio: profile.hideAudio, hideSyllableColoring: profile.hideSyllableColoring } : { hideAudio: true, hideSyllableColoring: true }
);

export const getProfile = createSelector(
    getUserState,
    state => {
        switch (state.accountType) {
            case AccountType.Student:
                return state.student;

            case AccountType.Teacher:
                return state.teacher;

            default:
                return null;
        }
    }
);

export const getUserId = createSelector(
    getProfile,
    state => state.id
);

export const getOidcUser = createSelector(
    getUserState,
    (state: UserState) => state.oidcUser
);

export const hasSchoolClassManagementPermissions = createSelector(
    getTeacherProfile,
    (profile) => profile?.accountOrigin !== 'SduiBearer'
);
