import { Component } from '@angular/core';

import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 555_Reset_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-reload',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M1.983,18.72a1.418,1.418,0,0,1,1.988.259l4.8,6.241a21.223,21.223,0,1,1,5.97,18.16,1.417,1.417,0,0,1,2-2A18.428,18.428,0,1,0,11.457,26.4l7.509-4.8a1.417,1.417,0,0,1,1.526,2.389l-9.8,6.256a1.578,1.578,0,0,1-.762.223,1.417,1.417,0,0,1-1.125-.553L1.723,20.707A1.416,1.416,0,0,1,1.983,18.72Z" />
    </svg>
  `
})
export class SvgIconReloadComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'reload';
    }
}
