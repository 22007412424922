import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

import { CalendarHelper } from '../helper';

@Pipe({ name: 'friendlyDate' })
export class FriendlyDatePipe implements PipeTransform {
    transform(dueDate: Date, format: string): string {
        if (dueDate == null) {
            return;
        }

        const date = new Date(dueDate);

        const currentDate = CalendarHelper.createCurrentDateFromDate(date);
        const dueDateNicely = moment(date).format('L');

        if (format === 'doneDate') {
            return `beendet am ${dueDateNicely}`;
        }

        if (CalendarHelper.isToday(currentDate, date.getDate())) {
            return `heute fällig`;
        }

        if (CalendarHelper.isInPast(date)) {
            return `abgelaufen am ${dueDateNicely}`;
        }

        return `fällig am ${dueDateNicely}`;
    }
}
