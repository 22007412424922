import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 084_Tipp_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-hint',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path
                d="M2.7,25.511a1.418,1.418,0,0,1,1.418-1.417h7.086a1.418,1.418,0,1,1,0,2.835H4.121A1.418,1.418,0,0,1,2.7,25.511ZM14.2,15.78,8.057,12.237A1.417,1.417,0,0,0,6.64,14.692l6.138,3.543a1.406,1.406,0,0,0,.707.19,1.417,1.417,0,0,0,.71-2.645Zm5.518-3.58a1.418,1.418,0,0,0,1.226-2.126L17.4,3.937a1.417,1.417,0,0,0-2.455,1.417l3.543,6.137A1.416,1.416,0,0,0,19.713,12.2Zm9.92-3.7V1.417a1.418,1.418,0,0,0-2.835,0V8.5a1.418,1.418,0,1,0,2.835,0ZM52.31,24.094H45.223a1.418,1.418,0,0,0,0,2.835H52.31a1.418,1.418,0,0,0,0-2.835Zm-8.657-5.859,6.137-3.543a1.417,1.417,0,0,0-1.417-2.455L42.236,15.78a1.417,1.417,0,1,0,1.417,2.455ZM40.971,3.418a1.415,1.415,0,0,0-1.936.519l-3.543,6.137a1.417,1.417,0,1,0,2.455,1.417L41.49,5.354A1.417,1.417,0,0,0,40.971,3.418Zm-9.1,47.605h-7.3a2.126,2.126,0,0,1-2.1-1.776l-1.319-7.912a22.422,22.422,0,0,0-2.7-6.734c-1.7-3.214-3.472-6.556-2.872-10.762A12.934,12.934,0,0,1,28.331,12.756,12.619,12.619,0,0,1,40.972,25.511c0,3.442-1.521,6.313-2.992,9.09a22.4,22.4,0,0,0-2.7,6.734l-1.319,7.912A2.126,2.126,0,0,1,31.867,51.023ZM23.7,39.685h9.037a28.484,28.484,0,0,1,2.741-6.411c1.369-2.585,2.662-5.025,2.662-7.763a9.922,9.922,0,0,0-19.843,0c0,2.738,1.293,5.179,2.662,7.763A28.569,28.569,0,0,1,23.7,39.685Zm7.711,8.5.8-5.669H24.219l.8,5.669Z" />
        </svg>
  `
})
export class SvgIconHintComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'hint';
    }
}
