import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'shared/services';
import { openPromotionCodeSuccessPopup } from 'store/layout/popup.actions';
import { enterPromoCode, enterPromoCodeFail, enterPromoCodeSuccess } from './licence.actions';

@Injectable()
export class LicenceEffects {
    enterPromotionCode$ = createEffect(() => this.actions$.pipe(
        ofType(enterPromoCode),
        switchMap(({ code }) => this.userService.usePromotionCode(code).pipe(
            map(licence => enterPromoCodeSuccess({licence})),
            catchError((error: unknown) => of( enterPromoCodeFail({error}) ))
        ))
    ));

    enterPromoCodeSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(enterPromoCodeSuccess),
        map(() => openPromotionCodeSuccessPopup())
    ));

    constructor(private actions$: Actions, private userService: UserService) {}
}
