export enum ReadingLevelBaseCssClass {
    foregroundColor = 'reading-level-color',
    backgroundColor = 'reading-level-bg-color'
}

export class ReadingLevelHelper {
    static getColorClassNames(
        readingLevel: number,
        baseCssClass = ReadingLevelBaseCssClass.foregroundColor
    ): string[] {
        if (readingLevel == null) {
            return;
        }

        if (readingLevel < 0 || readingLevel > 5) {
            return;
        }

        return [baseCssClass, `level-${readingLevel}`];
    }
}
