// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-flex;
  align-items: center;
  color: currentColor;
}
:host app-svg-icon-calendar {
  margin-right: 0.5rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR1ZS1kYXRlLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksb0JBQUE7RUFDQSxtQkFBQTtFQUNBLG1CQUFBO0FBQ0o7QUFDSTtFQUNJLG9CQUFBO0FBQ1IiLCJmaWxlIjoiZHVlLWRhdGUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogaW5saW5lLWZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBjb2xvcjogY3VycmVudENvbG9yO1xuXG4gICAgYXBwLXN2Zy1pY29uLWNhbGVuZGFyIHtcbiAgICAgICAgbWFyZ2luLXJpZ2h0OiAwLjVyZW07XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/due-date/due-date.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,oBAAA;AACR;AACA,4gBAA4gB","sourcesContent":[":host {\n    display: inline-flex;\n    align-items: center;\n    color: currentColor;\n\n    app-svg-icon-calendar {\n        margin-right: 0.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
