// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin-bottom: 60px;
}
:host > a {
  display: flex;
  align-items: center;
}
:host > a app-svg-icon-arrow {
  display: block;
  margin-right: 10px;
  transform: rotate(180deg);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhY2stdG8uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0VBRUEsbUJBQUE7QUFBSjtBQUVJO0VBQ0ksYUFBQTtFQUNBLG1CQUFBO0FBQVI7QUFFUTtFQUNJLGNBQUE7RUFDQSxrQkFBQTtFQUNBLHlCQUFBO0FBQVoiLCJmaWxlIjoiYmFjay10by5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXNwbGF5OiBibG9jaztcblxuICAgIG1hcmdpbi1ib3R0b206IDYwcHg7XG5cbiAgICA+IGEge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuXG4gICAgICAgIGFwcC1zdmctaWNvbi1hcnJvdyB7XG4gICAgICAgICAgICBkaXNwbGF5OiBibG9jaztcbiAgICAgICAgICAgIG1hcmdpbi1yaWdodDogMTBweDtcbiAgICAgICAgICAgIHRyYW5zZm9ybTogcm90YXRlKDE4MGRlZyk7XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/widgets/back-to/back-to.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EAEA,mBAAA;AAAJ;AAEI;EACI,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,cAAA;EACA,kBAAA;EACA,yBAAA;AAAZ;AACA,guBAAguB","sourcesContent":[":host {\n    display: block;\n\n    margin-bottom: 60px;\n\n    > a {\n        display: flex;\n        align-items: center;\n\n        app-svg-icon-arrow {\n            display: block;\n            margin-right: 10px;\n            transform: rotate(180deg);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
