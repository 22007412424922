import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import { GlobalNotification } from 'shared/models/global-notification.model';

@Injectable()
export class GlobalNotificationService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) { }

    getGlobalNotifications(): Observable<GlobalNotification[]> {
        return this.http.get<GlobalNotification[]>(`${this.baseApiUrl}/globalnotifications`);
    }

    /**
     * Updates the viewed status of the given notifications.
     * @param notificationIds The ids of the notifications to update.
     * @returns An observable that completes when the update is done.
     */
    updateViewedNotifications(notificationIds: number[]) {
        return this.http.post(`${this.baseApiUrl}/globalnotifications`, notificationIds);
    }
}
