import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-icon-link',
    templateUrl: './icon-link.component.html',
    styleUrls: ['./icon-link.component.scss']
})
export class IconLinkComponent {

    @HostBinding('attr.tabindex') tabindex = '';

    /** Set to false to show icons on small screens. Default is true. */
    @Input()
    hideIconOnSmallScreens = true;

    @Input()
    hideLabelOnSmallScreens = false;

    /** Set to true, to show icon, otherwise false to hide. Default is true. */
    @Input()
    showIcon = true;

    @Input()
    iconName = 'arrow';

    @Input()
    buttonStyles = '';

    constructor() { }
}
