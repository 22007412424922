import { Gender, UserModel, SchoolClassLightModel } from '.';

export interface TeacherModel extends UserModel {
    id: number;
    firstName: string;
    lastName: string;
    leseoName: string;
    eMail: string;
    gender: Gender;
    hasSchoolClass: boolean;
    hasStudents: boolean;
    hasActiveAssignments: boolean;

    isSchoolAdmin: boolean;
    isOnboarded: boolean;
}

export interface TeacherLightModel extends Pick<TeacherModel, 'id' | 'firstName' | 'lastName' > {
    schoolClasses: SchoolClassLightModel[];
}
