import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 117_Check_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-task',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path d="M11.34,43.937V12.756a1.417,1.417,0,0,1,1.417-1.417H45.011l-2.835,2.835h-28V42.52H42.52V25.856l2.835-2.835V43.937a1.417,1.417,0,0,1-1.417,1.417H12.757A1.416,1.416,0,0,1,11.34,43.937ZM51.44,8.919,26.93,33.429l-7.5-7.5a1.418,1.418,0,1,0-2,2l8.5,8.5a1.417,1.417,0,0,0,2,0L53.444,10.924a1.417,1.417,0,0,0-2-2Z" />
        </svg>
    `
})
export class SvgIconTaskComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'task';
    }
}
