import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 109_Information_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-information',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M35.344,38.267a1.417,1.417,0,0,1-1.417,1.418H22.589a1.418,1.418,0,1,1,0-2.835H26.84V25.512H22.589a1.418,1.418,0,1,1,0-2.835h5.669a1.417,1.417,0,0,1,1.417,1.417V36.85h4.252A1.417,1.417,0,0,1,35.344,38.267Zm15.591-9.921A22.677,22.677,0,1,1,28.258,5.668,22.7,22.7,0,0,1,50.935,28.346Zm-2.834,0A19.843,19.843,0,1,0,28.258,48.188,19.866,19.866,0,0,0,48.1,28.346ZM27.9,19.133a2.48,2.48,0,1,0-2.48-2.48A2.48,2.48,0,0,0,27.9,19.133Z" />
    </svg>
  `
})
export class SvgIconInformationComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'information';
    }
}
