import { createReducer, on } from '@ngrx/store';
import { Conversation, ConversationMessage, ConversationMessageDraft } from 'shared/models/conversation.model';
import * as ConversationActions from 'store/conversations/conversations.actions';
import { deleteSchoolClassSuccess, selectSchoolClass } from 'store/school-classes';
import { deleteStudentSuccess } from 'store/students/students.actions';
import { fetchProfileUpdatesSuccess } from 'store/user';

export interface ConversationsState {
    hasUnreadMessages: boolean;
    lastMessageDate?: Date;
    conversations: Conversation[];
    drafts: ConversationMessageDraft[];
    activeConversation: {
        participantId: number;
        conversationId?: number;
    };
    isLoading: boolean;
    isLoaded: boolean;
    error?: any;
}

export const initialState: ConversationsState = {
    hasUnreadMessages: false,
    lastMessageDate: null,
    conversations: [],
    drafts: [],
    activeConversation: {
        participantId: null,
        conversationId: null,
    },
    isLoading: false,
    isLoaded: false,
    error: null
};

const _conversationsReducer = createReducer(
    initialState,
    on(fetchProfileUpdatesSuccess, (state, { profileUpdates }) => {
        return {
            ...state,
            hasUnreadMessages: profileUpdates.hasNewMessages,
            lastMessageDate: profileUpdates.lastMessageDate || state.lastMessageDate
        };
    }),
    on(ConversationActions.fetchConversations, (state, action) => {
        return {
            ...state,
            isLoading: true
        };
    }),
    on(ConversationActions.fetchConversationsSuccess, (state, action) => {
        const convos: Conversation[] = action.conversations.map((c, i) => {
            const newThread = state.conversations.find(x => x.conversationId === c.conversationId)?.thread;

            return {
                ...c,
                thread: newThread,
            };
        });

        return {
            ...state,
            conversations: convos,
            isLoading: false,
            isLoaded: true,
            error: null
        };
    }),
    on(ConversationActions.fetchConversationsFail, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isLoaded: false,
            error: action.error
        };
    }),
    on(ConversationActions.setActiveConversation, (state, action) => {
        return {
            ...state,
            activeConversation: {
                participantId: action.participantId,
                conversationId: action.conversationId,
            }
        };
    }),
    on(ConversationActions.fetchConversationThread, (state, action) => {
        return {
            ...state,
            isLoading: true
        };
    }),
    on(ConversationActions.fetchConversationThreadSuccess, (state, {conversationThread, hasMore}) => {

        const convo: Conversation[] = state.conversations.map(c => {
            if (state.activeConversation.participantId === c.participants[0]) {
                const exisitingMessages = c.thread ?? [];
                let newMessages: ConversationMessage[] = conversationThread ?? [];

                if (exisitingMessages.length > 0) {
                    newMessages = conversationThread.filter(message => !exisitingMessages.map(m => m.id).includes(message.id));
                }

                const newThread = [...exisitingMessages, ...newMessages]
                    // sort ascending by date
                    .sort((a,b) => {
                            const msg1Date = a.createdAt.getTime();
                            const msg2Date = b.createdAt.getTime();

                            if (msg1Date === msg2Date) return 0;

                            return msg1Date <  msg2Date ? -1 : 1;
                        });

                return {
                    ...c,
                    thread: newThread,
                    hasMoreMessages: hasMore
                };
            }

            return c;
        });

        return {
            ...state,
            conversations: convo,
            isLoading: false,
            error: null
        };
    }),
    on(ConversationActions.fetchConversationThreadFail, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
            error: action.error
        };
    }),
    on(ConversationActions.draftConversationMessage, (state, action) => {
        let newDrafts: ConversationMessageDraft[];
        const existingDraft = state.drafts.find(draft => draft.participantId === state.activeConversation.participantId);

        const newDraft: ConversationMessageDraft = {
            content: action.content,
            participantId: state.activeConversation.participantId,
            conversationId: state.activeConversation.conversationId
        };

        if (existingDraft) {
            newDrafts = state.drafts.map(draft => draft.participantId === state.activeConversation.participantId ? newDraft : draft);
        } else {
            newDrafts = [...state.drafts, newDraft];
        }

        return {
            ...state,
            drafts: newDrafts,
        };
    }),
    on(ConversationActions.sendConversationMessage, (state, action) => {
        return {
            ...state,
            isLoading: true
        };
    }),
    on(ConversationActions.sendConversationMessageSuccess, (state, action) => {
        const oldConvo = state.conversations.find(convo => convo.conversationId === action.message.conversationId);

        const newConversation: Conversation = {
            content: action.message.content,
            conversationId: action.message.conversationId,
            updatedAt: action.message.createdAt,
            isNew: false,
            participants: action.participants,
            thread: oldConvo ? [...oldConvo.thread, action.message] : [action.message],
            recentMessage: action.message,
            hasMoreMessages: oldConvo ? oldConvo.hasMoreMessages : false
        };

        const updatedConvos: Conversation[] = [newConversation, ...state.conversations.filter(convo => convo.conversationId !== action.message.conversationId)];
        const updatedDrafts = state.drafts.filter(draft => draft.content !== action.message.content);

        return {
            ...state,
            conversations: updatedConvos,
            drafts: updatedDrafts,
            isLoading: false
        };
    }),
    on(ConversationActions.sendConversationMessageFail, (state, action) => {
        return {
            ...state,
            isLoading: false,
            error: action.error
        };
    }),
    on(ConversationActions.setConversationAsReadSuccess, (state, action) => {
        const newConvos = state.conversations.map(convo => {
            if (convo.participants[0] === action.participantId) {
                return {
                    ...convo,
                    conversationId: convo.conversationId || action.conversationId,
                    isNew: false,
                    recentMessage: {
                        ...convo.recentMessage,
                        conversationId: convo.conversationId || action.conversationId,
                        isNew: false
                    }
                };
            }

            return convo;
        });
        return {
            ...state,
            conversations: newConvos
        };
    }),
    on(ConversationActions.markConversationThreadAsRead, (state, action) => {
        const newConvos = state.conversations.map(convo => {
            if (convo.participants[0] === action.participantId) {
                const newThread = convo.thread.map(message => {
                    return {
                        ...message,
                        isNew: false
                    };
                });

                return {
                    ...convo,
                    thread: [...newThread]
                };
            }

            return convo;
        });
        return {
            ...state,
            conversations: newConvos
        };
    }),
    on(deleteSchoolClassSuccess, selectSchoolClass, (state, action) => {
        return {
            ...initialState,
            hasUnreadMessages: state.hasUnreadMessages,
            isLoaded: true
        };
    }),
    on(deleteStudentSuccess, (state, { student }) => {
        const newConvos = state.conversations.filter(convo => !convo.participants.includes(student.id));
        const newDrafts = state.drafts.filter(d => d.participantId !== student.id);
        let newActiveConversation;

        if (state.activeConversation.participantId === student.id) {
            newActiveConversation = {
                conversationId: null,
                participantId: null
            };
        }

        return {
            ...state,
            conversations: newConvos,
            drafts: newDrafts,
            activeConversation: newActiveConversation || state.activeConversation
        };
    }),
);

export function conversationsReducer(state, action) {
    return _conversationsReducer(state, action);
}

const hasUnreadMessages = (conversations: Conversation[]) => {
    return conversations.some(c => c.isNew);
};
