import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class UserClickedUIElement extends BaseTrackingEvent {
    constructor(uielementTitle: string, uielementId: number = 0) {
        super({
            eventId: '220c8c8f-624f-46bc-b7c8-e7473953b086',
            eventVerb: TrackingVerbs.CLICKED,
            eventObject: TrackingObjects.UIELEMENT,
            eventPayload: {
               uielementTitle,
               uielementId
            },
        });
    }
}
