import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, delay, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CvPopUpHelper, PopupChannels } from 'shared/helper';
import { Conversation, NotificationType, trackingEvents } from 'shared/models';
import { ConversationService } from 'shared/services';
import * as ConversationActions from 'store/conversations/conversations.actions';
import { selectActiveConversation, selectActiveDraft, selectConversations, selectConversationsState } from './conversations.selector';
import * as TrackingActions from '../tracking/tracking.actions';
import * as UserActions from '../user/user.actions';
import { UserSendMessage } from 'shared/tracking/models/user-send-message.model';
import { Router } from '@angular/router';
import { getAccountType } from 'store/user';

@Injectable()
export class ConversationsEffects {
    fetchConversations$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.fetchConversations, UserActions.fetchProfileUpdatesSuccess),
        exhaustMap(() => this.conversationService.getConversations().pipe(
            map((conversations) => ConversationActions.fetchConversationsSuccess({ conversations })),
            catchError((error: unknown) => of(ConversationActions.fetchConversationsFail({ error })))
        ))
    ));

    fetchConversationsForGroup$ = createEffect(() => this.actions$.pipe(
      ofType(ConversationActions.fetchConversationsForGroup),
      exhaustMap((data) => this.conversationService.getConversations(data.groupId).pipe(
          map((conversations) => ConversationActions.fetchConversationsSuccess({ conversations })),
          catchError((error: unknown) => of(ConversationActions.fetchConversationsFail({ error })))
      ))
    ));

    setInitialActiveConversation = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.fetchConversationsSuccess),
        filter(({conversations}) => conversations.length > 0),
        withLatestFrom(this.store.pipe(select(selectConversationsState))),
        filter(([_, { activeConversation }]) => !activeConversation.participantId),
        map(([{conversations}]) => {
            try {
                const firstConversation = conversations[0];

                return ConversationActions.setActiveConversation({
                    participantId: firstConversation.participants[0],
                    conversationId: firstConversation.conversationId
                });

            } catch (error) {
                return ConversationActions.fetchConversationThreadFail({
                    error: `Initial active convo couldn't be set. ${error as TypeError}`
                });
            }
        })
    ));

    fetchUpdatesForActiveConversationThread$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.fetchConversationsSuccess),
        withLatestFrom(this.store.pipe(select(selectActiveConversation))),
        filter(([{ conversations }, { conversation }]) => this.hasUpdates(conversations, conversation?.participants[0])),
        map(([_, { conversation }] ) => ConversationActions.fetchConversationThread({
                participantId: conversation.participants[0],
                conversationId: conversation.conversationId
            }))
    ));

    setActiveConversation$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.setActiveConversation),
        filter(({ conversationId }) => !!conversationId),
        withLatestFrom(this.store.pipe(select(selectConversations))),
        filter(([{ participantId }, conversationsList]) => this.hasUpdates(conversationsList, participantId)),
        map(([{ conversationId, participantId: studentId }, _]) => ConversationActions.fetchConversationThread({ conversationId, participantId: studentId, skip: 0, limit: 20 }))
    ));

    fetchConversationThread$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.fetchConversationThread),
        filter((action) => !!action.participantId),
        withLatestFrom(this.store.pipe(select(selectConversations))),
        switchMap(([{ conversationId, participantId, skip, limit }, conversationsList]) => this.conversationService.getConversation(conversationId, skip, limit).pipe(
            concatMap((conversationThreadList) => {
                const activeConvo = conversationsList.find(c => c.participants[0] === participantId);
                const actions: any[] = [ ConversationActions.fetchConversationThreadSuccess({ conversationThread: conversationThreadList.messages, hasMore: conversationThreadList.hasMore }) ];

                if (activeConvo?.isNew && this.router.url.includes('nachrichten')) {
                    actions.push( ConversationActions.setConversationAsRead({ conversationId, participantId }) );
                }

                return actions;
            }),
            catchError((error: unknown) => of(ConversationActions.fetchConversationThreadFail({ error })))
        ))
    ));



    updateActiveConversation$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.updateActiveConversation),
        withLatestFrom(this.store.pipe(select(selectActiveConversation))),
        filter(([_, {conversation}]) => conversation.hasMoreMessages),
        map(([{ limit }, {conversation}]) => ConversationActions.fetchConversationThread({ conversationId: conversation.conversationId, participantId: conversation.participants[0], skip: conversation.thread?.length || 0, limit }))
    ));

    sendConversationMessage$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.sendConversationMessage),
        withLatestFrom(this.store.pipe(select(selectActiveDraft))),
        switchMap(([_, activeDraft]) => this.conversationService.sendConversation(activeDraft).pipe(
            map((message) => ConversationActions.sendConversationMessageSuccess({ message, participants: [activeDraft.participantId] })),
            catchError((error: unknown) => of(ConversationActions.sendConversationMessageFail({ error })))
        ))
    ));

    sendConversationMessageSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.sendConversationMessageSuccess),
        map((data) => {
            const messageTypeString = this.getMessageTypeStringForTracking(data.message.messageType);
            return TrackingActions.userSendMessage({ event: new UserSendMessage(messageTypeString) });
        })
    ));

    sendConversationMessageFail$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.sendConversationMessageFail),
        map(() => {
            const message = 'Die Nachricht konnte nicht gesendet werden.';
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.AddAssignmentFailure,
                message
            );

            return openPopup;
        })
    ));

    setConversationAsRead$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.setConversationAsRead),
        switchMap(({conversationId, participantId: studentId}) => this.conversationService.setConversationAsRead(conversationId, studentId).pipe(
            map((newConversationId) => ConversationActions.setConversationAsReadSuccess({conversationId: newConversationId, participantId: studentId})),
            catchError((error: unknown) => of(ConversationActions.setConversationAsReadFail({error})))
        ))
    ));

    setConversationAsReadSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.setConversationAsReadSuccess),
        delay(5000),
        map(({conversationId, participantId}) => ConversationActions.markConversationThreadAsRead({conversationId, participantId}))
    ));

    updateUserInfo$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationActions.setConversationAsReadSuccess),
        withLatestFrom(this.store.pipe(select(getAccountType))),
        map(([_, accountType]) => UserActions.fetchProfileUpdates({accountType}))
    ));

    constructor(
        private actions$: Actions,
        private conversationService: ConversationService,
        private store: Store,
        private router: Router
      ) {

    }

    /**
     * checks if active conversation has any new messages
     */
         private hasUpdates(conversations: Conversation[], participantId: number): boolean {
            const activeConversation: Conversation = conversations?.find(c => c.participants?.includes(participantId));

            if (!activeConversation) {
                return false;
            }

            if (activeConversation?.isNew) {
                return true;
            }

            const messageIds = activeConversation.thread?.map(message => message.id) || [];
            const activeMessageLoaded = messageIds.includes(activeConversation.recentMessage?.id);

            return !activeMessageLoaded;
        }

    private getMessageTypeStringForTracking(notificationType: NotificationType): string {
        switch (notificationType) {
            case NotificationType.LevelUp:
                return 'LevelUp';
            case NotificationType.LevelDown:
                return 'LevelDown';
            case NotificationType.UpdateNote:
                return 'UpdateNote';
            case NotificationType.MaxScore:
                return 'MaxScore';
            case NotificationType.Message:
            default:
                return 'Message';
        }
    }
}
