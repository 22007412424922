import { TrackingVerbs, TrackingObjects } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class StudentUsedMonsterChangeFeature extends BaseTrackingEvent{
    constructor(featureMonsterId: string, featureTitle: string = '', featureId: number = 0) {
        super({
            eventId: 'b63fe8c5-d6e1-409d-8bc4-c0ecab83ca83',
            eventVerb: TrackingVerbs.USED,
            eventObject: TrackingObjects.FEATURE,
            eventPayload: {
                featureTitle: featureTitle,
                featureId: featureId,
                featureMonsterId: featureMonsterId
            },
        });
    }
}
