import { AfterContentInit, AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList } from '@angular/core';
import { Subject } from 'rxjs';
import { retryWhen, startWith, takeUntil } from 'rxjs/operators';
import { CvStepComponent } from './cv-step/cv-step.component';

@Component({
    selector: 'app-cv-stepper',
    templateUrl: './cv-stepper.component.html',
    styleUrls: ['./cv-stepper.component.scss']
})
export class CvStepperComponent implements AfterContentInit, AfterViewInit, OnDestroy {

    protected readonly unsubscibe$ = new Subject<void>();

    @ContentChildren(CvStepComponent, { descendants: true }) _steps: QueryList<CvStepComponent>;

    readonly steps: QueryList<CvStepComponent> = new QueryList<CvStepComponent>();

    private _linear = false;
    @Input()
    get linear(): boolean {
        return this._linear;
    }
    set linear(value: boolean) {
        this._linear = !!value;
    }

    @Input()
    loop = false;

    private _selectedIndex = 0;

    @Input()
    get selectedIndex() {
        return this._selectedIndex;
    }
    set selectedIndex(index: number) {
        const newIndex = index;

        if (this.steps && this._steps) {
            // Ensure that the index can't be out of bounds.
            if (!this.isValidIndex(newIndex)) {
                console.debug('CvStepper: Cannot assign out-of-bounds value to `selectedIndex`.');
                throw Error('CvStepper: Cannot assign out-of-bounds value to `selectedIndex`.');
            }

            this.selected?.markAsInteracted();

            if (this._selectedIndex !== newIndex && !this.stepIncomplete(newIndex)
                && (newIndex >= this._selectedIndex || this.steps.toArray()[newIndex].editable)) {
                this.updateSelectedItemIndex(newIndex);
            }

        } else {
            this._selectedIndex = newIndex;
        }
    }

    @Input()
    get selected(): CvStepComponent | undefined {
        return this.steps ? this.steps.toArray()[this.selectedIndex] : undefined;
    }
    set selected(step: CvStepComponent | undefined) {
        this.selectedIndex = step && this.steps ? this.steps.toArray().indexOf(step) : -1;
    }

    @Output() readonly selectionChange = new EventEmitter<StepperSelectionEvent>();

    constructor() { }

    ngAfterContentInit() {
        this._steps.changes
            .pipe(startWith(this._steps), takeUntil(this.unsubscibe$))
            .subscribe((steps: QueryList<CvStepComponent>) => {
                this.steps.reset(steps.filter(step => step._stepper === this));
                this.steps.notifyOnChanges();
            });
    }

    ngAfterViewInit() {
        this.steps.changes.subscribe(() => {
            if (!this.selected) {
                this._selectedIndex = Math.max(this._selectedIndex - 1, 0);
            }
        });
        this.updateSelectedItemIndex(this._selectedIndex);
    }

    ngOnDestroy() {
        this.steps.destroy();
        this.unsubscibe$.next();
        this.unsubscibe$.complete();
    }

    /** Selects and focuses the next step in list. */
    next(): void {
        const canGoForward = this._selectedIndex + 1 < this.steps.length;

        if (this.loop && !canGoForward) {
            this.selectedIndex = 0;
        } else {
            if (this._selectedIndex + 1 === this.steps.length) {
                this.emitLastStep();
            }

            this.selectedIndex = Math.min(this._selectedIndex + 1, this.steps.length - 1);
        }

    }

    /** Selects and focuses the previous step in list. */
    previous(): void {
        const canGoBack = this._selectedIndex > 0;

        if (this.loop && !canGoBack) {
            this.selectedIndex = this.steps.length - 1;
        } else {
            this.selectedIndex = Math.max(this._selectedIndex - 1, 0);
        }
    }

    hideSteps(): boolean {
        if (this._steps.length === 1) return true;
    }

    hideBackButton(): boolean {
        if (this._steps.length === 1) return true;

        if (this.loop) return false;

        return this.selectedIndex <= 0 || !this.canGoBack();
    }

    canGoBack(): boolean {
        const stepsArray = this.steps.toArray();

        const previousIndex = Math.max(this._selectedIndex - 1, 0);

        return stepsArray[previousIndex].editable;
    }

    // simple swipe
    private swipeCoord?: [number, number];
    private swipeTime?: number;
    swipe(e: TouchEvent, when: string): void {
        const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (when === 'end') {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (duration < 200
                && Math.abs(direction[0]) > 30 // Long enough
                && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
                //const swipe = direction[0] < 0 ? 'next' : 'previous';

                // Do whatever you want with swipe
                direction[0] < 0 ? this.next() : this.previous();

            }
        }
    }

    private updateSelectedItemIndex(newIndex: number): void {
        const stepsArray = this.steps.toArray();

        this.selectionChange.emit({
            selectedIndex: newIndex,
            previouslySelectedIndex: this._selectedIndex,
            selectedStep: stepsArray[newIndex],
            previouslySelectedStep: stepsArray[this._selectedIndex],
        });

        this._selectedIndex = newIndex;
    }

    private stepIncomplete(index: number): boolean {
        if (this._linear && index >= 0) {
            return this.steps
                .toArray()
                .slice(0, index)
                .some(step => {
                    return step.interacted && !step.completed;
                });
        }

        return false;
    }

    private emitLastStep() {
        const stepsArray = this.steps.toArray();

        this.selectionChange.emit({
            selectedIndex: this._selectedIndex,
            previouslySelectedIndex: this._selectedIndex,
            selectedStep: null,
            previouslySelectedStep: stepsArray[this._selectedIndex],
        });
    }

    private isValidIndex(index: number): boolean {
        return index > -1 && (!this.steps || index < this.steps.length);
    }
}


export class StepperSelectionEvent {

    /** Index of the step now selected. */
    selectedIndex: number;

    /** Index of the step previously selected. */
    previouslySelectedIndex: number;

    /** The step instance now selected. */
    selectedStep: CvStepComponent;

    /** The step instance previously selected. */
    previouslySelectedStep: CvStepComponent;
}
