import {
    Component,
    forwardRef,
    ElementRef,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    HostBinding,
    HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-cv-inline-text-edit',
    templateUrl: './cv-inline-text-edit.component.html',
    styleUrls: ['./cv-inline-text-edit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CvInlineTextEditComponent),
            multi: true
        }
    ]
})
export class CvInlineTextEditComponent implements ControlValueAccessor {
    private _disabled: null | boolean = null;
    private _value: string;
    private _inputElement: HTMLInputElement;
    private _originalValue: string;
    private _clickedInsideOfTheInput: boolean;

    @HostBinding('class.edit-mode')
    inEditMode = false;

    get value() {
        return this._value;
    }

    set value(val: string) {
        this._value = val;
        this.onChange(val);
    }

    get disabled() {
        return this._disabled;
    }

    get charCount() {
        if (this.value == null) {
            return 0;
        }

        return this.value.length;
    }

    @Input()
    iconColor = 'black';

    @Input()
    type = 'single-line';

    @Input()
    placeholder: string;

    @Input()
    maxlength: number = null;

    @Output()
    submitChange: EventEmitter<string> = new EventEmitter();

    @ViewChild('inputElement')
    set inputElement(element: ElementRef) {
        if (element === undefined) {
            return;
        }

        this._inputElement = element.nativeElement;
        this._inputElement.focus();
    }

    /**
     * Click on input/textarea
     * This is for iOS
     * because it doesn't respond to the browsers blur event ....
     */
    @HostListener('click')
    @HostListener('touchstart', ['$event'])
    clickInside(event): void {
        if (event) {
            this._clickedInsideOfTheInput = true;
        }
    }

    /**
     * Click outside of input/textarea
     * This is for iOS
     * because it doesn't respond to the browsers blur event ....
     */
    @HostListener('document:click')
    @HostListener('document:touchstart', ['$event'])
    blur(event): void {
        if (!this._clickedInsideOfTheInput && event && this.inEditMode) {
            this.commitChange();
            event.target.blur();
        }

        this._clickedInsideOfTheInput = false;
    }

    constructor() {}

    changeToEdit(): void {
        if (this.disabled === true) {
            return;
        }

        if (this.inEditMode === true) {
            return;
        }

        this._originalValue = this._value;
        this.inEditMode = true;
    }

    inputFocusOut(e: Event): void {
        if (this.inEditMode === false) {
            return;
        }

        this.commitChange();
    }

    cancelChanges(e: Event): void {
        e.preventDefault();
        this.resetChange();
    }

    onChange = (value: string) => {};
    onTouched = () => {};

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }

    commitChange(): void {
        this.inEditMode = false;

        if (this._value !== this._originalValue && this._value.length > 0) {
            this.submitChange.emit(this._value);
        } else if (this._value.length === 0) {
            this.resetChange();
        }
    }

    private resetChange(): void {
        // reset value
        this.value = this._originalValue;
        this.inEditMode = false;
    }
}
