import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { RedirectionHelper } from 'shared/helper';
import { getUserState, UserState } from 'store/user';

@Injectable()
export class LandingPageGuard  {
    constructor(private router: Router, private store: Store<UserState>) {}

    /**
     * Go to landing page if it returns true
     * otherwise return false and navigate to dashboard
     * (where student / teacher guard will do more checks)
     */
    canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(getUserState),
            map(userState => {
                if (userState.loaded && userState.isAuthorized) {
                    const dashboard = RedirectionHelper.getDashboardRoute(userState.accountType);
                    this.router.navigate(dashboard);
                    return false;
                }

                return true;
            }),
            switchMap(value => of(value)),
            take(1)
        );
    }
}
