import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import { CreateMessageModel } from 'shared/models';

@Injectable()
export class MessageService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) { }

    postMessage(message: CreateMessageModel): Observable<void> {
        const body = {
            body: message.body,
            recipientId: message.recipientId,
            notificationId: message.notificationId,
        };

        return this.http.post<void>(`${this.baseApiUrl}/message`, body);
    }
}
