import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherActivatedLicence extends BaseTrackingEvent{
    constructor(licenceActivationResult: string, isOldLicenceActive: boolean, licenceTitle: string = '', licenceId: number = 0) {
        super({
            eventId: 'aa50727d-11a7-4ecb-a52b-7cc0496f92ee',
            eventVerb: TrackingVerbs.ACTIVATED,
            eventObject: TrackingObjects.LICENCE,
            eventPayload: {
                licenceTitle: licenceTitle,
                licenceId: licenceId,
                licenceActivationResult: licenceActivationResult,
                licenceIsOldLicenceActive: isOldLicenceActive
            },
        });
    }
}
