import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StudentsState } from './students.reducer';
import { StudentDetails, StudentModel } from 'shared/models';
import { StoreHelper } from 'shared/helper';
import { getRouterState } from 'store/router';
import { getAllBooksDict } from 'store/books/books.selector';

const sortStudents = (student1: StudentModel, student2: StudentModel) => {
    if (student1.userName === student2.userName) {
        return 0;
    }

    if (student1.userName > student2.userName) {
        return 1;
    } else {
        return -1;
    }
};

export const getStudentsState = createFeatureSelector<StudentsState>('students');

export const getStudentsItems = createSelector(
    getStudentsState,
    state => state.allDict
);

export const selectAllStudents = createSelector(getStudentsItems, items =>
    StoreHelper.dictToArray(items).sort(sortStudents)
);

export const selectStudentsLoading = createSelector(getStudentsState, state => state.loading);

export const selectStudentDetailsLoading = createSelector(getStudentsState, state => state.loadingDetails);

export const hasStudents = createSelector(getStudentsState, state =>
    state.loaded && state.allDict != null && Object.keys(state.allDict).length > 0
);

export const allStudentsHaveAudioHidden = createSelector(
    selectAllStudents, students =>
    students.every(student => student.hideAudio)
);

export const allStudentsHaveSyllableColoringHidden = createSelector(
    selectAllStudents, students =>
        students.every(student => student.hideSyllableColoring)
);

export const getStudentsCount = createSelector(
    getStudentsItems,
    studentsDict => StoreHelper.getDictLength(studentsDict)
);

export const getSelectedStudent = createSelector(
    getStudentsItems,
    getRouterState,
    (students, router): StudentModel => {
        const { state } = router;

        if (state == null || students == null) {
            return null;
        }

        const { studentId } = state.params;
        return students[studentId];
    }
);

export const getSelectedStudentDetails = createSelector(
    getSelectedStudent,
    (student): StudentDetails => {
        if (student == null) {
            return null;
        }

        return student.details;
    }
);

export const getSelectedStudentNote = createSelector(
    getSelectedStudent,
    (student): {studentId: number, text: string} => {
        if (student == null) {
            return null;
        }

        return { studentId: student.id, text: student.details?.teacherNote };
    }
);

export const selectOpenedBooksFromStudent = createSelector(
    getSelectedStudentDetails,
    getAllBooksDict,
    (details, bookState) => {
        if (!details) {
            return [];
        }


        return details.openedBooks
            .map(id => bookState.dict[id])
            .sort((a, b) => {
                if (a.readingLevel === b.readingLevel) {
                    return a.title < b.title ? -1 : 0;
                }

                return a.readingLevel < b.readingLevel ? -1 : 1;
            })
            ;
    }
);

export const selectReadBooksFromStudent = createSelector(
    getSelectedStudentDetails,
    getAllBooksDict,
    (details, bookState) => {
        if (!details) {
            return [];
        }

        return details.readBooks
            .map(id => bookState.dict[id])
            .sort((a, b) => {
                if (a.readingLevel === b.readingLevel) {
                    return a.title < b.title ? -1 : 0;
                }

                return a.readingLevel < b.readingLevel ? -1 : 1;
            });
    }
);
