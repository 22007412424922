import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BooksService, ExerciseService } from 'shared/services';
import * as BookViewerActions from './book-viewer.actions';
import * as TrackingActions from '../tracking/tracking.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { UserOpenedBookViewer } from 'shared/tracking/models';
import { AlertService } from 'shared/_alert';

@Injectable()
export class BookViewerEffect {

    constructor(
        private actions$: Actions,
        private exerciseService: ExerciseService,
        private booksService: BooksService,
        private router: Router,
        private alertService: AlertService
    ) { }

    openBookEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookViewerActions.openBook),
            tap(({ book, isAssigned, isTeacher }) => {
                if (isTeacher) {
                    this.router.navigate(['lehrer', 'buch-lesen', book.seoFriendlyTitle], { state: { defaultBookPath: this.router.url } });
                } else {
                    this.router.navigate(['schueler', 'buch-lesen', book.seoFriendlyTitle], { state: { defaultBookPath: this.router.url } });
                }
            })
        ), { dispatch: false });

    openBookTrackingEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BookViewerActions.openBook),
            map(({ book, isAssigned }) =>
                TrackingActions.userOpenedBookViewer({
                    event: new UserOpenedBookViewer(book.title, 0, this.getAssignedString(isAssigned))
                })
            )
        );
    });

    openExerciseResultEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookViewerActions.openExerciseResult),
            switchMap(({ exerciseId, studentId }) =>
                this.exerciseService.getExerciseResultPageLink(exerciseId, studentId).pipe(
                    tap(({ bookLink }) => {
                        if (bookLink === null) {
                            this.alertService.error('Fehler beim Laden', { autoClose: true });
                        }
                    }),
                    switchMap(({ bookLink }) => of(BookViewerActions.openExerciseResultSuccess({ exerciseUrl: bookLink, studentId: studentId }))),
                    catchError((error: unknown) => of(BookViewerActions.openExerciseResultFail({ error: error })))
                )
            ),
        )
    );

    navigateToExerciseResultEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookViewerActions.openExerciseResultSuccess),
            tap(({ exerciseUrl, studentId }) => {
                this.router.navigate([`${exerciseUrl}`], { queryParams: { studentId: studentId }, state: { defaultBookPath: this.router.url } });
            })
        )
        , { dispatch: false });

    openExercisePageEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookViewerActions.openExercisePage),
            switchMap(({ exercise }) =>
                this.booksService.getExerciseLink(exercise.id).pipe(
                    tap(({ bookLink }) => {
                        if (bookLink === null) {
                            this.alertService.error('Fehler beim Laden', { autoClose: true });
                        }
                    }),
                    switchMap(({ bookLink }) => of(BookViewerActions.openExercisePageSuccess({ exerciseUrl: bookLink }))),
                    catchError((error: unknown) => of(BookViewerActions.openExercisePageFail({ error: error })))
                )
            ),
        ));

    navigateToExercisePageEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BookViewerActions.openExercisePageSuccess),
            tap(({ exerciseUrl }) => {
                this.router.navigate([`${exerciseUrl}`], { state: { defaultBookPath: this.router.url } });
            })
        )
        , { dispatch: false });

    private getAssignedString(isAssigned: boolean): string {
        return isAssigned ? 'assigned' : 'voluntary';
    }
}
