import {
    Directive,
    Input,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';

import { SortDirection, SortEvent } from 'shared/models';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[sortable]'
})
export class SortableDirective {
    private readonly rotate: { [key: string]: SortDirection } = {
        asc: 'desc',
        desc: 'asc'
    };

    @Input() sortable: string;
    @Input() direction: SortDirection = 'asc';

    @Output() sort = new EventEmitter<SortEvent<any>>();

    @HostListener('click')
    onClick() {
        // don't sort when sortable undefined
        if (this.sortable === undefined) {
            return;
        }

        this.direction = this.rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}
