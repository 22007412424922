import { UserModel, StudentDetails } from '.';

export const defaultMonsterKey = 'defaultmonster';

export interface StudentModel extends UserModel {
    id: number;
    userName: string;
    avatarKey: string;
    readingLevel?: number;
    readBooks: number;
    openedBooks: number;
    hideAudio: boolean;
    hideSyllableColoring: boolean;

    exerciseCount: number; // used by teacher app only
    doneExerciseCount: number; // used by teacher app only
    studentCode?: string; // used by teacher app only
    details?: StudentDetails; // used by teacher app only

    avatarUri?: string;  // used by student app only
    hasLicence: boolean;  // used by student app only
    tutorLeseoName: string;  // used by student app only
    totalScore: number; // used by student app only
    maxScore?: number; // used by student app only
    openAssignmentsCount?: number; // used by student app only

    schoolClassId: number;
}

export interface StudentCreateModel {
    userName: string;
    readingLevel?: number;
}

export interface StudentOnlyCodeModel {
    id: number;
    studentCode: string;
}

export enum StudentReadingConfigurationEnum {
    HideAudio,
    HideSyllableColoring
}
