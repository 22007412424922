import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CvPopUpHelper } from 'shared/helper';
import { AccountType, defaultMonsterKey, NotificationType, Sort, StudentModel } from 'shared/models';
import { NotificationService } from 'shared/services';
import { closePopup } from 'store/layout/layout.actions';
import { getIsPopupVisible } from 'store/layout/layout.selector';
import { fillStore } from 'store/user';
import * as NotificationActions from './notifications.actions';
import { NotificationsState } from './notifications.reducer';
import { getTeacherNotifications } from './notifications.selector';

@Injectable()
export class NotificationsEffects {
    fetchNotificationsInitialPageLoadStudent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fillStore),
            filter(({accountType}) => accountType === AccountType.Student),
            map(({profile}) => <StudentModel>profile),
            filter(p => this.isProfileSetUp(p)),
            map((_) => NotificationActions.fetchNotifications({ limit: 1 })),
            catchError((e: unknown) => of(NotificationActions.fetchNotificationsFail({error: e})))
        )
    );

    fetchNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.fetchNotifications),
            switchMap((props) =>
                this.notificationService.getNotifications(props.cursor, props.limit, props.sort).pipe(
                    map(notifications =>
                        NotificationActions.fetchNotificationsSuccess({
                            notifications
                        })
                    ),
                    catchError((error: unknown) =>
                        of(
                            NotificationActions.fetchNotificationsFail({
                                error
                            })
                        )
                    )
                )
            )
        )
    );

    fetchTeacherNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.fetchTeacherNotifications),
            withLatestFrom(
                this.store.pipe(select(getTeacherNotifications))
            ),
            switchMap(([props, notificationsList]) => {
                let cursor = 0;
                let sort = props.sort;

                if (sort && notificationsList.length > 0) {
                    cursor = props.sort === Sort.desc
                        ? notificationsList[notificationsList.length - 1].notification.id
                        : notificationsList[0].notification.id;
                }

                if (!sort && notificationsList.length > 0) {
                    // ascending
                    cursor = notificationsList[notificationsList.length - 1].notification.id;
                    sort = Sort.asc;
                }

                return this.notificationService.getTeacherNotifications(cursor, props.limit, sort).pipe(
                    map(notifications =>
                        NotificationActions.fetchTeacherNotificationsSuccess({
                            notifications
                        })
                    ),
                    catchError((error: unknown) =>
                        of(
                            NotificationActions.fetchNotificationsFail({
                                error
                            })
                        )
                    )
                );
            }
            )
        )
    );

    openNotificationPopup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.fetchNotificationsSuccess),
            withLatestFrom(
                this.store.pipe(select(getIsPopupVisible)),
                ({ notifications }, isPopupVisible) => ({
                    notifications,
                    isPopupVisible
                })
            ),
            filter(({ isPopupVisible }) => !isPopupVisible),
            map(({ notifications }) =>
                // filter unread & level up notifications
                notifications.filter(n => n.isNew && n.typeOfNotification === NotificationType.LevelUp)
            ),
            filter(notifications => notifications.length > 0),
            map(notifications => {
                const notification = notifications[0];
                const openPopup = CvPopUpHelper.createOpenPopupActionForNotification(
                    notification
                );
                return openPopup;
            })
        )
    );

    markAsRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.markAsRead),
            switchMap(({ id }) =>
                this.notificationService.markAsRead(id).pipe(
                    map(() => NotificationActions.markAsReadSuccess({ id })),
                    catchError((error: unknown) =>
                        of(NotificationActions.markAsReadFail({ error }))
                    )
                )
            )
        )
    );

    // todo: move to popup effect
    closeNotificationPopup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                NotificationActions.markAsReadSuccess,
                NotificationActions.markAsReadFail
            ),
            map(() => closePopup())
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<NotificationsState>,
        private notificationService: NotificationService
    ) { }

    private isProfileSetUp(profile: StudentModel) {
        return !(profile.avatarUri.includes(defaultMonsterKey));
    }
}
