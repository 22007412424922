import { TrackingVerbs, TrackingObjects } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherClickedCompletedTasks extends BaseTrackingEvent{
    constructor() {
        super({
            eventId: '37473e82-8cdc-4be8-9579-26c812f87eb2',
            eventVerb: TrackingVerbs.CLICKED,
            eventObject: TrackingObjects.UIELEMENT,
            eventPayload: {
                uielementTitle: 'Completed Tasks',
                uielementId: 0,
            },
        });
    }
}
