import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Sort, TeacherNotificationModel, StudentNotificationModel } from 'shared/models';
import { EnvironmentService } from 'app/core/services/environment.service';

@Injectable()
export class NotificationService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) {}

    // @todo: change to use latest endpoint
    getNotifications(cursor = 0, limit = 20, sort = Sort.desc): Observable<StudentNotificationModel[]> {
        const params: any = {};
        params.id = cursor;
        params.limit = limit;
        params.sort = sort;

        return this.http.get<StudentNotificationModel[]>(
            `${this.baseApiUrl}/notification`, { params }
        );
    }

    /**
     * Gets notifications for the teacher
     * @param cursor API will start with ids following the cursor, default = 0
     * @param limit number of notifications to get, default = 20
     * @param sort direction in which the results will be requested, default = Sort.desc
     */
    getTeacherNotifications(cursor = 0, limit = 20, sort = Sort.desc): Observable<TeacherNotificationModel[]> {
        const params: any = {};
        params.id = cursor;
        params.limit = limit;
        params.sort = sort;

        return this.http.get<TeacherNotificationModel[]>(
            `${this.baseApiUrl}/notification`, { params }
        );
    }

    markAsRead(id: number): Observable<void> {
        return this.http.patch<void>(`${this.baseApiUrl}/notification`, id);
    }
}
