import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {

    get nativeWindow(): any {
        return window;
    }

    public getWindowLocationHref(): string {
        return window.location.href;
    }

    public getUserAgent(): string {
        return window.navigator.userAgent;
    }

    public encodeString(valueToEncode: string) {
        return window.btoa(valueToEncode);
    }

    public openInNewTab(url: string) {
        window.open(url, '_blank');
    }
}
