import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { Store, select } from '@ngrx/store';

import { fetchStudentDetail } from 'store/students/students.actions';
import { AssignmentDetailModel } from 'shared/models';
import { Observable } from 'rxjs';
import { getSelectedAssignmentItem } from 'store/assignments';
import { SelectExerciseComponent } from '../select-exercise/select-exercise.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-assignment',
    templateUrl: './edit-assignment.component.html',
    styleUrls: ['./edit-assignment.component.scss']
})
export class EditAssignmentComponent implements OnInit {

    assignmentDetail$: Observable<AssignmentDetailModel>;

    studentIds?: number[] = [];

    updating = false;

    @ViewChild('createExerciseComponent')
    createExerciseComponent: SelectExerciseComponent;

    constructor(private store: Store, private dialogRef: MatDialogRef<EditAssignmentComponent>, @Inject(MAT_DIALOG_DATA) private data: AssignmentDetailModel) {}

    ngOnInit() {
        this.assignmentDetail$ = this.store.pipe(
            select(getSelectedAssignmentItem)
        );
    }

    updateAssignment(): void {
        if (this.createExerciseComponent) {
            this.updating = true;
            const success = this.createExerciseComponent.publish();
            if (success) {
                if (this.studentIds.length === 1) {
                    this.store.dispatch(fetchStudentDetail({ studentId: this.studentIds[0] }));
                }
                this.dialogRef.close();
            }
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
