import { Component, OnInit, Input } from '@angular/core';
import { NotificationType, CreateMessageModel } from 'shared/models';
import { Store } from '@ngrx/store';
import { RootState } from 'store/root.reducer';
import { postMessage } from 'store/messages/messages.actions';
import { closePopup } from 'store/layout';

@Component({
    selector: 'app-send-message',
    templateUrl: './send-message.component.html',
    styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {

    @Input()
    studentId: number;

    @Input()
    userName: string;

    @Input()
    typeOfNotification: NotificationType;

    @Input()
    notificationId: number;

    maxMessageLength = 1000;

    subheadline = '';
    action = '';
    message = '';
    sticker = '';
    feebackOptions = [];
    sending = false;

    tooltipOptions = {
        'tooltip-class': 'feedback-tooltip',
        'theme': 'light'
    };

    tooltipText = 'Um den Lernerfolg Ihrer Lernenden zu fördern, hilft Feedback in Form von lernrelevanten und lernwirksamen Rückmeldungen. Dieses Feedback sollte den Lernprozess begleiten und nicht erst am Ende eines Lernziels gegeben werden.';

    get showWarning() {
        return !(this.message || this.message.length > 0);
    }

    constructor(private store: Store<RootState>) { }

    ngOnInit(): void {
        this.setMessageContent();
    }

    private setMessageContent() {
        switch (this.typeOfNotification) {
            case NotificationType.LevelUp:
                this.subheadline = 'Senden Sie zielgerichtetes Feedback bzgl. des Lesestufenaufstiegs, so wird Lernerfolg und Motivation gefördert.';
                this.message = `Bravo, ${this.userName}! Du hast eine neue Lesestufe erreicht.`;
                this.sticker = 'berg';
                this.action = 'loben';
                this.feebackOptions = [
                    'Glückwunsch!',
                    'Du hast richtig viel geübt.',
                    'Mach weiter so!',
                    'Lies weiterhin so konzentriert und gewissenhaft.',
                    'Schau gleich, welche spannenden Bücher es noch gibt!'
                ];
                break;
            case NotificationType.LevelDown:
                this.subheadline = 'Senden Sie zielgerichtetes Feedback bzgl. des Lesestufenabstiegs, um Lernerfolg und Motivation zu fördern.';
                this.message = `Du bist leider eine Lesestufe abgestiegen. Kopf hoch, ${this.userName}!`;
                this.sticker = 'pflaster';
                this.action = 'ermutigen';
                this.feebackOptions = [
                    'Lass uns noch mehr gemeinsam üben.',
                    'Lies langsamer.',
                    'Konzentrier dich auf den Text vor dir.',
                    'Lies bekannte Texte noch einmal.',
                    'Schau dir nochmal die Fragen zu den Texten in Ruhe an.',
                    'Verstehst du deine Fehler?'
                ];
                break;
            case NotificationType.MaxScore:
                this.subheadline = 'Senden Sie zielgerichtetes Feedback zum Erreichen der vollen Punktzahl, so fördern Sie Lernerfolg und Motivation.';
                this.message = `Wunderbar, ${this.userName}! Du hast volle Punktzahl, lies weiter so konzentriert.`;
                this.sticker = 'stern';
                this.action = 'loben';
                this.feebackOptions = [
                    'Du hast wiederholt alles richtig, das ist echt prima!',
                    'Klasse, du hast alle Aufgaben richtig gelöst!',
                    'Du kannst stolz auf dich sein.',
                    'Weiter so!',
                    'Lies weiterhin so konzentriert und gewissenhaft.'
                ];
                break;
            case NotificationType.PriorKnowledge:
                this.subheadline = 'Senden Sie zielgerichtetes Feedback zum Vorwissen, so fördern Sie Lernerfolg und Motivation.';
                this.message = `Danke für deine Sprachnachricht über das Buchthema, ${this.userName}!`;
                this.sticker = 'vorwissen';
                this.action = 'Feedback geben';
                this.feebackOptions = [
                    'Prima, wie viel du schon über […] weißt.',
                    'Leider hast du die Frage nicht richtig verstanden.',
                    'Denk nochmal über […] nach.',
                    'Die Frage war, […].',
                    'Im Text erfährst du mehr dazu.'
                ];
                break;
            case NotificationType.Expectations:
                this.subheadline = 'Senden Sie zielgerichtetes Feedback zu den Leseerwartungen, um Lernerfolg und Motivation zu fördern.';
                this.message = `Prima, ${this.userName}, dass du dir vor dem Lesen schon Gedanken über das Buch gemacht hast.`;
                this.sticker = 'leseerwartungen';
                this.action = 'Feedback geben';
                this.feebackOptions = [
                    'Tolle Ideen hattest du!',
                    'Es geht im Text aber doch um etwas anderes.',
                    'Eigentlich geht es im Text um […].',
                    'Schau dir das nächste Mal den Umschlag und den Titel noch etwas genauer an!'
                ];
                break;
            default:
                break;
        }
    }

    appendText(text: string, elem: HTMLElement): void {
        let newMsg = this.message.trim();
        newMsg += ' ' + text.trim();
        if (newMsg.length <= this.maxMessageLength) {
            this.message = newMsg;
            elem.focus();
        }
    }

    sendMessage(): void {
        if (!this.message || this.message.length === 0 || this.sending) {
            return;
        }

        const message: CreateMessageModel = {
            recipientId: this.studentId,
            body: this.message,
            notificationId: this.notificationId
        };

        this.sending = true;

        this.store.dispatch(postMessage({ message, userName: this.userName, notificationType: this.typeOfNotification }));
    }

    close(): void {
        this.store.dispatch(closePopup());
    }

}
