import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreHelper } from 'shared/helper';
import { SchoolClassesState } from './school-classes.reducer';

export const getSchoolClassesState = createFeatureSelector<SchoolClassesState>(
    'schoolClass'
);

export const getAllSchoolClasses = createSelector(
    getSchoolClassesState,
    state => state.all
);

export const getAllSchoolClassesList = createSelector(
    getSchoolClassesState,
    state => StoreHelper.recordToArray(state.all)
);

export const getAllSchoolClassesCount = createSelector(
    getSchoolClassesState,
    state => StoreHelper.recordToArray(state.all).length
);

export const getSchoolClass = createSelector(getSchoolClassesState, state => {
    // todo return empty state instead of null
    // and check loaded property... because...
    if (!state.activeSchoolClassId) {
        // no school-class
        return null;
    }
    return state.all[state.activeSchoolClassId];
});

export const hasSchoolClass = createSelector(getSchoolClassesState, state => {
    return !!(state.loaded && state.activeSchoolClassId);
});

export const getSchoolClassesId = createSelector(
    getSchoolClassesState,
    state => state.activeSchoolClassId
);

export const getSchoolClassesName = createSelector(
    getSchoolClass,
    state => state?.name
);

export const getSchoolClassesClassCode = createSelector(
    getSchoolClass,
    state => state?.classCode
);

export const selectSchoolClassDetails = createSelector(
    getSchoolClass,
    state => state?.details
);

export const getSchoolClassLoaded = createSelector(
    getSchoolClassesState,
    state => state.loaded
);

export const getSchoolClassLoading = createSelector(
    getSchoolClassesState,
    state => state.loading
);

export const isSchoolClassOwner = createSelector(
    getSchoolClassesState,
    state => state.all[state.activeSchoolClassId]?.isOwner
);
