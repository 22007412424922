import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class StudentReceivedMessage extends BaseTrackingEvent {
    constructor(messageTitle: string = '', messageId: number = 0) {
        super({
            eventId: '7edd8f88-bd2a-4283-8118-075f90b29711',
            eventVerb: TrackingVerbs.DETECTED, // using this verb since there is no other more appropriate on cvpa
            eventObject: TrackingObjects.MESSAGE,
            eventPayload: {
                messageTitle: messageTitle,
                messageId: messageId
            },
        });
    }
}
