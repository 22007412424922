import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { combineLatestWith, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AccountType, StudentModel } from 'shared/models';
import { TrackingService } from 'shared/services';
import { StudentAuthenticatedIdentity } from 'shared/tracking/models';
import { TeacherAuthenticatedIdentity } from 'shared/tracking/models/teacher-authenticated-identity.model';
import { getSchoolClassesState } from 'store/school-classes';
import { SchoolState } from 'store/school/school.reducer';
import { getAccountType, getLicenceOwnerRole, getProfile, loadProfileSuccess, loginStudentSuccess, trackLearnerLoginSuccess } from 'store/user';
import * as TrackingActions from './tracking.actions';

@Injectable()
export class TrackingEffects {
    constructor(
        private actions$: Actions,
        private store: Store<SchoolState>,
        private trackingService: TrackingService
    ) { }

    sendTrackingEventForNotLoggedInUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(TrackingActions.userViewedScreen),
                withLatestFrom(this.store.select(getAccountType)),
                filter(([_, accountType]) => accountType === null),
                switchMap(([{ event }]) =>
                    this.trackingService.track(event, null, null, null, null, null).pipe(
                        map(() => TrackingActions.trackingEventSentSuccessfully({ event, accountType: null, profile: null, schoolClassId: null }))
                    ))
            )
    );

    trackTeacherLogin$ = createEffect(
        () => this.actions$.pipe(
            ofType(loadProfileSuccess),
            filter(({ accountType, firstTime }) => accountType === AccountType.Teacher && firstTime),
            map(({ profile }) => {
                const isLicenceActive = profile?.licence?.isActive;
                const schoolId = profile?.schoolId;

                return TrackingActions.teacherAuthenticatedIdentity({ event: new TeacherAuthenticatedIdentity(isLicenceActive, '', 0, schoolId) });
            })
        )
    );

    // this is used for the learner login via leseo password
    trackLearnerLeseoLogin$ = createEffect(
        () => this.actions$.pipe(
            ofType(loginStudentSuccess),
            map(() => trackLearnerLoginSuccess({ schoolId: '' }))
        )
    );

    trackLearnerLogin$ = createEffect(
        () => this.actions$.pipe(
            ofType(trackLearnerLoginSuccess),
            map(({ schoolId }) => TrackingActions.studentAuthenticatedIdentity({ event: new StudentAuthenticatedIdentity('', 0, schoolId) }))
        )
    );

    sendTeacherTrackingEvent$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    TrackingActions.teacherActivatedLicence,
                    TrackingActions.teacherAuthenticatedIdentity,
                    TrackingActions.teacherClickedBanner,
                    TrackingActions.teacherClickedCompletedTasks,
                    TrackingActions.teacherCreatedSchoolClass,
                    TrackingActions.teacherCreatedTask,
                    TrackingActions.teacherDeletedSchoolClass,
                    TrackingActions.teacherOpenedResultPage,
                    TrackingActions.teacherRegisteredAccount,
                    TrackingActions.teacherSavedStudentNoteFeature,
                    TrackingActions.teacherUpdatedTask,
                    TrackingActions.teacherUsedGlobalNotificationFeature,
                    TrackingActions.teacherUsedReadingConfigurationFeature,
                    TrackingActions.teacherUsedReadingLevelFeature,
                    TrackingActions.teacherUpdateLearnersPerGroup,

                    TrackingActions.userAddedFavorite,
                    TrackingActions.userClickedUIElement,
                    TrackingActions.userDeletedFavorite,
                    TrackingActions.userOpenedBookViewer,
                    TrackingActions.userOpenedTask,
                    TrackingActions.userSendMessage,
                    TrackingActions.userViewedScreen,
                    TrackingActions.userUsedBookFilter,
                ),
                withLatestFrom(this.store.select(getAccountType)),
                filter(([_, accountType]) => accountType === AccountType.Teacher),
                // merge map waits for inner observable to finish
                // so that each tracking action is completed
                mergeMap(([{ event }, accountType]) =>
                    combineLatest([this.store.select(getProfile), this.store.select(getSchoolClassesState), this.store.select(getLicenceOwnerRole)]).pipe(
                        filter(([profile, schoolClassState]) => profile !== null && profile.loaded && schoolClassState.loaded),
                        take(1), // take 1 so we don't send endless, duplicate tracking events
                        switchMap(([profile, schoolClassState, role]) => {
                            const schoolClassId = schoolClassState?.activeSchoolClassId || 0;
                            return this.trackingService.track(event, accountType, role, profile, schoolClassId, profile.schoolId).pipe(
                                map(() => TrackingActions.trackingEventSentSuccessfully({ event, accountType, profile, schoolClassId }))
                            );
                        }),
                    )
                )
            )
    );

    sendStudentTrackingEvent$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    TrackingActions.studentUsedMonsterChangeFeature,
                    TrackingActions.studentReceivedMessage,
                    TrackingActions.studentNavigatedOnboarding,
                    TrackingActions.studentAuthenticatedIdentity,

                    TrackingActions.userAddedFavorite,
                    TrackingActions.userClickedUIElement,
                    TrackingActions.userDeletedFavorite,
                    TrackingActions.userOpenedBookViewer,
                    TrackingActions.userOpenedTask,
                    TrackingActions.userSendMessage,
                    TrackingActions.userUsedBookFilter,
                    TrackingActions.userViewedScreen,
                ),
                combineLatestWith(
                    this.store.pipe(select(getAccountType))
                ),
                filter(([{ event }, accountType]) => accountType === AccountType.Student),
                // merge map waits for inner observable to finish
                // so that each tracking action is completed
                mergeMap(([{ event }, accountType]) =>
                    combineLatest([this.store.select(getProfile), this.store.select(getLicenceOwnerRole)]).pipe(
                        filter(([profile]) => profile !== null && profile.loaded),
                        take(1), // take 1 so we don't send endless, duplicate tracking events
                        switchMap(
                            ([profile, role]) => {
                                const schoolClassId = (profile as StudentModel).schoolClassId;

                                return this.trackingService.track(event, accountType, role, profile, (profile as StudentModel).schoolClassId, profile.schoolId).pipe(
                                    map(() => TrackingActions.trackingEventSentSuccessfully({ event, accountType, profile, schoolClassId }))
                                );
                            }
                        )
                    )
                )
            )
    );
}
