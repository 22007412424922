import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isGlobalNotificationNew, showGlobalNotifications } from 'store/layout';

@Component({
    selector: 'app-global-notifications',
    templateUrl: './global-notifications.component.html',
    styleUrls: ['./global-notifications.component.scss']
})
export class GlobalNotificationsComponent implements OnInit {
    showNotifications = false;
    isUnread$: Observable<boolean>;

    defaultLinkText = 'Mehr erfahren';

    constructor(private store: Store) { }

    ngOnInit(): void {
        this.isUnread$ = this.store.pipe(select(isGlobalNotificationNew));
    }

    toggleNotifications() {
        this.showNotifications = !this.showNotifications;

        if (this.showNotifications) {
            this.store.dispatch(showGlobalNotifications());
        }
    }

}
