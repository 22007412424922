import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StoreHelper } from 'shared/helper';
import { AssignmentModel, AssignmentStatus } from 'shared/models';
import { getBooksState } from 'store/books';
import { TeacherAssignmentsState } from './assignments.reducer';

export const getAssignmentsState = createFeatureSelector<TeacherAssignmentsState>(
    'teacherAssignments'
);

export const getAssignments = createSelector(getAssignmentsState, state => state.all);

export const getAssignmentsLoaded = createSelector(
    getAssignments,
    state => state.loaded
);

export const getAssignmentsDict = createSelector(
    getAssignments,
    state => state
);

export const getAssignmentsList = createSelector(
    getAssignments,
    ({ loaded, dict }) => {
        const list = StoreHelper.dictToArray(dict);

        return {
            list,
            loaded
        };
    }
);

export const selectHasLoadedCompletedAssignments = createSelector(
    getAssignments,
    (state) => state.loadedCompletedAssignments
);

export const getSortedOpenAssignmentsList = createSelector(
    getAssignmentsList,
    state =>
        state.list
            .filter(a => a.status === AssignmentStatus.Open)
            .sort((a, b) => {
                if (a.dueDate === b.dueDate) {
                    return 0;
                }

                return a.dueDate < b.dueDate ? -1 : 1;
            })
);

export const getSelectedAssignment = createSelector(
    getAssignmentsState,
    state => state.selected
);

export const getSelectedAssignmentItem = createSelector(
    getSelectedAssignment,
    selectedAssignment => selectedAssignment.item
);

export const getSelectedAssignmentLoading = createSelector(
    getSelectedAssignment,
    selectedAssignment => selectedAssignment.loading
);

export const getSelectedAssignmentLoaded = createSelector(
    getSelectedAssignment,
    selectedAssignment => selectedAssignment.loaded
);

export const getBookFromSelectedAssignment = createSelector(
    getSelectedAssignment,
    getBooksState,
    (assignmentState, { booksDetails }) => {
        if (assignmentState == null || assignmentState.loaded === false) {
            return null;
        }

        return booksDetails[assignmentState.item.bookId];

    }
);


export const getSelectedAssignmentWithBookMetadata = createSelector(
    getSelectedAssignment,
    getBookFromSelectedAssignment,
    (assignmentState, book) => {
        if (assignmentState == null || assignmentState.loaded === false || book === null) {
            return null;
        }

        return { assignmentDetail: assignmentState.item, book: book };
    }
);

export const hasReadingStrategies = createSelector(
    getBookFromSelectedAssignment,
    (book) => {

        if (book && book.metadata && book.metadata.strategies && book.metadata.strategies.length > 0) {
            return true;
        }

        return false;
    }
);

export const getAssignmentById = createSelector(
    getAssignmentsDict,
    (allAssignments, props): AssignmentModel => {
        if (!allAssignments.loaded) {
            return;
        }

        const { dict } = allAssignments;

        return dict[props];
    }
);

export const getEditor = createSelector(
    getAssignmentsState,
    (state) => {
        return state.editor;
    }
);

export const getEditorExercises = createSelector(
    getEditor,
    (editor) => {
        return editor.exercises;
    }
);
