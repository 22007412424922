import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 028_PDF-Dokument_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-pdf-file',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
  <path d="M12.758,51.024h7.086V48.189H14.175V8.5H32.6v8.5a1.417,1.417,0,0,0,1.417,1.417h8.5V35.433h2.835V16.3a1.416,1.416,0,0,0-.415-1L35.728,6.084a1.42,1.42,0,0,0-1-.415H12.758A1.418,1.418,0,0,0,11.34,7.087V49.606A1.418,1.418,0,0,0,12.758,51.024ZM41.225,15.59h-5.79V9.8ZM31.173,40.476a3.97,3.97,0,0,0-1.426-.608,8.026,8.026,0,0,0-1.756-.183H24.1V51.023h2.557V47.18h1.409a6.236,6.236,0,0,0,1.7-.226,4.1,4.1,0,0,0,1.408-.7,3.349,3.349,0,0,0,.966-1.2,3.9,3.9,0,0,0,.356-1.722,3.809,3.809,0,0,0-.356-1.73A3.021,3.021,0,0,0,31.173,40.476ZM27.9,45.146H26.652V41.72h1.183a2.911,2.911,0,0,1,1.6.365,1.381,1.381,0,0,1,.557,1.252Q29.991,45.146,27.9,45.146Zm11.947,5.53A4.656,4.656,0,0,0,41.6,49.624a4.709,4.709,0,0,0,1.13-1.783,7.288,7.288,0,0,0,.4-2.539,7.136,7.136,0,0,0-.4-2.521,4.468,4.468,0,0,0-1.148-1.748,4.71,4.71,0,0,0-1.791-1.018,7.8,7.8,0,0,0-2.347-.33h-3.2V51.023h3.34A7.008,7.008,0,0,0,39.851,50.676ZM36.8,48.954v-7.2h.487a4.675,4.675,0,0,1,1.322.175,2.37,2.37,0,0,1,1.008.582,2.761,2.761,0,0,1,.652,1.095,5.284,5.284,0,0,1,.235,1.7,5.429,5.429,0,0,1-.235,1.722,2.9,2.9,0,0,1-.652,1.121,2.439,2.439,0,0,1-1.008.618,4.3,4.3,0,0,1-1.322.191Zm8.425-9.269h7.148v2.156H47.781v2.626h3.93v2.156h-3.93v4.4H45.224Z" />
</svg>
  `
})
export class SvgIconPdfFileComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'pdf-file';
    }
}
