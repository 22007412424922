export interface AuthConfig {
    enableDebugMode: boolean;
    httpInterceptor: HttpInterceptorConfig;
    authConfig: OidcClientConfig;
    berlinConfig: OidcClientConfig;
    eduPoolConfig: OidcClientConfig;
    studentConfig: {
        validationPattern: RegExp
    }
}

export interface HttpInterceptorConfig {
    sendAccessToken: boolean;
    allowedUrls: string[];
}

export interface OidcClientConfig {
    authority: string;
    client_id: string;
    client_secret: string;
    redirect_uri: string;
    post_logout_redirect_uri: string;
    response_type: string;
    response_mode: string;
    scope: string;
    silent_redirect_uri: string;
    automaticSilentRenew: boolean;
    accessTokenExpiringNotificationTime: number;
    filterProtocolClaims: boolean;
    loadUserInfo: boolean;
    extraQueryParams?: { audience: string };
    clockSkew?: number;
}

export enum LoginProvider {
    cornelsen = 'cornelsen',
    berlin = 'berlin',
    edupool = 'edupool',
}
