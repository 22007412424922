import { createAction, props } from '@ngrx/store';
import { BookDetailModel, BookModel, ExerciseModel } from 'shared/models';

// TODO: Refactor actions as well when CLAB is ready;

export enum BookViewerActions {
    OpenBook = '[Book viewer] Open book',

    OpenExercisePage = '[Book viewer] Open Exercise Page',
    OpenExercisePageSuccess = '[Book viewer] Open Exercise Page success',
    OpenExercisePageFail = '[Book viewer] Open Exercise Page fail',

    OpenExerciseResult = '[Book viewer] Open exercise result',
    OpenExerciseResultSuccess = '[Book viewer] Open exercise result success',
    OpenExerciseResultFail = '[Book viewer] Open exercise result fail',
}

export const openBook = createAction(BookViewerActions.OpenBook, props<{ book: BookModel | BookDetailModel, isAssigned: boolean, isTeacher: boolean }>());

export const openExercisePage = createAction(BookViewerActions.OpenExercisePage, props<{ book: BookModel | BookDetailModel, exercise: ExerciseModel, isAssigned: boolean }>());
export const openExercisePageSuccess = createAction(BookViewerActions.OpenExercisePageSuccess, props<{ exerciseUrl: string }>());
export const openExercisePageFail = createAction(BookViewerActions.OpenExercisePageFail, props<{ error: unknown }>());

export const openExerciseResult = createAction(BookViewerActions.OpenExerciseResult, props<{ exerciseId: number, studentId: number }>());
export const openExerciseResultSuccess = createAction(BookViewerActions.OpenExerciseResultSuccess, props<{ exerciseUrl: string, studentId: number }>());
export const openExerciseResultFail = createAction(BookViewerActions.OpenExerciseResultFail, props<{ error: unknown }>());
