import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { LeseoNameErrorPopupComponent } from 'app/teacher/user-sidebar/components/leseo-name-error-popup/leseo-name-error-popup.component';
import { DEFAULT_DIALOG_CONFIG } from 'environments/app-config';
import { concatMap, concat, map, catchError, of, tap, mergeMap } from 'rxjs';
import { UserService } from 'shared/services';
import * as TutorOnboardingActions from 'store/tutor-onboarding/tutor-onboarding.actions';

@Injectable()
export class TutorOnboardingEffects {
    setLeseoTutorName$ = createEffect(() => this.actions$.pipe(
        ofType(TutorOnboardingActions.addLeseoName),
        concatMap(({leseoName}) =>
            concat(
                this.userService.updateLeseoName(leseoName).pipe(
                    map((leseoName) => TutorOnboardingActions.addLeseoNameSuccess({leseoName})),
                    catchError((errorResponse: unknown) => {
                        return of(TutorOnboardingActions.addLeseoNameFail({ error: errorResponse }));
                    })
                )
            )
        )
    ));

    addLeseoNameSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(TutorOnboardingActions.addLeseoNameSuccess),
        map(() => TutorOnboardingActions.nextOnboardingStep())
    ));

    updateUserNameFail$ = createEffect(() => this.actions$.pipe(
        ofType(TutorOnboardingActions.addLeseoNameFail),
        tap(() => {
            this.dialog.open(LeseoNameErrorPopupComponent, {
                panelClass: [...DEFAULT_DIALOG_CONFIG.panelClass, 'modal-alert']
            });
        })
    ), {dispatch: false});

    completeOnboarding$ = createEffect(() => this.actions$.pipe(
        ofType(TutorOnboardingActions.completeOnboarding),
        mergeMap(() =>
            this.userService.onboardPrivateUser().pipe(
                map(() => TutorOnboardingActions.completeOnboardingSuccess()),
                catchError((errorResponse: unknown) => {
                    return of(TutorOnboardingActions.completeOnboardingFail({ error: errorResponse }));
                })
            )
        )
    ));

    completeOnboardingSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(TutorOnboardingActions.completeOnboardingSuccess, TutorOnboardingActions.skipOnboarding),
        tap(() => this.router.navigate(['lehrer']))
    ), {dispatch: false});

    // todo: show generic error popup on fail

    constructor(private actions$: Actions, private userService: UserService, private dialog: MatDialog, private router: Router) {}

}
