import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceModel, SettingsModel } from 'shared/models';

@Injectable()
export class SettingsService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) {}

    getSettings(): Observable<SettingsModel> {
        return this.http
        .get<SettingsModel>(`${this.baseApiUrl}/settings`)
        .pipe(
            map(s => {
                if (s != null && typeof s.maintenance === 'string' && s.maintenance.length > 0) {
                    const maintenance: MaintenanceModel = JSON.parse(<string>s.maintenance);
                    if (maintenance.title === undefined) {
                        maintenance.title = 'Wartungsarbeiten';
                    }
                    maintenance.startDateTime = new Date(maintenance.startDateTime);
                    maintenance.endDateTime = new Date(maintenance.endDateTime);
                    return { maintenance };
                }

                return {} as SettingsModel;
            })
        );
    }
}
