import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserRoles } from 'app/core/enums';
import { OidcUserService } from 'app/core/services';
import { environment } from 'environments/environment';
import { concat, from, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CvPopUpHelper, PopupChannels } from 'shared/helper';
import { AccountType, defaultMonsterKey, LoginProvider, PopoverType, ProfileUpdatesModel, StudentModel, TeacherModel } from 'shared/models';
import { AuthService, ConsentService, CookieService, HotjarService, ImageService, UserService } from 'shared/services';
import { StudentReceivedMessage } from 'shared/tracking/models';
import { TeacherActivatedLicence } from 'shared/tracking/models/teacher-activated-licence.model';
import { TeacherRegisteredAccount } from 'shared/tracking/models/teacher-registered-account.model';
import * as BooksActions from 'store/books/books.actions';
import { selectConversationsHasUnreadMessages } from 'store/conversations/conversations.selector';
import { closeUserSidebar, fetchSettings, getGlobalNotifications, getGlobalNotificationToken, hideLoadingIndicator, lockUi, showPopover, unlockUi } from 'store/layout/layout.actions';
import { getIsUiLocked } from 'store/layout/layout.selector';
import * as PollingActions from 'store/polling/polling.actions';
import { RootState } from 'store/root.reducer';
import { getRouteUrl, isConversationPage } from 'store/router';
import * as UserActions from 'store/user/user.actions';
import { getIsPrivateUserOrChild, getLicence, getOidcUser, getStudentProfile, getTeacherId, getUserState } from 'store/user/user.selector';
import * as TrackingActions from '../tracking/tracking.actions';
import { EnvironmentService } from 'app/core/services/environment.service';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserEffects {
    registerTeacher$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.registerTeacher),
        switchMap(() =>
            this.userService.registerTeacher().pipe(
                map(teacher => {
                    if (teacher) {
                        return UserActions.registerTeacherSuccess({ teacher });
                    } else {
                        return UserActions.registerTeacherFail({ error: { teacher } });
                    }
                }),
                catchError((error: unknown) => of(UserActions.registerTeacherFail({ error })))
            )
        )
    ));

    registerTeacherSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.registerTeacherSuccess),
        switchMap(action => [
            UserActions.loadProfileSuccess({ profile: action.teacher.profile, accountType: AccountType.Teacher, firstTime: true }),
            TrackingActions.teacherRegisteredAccount({
                event: new TeacherRegisteredAccount(),
            })
        ])
    ));

    registerTeacherFail$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.registerTeacherFail),
        concatMap(({ error }) => {
            let message: string;

            if (error.status === 400) {
                message = 'Die Registrierung konnte leider nicht abgeschlossen werden. Bitte versuchen Sie es später erneut.';
            } else {
                message = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie erneut.';
            }

            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.StudentLoginFail,
                message
            );
            return [hideLoadingIndicator({ isTriggeredByRouter: false }), openPopup];
        })
    ));

    setUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.setUser),
        filter(({ isTriggeredBySilentRefresh }) => !isTriggeredBySilentRefresh),
        map(({ accountType, isTriggeredBySilentRefresh }) => UserActions.loadProfile({ accountType, firstTime: !isTriggeredBySilentRefresh }))
    ));

    loadProfile$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadProfile),
        withLatestFrom(this.store.pipe(select(getStudentProfile))),
        exhaustMap(([{ accountType, firstTime }, prevProfileState]) =>
            this.userService.getProfile().pipe(
                switchMap(userData => {
                    const actions: any[] = [];

                    if (userData.isNewUser) {
                        actions.push(UserActions.oidcUserCreateAccount());
                        return actions;
                    }

                    const profile = userData.profile;

                    if (profile.accountType === AccountType.Student) {
                        const studentProfile = <StudentModel>profile;
                        studentProfile.avatarUri = this.imageService.buildAvatarUrl(studentProfile);

                        const hasReadingStatsChanges = prevProfileState.readBooks < studentProfile.readBooks || prevProfileState.openedBooks < studentProfile.openedBooks;

                        if (hasReadingStatsChanges) {
                            actions.push(BooksActions.fetchBooks());
                        }

                        if (firstTime && studentProfile.schoolId !== '') {
                            actions.push(UserActions.trackLearnerLoginSuccess({ schoolId: studentProfile.schoolId }));
                        }
                    }

                    actions.push(UserActions.loadProfileSuccess({ profile, accountType: profile.accountType, firstTime }));

                    return actions;
                }),
                catchError((error: unknown) => of(UserActions.loadProfileFail({ accountType: null, error })))
            )
        )
    ));

    loadProfileSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadProfileSuccess),
        withLatestFrom(this.store.pipe(select(getRouteUrl)), this.store.pipe(select(getIsPrivateUserOrChild))),
        tap(([{ accountType, profile, firstTime }, routeUrl, isPrivateUserOrChild]) => {

            if (firstTime) {
                this.userService.setAccountTypeInLocalStorage(accountType);

                if (accountType === AccountType.Student) {
                    const isNewStudent = (profile as StudentModel).avatarUri.includes(defaultMonsterKey);
                    if (isNewStudent || routeUrl === '/kinder-login') {
                        const route = isNewStudent ? ['/', 'schueler', 'monsterauswahl'] : ['/schueler'];

                        this.router.navigate(route);
                    }
                    else {
                        // this is for SDUI students
                        if (routeUrl === '/lehrer' || routeUrl === null) {
                            this.router.navigate(['/schueler']);
                        } else {
                            this.router.navigate([routeUrl]);
                        }
                    }
                }

                if (accountType === AccountType.Teacher && isPrivateUserOrChild && !(profile as TeacherModel).isOnboarded) {
                    this.router.navigate(['/', 'lehrer', 'leseo-starten']);
                }
            }
            if (accountType === AccountType.Teacher) {
                isPrivateUserOrChild ? this.hotjarService.sendPrivateUserLoginEvent() : this.hotjarService.sendTeacherLoginEvent();
            }

            // todo: we have a race condition (config is not loaded), need to speak to the nepal team about this
            // if (isPrivateUserOrChild && !this.consentService.isConsentConfirmed()) {
            if (isPrivateUserOrChild && !this.cookieService.cookieExists('consentManager')) {
                this.consentService.show();
            }
        }),
        mergeMap(([{ accountType, profile, firstTime }, routerState]) => {
            if (profile == null) {
                return [UserActions.loadProfileFail({ accountType, error: `Profile is ${profile}` })];
            }

            let actions: any[] = [];
            const isTeacher = accountType === AccountType.Teacher;

            if (isTeacher && profile.licence.isExpired && (profile as TeacherModel).isSchoolAdmin) {
                return actions;
            }

            actions.push(UserActions.fillStore({ accountType, profile, firstTime }));

            if (isTeacher) {
                if (firstTime) {
                    actions.push(BooksActions.fetchRecommendedBook());
                }
                actions.push(getGlobalNotificationToken());
                actions.push(getGlobalNotifications());
            } else {
                actions.push(PollingActions.resetErrors());

                if (!firstTime) {
                    actions.push(UserActions.fetchProfileUpdates({ accountType }));
                }
            }

            return actions;
        }),
        catchError((error: unknown) => {
            console.error(error);
            return of(UserActions.loadProfileFail({ accountType: null, error }));
        })
    ));

    catchAdminTeachers$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadProfileSuccess),
        filter(
            ({ accountType, profile }) =>
                accountType === AccountType.Teacher &&
                profile.licence != null &&
                profile.licence.isExpired
        ),
        map(({ profile }) => profile as TeacherModel),
        filter(teacher => teacher.isSchoolAdmin),
        map(() => hideLoadingIndicator({ isTriggeredByRouter: false })),
        tap(() => this.router.navigate(['/lehrer', 'meine-schule']))
    ));

    teacherLoadProfileFailCreateAccount$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadProfileFail),
        filter(({ accountType, error }) => accountType === AccountType.Teacher && !this.isUserFound(error)),
        tap(() => this.router.navigate(['/account-bestaetigung'])),
        map(() => hideLoadingIndicator({ isTriggeredByRouter: false }))
    ));

    userCreateAccount$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.oidcUserCreateAccount),
        tap(() => this.router.navigate(['/account-bestaetigung'])),
        map(() => hideLoadingIndicator({ isTriggeredByRouter: false }))
    ));

    teacherLoadProfileFailBadRequest$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadProfileFail),
        filter(({ accountType, error }) =>
            accountType === AccountType.Teacher && this.isUserAuthorized(error) && this.isUserFound(error)
        ),
        tap(() => this.router.navigate(['/profil-fehler'])),
        map(() => hideLoadingIndicator({ isTriggeredByRouter: false }))
    ));

    studentLoadProfileFail$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadProfileFail),
        filter(({ error, accountType }) => accountType === AccountType.Student && this.isUserFound(error)),
        tap(() => this.router.navigate(['/profil-fehler'])),
    ), { dispatch: false });

    studentLoadProfileFailLogOut$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadProfileFail),
        filter(({ error, accountType }) => !accountType || (accountType === AccountType.Student && !this.isUserFound(error))),
        map(() => UserActions.logout({ redirectUri: '/profil-fehler' }))
    ));

    fillStore$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.fillStore),
        filter(({ firstTime }) => firstTime),
        switchMap(({ accountType }) => {
            const pollingAction = accountType === AccountType.Student
                ? UserActions.loadProfile({ accountType, firstTime: false })
                : UserActions.fetchProfileUpdates({ accountType }); // eventually this should suffice (and replace loadProfile)

            return [UserActions.fetchProfileUpdates({ accountType }), PollingActions.startPolling({
                pollingAction,
                interval: 1 * 60 * 1000, // one minute
                startImmediately: false
            })];
        })
    ));

    fetchProfileUpdates$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.fetchProfileUpdates),
        withLatestFrom(this.store.select(selectConversationsHasUnreadMessages)),
        switchMap(([{ accountType }, prevUnreadConversations]) => this.userService.getProfileUpdates().pipe(
            map((profileUpdates) => UserActions.fetchProfileUpdatesSuccess({ profileUpdates, accountType, prevUnreadConversations })),
            catchError((error: unknown) => of(UserActions.fetchProfileUpdatesFail({ error }))),
        ))
    ));

    fetchProfileUpdatesFail$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.fetchProfileUpdatesFail),
        map((_) => PollingActions.stopPolling())
    ));

    fetchProfileUpdatesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.fetchProfileUpdatesSuccess),
        withLatestFrom(this.store.select(isConversationPage), this.store.select(selectConversationsHasUnreadMessages)),
        filter(([{ accountType, profileUpdates, prevUnreadConversations }, isConversationPage]) =>
            this.shouldTrackStudentReceivedMessage(accountType, profileUpdates, prevUnreadConversations, isConversationPage)
        ),
        map(() => TrackingActions.studentReceivedMessage({ event: new StudentReceivedMessage() }))
    ));

    evaluateTeacherLicence$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.fillStore),
        filter(({ accountType }) => accountType === AccountType.Teacher),
        // todo: refactor
        filter(({ profile }) => profile != null && profile.licence != null),
        map(({ profile }) => {
            const teacher = profile as TeacherModel;
            if (teacher.licence.isExpired && !teacher.isSchoolAdmin) {
                return lockUi();
            }

            return unlockUi();
        })
    ));

    evaluateStudentLicence$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.fillStore),
            filter((action) => action.accountType === AccountType.Student),
            withLatestFrom(this.store.pipe(select(getIsUiLocked))),
            concatMap(([action, uiLocked]) => {
                const student = action.profile as StudentModel;

                if (!student.hasLicence) {
                    return [lockUi()];
                }

                if (student.hasLicence && uiLocked) {
                    return [unlockUi()];
                }

                return [];
            })
        )
    );

    logout$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.logout),
        withLatestFrom(this.store.pipe(select(getUserState)), of(this.oidcUserService.isBerlinUser())),
        filter(([{ redirectUri }, userState]) => userState.loaded && userState.accessToken != null),
        tap(([{ redirectUri }]) => this.hotjarService.sendUrlChangedEvent(redirectUri)),
        concatMap(([{redirectUri}, userState, isBerlinUser]) =>
            concat(
                of(PollingActions.stopPolling()),
                from(this.authService.logout(redirectUri, userState)).pipe(
                    tap((uri: string[]) => {
                        if(isBerlinUser) {
                            window.location.href = EnvironmentService.berlinLogoutRedirectUrl;
                        } else {
                            this.router.navigate(uri);
                        }
                    }),
                    map(() => UserActions.logoutSuccess())
                )
            )
        )
    ));

    // User not logged in, so we just redirect
    logoutRedirectOnly$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.logout),
        withLatestFrom(this.store.pipe(select(getUserState))),
        filter(([{ redirectUri }, userState]) => !userState.loaded && userState.accessToken == null),
        tap(([{ redirectUri }, userState]) => this.router.navigate([redirectUri]))
    ), { dispatch: false });

    logoutSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.logoutSuccess),
        map(() => fetchSettings())
    ));

    clearUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.UserActionTypes.ClearUser),
        withLatestFrom(
            this.store.pipe(select(getIsUiLocked)),
            (action, isUiLocked) => isUiLocked
        ),
        filter(isUiLocked => isUiLocked === true),
        map(() => unlockUi())
    ));

    cleanTrackAndReload$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.UserActionTypes.ClearUser),
        tap(({ reloadPage }) => {
            // user logged out

            this.authService.clearLocalStorages();

            if (reloadPage) {
                location.reload();
            }
        })
    ), { dispatch: false });

    validateEmail$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.validateEmail),
        switchMap(({ emailConfirm }) =>
            this.userService.validate(emailConfirm).pipe(
                map(() => UserActions.deleteProfile()),
                catchError((error: unknown) => of(UserActions.validateEmailFail({ error })))
            )
        )
    ));

    validateEmailFail$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.validateEmailFail),
        map(() => showPopover({
            settings: {
                type: PopoverType.DeleteProfileEmailError,
                autoClose: 3000
            }
        })
        )
    ));

    deleteProfile$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.deleteProfile),
        withLatestFrom(this.store.select(getTeacherId)),
        switchMap(([_, id]) => this.userService.deleteProfile(id).pipe(
            map(() => UserActions.logout({ redirectUri: 'loesch-bestaetigung' })),
            catchError((error: unknown) => of(UserActions.deleteProfileFail({ error })))
        ))
    ));


    deleteProfileFail$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.deleteProfileFail),
        concatMap(() => {
            const closePanel = closeUserSidebar();
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.DeleteTeacherFail,
                'Es ist ein Fehler aufgetreten. Probieren Sie es bitte erneut.',
                3000
            );

            return [closePanel, openPopup];
        })
    ));

    activateLicence$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.UserActionTypes.ActivateLicence),
        map((action: UserActions.ActivateLicence) => action),
        withLatestFrom(this.store.select(getLicence)),
        switchMap(([{ code }, oldLicence]) =>
            this.userService.activateLicence(code).pipe(
                map(licence => UserActions.activateLicenceSuccess({ licence, isOldLicenceActive: oldLicence.isActive })),
                catchError((error: unknown) => {
                    const reqHeaders = (error as HttpErrorResponse).headers as HttpHeaders;
                    const errorCodeResults = reqHeaders.get('ErrorCodeResults');

                    // extend error with errorCodeResults because of the problem with immutable headers outside this effect
                    error = {
                        ...(error as HttpErrorResponse),
                        errorCodeResults
                    };
                    return of(UserActions.activateLicenceFail({ error, isOldLicenceActive: oldLicence.isActive }));
                })
            )
        )
    ));


    activateLicenceSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.activateLicenceSuccess),
        map(() => {
            const message = 'Ihre Lizenz wurde erfolgreich freigeschaltet.';
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.ActivateLicenceSuccess,
                message,
                3000
            );

            return openPopup;
        })
    ));

    // todo: move this to tracking effects?
    activateLicenceSuccessTracking$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.activateLicenceSuccess),
        map((action) => TrackingActions.teacherActivatedLicence({
            event: new TeacherActivatedLicence('success', action.isOldLicenceActive)
        }))
    ));

    activateLicenceFailTracking$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.activateLicenceFail),
        map((action) => TrackingActions.teacherActivatedLicence({
            event: new TeacherActivatedLicence('fail', action.isOldLicenceActive)
        }))
    ));


    constructor(
        private router: Router,
        private actions$: Actions,
        private userService: UserService,
        private authService: AuthService,
        private store: Store<RootState>,
        private imageService: ImageService,
        private hotjarService: HotjarService,
        private consentService: ConsentService,
        private cookieService: CookieService,
        private oidcUserService: OidcUserService
    ) { }

    private isUserFound(error: any): boolean {
        return error.status !== 404;
    }

    private isUserAuthorized(error: any): boolean {
        return error.status !== 401;
    }

    private shouldTrackStudentReceivedMessage(
        accountType: AccountType,
        profileUpdates: ProfileUpdatesModel,
        prevUnreadConversations: boolean,
        isConversationPage: boolean): boolean {
        return accountType === AccountType.Student && profileUpdates.hasNewMessages && !prevUnreadConversations && !isConversationPage;
    }
}
