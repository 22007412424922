import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 113_Loeschen_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-remove',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M45.732,9.264A73.684,73.684,0,0,0,29.765,7.107V5.669a1.418,1.418,0,0,0-2.835,0V7.107A73.7,73.7,0,0,0,10.963,9.264,1.417,1.417,0,0,0,9.922,10.63v4.961a1.417,1.417,0,0,0,1.418,1.417h1.417V46.772a1.417,1.417,0,0,0,1.417,1.417H42.521a1.417,1.417,0,0,0,1.418-1.417V17.008h1.417a1.417,1.417,0,0,0,1.417-1.417V10.63A1.416,1.416,0,0,0,45.732,9.264ZM12.757,11.727A72.832,72.832,0,0,1,28.348,9.921a72.816,72.816,0,0,1,15.59,1.806v2.447H12.757ZM41.1,45.355H15.592V17.008H41.1ZM19.844,41.1V21.26a1.418,1.418,0,0,1,2.835,0V41.1a1.418,1.418,0,0,1-2.835,0Zm7.086,0V21.26a1.418,1.418,0,0,1,2.835,0V41.1a1.418,1.418,0,0,1-2.835,0Zm7.087,0V21.26a1.418,1.418,0,0,1,2.835,0V41.1a1.418,1.418,0,0,1-2.835,0Z" />
    </svg>`
})
export class SvgIconRemoveComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'remove';
    }
}
