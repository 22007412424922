import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 052_Sehen_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-eye',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M28.284,11.338c-10.516,0-21.016,6-28.088,16.043a1.413,1.413,0,0,0-.259.816v.3a1.413,1.413,0,0,0,.259.816C7.268,39.357,17.768,45.355,28.284,45.355s21.015-6,28.088-16.042a1.419,1.419,0,0,0,.258-.816v-.3a1.419,1.419,0,0,0-.258-.816C49.3,17.335,38.8,11.338,28.284,11.338ZM42.457,28.347A14.174,14.174,0,1,1,28.284,14.173,14.189,14.189,0,0,1,42.457,28.347Zm-39.465,0a36.73,36.73,0,0,1,12.14-10.713,16.807,16.807,0,0,0,0,21.425A36.708,36.708,0,0,1,2.992,28.347ZM41.435,39.059a16.805,16.805,0,0,0,0-21.425,36.73,36.73,0,0,1,12.14,10.713A36.715,36.715,0,0,1,41.435,39.059ZM28.283,34.016a5.67,5.67,0,1,0-5.669-5.669A5.676,5.676,0,0,0,28.283,34.016Zm0-8.787a3.118,3.118,0,1,1-3.117,3.118A3.121,3.121,0,0,1,28.283,25.229Z" />
    </svg>
  `
})
export class SvgIconEyeComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'eye';
    }
}
