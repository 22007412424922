import { Component, Input, HostBinding } from '@angular/core';

import { AssignmentHelper, Colors } from 'shared/helper';

@Component({
    selector: 'app-due-date',
    templateUrl: './due-date.component.html',
    styleUrls: ['./due-date.component.scss']
})
export class DueDateComponent {
    @Input()
    dueDate: Date;

    @Input()
    format: string;

    @HostBinding('class.is-nearly-expired')
    get isDueDateNearlyExpired(): boolean {
        if (this.dueDate == null || this.format === 'doneDate' || AssignmentHelper.isDueDateExpired(this.dueDate)) {
            return false;
        }

        return AssignmentHelper.isDueDateIn3Days(this.dueDate);
    }

    constructor() { }
}
