import { Action, createReducer, on } from '@ngrx/store';
import * as BookViewerActions from './book-viewer.actions';

export interface BookViewerState {
    link: string | null;
    loading: boolean;
    error: unknown;
}

export const initialState: BookViewerState = {
    link: null,
    loading: false,
    error: null,
};

const _bookViewerReducer = createReducer(
    initialState,
    on(BookViewerActions.openExerciseResult, (state) => ({
        ...state,
        loading: true,
    })),
    on(BookViewerActions.openExerciseResultSuccess, (state, { exerciseUrl }) => ({
        ...state,
        link: exerciseUrl,
        loading: false,
    })),
    on(BookViewerActions.openExerciseResultFail, (state, { error }) => ({
        ...state,
        error: error,
        loading: false
    })),
);

export function bookViewerReducer(state: BookViewerState, action: Action) {
    return _bookViewerReducer(state, action);
}
