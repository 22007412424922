import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BookHelper, RedirectionHelper } from 'shared/helper';
import { BookModel } from 'shared/models';
import * as BookViewerActions from 'store/book-viewer';
import { BooksState, getRecommendedBook } from 'store/books';
import { getAccountType } from 'store/user';

@Component({
    selector: 'app-book-recommendation-header',
    templateUrl: './book-recommendation-header.component.html',
    styleUrls: ['./book-recommendation-header.component.scss']
})
export class BookRecommendationHeaderComponent implements OnDestroy {
    private readonly ngUnsubscribe = new Subject<void>();

    book$ = this.store.pipe(select(getRecommendedBook), filter(book => book != null));

    get genres$(): Observable<string> {
        return this.book$.pipe(
            map(book => this.bookHelper.getFormatedGenreInfo(book))
        );
    }

    get badgeText$(): Observable<string> {
        return this.book$.pipe(
            map(b => {
                if (b == null) {
                    return '';
                }

                if (b.isNew) {
                    return 'Neuerscheinung';
                }

                return 'Lesetipp';
            })
        );
    }

    constructor(
        private store: Store<BooksState>,
        private router: Router,
        private bookHelper: BookHelper
    ) { }

    openBook(): void {
        this.book$.pipe(
            map(book => {
                return this.store.dispatch(BookViewerActions.openBook({ book: book, isAssigned: false, isTeacher: false }));
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe();
    }

    bookDetails(): void {
        this.book$.pipe(
            withLatestFrom(this.store.select(getAccountType)),
            tap(([book, acountType]) => {
                const route = RedirectionHelper.getBookDetailRoute(book, acountType);
                this.router.navigate(route);
            }),
            takeUntil(this.ngUnsubscribe),
        )
            .subscribe();
    }

    hasGenre(genre: string, book: BookModel): boolean {
        if (book && genre && genre.trim().length > 0) {
            return book.genres.some(g => g.trim().toLowerCase() === genre.trim().toLowerCase());
        }
        return false;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
