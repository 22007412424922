import { Action, createAction, props } from '@ngrx/store';

import {
    AssignmentModel,
    AssignmentCreateModel,
    AssignmentDetailModel,
    AssignmentUpdateModel,
    ExerciseDetailModel,
    ExerciseModel,
    AssignmentStatus
} from 'shared/models';

export enum AssignmentsActionTypes {
    FetchSelectedAssignment = '[Assignment detail page] Fetch selected assignment',
    FetchSelectedAssignmentSuccess = '[Assignment API] Fetch selected assignment success',
    FetchSelectedAssignmentFail = '[Assignment API] Fetch selected assginment fail',

    AddAssignment = '[Assignments] Add assignment',
    AddAssignmentSuccess = '[Assignments] Add assignment success',
    AddAssignmentFailure = '[Assignments] Add assignment failure',

    UpdateAssignment = '[Assignments] Update assignment',
    UpdateAssignmentSuccess = '[Assignments] Update assignment success',
    UpdateAssignmentFailure = '[Assignments] Update assignment failure',

    DeleteAssignment = '[Assignments] delete assignment',
    DeleteAssignmentSuccess = '[Assignments] delete assignment success',
    DeleteAssignmentFail = '[Assignments] delete assignment fail',
}

export enum AddAssignmentConfirmationType {
    Default,
    CtaMessage
}

export const fetchAssignments = createAction('[Assignments] load assignments',
    props<{schoolClassId: number, status?: AssignmentStatus, limit?: number}>()
);

export const fetchAssignmentsSuccess = createAction('[Assignments] load assignments success',
    props<{ assignments: AssignmentModel[], loadedCompletedAssignments?: boolean}>()
);

export const fetchAssignmentsFailure = createAction('[Assignments] load assignments failure',
    props<{ error: any}>()
);

export class FetchSelectedAssignment implements Action {
    readonly type = AssignmentsActionTypes.FetchSelectedAssignment;

    constructor(public assignmentId: number) {}
}

export class FetchSelectedAssignmentSuccess implements Action {
    readonly type = AssignmentsActionTypes.FetchSelectedAssignmentSuccess;

    constructor(public assignment: AssignmentDetailModel) {}
}

export class FetchSelectedAssignmentFail implements Action {
    readonly type = AssignmentsActionTypes.FetchSelectedAssignmentFail;

    constructor(public error: any) {}
}

export class AddAssignment implements Action {
    readonly type = AssignmentsActionTypes.AddAssignment;

    constructor(
        public newAssignment: AssignmentCreateModel,
        public studentDetailIdToUpdate?: number,
        public confirmationType: AddAssignmentConfirmationType = AddAssignmentConfirmationType.Default
    ) {}
}

export class AddAssignmentSuccess implements Action {
    readonly type = AssignmentsActionTypes.AddAssignmentSuccess;

    constructor(
        public assignment: AssignmentModel,
        public studentDetailIdToUpdate?: number
    ) {}
}

export class AddAssignmentFailure implements Action {
    readonly type = AssignmentsActionTypes.AddAssignmentFailure;

    constructor(public error: any) {}
}

export class UpdateAssignment implements Action {
    readonly type = AssignmentsActionTypes.UpdateAssignment;

    constructor(
        public updateModel: AssignmentUpdateModel
    ) {}
}

export class UpdateAssignmentSuccess implements Action {
    readonly type = AssignmentsActionTypes.UpdateAssignmentSuccess;

    constructor(
        public assignment: AssignmentModel
    ) {}
}

export class UpdateAssignmentFailure implements Action {
    readonly type = AssignmentsActionTypes.UpdateAssignmentFailure;

    constructor(public error: any) {}
}

export class DeleteAssignment implements Action {
    readonly type = AssignmentsActionTypes.DeleteAssignment;

    constructor(public assignment: AssignmentDetailModel) {}
}

export class DeleteAssignmentSuccess implements Action {
    readonly type = AssignmentsActionTypes.DeleteAssignmentSuccess;

    constructor(public assignment: AssignmentModel) {}
}

export class DeleteAssignmentFail implements Action {
    readonly type = AssignmentsActionTypes.DeleteAssignmentFail;

    constructor(public error: any) {}
}

export const fetchExerciseDetails = createAction(
    '[Assignments] Fetch exercise details',
    props<{ bookId: number, studentIds?: number[], exercises?: ExerciseModel[], assignment?: AssignmentDetailModel }>()
);
export const fetchExerciseDetailsSuccess = createAction(
    '[Assignments] Fetch exercise details success',
    props<{ bookId: number, exerciseDetails: ExerciseDetailModel }>()
);
export const fetchExerciseDetailsFail = createAction(
    '[Assignments] Fetch exercise details fail',
    props<{ error: any }>()
);

export type AssignmentsActions =
    | AddAssignment
    | AddAssignmentSuccess
    | AddAssignmentFailure
    | UpdateAssignment
    | UpdateAssignmentSuccess
    | UpdateAssignmentFailure
    | FetchSelectedAssignment
    | FetchSelectedAssignmentSuccess
    | FetchSelectedAssignmentFail
    | DeleteAssignment
    | DeleteAssignmentSuccess
    | DeleteAssignmentFail;
