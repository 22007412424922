import {
    Component,
    OnInit,
    OnDestroy,
    HostBinding,
    HostListener
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { LayoutState, clickOnBackdrop, getBackdrop } from 'store/layout';
import { BackdropColor } from 'shared/models';

@Component({
    selector: 'app-backdrop',
    templateUrl: './backdrop.component.html',
    styleUrls: ['./backdrop.component.scss']
})
export class BackdropComponent implements OnInit, OnDestroy {
    private readonly ngUnsubscribe = new Subject<void>();

    @HostBinding('class')
    get classNames(): string {
        const classNames = [];

        if (this.isVisible === true) {
            classNames.push('is-visible');

            if (this.color != null) {
                const className = BackdropColor[this.color].toLowerCase();
                classNames.push(`${className}-color`);

                if (this.color === BackdropColor.Black) {
                    classNames.push('full-backdrop');
                }
            }
        }

        return classNames.join(' ');
    }

    isVisible = false;
    isClosable = true;
    color: BackdropColor;

    constructor(private store: Store<LayoutState>) {}

    ngOnInit() {
        this.store
            .pipe(
                select(getBackdrop),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(state => {
                if (state.isVisible) {
                    this.isVisible = state.isVisible;
                    this.color = state.color;
                    this.isClosable = state.closable;

                    return;
                }

                this.isVisible = false;
            });
    }

    @HostListener('click', ['$event'])
    backdropClick(event) {
        this.store.dispatch(clickOnBackdrop());
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
