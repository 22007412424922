import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import { Conversation, ConversationMessage, ConversationMessageDraft, ConversationMessageList } from 'shared/models';

@Injectable()
export class ConversationService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) { }

    getConversations(schoolClassId?: number): Observable<Conversation[]> {
        let headers = new HttpHeaders();

        if (schoolClassId) {
            headers = headers.append('x-school-class-id', schoolClassId + '');
        }
        return this.http.get<Conversation[]>(`${this.baseApiUrl}/conversations`, {headers: headers});
    }

    getConversation(conversationId: number, skip?: number, limit?: number): Observable<ConversationMessageList> {
        let params = new HttpParams();
        if (skip) {
            params = params.set('skip', skip + '');
        }

        if (limit) {
            params = params.set('limit', limit + '');
        }
        return this.http.get<ConversationMessageList>(`${this.baseApiUrl}/conversations/${conversationId}`, { params });
    }

    sendConversation(conversation: ConversationMessageDraft): Observable<ConversationMessage> {
        const body = {
            conversationId: conversation.conversationId || 0,
            userId: conversation.participantId || 0,
            content: conversation.content
        };

        return this.http.post<ConversationMessage>(`${this.baseApiUrl}/conversations/`, body);
    }

    setConversationAsRead(conversationId: number, participantId: number): Observable<number> {
        return this.http.put<number>(`${this.baseApiUrl}/conversations/${conversationId}/${participantId}`, {});
    }
}
