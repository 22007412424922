import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import { GlobalNotification } from 'shared/models/global-notification.model';

@Injectable()
export class GlobalNotificationService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) { }

    getGlobalNotifications(): Observable<GlobalNotification[]> {
        return this.http.get<GlobalNotification[]>(`${this.baseApiUrl}/globalnotifications`);
    }

    setGlobalNotificationToken(): void {
        const viewedAt = new Date();
        const value = JSON.stringify({viewedAt});
        localStorage.setItem('global-notification', value);
    }

    getGlobalNotificationToken(): Date {
        const token = localStorage.getItem('global-notification');

        if (token) {
            const date =  JSON.parse(token).viewedAt;

            return new Date(date);
        }

        return null;
    }
}
