import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fromEvent, merge, of, timer } from 'rxjs';
import {
    filter, map, mapTo, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom
} from 'rxjs/operators';
import { AccountType } from 'shared/models';
import { getUserState } from 'store/user';
import * as PollingActions from './polling.actions';
import { PollingState } from './polling.reducer';
import { getIsErrorModeActive, getPollingAction, getPollingErrorCount, getPollingState } from './polling.selector';

@Injectable()
export class PollingEffects {
    browserIsOnline$ = merge(
        of(navigator, 'onLine'), // get initial value
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
    ).pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    userBackOnlineStartPolling$ = createEffect(() =>
        this.browserIsOnline$.pipe(
            withLatestFrom(this.store.pipe(select(getPollingAction))),
            // isOnline is only true after internet connection was re-established (not initially)
            filter(([isOnline]) => isOnline === true),
            // tap(_ => console.log('user is back online')),
            map(([_, pollingAction]) => PollingActions.restartPolling({ pollingAction, interval: 1 * 60 * 1000}))
        )
    );

    userOfflineStopPolling$ = createEffect(() =>
        this.browserIsOnline$.pipe(
            filter((isOffline) => isOffline === false),
            // tap(_ => console.log('user is offline - stop polling')),
            map(() => PollingActions.stopPolling())
        )
    );

    visibilitychange$ = fromEvent(document, 'visibilitychange').pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    pageVisibleStartsPolling$ = createEffect(() =>
        this.visibilitychange$.pipe(
            withLatestFrom(this.store.pipe(select(getPollingState))),
            filter(([_, { isPollingActive }]) => document.visibilityState === 'visible' && !isPollingActive),
            // tap(_ => console.log('doc is visible and polling is inactive - restart polling')),
            map(([_, {pollingAction}]) => PollingActions.restartPolling({ pollingAction, interval: 1 * 60 * 1000 }))
        )
    );

    pageHiddenStopsPolling$ = createEffect(() =>
        this.visibilitychange$.pipe(
            filter(() => document.visibilityState === 'hidden'),
            // tap(_ => console.log('page hidden - stop polling')),
            map(() => PollingActions.stopPolling())
        )
    );

    startPolling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PollingActions.startPolling),
            switchMap(({ pollingAction, interval, startImmediately }) =>
                // trigger action immediately and then in interval
                timer(startImmediately ? 0 : interval, interval).pipe(
                    mapTo(pollingAction),
                    takeUntil(this.stopPolling$),
                )
            )
        )
    );

    failedPolling = createEffect(() =>
        this.actions$.pipe(
            ofType(PollingActions.failedPolling),
            // tap(() => console.log('failed polling action')),
            withLatestFrom(
                this.store.pipe(select(getPollingAction)),
                this.store.pipe(select(getPollingErrorCount)),
                this.store.pipe(select(getIsErrorModeActive)),
                (action, pollingAction, pollingErrorCount, isErrorModeActive) => ({
                    pollingAction,
                    pollingErrorCount,
                    isErrorModeActive
                })
            ),
            // tap(({ pollingAction }) => console.log('action of polling failed', pollingAction.type)),
            map(({ isErrorModeActive, pollingErrorCount }) => {
                console.log('isErrorModeActive', isErrorModeActive);
                if (isErrorModeActive) {
                    if (pollingErrorCount > 1) {
                        return PollingActions.stopPolling();
                    }
                } else {
                    if (pollingErrorCount > 0) {
                        return PollingActions.switchToErrorMode();
                    }
                }
                return null; // test what happens if it gets here / maybe filter out before
            }),
        )
    );

    stopPolling$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PollingActions.stopPolling),
            ),
        { dispatch: false }
    );

    restartPolling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PollingActions.restartPolling),
            withLatestFrom(this.store.select(getUserState)),
            filter(([{interval}, userState]) => {
                // console.log('Check if profile is loaded and then restart polling.');
                    const isAuthorized = userState.isAuthorized;
                    if (isAuthorized) {
                        if (userState.teacher?.loaded || userState.student?.loaded) {
                            console.log('restart polling', interval);
                            return true;
                        }
                    }
                    // console.log('Profile not loaded. Won\'t restart polling.');
                    return false;
                }
            ),
            map(([{ pollingAction, interval }]) =>
                PollingActions.startPolling({
                    pollingAction,
                    interval,
                    startImmediately: false
                })
            )
        )
    );

    switchToErrorMode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PollingActions.switchToErrorMode),
            withLatestFrom(
                this.store.pipe(select(getPollingAction)),
                (act, pollingAction) => ({ pollingAction })
            ),
            switchMap(({ pollingAction }) => [
                PollingActions.restartPolling({
                    pollingAction,
                    interval: 5 * 60 * 1000 // 5 minutes
                })
            ]
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<PollingState>
    ) { }
}
