import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreHelper } from 'shared/helper';
import { getRouteSchoolClassId } from 'store/router';
import {
    SchoolState,
    storeTeacherToTeacherLight
} from 'store/school/school.reducer';

const getSchoolState = createFeatureSelector<SchoolState>('school');

export const getSchoolName = createSelector(
    getSchoolState,
    (school: SchoolState) => school.name
);

export const getSchoolIsLoading = createSelector(
    getSchoolState,
    state => state.loading
);

export const getSchoolIsLoaded = createSelector(
    getSchoolState,
    state => state.loaded
);

export const getSchoolTeachersDict = createSelector(
    getSchoolState,
    state => state.teachersDict
);

export const getSchoolSchoolClassesDict = createSelector(
    getSchoolState,
    state => state.schoolClassesDict
);

export const getSchoolTeachers = createSelector(getSchoolState, state => {
    if (
        state == null ||
        state.teachersDict == null ||
        state.schoolClassesDict == null
    ) {
        return [];
    }

    const teacherList = StoreHelper.dictToArray(state.teachersDict);
    return teacherList.map(t =>
        storeTeacherToTeacherLight(t, state.schoolClassesDict)
    );
});

export const getSchoolSelectedSchoolClass = createSelector(
    getRouteSchoolClassId,
    getSchoolSchoolClassesDict,
    (schoolClassId, schoolClassesDict) => {
        if (schoolClassId == null || schoolClassesDict == null) {
            return;
        }

        return schoolClassesDict[schoolClassId];
    }
);

export const getSchoolSelectedSchoolClassStudents = createSelector(
    getSchoolSelectedSchoolClass,
    (schoolClass) => {
        if (schoolClass == null || schoolClass.students == null) {
            return [];
        }

        return StoreHelper.dictToArray(schoolClass.students);
    }
);

export const getSchoolIsLoadingStudents = createSelector(
    getSchoolSelectedSchoolClass,
    schoolClass => {
        if (schoolClass == null) {
            return;
        }

        return schoolClass.studentsLoading;
    }
);

export const getSchoolIsLoadedStudents = createSelector(
    getSchoolSelectedSchoolClass,
    schoolClass => {
        if (schoolClass == null) {
            return;
        }

        return schoolClass.studentsLoaded;
    }
);
