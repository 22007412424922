import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 040_Hoerverstehen_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-ear',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path d="M16.987,24.094a1.417,1.417,0,1,1-2.834,0V21.26c0-9.034,5.96-15.591,14.173-15.591,7.043,0,14.173,4.869,14.173,14.173,0,5.288-3.061,9.282-6.022,13.143-2.436,3.178-4.738,6.179-5.336,9.767-.914,5.489-3.292,8.272-7.067,8.272-4.907,0-6.306-2.509-7.065-6.842a1.417,1.417,0,1,1,2.792-.49c.689,3.937,1.53,4.5,4.273,4.5,1.531,0,3.4-.671,4.271-5.9C29.06,38,31.687,34.574,34.228,31.26c2.8-3.647,5.437-7.091,5.437-11.418,0-7.444-5.7-11.338-11.339-11.338-6.57,0-11.339,5.365-11.339,12.756Zm3.944,5.7a1.417,1.417,0,0,0,.307,2.8,1.5,1.5,0,0,0,.31-.034,5.29,5.29,0,0,0,3.943-4.927,5.279,5.279,0,0,0-2.834-4.508V21.26c0-4.239,2.278-7.087,5.669-7.087A5.484,5.484,0,0,1,34,19.842a1.417,1.417,0,1,0,2.834,0,8.318,8.318,0,0,0-8.5-8.5c-5.007,0-8.5,4.081-8.5,9.922v2.834a1.419,1.419,0,0,0,1.109,1.384,2.5,2.5,0,0,1,1.726,2.16A2.5,2.5,0,0,1,20.931,29.8Z" />
        </svg>
  `
})
export class SvgIconEarComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'ear';
    }
}
