import * as moment from 'moment';

import {
    CalendarSettings,
    SelectedDates,
    CalendarCurrentDate,
    CalendarSelectionDisabled
} from 'shared/models';

export class CalendarHelper {
    static readonly settings = {
        default: <CalendarSettings>{
            monthsLabels: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember'
            ],
            daysLabels: ['Mo', 'Di', 'Mi', 'Do', 'Fr'],
            showTodaysDate: true,
            showOnFocus: true,
            inputLabel: 'Fälligkeitsdatum'
        },
        widget: <CalendarSettings>{
            showTodaysDate: true,
            showOnFocus: false,
            inputLabel: 'Fälligkeitsdatum',
            selectionDisabled: CalendarSelectionDisabled.Always
        },
        datePicker: <CalendarSettings>{
            showTodaysDate: true,
            showOnFocus: true,
            selectionDisabled: CalendarSelectionDisabled.OnlyForThePast
        }
    };

    static createDateFromCurrentDate(date: CalendarCurrentDate, day: number): Date {

        let month = '' + (date.month + 1);
        let d = '' + day;

        if (month.length < 2) {
            month = '0' + month;
        }
        if (d.length < 2) {
            d = '0' + d;
        }

        return new Date([date.year, month, d].join('-'));
    }

    static createCurrentDateFromDate(date: Date): CalendarCurrentDate {
        const month = date.getMonth();
        const year = date.getFullYear();

        return { year, month };
    }

    static isToday(date: CalendarCurrentDate, day: number): boolean {
        const now = new Date();

        return (
            day === now.getDate() &&
            date.month === now.getMonth() &&
            date.year === now.getFullYear()
        );
    }

    static isInPast(date: Date): boolean {
        const now = moment();
        const diffToNow = moment(date).diff(now, 'days'); // < 0 => past; 0 => today; > 0 future

        return diffToNow < 0;
    }

    static isIn3Days(dueDate: Date): boolean {
        const dayAfterTomorrow = moment().add(3, 'day');

        return dayAfterTomorrow >= moment(dueDate);
    }

    static isWorkday(day: number): boolean {
        // between monday and friday
        return day > 0 && day < 6;
    }

    static createSelectedDates(dates: Date[]): SelectedDates {
        return dates.reduce((selectedDates: SelectedDates, date) => {
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDate();

            // set year if not already
            if (selectedDates[year] == null) {
                selectedDates[year] = {};
            }

            // set month if not already
            // and create days array
            if (selectedDates[year][month] == null) {
                selectedDates[year][month] = { days: [] };
            }

            // add day to the days array if not included already
            if (!selectedDates[year][month].days.includes(day)) {
                selectedDates[year][month].days.push(day);
            }

            return selectedDates;
        }, {});
    }

    static generateDate(): number[] {
        const days: number[] = [];
        const daysInMonth = 30;
        const now = new Date();

        for (let i = 1; i < daysInMonth; i++) {
            const tmpDay = new Date(now.getFullYear(), now.getMonth(), i);
            const day = tmpDay.getDay();

            if (CalendarHelper.isWorkday(day)) {
                days.push(tmpDay.getDate());
            }
        }

        return days.splice(0, 3);
    }

    static daysDiff(to: Date, from?: Date): number {
        if (from == null) {
            from = CalendarHelper.withoutTime(new Date());
        }

        const daysDiff = moment(to).diff(from, 'days');
        
        return daysDiff;
    }

    static withoutTime(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    }

    // returns the correct day when the date uses UTC and timezon shift
    // we need this for JSON.stringify: JSON.stringify(new Date(2021, 02, 16)) returns 2021-03-15T23:00:00.000Z.
    // but we need the correct day, 16th and not 15th.
    // this works: JSON.stringify(new Date("2021-03-16")) and returns 2021-03-16T00:00:00.000Z
    // Safari needs this date format
    static flattenUTCDate(date: Date): Date {
        const d = new Date(date);

        const year = d.getFullYear();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return new Date([year, month, day].join('-'));
    }
}
