import { Directive, AfterContentInit, ElementRef, Input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[autoFocus]'
})
export class AutofocusDirective implements AfterContentInit {
    @Input()
    appAutoFocus: boolean;

    constructor(private el: ElementRef) {}

    ngAfterContentInit() {
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 500);
    }
}
