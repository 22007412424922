import { UserState } from 'store/user';

import { AccountType, BookModel, MaintenanceModel } from 'shared/models';

export class RedirectionHelper {
    static getTargetRoute(userState: UserState, maintenanceSetting?: MaintenanceModel): string[] {
        if (maintenanceSetting != null && maintenanceSetting.active) {
            return ['wartungsarbeiten'];
        }

        if (userState == null || userState.isAuthorized === false) {
            return ['/kinder-login'];
        }

        return this.getDashboardRoute(userState.accountType);
    }

    static getDashboardRoute(accountType: AccountType): string[] {
        return [this.getUserSlug(accountType)];
    }

    static getLoginRoute(accountType: AccountType): string[] {
        if (accountType === AccountType.Teacher) {
            return ['/login'];
        }

        return ['/kinder-login'];
    }

    private static getUserSlug(accountType: AccountType): string {
        switch (accountType) {
            case AccountType.Teacher:
                return '/lehrer';

            case AccountType.Student:
                return '/schueler';

            default:
                return '/';
        }
    }

    static getAllBooksLink(accountType: AccountType): string[] {
        const accountTypePrefix = RedirectionHelper.getUserSlug(accountType);

        return [accountTypePrefix, 'bibliothek', 'alle-buecher'];
    }

    static getBookDetailRoute(book: BookModel, accountType: AccountType): string[] {
        if (book == null) {
            return;
        }

        const accountTypePrefix = this.getUserSlug(accountType);

        return [
            accountTypePrefix,
            'bibliothek',
            book.id.toString(),
            book.seoFriendlyTitle
        ];
    }

    static getAssignmentRoute(assignmentId: number): string[] {
        return [
            '/lehrer/aufgaben',
            assignmentId.toString(),
        ];
    }

    static getLibraryRoute(accountType: AccountType): string[] {
        switch (accountType) {
            case AccountType.Teacher:
                return ['lehrer', 'bibliothek'];

            case AccountType.Student:
                return ['schueler', 'bibliothek'];

            default:
                return ['/'];
        }
    }

    static getStudentDetailRoute(studentId: number, studentName?: string): Array<string> {
        const route = [
            '/lehrer',
            'lerngruppe',
            'entwicklung',
            studentId.toString(),
            studentName ?
                studentName
                    .toLowerCase()
                    .replace(' ', '-')
                    .replace('ä', 'ae')
                    .replace('ö', 'oe')
                    .replace('ü', 'ue')
                    .replace('ß', 'ss')
                : ''
        ];

        return route;
    }
}
