import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StudentAssignmentsState } from './student.reducer';

export const getStudentAssignmentsState = createFeatureSelector<StudentAssignmentsState>('studentAssignments');

export const getStudentAssignmentsLoaded = createSelector(
    getStudentAssignmentsState,
    (state) => state.loaded
);

export const getOpenAssignments = createSelector(
    getStudentAssignmentsState,
    (state) => state.openAssignments
);

export const getCompletedAssignments = createSelector(
    getStudentAssignmentsState,
    (state) => state.completedAssignments
);

export const getExpiredAssignments = createSelector(
    getStudentAssignmentsState,
    (state) => state.expiredAssignments
);

export const hasAssignments = createSelector(
    getOpenAssignments,
    getCompletedAssignments,
    getExpiredAssignments,
    (openA, completedA, expiredA) => openA.length > 0 || completedA.length > 0 || expiredA.length > 0
);
