import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 128_Korrekt_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-dialogue',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M48.4,42.088a13,13,0,0,0,5.462-10.907c0-8.61-6.676-14.173-17.008-14.173H35.633c-2.3-5.32-7.939-8.5-15.79-8.5C9.511,8.5,2.836,14.067,2.836,22.677A12.988,12.988,0,0,0,8.3,33.584a24.636,24.636,0,0,1-2.392,4.962,1.416,1.416,0,0,0,.179,1.786l.354.355a1.42,1.42,0,0,0,1,.415,1.358,1.358,0,0,0,.31-.035,21.6,21.6,0,0,0,8.929-4.408,25.916,25.916,0,0,0,3.162.191c.387,0,.759-.023,1.135-.038,2.3,5.32,8.024,8.542,15.873,8.542a25.932,25.932,0,0,0,3.163-.191,21.6,21.6,0,0,0,8.929,4.408,1.359,1.359,0,0,0,1.312-.38l.354-.355a1.416,1.416,0,0,0,.18-1.785A24.646,24.646,0,0,1,48.4,42.088Zm-31.829-8.3a1.435,1.435,0,0,0-1.451.482,17.706,17.706,0,0,1-5.675,3.386,29.678,29.678,0,0,0,1.914-4,1.379,1.379,0,0,0-.557-1.817,10.156,10.156,0,0,1-5.129-9.16c0-6.994,5.431-11.338,14.173-11.338s14.174,4.344,14.174,11.338S28.586,34.015,19.843,34.015A25.5,25.5,0,0,1,16.568,33.784Zm28.9,8.521a31.577,31.577,0,0,0,1.5,3.7,15.607,15.607,0,0,1-5.338-3.188,1.572,1.572,0,0,0-1.5-.523,21.345,21.345,0,0,1-3.273.23C30.626,42.519,26.1,40.305,24,36.51c7.925-1.31,12.851-6.444,12.851-13.833a15.249,15.249,0,0,0-.3-2.834h.3c8.742,0,14.174,4.344,14.174,11.338a10.156,10.156,0,0,1-5.129,9.16C45.272,40.714,45.264,41.6,45.467,42.305Z" />
    </svg>`
})
export class SvgIconDialogueComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'dialogue';
    }
}
