import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherCreatedTask extends BaseTrackingEvent{
    constructor(taskBookTitle: string, numOfStudentsInClass: number, numOfAssignees: number, taskTitle: string = '', taskId: number = 0) {
        super({
            eventId: '4810902d-d12e-4304-ba15-88af08f64cb2',
            eventVerb: TrackingVerbs.CREATED,
            eventObject: TrackingObjects.TASK,
            eventPayload: {
                taskTitle: taskTitle,
                taskId: taskId,
                taskBookTitle: taskBookTitle,
                taskNumOfStudentsInClass: numOfStudentsInClass,
                taskNumOfAssignees: numOfAssignees
            },
        });
    }
}
