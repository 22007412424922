import { BaseTrackingEvent } from 'shared/tracking/models/base-tracking-event.model';
import { TrackingObjects, TrackingVerbs } from 'shared/tracking/tracking';

export class UserAddedFavorite extends BaseTrackingEvent {
    constructor(favoriteTitle: string, favoriteId: string) {
        super({
            eventId: '97871223-9a3c-4445-ac69-294bcefaa13f',
            eventVerb: TrackingVerbs.ADDED,
            eventObject: TrackingObjects.FAVORITE,
            eventPayload: {
                favoriteTitle: favoriteTitle,
                favoriteId: favoriteId,
            },
        });
    }
}
