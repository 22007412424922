import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PollingState } from './polling.reducer';

export const getPollingState = createFeatureSelector<PollingState>(
    'polling'
);

export const getPollingAction = createSelector(
    getPollingState,
    state => state.pollingAction
);

export const getIsPollingActive = createSelector(
    getPollingState,
    state => state.isPollingActive
);

export const getPollingErrorCount = createSelector(
    getPollingState,
    state => state.pollingErrorCount
);

export const getIsErrorModeActive = createSelector(
    getPollingState,
    state => state.isErrorModeActive
);
