import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter, map, tap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { BooksState } from 'store/books';
import { getStudentsState } from 'store/students/students.selector';
import { fetchStudentDetail } from 'store/students/students.actions';

@Injectable()
export class StudentDetailsPreloadingGuard  {
    constructor(private store: Store<BooksState>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const studentId = +route.paramMap.get('studentId');

        console.log('StudentDetailsPreloadingGuard', studentId);

        return this.store.pipe(
            select(getStudentsState),
            filter(studentsState => studentsState.loaded),
            tap(studentsState => {
                const student = studentsState.allDict[studentId];

                if (student == null) {
                    console.warn(`student with ID ${studentId} doesn't exist in student-dict.`);
                    return;
                }

                // load metadata always because score need permanently updates
                if (!studentsState.loadingDetails) {
                    this.store.dispatch(fetchStudentDetail({studentId}));
                }
            }),
            map(_ => true),
            take(1)
        );
    }
}
