import {
    Component,
    ViewChild,
    ViewContainerRef,
    ComponentRef,
    ComponentFactoryResolver,
    Input,
    OnChanges,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    OnDestroy,
    SimpleChanges,
    SimpleChange,
    HostBinding,
    Renderer2
} from '@angular/core';

import { Subject, Observable, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CvPopUpSettings, CvPopupConfirmEvent } from 'shared/models';
import { CvMonstersMessageComponent } from '../cv-monsters-message/cv-monsters-message.component';
import { ReadingLevelRiseComponent } from 'app/student/reading-level-rise/reading-level-rise.component';
import { CvCalendarComponent } from '../cv-calendar/cv-calendar.component';
import { SignedOutPopupComponent } from 'app/teacher/signed-out-popup/signed-out-popup.component';
import { ActivateLicencePopupComponent } from 'app/teacher/activate-licence-popup/activate-licence-popup.component';
import { SendMessageComponent } from 'app/teacher/messages/send-message/send-message.component';
import { SendMessageConfirmationComponent } from 'app/teacher/messages/send-message-confirmation/send-message-confirmation.component';
import { EditAssignmentComponent } from 'app/teacher/assignments/editor/edit-assignment/edit-assignment.component';
import { CreateAssignmentComponent } from 'app/teacher/assignments/editor/create-assignment/create-assignment.component';
import { UpdateMonsterComponent } from 'app/student/update-monster/update-monster.component';
import { PromotionCodeComponent } from 'app/teacher/promotion-code/promotion-code.component';
import { CvGroupLimitInfoComponent } from '../cv-group-limit-info/cv-group-limit-info.component';

@Component({
    selector: 'app-cv-popup',
    templateUrl: './cv-popup.component.html',
    styleUrls: ['./cv-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvPopupComponent implements OnChanges, OnDestroy {
    // TODO find better way to inject components dynamically by name (string)
    private readonly componentsDict = {
        'CreateAssignmentComponent': CreateAssignmentComponent,
        'EditAssignmentComponent': EditAssignmentComponent,
        'CvMonstersMessageComponent': CvMonstersMessageComponent,
        'ReadingLevelRiseComponent': ReadingLevelRiseComponent,
        'CvCalendarComponent': CvCalendarComponent,
        'SignedOutPopupComponent': SignedOutPopupComponent,
        'ActivateLicencePopupComponent': ActivateLicencePopupComponent,
        'SendMessageComponent': SendMessageComponent,
        'SendMessageConfirmationComponent': SendMessageConfirmationComponent,
        'UpdateMonsterComponent': UpdateMonsterComponent,
        'PromotionCodeComponent': PromotionCodeComponent,
        'GroupLimitInfoComponent': CvGroupLimitInfoComponent,
    };
    private readonly ngUnsubscribe = new Subject<void>();
    private readonly baseWrapperClasses = ['popup-content'];

    private wrapperCssClasses = [...this.baseWrapperClasses];
    private renderedComponentOutputData: any;
    private timer: Subscription;

    @ViewChild('renderedComponentContainer', {
        read: ViewContainerRef
    })
    viewContainer: ViewContainerRef;

    @Input()
    visible = false;

    @Input()
    settings: CvPopUpSettings;

    @Output()
    readonly closePopup = new EventEmitter<void>();

    @Output()
    readonly renderedComponentOutput = new EventEmitter<void>();

    @Output()
    readonly hasConfirmed = new EventEmitter<CvPopupConfirmEvent>();

    @HostBinding('class')
    get cssClasses(): string {
        return this.wrapperCssClasses.join(' ');
    }

    get isCloseable(): boolean {
        if (this.settings == null || this.settings.closable == null) {
            return true;
        }

        return this.settings.closable;
    }

    get showButtons(): boolean {
        if (this.settings == null || this.settings.buttons == null) {
            return false;
        }

        return true;
    }

    get title(): string {
        return this.settings.title;
    }

    get hasButtons(): boolean {
        return this.showButtons;
    }

    get hasTitle(): boolean {
        return this.settings ? !!this.settings.title : false;
    }

    @HostBinding('style.display')
    get popupStyleDisplay(): string {
        return this.visible === true ? 'block' : 'none';
    }

    @HostBinding('style.overflow')
    get overflow(): string {
        return this.settings == null || this.settings.overflow == null
            ? 'auto'
            : this.settings.overflow;
    }

    componentRef: ComponentRef<any>;

    constructor(private resolver: ComponentFactoryResolver, private renderer: Renderer2) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.wrapperCssClasses = [...this.baseWrapperClasses];

        const settingsChange = changes.settings;

        if (settingsChange && this.viewContainer) {
            if (this.componentRef != null) {
                this.componentRef.destroy();
            }

            const settings: CvPopUpSettings = settingsChange.currentValue;

            if (settings) {
                if (settings.size) {
                    this.wrapperCssClasses.push(settings.size);
                }

                if (settings.padding == null || settings.padding === true) {
                    this.wrapperCssClasses.push('padding');
                }

                if (settings.transparentBackground === true) {
                    this.wrapperCssClasses.push('transparent-background');
                }

                if (settings.renderedComponentConfig) {
                    this.createComponent();
                }

                const { autoClose } = settings;

                if (settings.closable && settings.autoClose != null) {
                    this.setUpAutoClose(autoClose);
                }
            }
        }

        // if the popup has been closed and opend again, the inner compoent should be recreated
        const visibleChange: SimpleChange = changes.visible;

        if (visibleChange && visibleChange.currentValue) {
            if (this.settings.renderedComponentConfig) {
                this.createComponent();
            }
        } else {
            this.renderer.removeClass(document.body, 'no-scroll');
        }
    }

    ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    close(): void {
        this.closePopup.emit();

        if (this.componentRef) {
            this.componentRef.destroy();
        }

        this.renderedComponentOutputData = null;
    }

    private setUpAutoClose(timeout: number): void {
        if (this.timer != null) {
            this.timer.unsubscribe();
        }

        this.timer = timer(timeout)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.close();
            });
    }

    confirm(): void {
        this.hasConfirmed.emit({
            channel: this.settings.channel,
            data: this.settings.data,
            renderedComponentOutputData: this.renderedComponentOutputData
        });
        this.close();
    }

    private createComponent() {
        this.viewContainer.clear();

        // create component
        const { componentName } = this.settings.renderedComponentConfig;
        const component: any = this.componentsDict[componentName];

        if (component == null) {
            throw new Error(
                `the component "${componentName}" isn't defined in CvPopupComponents component list`
            );
        }

        const factory = this.resolver.resolveComponentFactory(component);

        this.componentRef = this.viewContainer.createComponent(factory);

        const { instance } = this.componentRef;
        const { data } = this.settings;

        if (data != null) {
            // set properties to child component
            for (const key in data) {
                instance[key] = data[key];
            }
        }

        const confirmEvent: Observable<void> = instance['confirmPopup'];

        if (confirmEvent != null) {
            confirmEvent
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => this.confirm());
        }

        const dismissEvent: Observable<void> = instance['dismissPopup'];

        if (dismissEvent != null) {
            dismissEvent
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => this.close());
        }

        this.renderer.addClass(document.body, 'no-scroll');
    }
}
