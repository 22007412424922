import { StrategyModel } from './strategy.model';

export enum ExerciseType {
    Unknown = 0,

    /** Die fünf/zehn Fragen */
    MultipleChoice = 1,

    /** Die passenden Wörter */
    Skimming = 2,

    /** Das richtige Feld */
    Matrix = 3,

    /** Die richtige Reihenfolge */
    Arrange = 4,

    /** Der Lückentext */
    GapFill = 5,

    /** Die richtige Zuordnung */
    DragAndDrop = 6,

    /** Das Suchbild */
    Hotspot = 7,

    /** Die (verdrehte) Wortschlange */
    Wordsnake = 8,

    /** Die richtigen Paare */
    FindThePairs = 9,

    /** Das falsche Wort */
    MarkTheWords = 10
}

export interface ExerciseModel {
    id: number;
    title: string;
    type: ExerciseType;
    maxScore?: number;
    url?: string;
}

export interface ExerciseDetailModel {
    exercises: ExerciseModel[];
    completedExercises: StudentCompletedExerciseModel[];
    strategies?: StrategyModel[];
}

export interface StudentCompletedExerciseModel {
    studentId: number;
    completedExerciseIds: number[];
}

export interface ExerciseBookData {
    path: string,
    alt: string,
    bookTitle: string
}

export interface ExerciseExample {
    title: string;
    images: ExerciseBookData[]; 
}

export interface ExerciseExplanationModel extends Pick<ExerciseModel, 'title' | 'type'> {
    imageUrl: string;
    description: string[]
}
