import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'slug'})
export class SlugPipe implements PipeTransform {
    transform(value: string): string {
        const whitespace = /\s+/g;
        const specialChars = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const basicChars = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';

        const specialCharsExp = new RegExp(specialChars.split('').join('|'), 'g');

        const slug: string = value
            .toLowerCase()
            .replace(whitespace, '-')
            .replace(specialCharsExp, c => basicChars.charAt(specialChars.indexOf(c)));

        return slug;
    }
}
