import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TeacherClickedBanner } from 'shared/tracking/models/teacher-clicked-banner.model';
import { UserState } from 'store/user';
import * as TrackingActions from '../../store/tracking/tracking.actions';

@Component({
  selector: 'app-sale-banner',
  templateUrl: './sale-banner.component.html',
  styleUrls: ['./sale-banner.component.scss']
})
export class SaleBannerComponent {
    private readonly today = new Date();
    private readonly saleStart = new Date('2020-05-12');
    private readonly saleEnd = new Date('2020-11-01');

    readonly showSaleAlert = this.today >= this.saleStart && this.today < this.saleEnd;
    readonly isOnSale = this.today >= new Date('2020-05-18');

    constructor(private store: Store<UserState>) { }

    trackBanner(): void {
        this.store.dispatch(TrackingActions.teacherClickedBanner({ event: new TeacherClickedBanner('Sales Banner School Board Licence') }));
    }
}
