import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable, combineLatest, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AccountType } from 'shared/models';
import { getSchoolClassesId } from 'store/school-classes';
import { sessionExpired } from 'store/user';
import { UserState } from 'store/user/user.reducer';
import { getUserState } from 'store/user/user.selector';

@Injectable()
export class OidcInterceptor implements HttpInterceptor {
    private readonly _config = EnvironmentService.authService.httpInterceptor;
    private readonly _jwtHelper: JwtHelperService = new JwtHelperService();

    constructor(private store: Store<UserState>) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this._config) {
            return next.handle(request);
        }

        if (!this._config.allowedUrls) {
            return next.handle(request);
        }

        if (this._config.sendAccessToken === false) {
            return next.handle(request);
        }

        const url = request.url.toLowerCase();

        if (!this.checkUrl(url)) {
            return next.handle(request);
        }

        return combineLatest([this.store.select(getUserState), this.store.select(getSchoolClassesId)]).pipe(
            take(1),
            switchMap(([userState, schoolClassId]) => {
                const { isAuthorized, oidcUser, accessToken } = userState;

                if (isAuthorized === false) {
                    // not authorized
                    return next.handle(request);
                }

                if (this.isTokenExpired(userState) === true) {
                    return next.handle(request);
                }

                // add access-token to http-header
                let headers = request.headers.set(
                    'Authorization',
                    `Bearer ${oidcUser?.id_token || accessToken}` // use oidcUser for teachers, and accessToken for students and demo-accounts,
                );

                if (schoolClassId != null && headers.get('x-school-class-id') == null){
                    headers = headers.set('x-school-class-id', schoolClassId.toString());
                }

                const authRequest = request.clone({ headers });

                // eslint-disable-next-line rxjs/no-implicit-any-catch
                return next.handle(authRequest).pipe(catchError((err) => this.handleAuthError(err)));
            })
        );
    }

    private isTokenExpired(userState: UserState): boolean {
        const { accountType, accessToken, oidcUser } = userState;

        switch (accountType) {
            case AccountType.Teacher: {
                return oidcUser.expired === true;
            }

            case AccountType.Student: {
                const isExpired = this._jwtHelper.isTokenExpired(accessToken);
                return isExpired === true;
            }

            default:
                throw new Error(
                    `account-type "${accountType}" isn't supported.`
                );
        }
    }

    private checkUrl(url: string): boolean {
        const found = this._config.allowedUrls.find(u => url.startsWith(u));
        return !!found;
    }

    private handleAuthError(errorResponse: HttpErrorResponse): Observable<never> {
        // we check for 401 because then the session of the user is expired
        // they should reload the page to log back in
        if (errorResponse.status === 401) {
            this.store.dispatch(sessionExpired());
        }

        const error = {
            ...errorResponse,
            errorCode: errorResponse.error?.errorCode
        };

        return throwError(() => error);
    }
}
