const cvColors = {
    colorBlatt: '#00AA64',
    colorCvRed: '#DE2922',
    colorFeuer: '#B1201B',
    colorFlieder: '#AA1EA0',
    colorHimmel: '#0064AA',
    colorSommer: '#F06E00',
    colorSonne: '#FFC107',
    colorSprosse: '#82C800',
    colorTiefsee: '#323CA0',
    colorSkyBlue: '#2196F3',

    colorBlattLight: '#E5F6EF',
    colorFeuerLight: '#F7E8E7',
    colorFliederLight: '#F6E8F5',
    colorHimmelLight: '#E6EFF6',
    colorMeerLight: '#E5EFF6',
    colorSommerLight: '#FDF0E5',
    colorSonneLight: '#FFF8E6',
    colorTiefseeLight: '#EAEBF5',
    colorSkyBlueLight: '#E9F5FE',
};

export const Colors = {
    colorPrimary: '#1A73E8',
    colorSecondary: '#E8F1FD',
    colorSuccess: '#00CE64',
    colorError: '#D30021',

    colorWhite: '#ffffff',
    colorBlack: 'rgb(21,25,29)',
    colorBlackLight: 'rgba(0,0,0,.7)',
    colorBlackUltraLight: 'rgba(0,0,0,.15)',
    colorGraphite: '#6d6d6d',
    colorGraphiteLight: '#E5E5E5',
    colorGraphiteUltraLight: '#F8F8F8',
    colorGraphiteBackgroundLight: '#F5F5F5',

    // reading level colors
    0: cvColors.colorSkyBlue,
    1: cvColors.colorSonne,
    2: cvColors.colorTiefsee,
    3: cvColors.colorFlieder,
    4: cvColors.colorBlatt,
    5: cvColors.colorSommer,
    '0-light': '#E9F5FE',
    '1-light': '#FFF8E6',
    '2-light': '#EAEBF5',
    '3-light': '#F6E8F5',
    '4-light': '#E5F6EF',
    '5-light': '#FDF0E5',

    // monster colors
    monster: {
        '01': cvColors.colorMeerLight,
        '02': cvColors.colorFliederLight,
        '03': cvColors.colorSommerLight,
        '04': cvColors.colorTiefseeLight,
        '05': cvColors.colorBlattLight,
        '06': cvColors.colorFeuerLight
    }
};
