import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 042_Lesen_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-book',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path
            d="M52.414,8.5H35.405c-3.38,0-5.323.557-7.086,2.185C26.556,9.061,24.613,8.5,21.233,8.5H4.225A1.418,1.418,0,0,0,2.807,9.921V46.772a1.418,1.418,0,0,0,1.418,1.417H21.233c3.451,0,4.571.608,6,2.323a1.416,1.416,0,0,0,2.18,0c1.425-1.715,2.545-2.323,6-2.323H52.414a1.418,1.418,0,0,0,1.417-1.417V9.921A1.418,1.418,0,0,0,52.414,8.5ZM21.233,45.354H5.642V11.339H21.233c3.182,0,4.384.529,5.669,1.954v33.2A10.975,10.975,0,0,0,21.233,45.354Zm29.763,0H35.405a10.975,10.975,0,0,0-5.669,1.136v-33.2c1.285-1.425,2.487-1.954,5.669-1.954H51Z" />
        <path
            d="M21.232,19.842H9.894a1.418,1.418,0,0,0,0,2.835H21.232a1.418,1.418,0,1,0,0-2.835Z" />
        <path
            d="M21.232,26.929H9.894a1.418,1.418,0,0,0,0,2.835H21.232a1.418,1.418,0,1,0,0-2.835Z" />
        <path d="M18.4,34.016h-8.5a1.418,1.418,0,0,0,0,2.835h8.5a1.418,1.418,0,0,0,0-2.835Z" />
        <path
            d="M33.988,21.26a1.417,1.417,0,0,0,1.418,1.417H46.744a1.418,1.418,0,0,0,0-2.835H35.406A1.418,1.418,0,0,0,33.988,21.26Z" />
        <path
            d="M46.744,26.929H35.406a1.418,1.418,0,1,0,0,2.835H46.744a1.418,1.418,0,0,0,0-2.835Z" />
        <path d="M43.91,34.016h-8.5a1.418,1.418,0,1,0,0,2.835h8.5a1.418,1.418,0,0,0,0-2.835Z" />
    </svg>
  `
})
export class SvgIconBookComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'book';
    }
}
