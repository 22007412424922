import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 126_Schliessen_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-close',
    template: `
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693">
        <path d="M30.351,28.346,47.774,10.923a1.417,1.417,0,1,0-2-2L28.347,26.342,10.924,8.919a1.417,1.417,0,0,0-2,2L26.344,28.346,8.92,45.769a1.417,1.417,0,0,0,2,2L28.347,30.35,45.771,47.772a1.416,1.416,0,1,0,2-2Z" />
    </svg>
  `
})
export class SvgIconCloseComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'close';
    }
}
