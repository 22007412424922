import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 532_Lehrer-Ansicht_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-admin',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693">
    <path d="M46.05,34.015a7.086,7.086,0,1,0-7.086-7.086A7.094,7.094,0,0,0,46.05,34.015Zm0-11.338A4.252,4.252,0,1,1,41.8,26.929,4.257,4.257,0,0,1,46.05,22.677ZM50.3,36.85H41.8A6.009,6.009,0,0,0,36.686,39.5,7.072,7.072,0,0,0,31.17,36.85H28.335V32.6a8.513,8.513,0,0,0-8.5-8.5H8.492a8.513,8.513,0,0,0-8.5,8.5V49.606a1.416,1.416,0,0,0,1.417,1.417H26.918a1.416,1.416,0,0,0,1.417-1.417V39.684H31.17a4.256,4.256,0,0,1,4.251,4.252v2.836a1.417,1.417,0,0,0,2.834,0V43.936c0-1.961.928-4.252,3.543-4.252h8.5c2.616,0,3.544,2.291,3.544,4.252v2.836a1.417,1.417,0,0,0,2.834,0V43.936C56.68,39.764,54.058,36.85,50.3,36.85ZM25.5,48.189H2.822V32.6a5.677,5.677,0,0,1,5.67-5.67H19.831A5.677,5.677,0,0,1,25.5,32.6ZM14.162,19.842a8.5,8.5,0,1,0-8.5-8.5A8.513,8.513,0,0,0,14.162,19.842Zm0-14.173a5.67,5.67,0,1,1-5.67,5.67A5.676,5.676,0,0,1,14.162,5.669Zm11.75,17.375a11.281,11.281,0,0,0-3.037-1.358,7.078,7.078,0,1,1,8.3,11.37V32.6a11.315,11.315,0,0,0-.379-2.857,4.236,4.236,0,0,0-4.879-6.7Z" />
    </svg>
  `
})
export class SvgIconAdminComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'admin';
    }
}
