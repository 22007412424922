import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 147_Meine_Klasse_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-school-class',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M42.521,14.172A5.669,5.669,0,1,0,36.851,8.5,5.675,5.675,0,0,0,42.521,14.172Zm0-8.5A2.835,2.835,0,1,1,39.686,8.5,2.837,2.837,0,0,1,42.521,5.669Zm-14.173,8.5A5.669,5.669,0,1,0,22.678,8.5,5.675,5.675,0,0,0,28.348,14.172Zm0-8.5A2.835,2.835,0,1,1,25.513,8.5,2.837,2.837,0,0,1,28.348,5.669Zm-14.174,8.5A5.669,5.669,0,1,0,8.505,8.5,5.676,5.676,0,0,0,14.174,14.172Zm0-8.5A2.835,2.835,0,1,1,11.34,8.5,2.838,2.838,0,0,1,14.174,5.669ZM29.765,31.181a5.669,5.669,0,1,0,5.669-5.669A5.675,5.675,0,0,0,29.765,31.181Zm8.5,0a2.835,2.835,0,1,1-2.834-2.835A2.837,2.837,0,0,1,38.268,31.181ZM2.835,29.764a1.417,1.417,0,0,1,1.418-1.417H5.67V23.385A7.1,7.1,0,0,1,12.757,16.3h2.835a7.073,7.073,0,0,1,5.669,2.853A7.073,7.073,0,0,1,26.93,16.3h2.835a7.073,7.073,0,0,1,5.669,2.853A7.073,7.073,0,0,1,41.1,16.3h2.835a7.1,7.1,0,0,1,7.087,7.087v4.962h1.417a1.417,1.417,0,1,1,0,2.834H43.654a8.154,8.154,0,0,0-.514-2.834h5.05V23.385a4.258,4.258,0,0,0-4.252-4.253H41.1A4.244,4.244,0,0,0,36.881,23.1a7.543,7.543,0,0,0-2.893.008,4.245,4.245,0,0,0-4.223-3.972H26.93A4.245,4.245,0,0,0,22.707,23.1a7.543,7.543,0,0,0-2.893-.008,4.244,4.244,0,0,0-4.222-3.964H12.757a4.258,4.258,0,0,0-4.252,4.253v4.962h5.049a8.178,8.178,0,0,0-.514,2.834H4.253A1.417,1.417,0,0,1,2.835,29.764Zm18.426-4.252a5.669,5.669,0,1,0,5.67,5.669A5.676,5.676,0,0,0,21.261,25.512Zm0,8.5A2.835,2.835,0,1,1,24.1,31.181,2.838,2.838,0,0,1,21.261,34.016ZM52.442,51.024h-8.5V46.062a7.1,7.1,0,0,0-7.087-7.086H34.017a7.073,7.073,0,0,0-5.669,2.852,7.076,7.076,0,0,0-5.67-2.852H19.844a7.1,7.1,0,0,0-7.087,7.086v4.962h-8.5a1.417,1.417,0,1,0,0,2.834H52.442a1.417,1.417,0,0,0,0-2.834ZM34.017,41.81h2.834A4.256,4.256,0,0,1,41.1,46.062v4.962H29.765V46.062A4.256,4.256,0,0,1,34.017,41.81ZM15.592,46.062a4.256,4.256,0,0,1,4.252-4.252h2.834a4.256,4.256,0,0,1,4.252,4.252v4.962H15.592Z" />
    </svg>
    `
})
export class SvgIconSchoolClassComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'school-class';
    }
}
