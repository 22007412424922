import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 161_Lern-_und_Arbeitstechniken_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-strategy',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M52.8,36.342a2.847,2.847,0,0,1-2.132,1.448l-2.49.31v6.545a4.96,4.96,0,0,1-4.96,4.96H32.588c-9.177,0-13.759-2.931-16.906-10.812a1.417,1.417,0,1,1,2.632-1.051c2.729,6.835,6.2,9.029,14.274,9.029h10.63a2.126,2.126,0,0,0,2.126-2.126v-7.8a1.417,1.417,0,0,1,1.242-1.406l3.731-.466-4.708-10a2.866,2.866,0,0,1-.266-1.2C45.265,13.076,41.226,8.5,31.88,8.5a1.417,1.417,0,1,1,0-2.834c11.026,0,16.211,5.754,16.3,18.105l4.7,10A2.838,2.838,0,0,1,52.8,36.342ZM4.95,17.008H1.407a1.417,1.417,0,1,0,0,2.834H4.95a1.417,1.417,0,1,0,0-2.834Zm4.115-7.1L6.56,7.4a1.417,1.417,0,1,0-2,2L7.061,11.91a1.417,1.417,0,1,0,2-2Zm6.516-.694A1.417,1.417,0,0,0,17,7.8V4.252a1.418,1.418,0,0,0-2.835,0V7.8A1.417,1.417,0,0,0,15.581,9.212Zm9.212,9.213a1.417,1.417,0,0,0,1.418,1.417h3.543a1.417,1.417,0,1,0,0-2.834H26.211A1.417,1.417,0,0,0,24.793,18.425Zm-1.7-6.1a1.413,1.413,0,0,0,1-.415l2.5-2.505a1.417,1.417,0,1,0-2-2L22.1,9.906a1.417,1.417,0,0,0,1,2.419ZM16.46,32.6H14.7a3.562,3.562,0,0,1-3.53-3.248l-.192-2.3a11.916,11.916,0,0,0-1.3-4.324,9.78,9.78,0,0,1-1.188-4.3,7.087,7.087,0,1,1,14.173,0,9.78,9.78,0,0,1-1.188,4.3,11.942,11.942,0,0,0-1.3,4.324l-.192,2.3A3.562,3.562,0,0,1,16.46,32.6ZM11.329,18.425a7.251,7.251,0,0,0,.926,3.111A19.7,19.7,0,0,1,13.278,24.1h4.6a19.7,19.7,0,0,1,1.023-2.563,7.251,7.251,0,0,0,.926-3.111,4.252,4.252,0,1,0-8.5,0ZM14.7,29.763H16.46a.712.712,0,0,0,.7-.65l.21-2.462h-3.59L14,29.113A.712.712,0,0,0,14.7,29.763Zm26.391-2.127a1.417,1.417,0,0,0,1.418-1.417V24.093a1.419,1.419,0,0,0-1.651-1.4l-4.252.709a1.418,1.418,0,0,0,.466,2.8l2.6-.435v.453A1.416,1.416,0,0,0,41.092,27.636Z" />
    </svg>
  `
})
export class SvgIconStrategyComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'exercise';
    }
}
