import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 541_Pfeil_nach_rechts_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-arrow',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon"> <path d="M31.6,44.351a1.417,1.417,0,1,0,2,2.005L50.609,29.348a1.419,1.419,0,0,0,0-2L33.6,10.335a1.417,1.417,0,0,0-2,2L46.185,26.928H4.252a1.418,1.418,0,0,0,0,2.835H46.185Z" /> </svg>
  `
})
export class SvgIconArrowComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'arrow';
    }
}
