import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

@Component({
    selector: 'app-svg-icon-new-note',
    template: `
    <!-- 507_Neue_Notiz_linear_CV_Uni_Icon -->
        <svg [attr.height]="height" [attr.width]="width" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path [attr.fill]="color" d="M36.851,38.268H19.843a1.417,1.417,0,0,1,0-2.834H36.851a1.417,1.417,0,0,1,0,2.834Zm7.087-12.756a1.417,1.417,0,0,0-1.418,1.417v21.26H14.174V14.173h15.59a1.417,1.417,0,1,0,0-2.834H12.756a1.417,1.417,0,0,0-1.417,1.417v36.85a1.417,1.417,0,0,0,1.417,1.417H43.938a1.417,1.417,0,0,0,1.417-1.417V26.929A1.417,1.417,0,0,0,43.938,25.512Zm8.493-14.173H45.344V4.252a1.417,1.417,0,0,0-2.834,0v7.087H35.423a1.417,1.417,0,0,0,0,2.834H42.51V21.26a1.417,1.417,0,1,0,2.834,0V14.173h7.087a1.417,1.417,0,1,0,0-2.834ZM36.851,29.764H19.843a1.417,1.417,0,0,0,0,2.834H36.851a1.417,1.417,0,0,0,0-2.834ZM19.843,41.1a1.417,1.417,0,1,0,0,2.834H31.181a1.417,1.417,0,1,0,0-2.834Z" />
        </svg>
    `
})
export class SvgIconNewNoteComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'new-note';
    }
}
