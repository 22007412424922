import { ExerciseModel, StrategyModel } from '.';
import { StrategyType } from './strategy.model';

export interface BookModel {
    coverUri: string;
    createdAt: Date;
    exercises: { type: StrategyType, title: string }[];
    genres: string[];
    id: number;
    isFavorite?: boolean;
    isCompleted?: boolean;
    isNew?: boolean;
    lastReadAt?: Date;
    pageCount: number;
    readingLevel: number;
    seoFriendlyTitle: string;
    series: string;
    strategies: { strategyType: StrategyType, title: string }[];
    competences: { type: number, title: string }[];
    title: string;
    isbn?: string; // can this be removed from API response?
    originalIsbn?: string; // can this be removed from API response?
    description?: string; // can this be removed from API response?
    connectionToPrints: ConnectionToPrintBookInfo[];
}

export interface BookDetailModel extends BookModel {
    metadata: BookMetadata;
}

export interface BookMetadata {
    productManagement: string;
    editors: string;
    originalTitle: string;
    copyright: string;
    audioProduction: string;
    speaker: string;
    artwork: string;
    translation: string;
    coverCopyright: string;
    author: string;
    exercises: ExerciseModel[];
    strategies: StrategyModel[];
    developmentEditing?: string;
}

export interface LibraryModel {
    filters: { name: string, values: { key: number, value: any }[] }[];
    books: BookModel[];
}

export interface RecommendedBook {
    coverUri: string;
    description: string;
    genres: string[];
    id: number;
    isNew: boolean;
    readingLevel: number;
    seoFriendlyTitle: string;
    title: string;
}

export interface PrintedBook {
    cover: string;
    name: string;
    bookId: number;
}

export interface ConnectionToPrintBookInfo {
    chapter: string;
    pageNumber: number;
    textBookName: string;
}
