import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconAdminComponent } from './svg-icon-admin.component';
import { SvgIconArrowComponent } from './svg-icon-arrow.component';
import { SvgIconCarrotComponent } from './svg-icon-carrot.component';
import { SvgIconAudioComponent } from './svg-icon-audio.component';
import { SvgIconBookComponent } from './svg-icon-book.component';
import { SvgIconCalendarComponent } from './svg-icon-calendar.component';
import { SvgIconChevronComponent } from './svg-icon-chevron.component';
import { SvgIconCloseComponent } from './svg-icon-close.component';
import { SvgIconCorrectComponent } from './svg-icon-correct.component';
import { SvgIconDoneComponent } from './svg-icon-done.component';
import { SvgIconExerciseComponent } from './svg-icon-exercise.component';
import { SvgIconEyeComponent } from './svg-icon-eye.component';
import { SvgIconGroupComponent } from './svg-icon-group.component';
import { SvgIconHintComponent } from './svg-icon-hint.component';
import { SvgIconHouseComponent } from './svg-icon-house.component';
import { SvgIconInvalidComponent } from './svg-icon-invalid.component';
import { SvgIconLibraryComponent } from './svg-icon-library.component';
import { SvgIconLogoutComponent } from './svg-icon-logout.component';
import { SvgIconNewNoteComponent } from './svg-icon-new-note.component';
import { SvgIconNewWindowComponent } from './svg-icon-new-window.component';
import { SvgIconNotificationComponent } from './svg-icon-notification.component';
import { SvgIconOpenBookComponent } from './svg-icon-open-book.component';
import { SvgIconPencilComponent } from './svg-icon-pencil.component';
import { SvgIconPlusComponent } from './svg-icon-plus.component';
import { SvgIconPrintComponent } from './svg-icon-print.component';
import { SvgIconReloadComponent } from './svg-icon-reload.component';
import { SvgIconRemoveComponent } from './svg-icon-remove.component';
import { SvgIconSchoolClassComponent } from './svg-icon-school-class.component';
import { SvgIconStarComponent } from './svg-icon-star.component';
import { SvgIconStrategyComponent } from './svg-icon-strategy.component';
import { SvgIconSyllableComponent } from './svg-icon-syllable.component';
import { SvgIconTaskComponent } from './svg-icon-task.component';
import { SvgIconThreeFilledDotsComponent } from './svg-icon-three-filled-dots.component';
import { SvgIconTrophyComponent } from './svg-icon-trophy.component';
import { SvgIconUserComponent } from './svg-icon-user.component';
import { SvgIconWarningComponent } from './svg-icon-warning.component';
import { SvgIconPdfFileComponent } from './svg-icon-pdf-file.component';
import { SvgIconSearchComponent } from './svg-icon-search.component';
import { SvgIconInformationComponent } from './svg-icon-information.component';
import { SvgIconAuthorComponent } from './svg-icon-author.component';
import { SvgIconDialogueComponent } from './svg-icon-dialogue.component';
import { SvgIconSendComponent } from './svg-icon-send.component';
import { SvgIconEarComponent } from './svg-icon-ear.component';
import { SvgIconShoppingCartComponent } from './svg-icon-shopping-cart.component';
import { SvgIconRatingComponent } from './svg-icon-rating.component';


@NgModule({
    imports: [CommonModule],
    exports: [
        SvgIconRatingComponent,
        SvgIconAdminComponent,
        SvgIconArrowComponent,
        SvgIconCalendarComponent,
        SvgIconChevronComponent,
        SvgIconCloseComponent,
        SvgIconCorrectComponent,
        SvgIconDoneComponent,
        SvgIconEyeComponent,
        SvgIconHintComponent,
        SvgIconHouseComponent,
        SvgIconInvalidComponent,
        SvgIconLibraryComponent,
        SvgIconLogoutComponent,
        SvgIconNewNoteComponent,
        SvgIconNewWindowComponent,
        SvgIconOpenBookComponent,
        SvgIconPencilComponent,
        SvgIconPlusComponent,
        SvgIconReloadComponent,
        SvgIconRemoveComponent,
        SvgIconTaskComponent,
        SvgIconThreeFilledDotsComponent,
        SvgIconTrophyComponent,
        SvgIconUserComponent,
        SvgIconWarningComponent,
        SvgIconGroupComponent,
        SvgIconBookComponent,
        SvgIconAudioComponent,
        SvgIconExerciseComponent,
        SvgIconSyllableComponent,
        SvgIconStarComponent,
        SvgIconStrategyComponent,
        SvgIconPrintComponent,
        SvgIconSchoolClassComponent,
        SvgIconNotificationComponent,
        SvgIconCarrotComponent,
        SvgIconPdfFileComponent,
        SvgIconSearchComponent,
        SvgIconInformationComponent,
        SvgIconAuthorComponent,
        SvgIconDialogueComponent,
        SvgIconSendComponent,
        SvgIconEarComponent,
        SvgIconShoppingCartComponent
    ],
    declarations: [
        SvgIconRatingComponent,
        SvgIconAdminComponent,
        SvgIconArrowComponent,
        SvgIconCalendarComponent,
        SvgIconChevronComponent,
        SvgIconCloseComponent,
        SvgIconCorrectComponent,
        SvgIconDoneComponent,
        SvgIconEyeComponent,
        SvgIconHintComponent,
        SvgIconHouseComponent,
        SvgIconInvalidComponent,
        SvgIconLibraryComponent,
        SvgIconLogoutComponent,
        SvgIconNewNoteComponent,
        SvgIconNewWindowComponent,
        SvgIconOpenBookComponent,
        SvgIconPencilComponent,
        SvgIconPlusComponent,
        SvgIconReloadComponent,
        SvgIconRemoveComponent,
        SvgIconSchoolClassComponent,
        SvgIconTaskComponent,
        SvgIconThreeFilledDotsComponent,
        SvgIconTrophyComponent,
        SvgIconUserComponent,
        SvgIconWarningComponent,
        SvgIconGroupComponent,
        SvgIconBookComponent,
        SvgIconAudioComponent,
        SvgIconExerciseComponent,
        SvgIconSyllableComponent,
        SvgIconStarComponent,
        SvgIconStrategyComponent,
        SvgIconPrintComponent,
        SvgIconNotificationComponent,
        SvgIconCarrotComponent,
        SvgIconPdfFileComponent,
        SvgIconSearchComponent,
        SvgIconInformationComponent,
        SvgIconAuthorComponent,
        SvgIconDialogueComponent,
        SvgIconSendComponent,
        SvgIconEarComponent,
        SvgIconShoppingCartComponent
    ]
})
export class SvgIconsModule {}
