import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EnvironmentService } from 'app/core/services/environment.service';
import { StudentDetails } from 'shared/models';

@Injectable()
export class StudentDetailsService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) {}

    getStudentDetails(studentId: number): Observable<StudentDetails> {
        return this.http.get<StudentDetails>(
            `${this.baseApiUrl}/studentdetails/${studentId}`
        );
    }

    postStudentDetailsNote(studentId: number, note: string): Observable<string> {
        return this.http.post<string>(
            `${this.baseApiUrl}/studentdetails/${studentId}/note`,
            {content: note}
        );
    }
}
