import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 581_Autoren_Herausgeber_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-author',
    template: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 56.693 56.693" enable-background="new 0 0 56.693 56.693" xml:space="preserve" class="icon">
        <g id="RZ_Kopie">
        </g>
        <path
            d="M35.433,45.355h-20.46l0.78-2.895c4.138-1.394,9.157-4.359,11.979-7.118c0.463-0.453,0.56-1.161,0.236-1.723l-1.14-1.974  l3.203-1.084c0.449-0.152,0.792-0.519,0.915-0.977c0.738-2.755,0.927-5.849,0.48-7.882c-0.064-0.293-0.22-0.558-0.444-0.757  l-0.9-0.798l1.77-1.021c0.428-0.247,0.696-0.7,0.708-1.194c0.134-5.752-1.023-11.93-2.949-15.737  c-0.342-0.677-1.152-0.964-1.847-0.652c-8.487,3.825-12.77,10.871-16.929,21c-0.159,0.388-0.138,0.827,0.058,1.198l0.932,1.771  H10.63c-0.761,0-1.386,0.601-1.416,1.36c-0.178,4.461,1.384,9.414,4.197,13.39l-1.656,6.141c-0.115,0.426-0.025,0.881,0.243,1.231  c0.268,0.35,0.684,0.555,1.125,0.555h22.311c0.783,0,1.417-0.634,1.417-1.417S36.216,45.355,35.433,45.355z M26.731,20.965  l2.005,1.777c0.182,1.263,0.132,3.247-0.349,5.383l-4.104,1.389c-0.402,0.137-0.723,0.445-0.872,0.843s-0.113,0.841,0.099,1.209  l1.435,2.485c-2.205,1.894-5.466,3.845-8.445,5.092l0.425-1.576c3.256-12.152,7.383-23.562,11.27-31.259  c0.965,3.09,1.546,7.024,1.543,10.768l-2.773,1.602c-0.395,0.228-0.655,0.632-0.701,1.085S26.39,20.662,26.731,20.965z   M12.053,28.346h2.12c0.496,0,0.957-0.26,1.214-0.685c0.256-0.425,0.271-0.953,0.04-1.393l-1.717-3.26  c3.253-7.818,6.445-13.364,11.757-16.954c-3.808,7.783-7.788,18.877-10.95,30.638C13.064,34.06,12.19,31.128,12.053,28.346z" />
    </svg>
  `
})
export class SvgIconAuthorComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'author';
    }
}
