import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 515_Hinzufuegen_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-plus',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M45.319,26.929H29.729V11.338a1.417,1.417,0,1,0-2.834,0V26.929H11.3a1.417,1.417,0,0,0,0,2.834H26.9V45.354a1.417,1.417,0,0,0,2.834,0V29.763h15.59a1.417,1.417,0,0,0,0-2.834Z" />
    </svg>
  `
})
export class SvgIconPlusComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'plus';
    }
}
