import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 118_Home_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-house',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path d="M5.672,25.512a1.412,1.412,0,0,0,.849-.284L8.505,23.74V46.771a1.418,1.418,0,0,0,1.418,1.418h36.85a1.417,1.417,0,0,0,1.417-1.418V23.74l1.984,1.488a1.418,1.418,0,1,0,1.7-2.268L29.2,5.953a1.418,1.418,0,0,0-1.7,0L4.82,22.96a1.418,1.418,0,0,0,.852,2.552ZM34.017,45.354H22.679V31.181H34.017Zm-5.669-36.5L45.355,21.614v23.74h-8.5V29.764a1.418,1.418,0,0,0-1.418-1.418H21.261a1.418,1.418,0,0,0-1.417,1.418v15.59h-8.5V21.614Z" />
        </svg>
  `
})
export class SvgIconHouseComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'house';
    }
}
