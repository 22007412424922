import { Component, Input } from '@angular/core';
import { Colors } from 'shared/helper';

@Component({
    selector: 'app-back-to',
    templateUrl: './back-to.component.html',
    styleUrls: ['./back-to.component.scss']
})
export class BackToComponent {
    readonly colors = Colors;

    @Input()
    link: string | (string|number)[];

    constructor() {}
}
