import { Component, Input } from '@angular/core';

import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 133_Warenkorb_flaechig_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-shopping-cart',
    template: `
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path d="M51.025,12.047V33.361a.709.709,0,0,1-.653.707L16.2,36.8l.66,5.718H38.977a.708.708,0,0,1,.709.708v1.417a.709.709,0,0,1-.709.709H14.96a.709.709,0,0,1-.7-.628L10.076,8.5H3.544A.708.708,0,0,1,2.836,7.8V6.379a.708.708,0,0,1,.708-.709h8.427a.709.709,0,0,1,.7.627l.582,5.041H50.316A.71.71,0,0,1,51.025,12.047ZM17.009,48.9a2.126,2.126,0,1,0,2.126,2.126A2.126,2.126,0,0,0,17.009,48.9Zm19.842,0a2.126,2.126,0,1,0,2.126,2.126A2.126,2.126,0,0,0,36.851,48.9Z" />
        </svg>

    `
})
export class SvgIconShoppingCartComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'shopping-cart';
    }
}
