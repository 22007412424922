import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map, filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { Colors } from 'shared/helper';
import { AccountType, MaintenanceModel } from 'shared/models';
import { LayoutState } from 'store/layout/layout.reducer';
import { getMaintenance } from 'store/layout/layout.selector';
import { hideMaintenance } from 'store/layout';
import { getAccountType } from 'store/user';

@Component({
    selector: 'app-maintenance-info',
    templateUrl: './maintenance-info.component.html',
    styleUrls: ['./maintenance-info.component.scss']
})
export class MaintenanceInfoComponent implements OnInit, OnDestroy {
    private readonly ngUnsubscribe = new Subject<void>();

    readonly colors = Colors;

    @HostBinding('class.show')
    isVisible = false;

    private _maintenance$: Observable<MaintenanceModel>;

    get maintenance$(): Observable<MaintenanceModel> {
        return this._maintenance$.pipe(
            filter(maintenance => maintenance != null),
            map(maintenance => maintenance)
        );
    }

    constructor(private store: Store<LayoutState>) {}

    ngOnInit() {
        this._maintenance$ = this.store.pipe(
            select(getMaintenance)
        );

        this._maintenance$.pipe(
            filter(m => m != null),
            withLatestFrom(this.store.pipe(select(getAccountType))),
            map(maintenance => maintenance),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(([maintenance, accountType]) => {
            if (accountType === AccountType.Student && maintenance.teacherOnly) {
                this.isVisible = false;
            } else {
                const isInFuture = maintenance.endDateTime?.getTime() > Date.now();
                this.isVisible = maintenance.active && isInFuture;
            }
        });
    }

    close(): void {
        this.isVisible = false;

        this.store.dispatch(hideMaintenance());
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
