import { Component } from '@angular/core';

import { SvgIconBaseDirective } from './svg-icon-base.component';

@Component({
    selector: 'app-svg-icon-calendar',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M49.607,8.506H42.521V7.087a1.418,1.418,0,0,0-2.835,0V8.506H17.009V7.087a1.418,1.418,0,0,0-2.835,0V8.506H7.088A1.417,1.417,0,0,0,5.67,9.923V49.607a1.417,1.417,0,0,0,1.418,1.417H49.607a1.417,1.417,0,0,0,1.418-1.417V9.923A1.417,1.417,0,0,0,49.607,8.506ZM14.174,11.34v1.417a1.418,1.418,0,0,0,2.835,0V11.34H39.686v1.417a1.418,1.418,0,0,0,2.835,0V11.34H48.19v5.669H8.505V11.34ZM8.505,48.19V19.843H48.19V48.19ZM31.891,41.1a1.417,1.417,0,0,1-1.418,1.417H26.221a1.417,1.417,0,1,1,0-2.834h4.252A1.417,1.417,0,0,1,31.891,41.1Zm-11.339,0a1.416,1.416,0,0,1-1.417,1.417H14.883a1.417,1.417,0,1,1,0-2.834h4.252A1.416,1.416,0,0,1,20.552,41.1ZM43.23,34.016a1.417,1.417,0,0,1-1.418,1.417H37.56a1.417,1.417,0,0,1,0-2.834h4.252A1.417,1.417,0,0,1,43.23,34.016Zm-11.339,0a1.417,1.417,0,0,1-1.418,1.417H26.221a1.417,1.417,0,1,1,0-2.834h4.252A1.417,1.417,0,0,1,31.891,34.016Zm-11.339,0a1.416,1.416,0,0,1-1.417,1.417H14.883a1.417,1.417,0,1,1,0-2.834h4.252A1.416,1.416,0,0,1,20.552,34.016ZM43.23,26.93a1.417,1.417,0,0,1-1.418,1.417H37.56a1.417,1.417,0,0,1,0-2.834h4.252A1.417,1.417,0,0,1,43.23,26.93Zm-11.339,0a1.417,1.417,0,0,1-1.418,1.417H26.221a1.417,1.417,0,1,1,0-2.834h4.252A1.417,1.417,0,0,1,31.891,26.93Z" />
    </svg>

  `
})
export class SvgIconCalendarComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'calendar';
    }
}
