import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 150_Versenden_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-send',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M53.433,3.25a1.418,1.418,0,0,0-1.5-.326L6.58,19.932a1.417,1.417,0,0,0-.5,2.329L19.833,36.02V49.605a1.418,1.418,0,0,0,2.418,1l6.094-6.077,6.076,6.076a1.416,1.416,0,0,0,1,.415,1.391,1.391,0,0,0,.3-.032A1.417,1.417,0,0,0,36.75,50.1L53.758,4.75A1.417,1.417,0,0,0,53.433,3.25ZM22.668,46.19V38.855l3.672,3.672Zm12.2.857L23.254,35.433,44.929,13.757a1.417,1.417,0,1,0-2.005-2L21.25,33.428,9.636,21.814,50.009,6.673Z" />
    </svg>
  `
})
export class SvgIconSendComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'send';
    }
}
