import { AccountType, LicenceModel } from '.';

export interface UserModel {
    licence?: LicenceModel;
    externalUserId: string;

    accountType: AccountType;
    accountOrigin: string;

    schoolId: string;
}

export interface ProfileUpdatesModel {
    hasNewMessages: boolean;
    lastMessageDate: Date;
}
