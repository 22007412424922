import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RoleDisplayNamePipe } from './pipes/role-display-name.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        RoleDisplayNamePipe
    ],
    providers: [
        RoleDisplayNamePipe
    ],
    exports: [
        RoleDisplayNamePipe
    ]
})
export class RoleDisplayNameModule {}
