import { Component, Input, HostBinding } from '@angular/core';
import { Colors } from 'shared/helper';

@Component({
    selector: 'app-collapse',
    templateUrl: './collapse.component.html',
    styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent {
    readonly colors = Colors;

    @Input()
    title = 'title';

    @Input()
    @HostBinding('class.is-collapsed')
    isCollapsed = true;

    constructor() {}

    toggle(): void {
        this.isCollapsed = !this.isCollapsed;
    }
}
