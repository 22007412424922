import { NgModule } from '@angular/core';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: []

})
export class CoreModule {}
