import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class UserOpenedBookViewer extends BaseTrackingEvent {
    constructor(bookViewerTitle: string, bookViewerId: number, bookViewerAssignment: string) {
        super({
            eventId: 'd38dff29-ecfc-4cf9-bdad-93a53c9bf449',
            eventVerb: TrackingVerbs.OPENED,
            eventObject: TrackingObjects.BOOK_VIEWER,
            eventPayload: {
                bookViewerTitle: bookViewerTitle,
                bookViewerId: bookViewerId,
                bookViewerAssignment: bookViewerAssignment
            },
        });
    }
}
