import { Component, HostBinding, OnInit } from '@angular/core';
import { TeacherClickedBanner } from 'shared/tracking/models/teacher-clicked-banner.model';
import * as TrackingActions from '../../../store/tracking/tracking.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-christmas-banner',
  templateUrl: './christmas-banner.component.html',
  styleUrls: ['./christmas-banner.component.scss']
})
export class ChristmasBannerComponent implements OnInit {

    private static readonly hideXmasBanner = 'hide-xmas-banner';
    private readonly today = new Date();
    private readonly saleStart = new Date('2020-12-01');
    private readonly saleEnd = new Date('2020-12-25');

    private readonly showSaleAlert = this.today >= this.saleStart && this.today < this.saleEnd;

    isVisible = false;

    @HostBinding('class.isVisible') isBannerVisible: boolean;

    constructor(private store: Store) { }

    ngOnInit(): void {
        this.isVisible = !this.isHiddenByUser() && this.showSaleAlert;
        this.isBannerVisible = this.isVisible;
    }

    trackBanner(): void {
        this.store.dispatch(TrackingActions.teacherClickedBanner({ event: new TeacherClickedBanner('Christmas Banner') }));
    }

    close(): void {
        this.isVisible = false;
        this.isBannerVisible = false;

        sessionStorage.setItem(
            ChristmasBannerComponent.hideXmasBanner,
            JSON.stringify(true)
        );
    }

    private isHiddenByUser(): boolean {
        const hideInfoValue = sessionStorage.getItem(
            ChristmasBannerComponent.hideXmasBanner
        );

        if (hideInfoValue == null) {
            // no entry
            return false;
        }

        return JSON.parse(hideInfoValue);
    }
}
