import { createAction, props } from '@ngrx/store';
import { MonsterModel, StudentAssignmentModel, StudentModel } from 'shared/models';

export const fetchOpenAssignments = createAction(
    '[Assignments comp] fetch open assignments', props<{ skip?: number, limit?: number }>()
);

export const fetchOpenAssignmentsSuccess = createAction(
    '[Student API] fetch open assignments success', props<{ assignments: StudentAssignmentModel[]}>()
);

export const fetchExpiredAssignments = createAction(
    '[Assignments comp] fetch expired assignments', props<{ skip?: number, limit?: number }>()
);

export const fetchExpiredAssignmentsSuccess = createAction(
    '[Student API] fetch expired assignments success', props<{ assignments: StudentAssignmentModel[]}>()
);

export const fetchCompletedAssignments = createAction(
    '[Assignments comp] fetch completed assignments', props<{ skip?: number, limit?: number }>()
);

export const fetchCompletedAssignmentsSuccess = createAction(
    '[Student API] fetch completed assignments success', props<{ assignments: StudentAssignmentModel[]}>()
);

export const fetchAssignmentsFail = createAction(
    '[Student API] fetch assignments fail', props<{ error: any }>()
);

export const setMonster = createAction(
    '[Monster selection page] set monster',
    props<{ monster: MonsterModel }>()
);

export const setMonsterSuccess = createAction(
    '[Student Monster API] set monster success',
    props<{ student: StudentModel }>()
);

export const setMonsterFail = createAction(
    '[Student Monster API] set monster fail',
    props<{ error: any }>()
);

export const updateMonster = createAction(
    '[Update monster comp] update monster',
    props<{ monster: MonsterModel }>()
);
