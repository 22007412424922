import { createReducer, on } from '@ngrx/store';
import { Dictionary, StoreHelper } from 'shared/helper';
import { StudentNotificationModel, TeacherNotificationModel } from 'shared/models';
import { postMessageSuccess } from 'store/messages/messages.actions';
import * as NotificationsActions from 'store/notifications/notifications.actions';

export interface NotificationsState {
    notifications: Dictionary<StudentNotificationModel | TeacherNotificationModel>;
    isLoading: boolean;
    isLoaded: boolean;
}

export const initialState: NotificationsState = {
    notifications: {},
    isLoading: false,
    isLoaded: false
};

const _notificationsReducer = createReducer(
    initialState,
    on(NotificationsActions.fetchNotifications, (state, action) => {
        const newState: NotificationsState = {
            ...state,
            isLoading: true,
            isLoaded: false
        };

        return newState;
    }),
    on(
        NotificationsActions.fetchNotificationsSuccess,
        (state, { notifications }) => {
            const newState: NotificationsState = {
                ...state,
                notifications: StoreHelper.arrayToDict(notifications),
                isLoading: false,
                isLoaded: true
            };

            return newState;
        }
    ),
    on(NotificationsActions.fetchNotificationsFail, (state, { error }) => {
        const newState: NotificationsState = {
            ...state,
            isLoading: false,
            isLoaded: true
        };

        return newState;
    }),
    on(NotificationsActions.fetchTeacherNotifications, (state, action) => {
        const newState: NotificationsState = {
            ...state,
            isLoading: true,
        };

        return newState;
    }),
    on(
        NotificationsActions.fetchTeacherNotificationsSuccess,
        (state, { notifications }) => {
            const newNotifications: TeacherNotificationModel[] = notifications.map(n => {
                return {
                    id: n.id,
                    typeOfNotification: n.typeOfNotification,
                    createdAt: n.createdAt,
                    data: n.data
                };
            });

            const newNotificationsDict = StoreHelper.arrayToDict(newNotifications);
            const merged = { ...state.notifications, ...newNotificationsDict };

            const newState: NotificationsState = {
                ...state,
                notifications: merged,
                isLoading: false,
                isLoaded: true
            };

            return newState;
        }
    ),
    on(NotificationsActions.fetchTeacherNotificationsFail, (state, { error }) => {
        const newState: NotificationsState = {
            ...state,
            isLoading: false,
            isLoaded: true
        };

        return newState;
    }),
    on(postMessageSuccess, (state, {notificationId}) => {
        const newNotifications = StoreHelper.removeKey(state.notifications, notificationId.toString());

        const newState: NotificationsState = {
            ...state,
            notifications: newNotifications,
        };

        return newState;
    }),
    on(NotificationsActions.markAsReadFail, (state, { error }) => {
        const newState: NotificationsState = {
            ...state,
        };

        return newState;
    }),
    on(NotificationsActions.markAsReadSuccess, (state, { id }) => {
        const newState: NotificationsState = {
            ...state,

            notifications: {
                ...state.notifications,
                [id]: {
                    ...state.notifications[id],
                    isNew: false
                }
            }
        };

        return newState;
    }),

);

export function notificationsReducer(state, action) {
    return _notificationsReducer(state, action);
}
