import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserRoles } from 'app/core/enums';
import { CustomErrorCodes } from 'app/core/enums/custom-error-codes';
import { LoginDataModel } from 'app/core/models';
import { OidcUserService } from 'app/core/services';
import { Log, User } from 'oidc-client-ts';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AccountType, LoginProvider, StudentLoginResponse } from 'shared/models';
import { TrackingService } from 'shared/tracking/tracking.service';
import { UserState } from 'store/user/user.reducer';
import { UserService } from './user.service';
import { EnvironmentService } from 'app/core/services/environment.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private static readonly redirectPathKey = 'redirectPath';
    private static readonly studentAccessTokenKey = 'student-access-token';
    private static readonly demoAccessTokenKey = 'demo-access-token';
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    get studentLoginEndpoint(): string {
        return `${this.baseApiUrl}/studentLogin`;
    }

    get demoLoginEndpoint(): string {
        return `${this.baseApiUrl}/demologin`;
    }

    get userProfileEndpoint(): string {
        return `${this.baseApiUrl}/User/me`;
    }

    get currentOidcUser$(): Observable<User> {
        return this.oidcUserService.getUser();
    }

    get redirectPath(): string {
        return sessionStorage.getItem(AuthService.redirectPathKey);
    }

    set redirectPath(value: string) {
        sessionStorage.setItem(AuthService.redirectPathKey, value);
    }

    get studentAccessToken(): string {
        return localStorage.getItem(AuthService.studentAccessTokenKey);
    }

    set studentAccessToken(value: string) {
        localStorage.setItem(AuthService.studentAccessTokenKey, value);
    }

    get demoAccessToken(): string {
        return localStorage.getItem(AuthService.demoAccessTokenKey);
    }

    set demoAccessToken(value: string) {
        localStorage.setItem(AuthService.demoAccessTokenKey, value);
    }

    get currentLoginProvider(): LoginProvider | null {
        return this.oidcUserService.currentLoginProvider;
    }

    constructor(
        // private store: Store<UserState>,
        private http: HttpClient,
        private jwtHelper: JwtHelperService,
        public oidcUserService: OidcUserService,
        private userService: UserService
    ) {
        if (EnvironmentService.authService.enableDebugMode === true) {
            Log.setLogger(console);
            Log.setLevel(Log.DEBUG);
        }
    }

    private addOidcEvents() {
        this.oidcUserService.addUserLoaded((user: User) => {
            console.info('user loaded', user);
            // this.store.dispatch(oidcUserLoginSuccess({user}));
        });

        this.oidcUserService.addUserUnloaded(() => {
            console.info('user unloaded');
        });

        this.oidcUserService.addAccessTokenExpiring(e =>
            console.info('token expiring', e)
        );

        this.oidcUserService.addAccessTokenExpired(e =>
            console.info('token expired', e)
        );

        this.oidcUserService.addSilentRenewError(e =>
            console.error('silent refresh', e)
        );
    }

    getOidcLoginData(): Observable<LoginDataModel> {
        return this.currentOidcUser$.pipe(
            map(oidcUser => {
                if (oidcUser == null) return null;

                const roles: string[] = oidcUser?.profile['roles'] as string[];
                let accountType: AccountType;

                if (roles?.length > 0) {
                    accountType = roles?.indexOf('Student') > -1 ? AccountType.Student : AccountType.Teacher;
                } else {
                    // BSP oidc response does not include role
                    accountType = this.userService.getAccountTypeFromLocalStorage();
                }

                const loginData: LoginDataModel = {
                    accountType: accountType || AccountType.Teacher,
                    roles: <UserRoles[]>roles ?? [],
                    accessToken: oidcUser.access_token,
                    expired: oidcUser.expired,
                    oidcUser: oidcUser,
                    isTriggeredBySilentRefresh: false,
                };

                return loginData;
            })
        );
    }

    getLeseoStudentOrDemoUserSession(): LoginDataModel {
        const isStudentSession = this.studentAccessToken && this.studentAccessToken.length > 0;

        if (isStudentSession) {
            return this.getLoggedInStudent();
        }

        const isDemoUserSession = this.demoAccessToken && this.demoAccessToken.length > 0;

        if (isDemoUserSession) {
            return this.getLoggedInDemoUser();
        }

        return null;
    }

    isJwtExpired(token: string): boolean {
        return this.jwtHelper.isTokenExpired(token);
    }

    decodeJwt(token: string): any {
        return this.jwtHelper.decodeToken(token);
    }

    private getLoggedInStudent(): LoginDataModel {
        if (this.isJwtExpired(this.studentAccessToken)) {
            throw new Error('student token is expired');
        }

        const decodedToken = this.jwtHelper.decodeToken(this.studentAccessToken);

        return {
            accountType: AccountType.Student,
            accessToken: this.studentAccessToken,
            isTriggeredBySilentRefresh: false,
            oidcUser: null,
            roles: decodedToken.roles,
            expired: false
        };
    }

    private getLoggedInDemoUser(): LoginDataModel {
        if (this.isJwtExpired(this.demoAccessToken)) {
            throw new Error('demoAccessToken is expired');
        }

        const decodedToken = this.jwtHelper.decodeToken(this.demoAccessToken);

        const signinResponse = {
            access_token: this.demoAccessToken,
            expires_at: decodedToken.exp,
            id_token: this.demoAccessToken,
            token_type: 'access_token',
        };

        const user = new User(signinResponse as any);

        return {
            accountType: AccountType.Teacher,
            accessToken: this.demoAccessToken,
            isTriggeredBySilentRefresh: false,
            oidcUser: user,
            roles: [UserRoles.Teacher],
            expired: false
        };
    }

    oidcUserLogin(provider: LoginProvider, redirectPath?: string) {
        if (redirectPath !== undefined) {
            this.redirectPath = redirectPath;
        }

        this.clearTokens();
        this.clearTrackingUserSession();

        this.oidcUserService.useProvider(provider);
        this.addOidcEvents();

        return this.oidcUserService.signinRedirect();
    }

    signinRedirectCallback(): Observable<User> {
        this.oidcUserService.useProvider(this.currentLoginProvider);
        this.addOidcEvents();

        return this.oidcUserService.signinRedirectCallback();
    }

    /**
     * Check client side if the basic format of the passcode is correct
     * to reduce number of requests that are made to the server
     * @param passcode
     *
     * @returns CustomErroCodes if passcode has incorrect format
     * @returns null if there are no errors
     */
    validateLearnerPasscode(passcode: string): CustomErrorCodes | null {
        if (passcode.length === 0) {
            return CustomErrorCodes.EmptyStudentPassword;
        }

        const pattern = EnvironmentService.authService.studentConfig.validationPattern;
        const match = passcode.match(pattern);

        if (!match) {
            return CustomErrorCodes.WrongStudentPassword;
        }

        return null;
    }

    studentlogin(passcode: string): Observable<{ accessToken: string, roles: UserRoles[] }> {
        this.clearTokens();
        this.clearTrackingUserSession();

        return this.http
            .post<StudentLoginResponse>(this.studentLoginEndpoint, { passCode: passcode })
            .pipe(switchMap(res => this.processStudentAccessToken(res.token)));
    }

    private processStudentAccessToken(accessToken: string): Observable<{ accessToken: string, roles: UserRoles[] }> {
        const isExpired = this.jwtHelper.isTokenExpired(accessToken);

        if (isExpired === true) {
            return throwError(() => new Error('student token is expired'));
        }

        // save access-token into local-storage
        this.studentAccessToken = accessToken;

        const decodedToken = this.jwtHelper.decodeToken(accessToken);

        return of({ accessToken, roles: decodedToken.roles });
    }

    demoUserLogin(username: string, password: string): Observable<string> {
        this.clearTokens();
        this.clearTrackingUserSession();

        return this.http
            .post<StudentLoginResponse>(this.demoLoginEndpoint, { username, password })
            .pipe(switchMap(res => this.processDemoAccessToken(res.token)));
    }

    private clearTrackingUserSession(): void {
        localStorage.removeItem(TrackingService.userSessionLabel);
    }

    private processDemoAccessToken(accessToken: string): Observable<string> {
        const isExpired = this.jwtHelper.isTokenExpired(accessToken);

        if (isExpired === true) {
            return throwError(() => new Error('token is expired'));
        }

        // save access-token into local-storage
        this.demoAccessToken = accessToken;

        return of(accessToken);
    }

    /**
     *
     * @param redirectUri should start with a '/'
     * @param userState
     * @returns string array with logout redirect url
     */
    async logout(redirectUri = '', userState: UserState): Promise<string[]> {
        if (!!this.demoAccessToken) {
            this.clearLocalStorages();
            return [redirectUri || '/demo-login'];
        }

        switch (userState.accountType) {
            case AccountType.Teacher:
                const uri = await this.logoutOidcUser(redirectUri);
                return uri;

            case AccountType.Student:
                if (!!userState.oidcUser) {
                    await this.logoutOidcUser(redirectUri);
                } else {
                    this.removeStudentToken();
                }

                return [redirectUri || '/kinder-login'];

            default:
                throwError(
                    `logout for account-type "${userState.accountType}" not supported`
                );
        }
    }

    private async logoutOidcUser(redirectUri: string): Promise<string[]> {
        if (this.oidcUserService.isCornelsenUser()) {
            await this.oidcUserService.createSignoutRequest();
        }

        this.clearLocalStorages();
        return [redirectUri || '/login'];
    }

    clearLocalStorages(): void {
        this.clearTokens();
        localStorage.removeItem('provider');
        this.userService.removeAccountTypeFromLocalStorage();
        sessionStorage.clear();
        this.removeRedirectPath();
    }

    private clearTokens(): void {
        this.removeDemoToken();
        this.removeStudentToken();
        this.removeTeacherToken();
    }

    private removeDemoToken(): void {
        localStorage.removeItem(AuthService.demoAccessTokenKey);
    }

    private removeStudentToken(): void {
        localStorage.removeItem(AuthService.studentAccessTokenKey);
    }

    private removeTeacherToken(): void {
        // remove all entries starting with 'oidc.user:'
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            if (key.startsWith('oidc.user:')) {
                localStorage.removeItem(key);
            }
        }
    }

    private removeRedirectPath(): void {
        sessionStorage.removeItem(AuthService.redirectPathKey);
    }
}
