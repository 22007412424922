import { Component, Input } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

@Component({
    selector: 'app-svg-icon-done',
    template: `
    <svg [attr.height]="height" [attr.width]="width" viewBox="0 0 24 24">
        <defs>
            <circle id="path-done" cx="12" cy="12" r="12"></circle>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-173.000000, -111.000000)">
                <g id="Group-2" transform="translate(173.000000, 111.000000)">
                    <mask id="mask-done" [attr.fill]="color">
                        <use xlink:href="#path-done"></use>
                    </mask>
                    <use id="Mask" [attr.fill]="circleColor" xlink:href="#path-done"></use>
                    <path d="M10.4078415,13.4781883 L16.9466899,6.93933983 C17.5324763,6.35355339 18.4822238,6.35355339 19.0680102,6.93933983 C19.6537967,7.52512627 19.6537967,8.47487373 19.0680102,9.06066017 L11.3180068,16.8106637 C10.6766401,17.4520303 9.61720296,17.3818486 9.06604328,16.6614838 L5.80869669,12.4041372 C5.3053,11.7461986 5.430581,10.8047503 6.08851965,10.3013536 C6.7464583,9.79795689 7.68790661,9.92323789 8.19130331,10.5811765 L10.4078415,13.4781883 Z" id="Path-2" [attr.fill]="color" fill-rule="nonzero" mask="#mask-done"></path>
                </g>
            </g>
        </g>
    </svg>`
})
export class SvgIconDoneComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'done';
    }

    get circleVisibility(): string {
        if (Boolean(this.isCircleVisible) === false) {
            return 'hidden';
        }
    }

    private _circleColor = '#DE2922';

    @Input()
    isCircleVisible = true;

    @Input()
    get circleColor(): string {
        if (this.isCircleVisible === false) {
            return;
        }

        return this._circleColor;
    }

    set circleColor(value: string) {
        this._circleColor = value;
    }
}
