import { NotificationType } from './notification.model';
import { StudentModel } from './student.model';

export interface ConversationPreviewListViewModel {
    conversations: ConversationViewModel[];
    uncontactedStudents: StudentModel[];
}

export interface Conversation {
    content: string;
    conversationId?: number;
    updatedAt: Date;
    isNew: boolean;
    participants: number[];
    thread?: ConversationMessage[];
    recentMessage?: ConversationMessage;
    hasMoreMessages: boolean;
}

export interface ConversationMessageList {
    hasMore: boolean;
    messages: ConversationMessage[];
}

export interface ConversationMessage {
    content: string; // empty if system notification
    conversationId?: number; // empty if system notification and no conversation yet
    createdAt: Date;
    id: string;
    isNew: boolean;
    messageType: NotificationType;  // message if system notification
    userId: number; // === studentId if system notification

    // if notificationData then system notifications
    studentEventData?: {
        type: NotificationType,
        assignmentId?: number,
        exerciseId?: number,
        readingLevel?: number
    };
}

export interface ConversationViewModel {
    conversation: Conversation;
    student: StudentModel;
}

export interface ConversationMessageDraft {
    content: string;
    conversationId?: number;
    participantId?: number;
}
