import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    BookDetailModel, BookViewerAccessLinkModel,
    ExerciseDetailModel,
    LibraryModel, RecommendedBook
} from 'shared/models';

@Injectable()
export class BooksService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) { }

    getBooks(): Observable<LibraryModel> {
        return this.http.get<LibraryModel>(`${this.baseApiUrl}/books`)
        .pipe(
            map(library => {
                const {filters} = library;

                // Put filters in correct order for ui
                const sortedFilters = [
                    filters[0], // Reading Level
                    filters[1], // Genre
                    filters[2], // Series
                    filters[4], // Exercise
                    filters[5], // Reading Strategy
                    filters[3], // Competence
                ];

                if (filters[6]) sortedFilters.push(filters[6]); // ConnectionToPrint is only added when option is in backend response
                library.filters = sortedFilters.filter(f => f.values?.length > 0);

                return library;
            })
        );
    }

    getLastReadBooks(): Observable<number[]> {
        return this.http.get<number[]>(`${this.baseApiUrl}/books/lastread?limit=6`);
    }

    getBookDetail(bookId: number): Observable<BookDetailModel> {
        return this.http.get<BookDetailModel>(`${this.baseApiUrl}/books/${bookId}`);
    }

    getExerciseLink(exerciseId: number): Observable<BookViewerAccessLinkModel> {
        return this.http.get<BookViewerAccessLinkModel>(`${this.baseApiUrl}/exercise/${exerciseId}/access`);
    }

    getExerciseDetails(bookId: number): Observable<ExerciseDetailModel> {
        return this.http.get<ExerciseDetailModel>(`${this.baseApiUrl}/books/${bookId}/exercisedetails`);
    }

    getRecommendedBook(): Observable<RecommendedBook> {
        return this.http.get<RecommendedBook>(`${this.baseApiUrl}/books/recommended`);
    }

    setFavorite(bookId: number): Observable<void> {
        return this.http.post<void>(`${this.baseApiUrl}/favorites`, bookId);
    }

    removeFavorite(bookId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseApiUrl}/favorites/${bookId}`);
    }

    getRelatedBookIds(bookId: number, page: number): Observable<number[]> {
      return this.http.get<number[]>(`${this.baseApiUrl}/books/filter/${bookId}?page=${page}`);
    }
}
