export interface SettingsModel {
    maintenance: MaintenanceModel | string;
}

export interface MaintenanceModel {
    active: boolean;
    title?: string;
    infotext: string;
    startDateTime: Date;
    endDateTime: Date;
    teacherOnly?: boolean;
}

