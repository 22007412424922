import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary, StoreHelper } from 'shared/helper';
import { Conversation, ConversationPreviewListViewModel, ConversationViewModel, StudentModel } from 'shared/models';
import { getStudentsItems } from 'store/students/students.selector';
import { getStudentProfile } from 'store/user/user.selector';
import { ConversationsState } from './conversations.reducer';

export const selectConversationsState = createFeatureSelector<ConversationsState>('conversations');

export const selectConversationsIsLoaded = createSelector(
    selectConversationsState,
    (state: ConversationsState) => state.isLoaded
);

export const selectConversationsHasUnreadMessages = createSelector(
    selectConversationsState,
    (state: ConversationsState) => state.hasUnreadMessages
);

export const selectConversationsIsLoading = createSelector(
    selectConversationsState,
    (state: ConversationsState) => state.isLoading
);

export const selectConversations = createSelector(
    selectConversationsState,
    (state: ConversationsState) => state.conversations
);

export const selectStudyGroupHasUnreadMessages = createSelector(
  selectConversations,
  (conversations: Conversation[]) => conversations.some(conversation => conversation.isNew)
);

// /**
//  * Sorts by desc date if both conversations are new
//  * Otherwise shift new ones before old(read) conversations
//  * @param a Conversation
//  * @param b Conversation
//  * @returns number
//  */
// function sortByIsNew(a: Conversation, b: Conversation): number {
//     if (a.isNew === b.isNew) {
//         if (a.updatedAt.getTime() > b.updatedAt.getTime()) {
//             return -1;
//         }

//         return 1;
//     }

//     return a.isNew ? -1 : 1;
// }

export const selectConversationLists = createSelector(
    selectConversations,
    getStudentsItems,
    (conversations: Conversation[], students: Dictionary<StudentModel>): ConversationPreviewListViewModel => {
        const convos: ConversationViewModel[] = conversations
        .filter(c => students[c.participants[0]])
        .map(c => {
            return {
                conversation: c,
                student: students[c.participants[0]]
            };
        });

        const allStudents = StoreHelper.dictToArray(students);

        const uncontactedStudents = allStudents
            .filter(s => !(convos.map(c => c.student.id).includes(s.id)))
            .sort((a: StudentModel, b: StudentModel) => a.userName < b.userName ? -1 : 1);

        return {
            conversations: convos,
            uncontactedStudents
        };
    }
);

export const selectActiveConversationTeacher = createSelector(
    selectConversationsState,
    getStudentsItems,
    (state: ConversationsState, students: Dictionary<StudentModel>) => {
        const { conversationId, participantId } = state.activeConversation;

        const conversation = state.conversations?.find(c => conversationId
            ? c.conversationId === conversationId
            : c.participants[0] === participantId
        );

        return {
            student: students[participantId],
            conversation
        };
    }
);

export const selectActiveConversationStudent = createSelector(
    selectConversationsState,
    getStudentProfile,
    (state: ConversationsState, student: StudentModel) => {
        const { conversationId, participantId } = state.activeConversation;

        const conversation = state.conversations.find(c => conversationId
            ? c.conversationId === conversationId
            : c.participants[0] === participantId
        );

        return {
            student: student,
            conversation
        };
    }
);

export const selectActiveConversation = createSelector(
    selectConversationsState,
    (state: ConversationsState) => {
        const { conversationId, participantId } = state.activeConversation;

        const conversation = state.conversations.find(c => conversationId
            ? c.conversationId === conversationId
            : c.participants[0] === participantId
        );

        return {
            conversation
        };
    }
);

export const selectActiveDraft = createSelector(
    selectConversationsState,
    (state: ConversationsState) => {
        const activeDraft = state.drafts.find(draft => draft.participantId === state.activeConversation.participantId);

        return activeDraft;
    }
);

export const selectNewMessagesCount = createSelector(
    selectConversations,
    (conversations) => {
        if (conversations.length === 0) {
            return 0;
        }

        return conversations.reduce((sum, c) => {
            if (!c.thread) {
                return sum;
            }

            const newMsg = c.thread.filter(message => message.isNew);
            return sum + newMsg.length;
        }, 0);
    }
);

