import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherUsedReadingConfigurationFeature extends BaseTrackingEvent {
    constructor(featureTarget: string, featureTitle: string = '' , featureId: string = '') {
        super({
            eventId: 'a7d010be-34e8-4087-b2ec-25fbcba33ecb',
            eventObject: TrackingObjects.FEATURE,
            eventVerb: TrackingVerbs.USED,
            eventPayload: {
                featureTarget: featureTarget,
                featureTitle: featureTitle,
                featureId: featureId,
            },
        });
    }
}
