import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AccountType } from 'shared/models';
import { UserClickedUIElement } from 'shared/tracking/models';
import { showConsentPage } from 'store/consent/consent.actions';
import { selectIsUserSidebarOpen } from 'store/layout';
import * as TrackingActions from 'store/tracking/tracking.actions';
import { getAccountType, getHasTutorRolePrivateUser } from 'store/user';

@Component({
    selector: 'app-cv-footer',
    templateUrl: './cv-footer.component.html',
    styleUrls: ['./cv-footer.component.scss']
})
export class CvFooterComponent {

    get showLogo(): boolean {
        return this.router.url === '/lehrer';
    }

    isTeacher$ = this.store.pipe(select(getAccountType), map(account => account === AccountType.Teacher));

    isPrivateUserOrChild$ = this.store.select(getHasTutorRolePrivateUser);

    isOpen$ = this.store.select(selectIsUserSidebarOpen);

    constructor(private router: Router, private store: Store) { }

    trackFaqLink() {
        this.store.dispatch(TrackingActions.userClickedUIElement({ event: new UserClickedUIElement('FAQLinkFooter') }));
    }

    openConsentPage(){
        this.store.dispatch(showConsentPage());
    }
}
