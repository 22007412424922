import { NavItemModel } from 'shared/models';

export class NavItemsHelper {
    static getTeacherNavItems(hasUnreadMessages: boolean, hasRolePrivateUser = false, root = '.'): NavItemModel[] {
      const navItems = [
        {
            label: 'Startseite',
            routing: {
                routerLink: `${root}`,
                isExact: true,
            },
        },
        {
            label: 'Bibliothek',
            routing: {
                routerLink: `${root}/bibliothek`,
            },
        },
        {
            label: 'Aufgaben',
            routing: {
                routerLink: `${root}/aufgaben`,
            },
        },
        {
            label: 'Lerngruppe',
            routing: {
                routerLink: `${root}/lerngruppe`,
            },
        },
        {
            label: 'Nachrichten',
            routing: {
                routerLink: `${root}/nachrichten`,
            },
            hasUpdates: hasUnreadMessages,
        },
      ];

      return navItems;
    }

    static getAdminNavItems(hasUnreadMessages: boolean, showAdminViewOnly: boolean, root: string = '.'): NavItemModel[] {
      const adminNavItem = [
        {
            label: 'Schule',
            routing: {
                routerLink: `${root}/meine-schule`,
            },
        },
      ];

      if (showAdminViewOnly) return adminNavItem;

      return this.getTeacherNavItems(hasUnreadMessages, false, root).concat(adminNavItem);
    }
}
