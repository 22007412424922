import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherDeletedSchoolClass extends BaseTrackingEvent{
    constructor(groupTitle: string, groupId: number) {
        super({
            eventId: '83dafb8d-cb3d-4a97-9769-b234c7682faf',
            eventVerb: TrackingVerbs.DELETED,
            eventObject: TrackingObjects.GROUP,
            eventPayload: {
                groupTitle: groupTitle,
                groupId: groupId
            },
        });
    }
}
