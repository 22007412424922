import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { User } from 'oidc-client-ts';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom, filter, combineLatestWith } from 'rxjs/operators';
import { CvPopUpHelper, PopupChannels } from 'shared/helper';
import { AccountType, CvPopUpSizes, LoginProvider } from 'shared/models';
import { AuthService } from 'shared/services';
import { getLoadingIndicator, hideLoadingIndicator } from 'store/layout';
import { getRouteUrl } from 'store/router';
import { loginCallBack as loginCallback, loginCallBackFail, loginCallbackSuccess, startUp, startUpAppComp, startUpError, startUpNoExisitingSession, startUpProfileErrorComp, startUpFoundExistingSession, sessionExpired } from './start-up.actions';
import { oidcUserLoginSuccess, setUser } from './user.actions';
import * as UserActions from 'store/user/user.actions';
import { UserRoles } from 'app/core/enums';
import { getOidcUser } from './user.selector';

@Injectable()
export class StartUpEffects {
    startUp$ = createEffect(() => this.actions$.pipe(
        ofType(startUp, startUpAppComp, startUpProfileErrorComp),
        combineLatestWith(this.authService.getOidcLoginData()),
        map(([_, loginData]) => {

            const provider = this.authService.currentLoginProvider;

            if (loginData) {
                if (loginData.expired) {
                    throw new Error(`account type ${loginData.accountType} token is expired`);
                }

                return startUpFoundExistingSession({
                    accountType: loginData.accountType,
                    accessToken: provider === LoginProvider.cornelsen ? loginData.oidcUser.id_token : loginData.accessToken,
                    isTriggeredBySilentRefresh: loginData.isTriggeredBySilentRefresh,
                    roles: loginData.roles,
                    oidcUser: loginData.oidcUser
                });
            }

            const sessionData = this.authService.getLeseoStudentOrDemoUserSession();

            if (sessionData) {
                return startUpFoundExistingSession({
                    accountType: sessionData.accountType,
                    accessToken: sessionData.accessToken,
                    isTriggeredBySilentRefresh: sessionData.isTriggeredBySilentRefresh,
                    roles: sessionData.roles,
                    oidcUser: sessionData.oidcUser
                });
            }

            return startUpNoExisitingSession();
        }),
        catchError((err: unknown) => {
            console.error(err);

            const error = new Error(err as string);
            return of(startUpError({
                err: {
                    name: error.name,
                    message: error.message,
                    stack: error.stack
                }
            }));
        })
    ));

    startUpSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(startUpFoundExistingSession),
        map(({ accountType, accessToken, isTriggeredBySilentRefresh, oidcUser, roles }) =>
            setUser({ accountType, accessToken, isTriggeredBySilentRefresh, oidcUser, roles }))
    ));

    // startUpFail$ = createEffect(() => this.actions$.pipe(
    //     ofType(startUpNoExisitingSession, startUpError),
    //     tap((_) => { })
    // ), { dispatch: false });

    /**
     * login for cornelsen tutors
     */
    loginTeacher$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loginTeacher),
        tap(() => this.authService.oidcUserLogin(LoginProvider.cornelsen, null))
    ), { dispatch: false });

    /**
     * login for bsp users
     */
    loginBerlinSchoolPortal$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loginBerlinSchoolPortalUser),
        tap(() => this.authService.oidcUserLogin(LoginProvider.berlin, null))
    ), { dispatch: false });

    /**
     * Login for Edupool users
     */
    loginEdupool$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loginEdupoolUser),
        tap(() => this.authService.oidcUserLogin(LoginProvider.edupool, null))
    ), { dispatch: false });

    loginCallbackOidcUser$ = createEffect(() => this.actions$.pipe(
        ofType(loginCallback),
        switchMap(() => {
            return this.authService.signinRedirectCallback().pipe(
                map((user: User) => loginCallbackSuccess({user})),
                catchError((err: unknown) => { console.log(err); return of(loginCallBackFail({ err })); })
            );
        })
    ));

    loginCallBackSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(loginCallbackSuccess),
        tap(_ => this.router.navigate(['/'])),
        map(({user}) => oidcUserLoginSuccess({user}))
    ));

    loginCallBackFail$ = createEffect(() => this.actions$.pipe(
        ofType(loginCallBackFail),
        tap(_ => this.router.navigate(['/profil-fehler']))
    ), { dispatch: false });

    loginOidcUserSuccess = createEffect(() => this.actions$.pipe(
        ofType(UserActions.oidcUserLoginSuccess),
        withLatestFrom(this.store.pipe(select(getOidcUser))),
        map(([{ user }, oldUser]) => {
            let isTriggeredBySilentRefresh = false;
            if (oldUser != null && oldUser.profile != null) {
                // check user is already login
                isTriggeredBySilentRefresh =
                    oldUser.profile.inum === user.profile.inum;
            }

            const loginProvider = LoginProvider[(<any>user.state)?.provider];

            const roles: string[] = user.profile['roles'] as string[];

            const action = UserActions.setUser({
                accountType: AccountType.Teacher,
                accessToken: loginProvider === LoginProvider.cornelsen ? user.id_token : user.access_token,
                isTriggeredBySilentRefresh,
                oidcUser: user,
                roles: <UserRoles[]>roles ?? []
            });

            return action;
        })
    ));


    sessionExpired$ = createEffect(() => this.actions$.pipe(
        ofType(sessionExpired),
        withLatestFrom(this.store.select(getLoadingIndicator), this.store.select(getRouteUrl)),
        map(([_, loadingIndicator, routeUrl]) => ({ loadingIndicator, routeUrl })),
        // we exclude this page because it already has a "reload/log back in" functionality
        filter(({ routeUrl }) => !routeUrl?.includes('profil-fehler')),
        switchMap(({ loadingIndicator }) => {
            const openPopup = CvPopUpHelper.createOpenPopupActionForCustomView(
                PopupChannels.TeacherSignedOut,
                'SignedOutPopupComponent',
                CvPopUpSizes.S,
                false
            );

            return loadingIndicator.isVisible ? [openPopup, hideLoadingIndicator({ isTriggeredByRouter: false })] : [openPopup];
        })));

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private store: Store) { }
}
