import { createAction, props } from '@ngrx/store';

import { SchoolModel, StudentModel, TeacherLightModel } from 'shared/models';
import { TrackingSource } from 'shared/services';

export const getSchool = createAction('[School] Get School');

export const getSchoolSuccess = createAction(
    '[School] Get School Success',
    props<{ school: SchoolModel }>()
);
export const getSchoolFail = createAction(
    '[School] Get School Fail',
    props<{ error: any }>()
);

export const getSchoolStudents = createAction(
    '[School] Get Students',
    props<{ schoolClassId: number }>()
);

export const getSchoolStudentsSuccess = createAction(
    '[School] Get Students Success',
    props<{ schoolClassId: number; students: StudentModel[] }>()
);

export const getSchoolStudentsFail = createAction(
    '[School] Get Students Fail',
    props<{ error: any }>()
);

export const deleteTeacher = createAction(
    '[School] Delete Teacher',
    props<{ teacherId: number, source: TrackingSource }>()
);

export const deleteTeacherSuccess = createAction(
    '[School] Delete Teacher Success',
    props<{ teacher: TeacherLightModel }>()
);

export const deleteTeacherFail = createAction(
    '[School] Delete Teacher Fail',
    props<{ error: any }>()
);
