import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 148_Benachrichtigungen_flaechig_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-notification',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox = "0 0 56.693 56.693" class="icon" > <path d="M7.076,44.645v-1.8a2.833,2.833,0,0,1,.411-1.47l.722-1.191a26.919,26.919,0,0,0,3.765-11.277l1.048-10.474A14.172,14.172,0,0,1,27.124,5.67h2.434a14.173,14.173,0,0,1,14.1,12.762l1.048,10.477a26.929,26.929,0,0,0,3.765,11.276l.722,1.19a2.842,2.842,0,0,1,.41,1.47v1.8a.709.709,0,0,1-.708.709H7.785A.71.71,0,0,1,7.076,44.645ZM22.667,48.33v1.275a5.678,5.678,0,0,0,5.679,5.669h.01a5.676,5.676,0,0,0,5.67-5.669V48.33Z" /></svg>
  `
})
export class SvgIconNotificationComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'notification';
    }
}
