import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RedirectionHelper } from 'shared/helper/redirection.helper';
import { BookModel } from 'shared/models';
import { RootState } from 'store/root.reducer';
import { getAccountType } from 'store/user/user.selector';

export enum BookClickMode {
    Link,
    Handler
}

@Component({
    selector: 'app-book-list',
    templateUrl: './book-list.component.html',
    styleUrls: ['./book-list.component.scss']
})
export class BookListComponent implements OnInit {
    readonly ClickModes = BookClickMode;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() readonly onBookClick = new EventEmitter<BookModel>();

    private _books: BookModel[];

    @Input() selectedBookId: number;
    @Input() clickMode = BookClickMode.Link;
    @Input() gridClasses: string[];
    @Input() showHeadline = true;
    @Input() headline = 'Bücher';
    @Input() limit = 0;

    get books(): BookModel[] {
        if (this.limit > 0 && this.isListCollapsed) {
            return this._books.slice(0, this.limit);
        }
        return this._books;
    }

    get totalBookCount(): number {
        return this._books.length;
    }

    @Input() set books(value: BookModel[])  {
        this._books = value;
    }

    isListCollapsed = false;

    arrowkeyLocation = -1;

    @HostListener('window:keydown', ['$event'])
    keyDown(event: KeyboardEvent) {
      // if book list has focus
      if (this.arrowkeyLocation > -1) {
        if ((event.key === 'ArrowUp' || event.key === 'ArrowLeft') && this.arrowkeyLocation > 0) {
          event.preventDefault();
          this.arrowkeyLocation--;
          this.focusElement();
        }
        else if ((event.key === 'ArrowDown' || event.key === 'ArrowRight') && this.arrowkeyLocation < this.totalBookCount - 1) {
          event.preventDefault();
          this.arrowkeyLocation++;
          this.focusElement();
        }
        else if((event.key === 'Enter' || event.key === ' ')) {
          event.preventDefault();
          this.onBookClick.emit(this.books[this.arrowkeyLocation]);
        }
      }
    }

    constructor(private store: Store<RootState>) { }

    ngOnInit() {
        if (this.limit > 0) {
            this.toggleBooks();
        }
    }

    getBookDetailLink$(book: BookModel): Observable<string[]> {
        return this.store.pipe(
            select(getAccountType),
            map(accountType =>
                RedirectionHelper.getBookDetailRoute(book, accountType)
            )
        );
    }

    toggleBooks() {
        this.isListCollapsed = !this.isListCollapsed;
    }

    focusElement(index?: number) {
      if (this.clickMode !== BookClickMode.Handler) return;

      this.arrowkeyLocation = index ?? this.arrowkeyLocation;
      const bookLinkElement = document.getElementById('book-link-' + this.arrowkeyLocation);
      bookLinkElement?.focus({ preventScroll: false });
      bookLinkElement?.scrollIntoView({ behavior: 'auto', block: 'center' });
    }

    resetFocus() {
      this.arrowkeyLocation = -1;
    }
}
