import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CvPopUpHelper, PopupChannels } from 'shared/helper';
import { StudentDetailsService, TeacherService, TrackingSource } from 'shared/services';
import { RootState } from 'store/root.reducer';
import { getIsAdmin } from 'store/user';
import * as StudentsActions from './students.actions';
import { getStudentsCount, getStudentsItems } from './students.selector';
import * as TrackingActions from 'store/tracking/tracking.actions';
import { TeacherSavedStudentNoteFeature } from 'shared/tracking/models/teacher-saved-student-note-feature.model';
import { TeacherUpdatedLearnersGroup } from 'shared/tracking/models';

@Injectable()
export class StudentsEffects {
    deleteStudent$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.deleteStudent),
        withLatestFrom(
            this.store.pipe(select(getStudentsItems)),
            ({ studentId, source }, studentsDict) => ({
                student: studentsDict[studentId],
                source
            })
        ),
        map(({ student, source }) => {
            const message = `Möchten Sie ${student.userName} wirklich löschen?`;
            const payload = {
                student,
                source
            };
            const openPopup = CvPopUpHelper.createOpenPopupActionForConfirmation(
                PopupChannels.DeleteStudentConfirmation,
                message,
                payload
            );

            return openPopup;
        })
    )
    );

    deleteStudentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.deleteStudentSuccess),
        tap(({ source }) => {
            if (source === TrackingSource.StudentDetailPageTeacher) {
                this.router.navigate(['/lehrer', 'lerngruppe', 'verwaltung']);
            }
        }),
        map(({ student }) => {
            const message = `${student.userName} wurde gelöscht.`;
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.DeleteStudentSuccessful,
                message
            );
            return openPopup;
        })
    )
    );

    fetchStudents$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.fetchStudents),
        // ignores new emissions while the inner observable is not yet complete
        exhaustMap(({ schoolClassId }) => {
            return this.teacherService.getStudentsBySchoolClassId(schoolClassId).pipe(
                map(students => StudentsActions.fetchStudentsSuccess({ students })),
                catchError((error: unknown) => of(StudentsActions.fetchStudentsFail({ error })))
            );
        })
    ));

    addStudents$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.addStudents),
        withLatestFrom(this.store.pipe(select(getIsAdmin))),
        switchMap(([{ schoolClassId, students }, isSchoolAdmin]) =>
            this.teacherService.addStudents(schoolClassId, students).pipe(
                map(newStudents => StudentsActions.addStudentsSuccess({ schoolClassId, students: newStudents, isSchoolAdmin })),
                catchError((error: unknown) => of(StudentsActions.addStudentsFail({ error })))
            )
        )
    ));

    addStudentsSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.addStudentsSuccess),
        tap(_ => this.router.navigate(['lehrer', 'lerngruppe', 'verwaltung']))
    ),
        { dispatch: false }
    );

    addStudentsFail$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.addStudentsFail),
        map(_ => {
            const message = `Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.`;
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.AddStudentsFail,
                message
            );
            return openPopup;
        })
    ));

    updateLearnesPerGroupTracking$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.deleteStudentSuccess, StudentsActions.addStudentsSuccess),
        withLatestFrom(this.store.select(getStudentsCount)),
        map(([_, studentCount]) => TrackingActions.teacherUpdateLearnersPerGroup({ event: new TeacherUpdatedLearnersGroup(studentCount) }))
    ));

    resetStudentCode$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.resetStudentCode),
        map(({ student }) => {
            const message = `Möchten Sie das Passwort von "${student.userName}" wirklich zurücksetzen?`;
            const openPopup = CvPopUpHelper.createOpenPopupActionForConfirmation(
                PopupChannels.ResetStudentCodeConfirmation,
                message,
                student
            );

            return openPopup;
        })
    ));

    resetStudentCodeSuccessful$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.resetStudentCodeSuccess),
        map(({ student }) => {
            const message = `Das Passwort von "${student.userName}" wurde zurückgesetzt.`;
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.ResetStudentCodeSuccessful,
                message
            );
            return openPopup;
        })
    ));

    /**
     * @todo: add error handling
     */
    // fetchStudentsFail$ = createEffect(() => this.actions$.pipe(
    //     ofType(SchoolClassesActions.fetchStudentsSuccess),

    // ));

    changeStudent$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.changeStudent),
        switchMap(action =>
            this.teacherService
                .changeStudent(action.studentId, action.changeFields)
                .pipe(
                    map(student => StudentsActions.changeStudentSuccess({ student })),
                    catchError((error: unknown) => of(StudentsActions.changeStudentFail({ error })))
                )
        )
    ));

    fetchStudentDetails$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.fetchStudentDetail),
        switchMap(action =>
            this.studentDetailsService.getStudentDetails(action.studentId).pipe(
                map(details => StudentsActions.fetchStudentDetailSuccessful({ studentId: action.studentId, details })),
                catchError((error: unknown) => of(StudentsActions.fetchStudentDetailFail({ studentId: action.studentId, error })))
            )
        )
    ));

    setStudentDetailTeacherNote$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.setStudentDetailTeacherNote),
        switchMap(action =>
            this.studentDetailsService
                .postStudentDetailsNote(action.studentId, action.note)
                .pipe(
                    map(
                        () =>
                            StudentsActions.setStudentDetailTeacherNoteSuccess({
                                studentId: action.studentId,
                                note: action.note
                            }),
                        catchError((error: unknown) =>
                            of(
                                StudentsActions.setStudentDetailTeacherNoteFail({
                                    studentId: action.studentId,
                                    error
                                })
                            )
                        )
                    )
                )
        )
    ));

    setStudentDetailTeacherNoteSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(StudentsActions.setStudentDetailTeacherNoteSuccess),
        map(_ => {
            return TrackingActions.teacherSavedStudentNoteFeature({ event: new TeacherSavedStudentNoteFeature('StudentNote') });
        })
    ));

    constructor(
        private actions$: Actions,
        private store: Store<RootState>,
        private router: Router,
        private teacherService: TeacherService,
        private studentDetailsService: StudentDetailsService) { }
}
