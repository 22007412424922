export interface HasId {
    id: number;
}

/**
 * @deprecated use Record<string,T> instead
 * @todo remove all usages
 */
export interface Dictionary<T> {
    [key: string]: T;
}

export class StoreHelper {

    /**
     * Turns a record into an array
     * @param record `Record<string, T>`
     * @returns array `Array<T>`
     */
    static recordToArray<T extends HasId>(record: Record<string, T>): Array<T> {
        if (!record) {
            return [];
        }

        return Object.keys(record).map(key => record[key]);
    }

    /**
     * Turns an array into a record
     * @param items array of type `T` where `T` has property `id`
     * @param initValues optional starting value
     * @returns `Record<string, T>`
     */
    static arrayToRecord<T extends HasId>(items: T[], initValues: Record<string, T> = {}): Record<string, T> {
        if (!items) {
            return initValues;
        }

        return items.reduce((previousValue: Record<string, T>, currentValue: T) => {
            return {
                ...previousValue,
                [currentValue.id.toString()]: currentValue
            };
        }, initValues);
    }


    /**
     * @deprecated use recordToArray<T extends HasId> instead
     * @todo remove all usages
     */
    static dictToArray<T extends HasId>(dict: Dictionary<T>): Array<T> {
        return Object.keys(dict).map(key => dict[key]);
    }

    /**
     * @deprecated use Record<string,T> instead
     * @todo remove all usages
     */
    static arrayToDict<T extends HasId>(items: T[], initValues = {}): Dictionary<T> {
        if (items == null) {
            return {};
        }

        return items.reduce(
            (prevValue: Dictionary<T>, item: T) => {
                return {
                    ...prevValue,
                    [item.id.toString()]: item
                };
            },
            { ...initValues }
        );
    }



    /**
     * @deprecated use Record<string,T> instead
     * @todo remove all usages
     */
    static getDictLength<T>(dict: Dictionary<T>): number {
        if (dict == null) {
            return 0;
        }

        return Object.keys(dict).length;
    }

    /**
     * @deprecated use Record<string,T> instead
     * @todo remove all usages
     */
    static removeKey<T>(dict: Dictionary<T>, deleteKey: string): Dictionary<T> {
        if (dict == null) {
            return;
        }

        if (!(deleteKey in dict)) {
            return dict;
        }

        // ES7 magic: destructuring assignment to remove keys from dict
        const { [deleteKey]: deletedItem, ...newDict } = dict;

        return newDict;
    }

    /**
     * @deprecated use Record<string,T> instead
     * @todo remove all usages
     */
    static removeKeys<T>(dict: Dictionary<T>, deleteKeys: string[]): Dictionary<T> {
        if (dict == null) {
            return;
        }

        let newDict: Dictionary<T> = { ...dict };

        if (deleteKeys == null || deleteKeys.length === 0) {
            return newDict;
        }

        for (const key of deleteKeys) {
            newDict = this.removeKey(newDict, key);
        }

        return newDict;
    }
}
