import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LeseoNameErrorPopupComponent } from 'app/teacher/user-sidebar/components/leseo-name-error-popup/leseo-name-error-popup.component';
import { DEFAULT_DIALOG_CONFIG } from 'environments/app-config';
import { catchError, concat, concatMap, map, of, tap } from 'rxjs';
import { UserService } from 'shared/services';
import * as TutorActions from 'store/user/tutor/tutor.actions';

@Injectable()
export class TutorEffects {
    setLeseoTutorName$ = createEffect(() => this.actions$.pipe(
        ofType(TutorActions.updateLeseoName),
        concatMap(({leseoName, oldLeseoName}) =>
            concat(
                this.userService.updateLeseoName(leseoName).pipe(
                    map((leseoName) => TutorActions.updateLeseoNameSuccess({leseoName})),
                    catchError((errorResponse: unknown) => {
                        return of(TutorActions.updateLeseoNameFail({ oldLeseoName, error: errorResponse }));
                    })
                )
            )
        )
    ));

    updateUserNameFail$ = createEffect(() => this.actions$.pipe(
        ofType(TutorActions.updateLeseoNameFail),
        tap(() => {
            this.dialog.open(LeseoNameErrorPopupComponent, {
                panelClass: [...DEFAULT_DIALOG_CONFIG.panelClass, 'modal-alert']
            });
        })
    ), {dispatch: false});

    constructor(private actions$: Actions, private userService: UserService, private dialog: MatDialog) {}

}
