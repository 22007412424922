import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherOpenedResultPage extends BaseTrackingEvent{
    constructor(screenTitle: string = '', screenId: string = '') {
        super({
            eventId: '37c1396d-7dfe-42c8-93ba-42f0a670b142',
            eventVerb: TrackingVerbs.VIEWED,
            eventObject: TrackingObjects.SCREEN,
            eventPayload: {
                screenTitle: screenTitle,
                screenId: screenId,
            },
        });
    }
}
