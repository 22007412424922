import {
    ChangeDetectionStrategy, Component,
    HostBinding, OnDestroy, OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { VERSION } from 'environments/version';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CvPopupConfirmEvent, CvPopUpSettings } from 'shared/models';
import {
    closePopup, confirmPopup, fetchSettings, getIsPopupVisible, getLoadingIndicatorMessage, getPopupSettings
} from 'store/layout';
import { RootState } from 'store/root.reducer';
import { startUpAppComp } from 'store/user/start-up.actions';

declare const window: any;
declare const _trbo: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly ngUnsubscribe = new Subject<void>();

    @HostBinding('style.overflow')
    overflowStyle: string;

    cvPopupSettings$: Observable<CvPopUpSettings>;
    isPopupVisible$: Observable<Boolean>;
    loadingMessage$: Observable<string>;

    version = VERSION.raw;

    constructor(
        private store: Store<RootState>,
        private router: Router
    ) {
        moment.locale('de');
    }

    ngOnInit() {
        this.store.dispatch(startUpAppComp());
        this.cvPopupSettings$ = this.store.pipe(select(getPopupSettings));
        this.isPopupVisible$ = this.store.pipe(select(getIsPopupVisible));

        this.isPopupVisible$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(isPopupVisible => {
                this.overflowStyle =
                    isPopupVisible === true ? 'hidden' : 'visible';
            });

        this.loadingMessage$ = this.store.pipe(
            select(getLoadingIndicatorMessage)
        );

        this.store.dispatch(fetchSettings());

        this.configureTrbo();
    }

    private configureTrbo() {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(() => {
                if (typeof _trbo !== 'undefined' && _trbo.app && _trbo.app.reloadApi) {
                    /**
                     * this call is needed for SPAs
                     * so that trbo can work properly
                     *
                     * @param removeLayers {boolean}
                     * @param addPageView {boolean}
                     */
                    _trbo.app.reloadApi(true, true);
                }
            });
    }

    onConfirmed(data: CvPopupConfirmEvent) {
        this.store.dispatch(confirmPopup({payload: data}));
    }

    onClosePopup() {
        this.store.dispatch(closePopup());
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
