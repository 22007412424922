import { createReducer, on } from '@ngrx/store';

import { Dictionary, StoreHelper } from 'shared/helper';
import {
    TeacherLightModel,
    SchoolClassLightModel,
    SchoolClassModel,
    TeacherModel,
    StudentModel
} from 'shared/models';
import * as SchoolActions from './school.actions';
import * as StudentsActions from 'store/students/students.actions';
import * as SchoolClassActions from 'store/school-classes/school-classes.actions';

interface TeacherStoreModel {
    id: number;
    firstName: string;
    lastName: string;

    schoolClassIds: number[];
}

interface SchoolClassStoreModel {
    id: number;
    name: string;
    classCode: string;

    students: Dictionary<StudentModel>;
    studentsLoading: boolean;
    studentsLoaded: boolean;
}

export interface SchoolState {
    id: number;
    name: string;

    teachersDict: Dictionary<TeacherStoreModel>;
    schoolClassesDict: Dictionary<SchoolClassStoreModel>;

    loaded: boolean;
    loading: boolean;
}

export const initialState: SchoolState = {
    id: null,
    name: null,

    teachersDict: {},
    schoolClassesDict: {},

    loaded: false,
    loading: false
};

const _schoolReducer = createReducer(
    initialState,
    on(SchoolActions.getSchool, state => ({ ...state, loading: true })),
    on(SchoolActions.getSchoolSuccess, (state, { school }) => {
        let schoolClassesDict: Dictionary<SchoolClassStoreModel> = null;
        const teachersDict: Dictionary<TeacherStoreModel> = {};

        if (school.teachers != null) {
            schoolClassesDict = {};

            for (const teacher of school.teachers) {
                const teacherItem: TeacherStoreModel = {
                    id: teacher.id,
                    firstName: teacher.firstName,
                    lastName: teacher.lastName,
                    schoolClassIds: teacher.schoolClasses.map(sc => sc.id)
                };

                for (const schoolClass of teacher.schoolClasses) {
                    if (schoolClass.id in schoolClassesDict) {
                        continue;
                    }

                    // add to dict
                    const newSchoolClass = schoolClassToStoreModel(schoolClass);
                    schoolClassesDict[schoolClass.id] = newSchoolClass;
                }

                // add to dict
                teachersDict[teacherItem.id] = teacherItem;
            }
        }

        const newState: SchoolState = {
            ...state,

            id: school.id,
            name: school.name,

            teachersDict,
            schoolClassesDict,

            loaded: true,
            loading: false
        };

        return newState;
    }),
    on(SchoolActions.getSchoolStudents, (state, { schoolClassId }) => {
        const newState: SchoolState = {
            ...state,
            schoolClassesDict: {
                ...state.schoolClassesDict,
                [schoolClassId]: {
                    ...state.schoolClassesDict[schoolClassId],

                    students: {},
                    studentsLoading: true,
                    studentsLoaded: false
                }
            }
        };

        return newState;
    }),
    on(
        SchoolActions.getSchoolStudentsSuccess,
        (state, { schoolClassId, students }) => {
            return {
                ...state,
                schoolClassesDict: {
                    ...state.schoolClassesDict,
                    [schoolClassId]: {
                        ...state.schoolClassesDict[schoolClassId],

                        students: StoreHelper.arrayToDict(students),
                        studentsLoading: false,
                        studentsLoaded: true
                    }
                }
            };
        }
    ),
    on(SchoolActions.deleteTeacherSuccess, (state, { teacher }) => {
        const key = teacher.id.toString();
        const teacherFromStore = state.teachersDict[key];

        // remove teacher
        const newTeachersDict = StoreHelper.removeKey(
            state.teachersDict,
            key
        );

        const schoolClassIdsToDelete: string[] = [];

        function isSchoolClassUsedByOtherTeacher(
            schoolClassId: number
        ): boolean {
            for (const otherTeacherId of Object.keys(newTeachersDict)) {
                const otherTeacher: TeacherStoreModel =
                    newTeachersDict[otherTeacherId];

                if (otherTeacher.schoolClassIds.includes(schoolClassId)) {
                    return true;
                }
            }

            return false;
        }

        for (const schoolClassId of teacherFromStore.schoolClassIds) {
            if (isSchoolClassUsedByOtherTeacher(schoolClassId)) {
                continue;
            }

            schoolClassIdsToDelete.push(schoolClassId.toString());
        }

        // deleted unlinked school-classes
        const newSchoolClassesDict = StoreHelper.removeKeys(
            state.schoolClassesDict,
            schoolClassIdsToDelete
        );

        const newState: SchoolState = {
            ...state,
            teachersDict: newTeachersDict,
            schoolClassesDict: newSchoolClassesDict
        };

        return newState;
    }),
    on(StudentsActions.deleteStudentSuccess, (state, { student }) => {
        // delete student from all school-classes

        const newSchoolClassesDict: Dictionary<SchoolClassStoreModel> = {};

        for (const schoolClassId of Object.keys(state.schoolClassesDict)) {
            const schoolClass: SchoolClassStoreModel = state.schoolClassesDict[schoolClassId];

            const students = StoreHelper.dictToArray(schoolClass.students).filter(s => s.id !== student.id);

            const newSchoolClass: SchoolClassStoreModel = {
                ...schoolClass,
                students: StoreHelper.arrayToDict(students)
            };
            newSchoolClassesDict[schoolClassId] = newSchoolClass;
        }

        const newState: SchoolState = {
            ...state,
            schoolClassesDict: newSchoolClassesDict
        };

        return newState;
    }),
    on(
        SchoolClassActions.deleteSchoolClassSuccess,
        (state, { schoolClass }) => {
            // unlink school-class from all teachers

            const newTeachersDict: Dictionary<TeacherStoreModel> = {};

            for (const teacherId of Object.keys(state.teachersDict)) {
                const teacher = state.teachersDict[teacherId];

                const newTeacher: TeacherStoreModel = {
                    ...teacher,
                    schoolClassIds: teacher.schoolClassIds.filter(
                        id => id !== schoolClass.id
                    )
                };
                newTeachersDict[teacherId] = newTeacher;
            }

            // remove school-class from global dict
            const newSchoolClassesDict = StoreHelper.removeKey(
                state.schoolClassesDict,
                schoolClass.id.toString()
            );
            const newState: SchoolState = {
                ...state,
                teachersDict: newTeachersDict,
                schoolClassesDict: newSchoolClassesDict
            };

            return newState;
        }
    ),
    on(
        SchoolClassActions.addSchoolClassSuccess,
        (state, { teacher, schoolClass }) => {
            const teacherFromStore = state.teachersDict[teacher.id];

            let newTeacher: TeacherStoreModel;

            if (teacherFromStore == null) {
                // teacher not in store dict
                newTeacher = teacherToStoreModel(teacher, [schoolClass]);
            } else {
                // teacher existis in store dict -> only add school-class ID
                newTeacher = {
                    ...teacherFromStore,
                    schoolClassIds: [
                        ...teacherFromStore.schoolClassIds,
                        schoolClass.id
                    ]
                };
            }

            const newSchoolClass = schoolClassToStoreModel(schoolClass);
            const newState: SchoolState = {
                ...state,
                teachersDict: {
                    ...state.teachersDict,
                    [teacher.id]: newTeacher
                },
                schoolClassesDict: {
                    ...state.schoolClassesDict,
                    [schoolClass.id]: newSchoolClass
                }
            };

            return newState;
        }
    ),
    on(
        StudentsActions.addStudentsSuccess,
        (state, { schoolClassId, students, isSchoolAdmin }) => {
            if (isSchoolAdmin) {
                // link new students to correct school-class
                const oldSchoolClass = state.schoolClassesDict[schoolClassId];

                if (oldSchoolClass == null) {
                    // do nothing
                    return state;
                }

                const newStudents = StoreHelper.arrayToDict(students);

                const newState: SchoolState = {
                    ...state,
                    schoolClassesDict: {
                        ...state.schoolClassesDict,
                        [schoolClassId]: {
                            ...oldSchoolClass,
                            students: { ...oldSchoolClass.students, ...newStudents }
                        }
                    }
                };

                return newState;
            }

            return state;
        }
    )
);

export function schoolReducer(state, action) {
    return _schoolReducer(state, action);
}

export function storeTeacherToTeacherLight(
    teacherFromStore: TeacherStoreModel,
    schoolClassesDict: Dictionary<SchoolClassStoreModel>
): TeacherLightModel {
    const schoolClasses = teacherFromStore.schoolClassIds.map(
        id => schoolClassesDict[id]
    );
    const teacher: TeacherLightModel = {
        id: teacherFromStore.id,
        firstName: teacherFromStore.firstName,
        lastName: teacherFromStore.lastName,
        schoolClasses
    };

    return teacher;
}

function schoolClassToStoreModel(schoolClass: SchoolClassLightModel | SchoolClassModel, students: Dictionary<StudentModel> = {}): SchoolClassStoreModel {
    const schoolClassStore: SchoolClassStoreModel = {
        id: schoolClass.id,
        name: schoolClass.name,
        classCode: schoolClass.classCode,

        students: students,
        studentsLoading: false,
        studentsLoaded: false
    };

    return schoolClassStore;
}

function teacherToStoreModel(
    teacher: TeacherModel,
    schoolClasses: SchoolClassModel[]
): TeacherStoreModel {
    const teacherStore: TeacherStoreModel = {
        id: teacher.id,
        firstName: teacher.firstName,
        lastName: teacher.lastName,

        schoolClassIds: schoolClasses.map(sc => sc.id)
    };

    return teacherStore;
}
