export class LanguageHelper {
    /**
     * Declines the name of a person in German (aka Genitive case)
     * If name ends in an s-sounding letter add an apostrophe => Marcus'
     * else add an 's' => Lisas
     * see -> https://www.duden.de/sprachwissen/sprachratgeber/Ehrenrettung-fur-Grossmutters-Apfelkuchen
     * https://de.babbel.com/de/magazine/genitiv-s-mit-oder-ohne-apostroph/
     * @param name string
     * @returns string
     */
    static getGenitiveCase(name: string): string {
        const lastLetter = name[name.length - 1].toLowerCase();
        const lastTwoLetters = name.slice(name.length - 2).toLowerCase();
        const sSounds = ['s', 'z', 'ß', 'x'];
        const addApostrophe = sSounds.includes(lastLetter) || lastTwoLetters === 'ce';

        return addApostrophe ? `${name}'` : `${name}s`;
    }

    /**
     * Declines the title of a person in the German Dative case
     * @param name
     * @returns Herrn Mussdermann
     */
    static getDativeCaseForTitle(name: string): string {
        if (!name.includes('Herr ')) {
            return name;
        }

        const parts = name.split(' ');
        return `${parts[0]}n ${parts[1]}`;
    }
}
