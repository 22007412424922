// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  color: white;
  font-size: 24px;
  line-height: 30px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlYWRpbmctbGV2ZWwtYm94LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksb0JBQUE7RUFDQSx1QkFBQTtFQUNBLG1CQUFBO0VBRUEsWUFBQTtFQUNBLFdBQUE7RUFFQSxZQUFBO0VBRUEsZUFBQTtFQUNBLGlCQUFBO0FBRkoiLCJmaWxlIjoicmVhZGluZy1sZXZlbC1ib3guY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogaW5saW5lLWZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcblxuICAgIGhlaWdodDogNjBweDtcbiAgICB3aWR0aDogNjBweDtcblxuICAgIGNvbG9yOiB3aGl0ZTtcblxuICAgIGZvbnQtc2l6ZTogMjRweDtcbiAgICBsaW5lLWhlaWdodDogMzBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/reading-level-box/reading-level-box.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,uBAAA;EACA,mBAAA;EAEA,YAAA;EACA,WAAA;EAEA,YAAA;EAEA,eAAA;EACA,iBAAA;AAFJ;AACA,woBAAwoB","sourcesContent":[":host {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n\n    height: 60px;\n    width: 60px;\n\n    color: white;\n\n    font-size: 24px;\n    line-height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
