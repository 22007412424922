import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { getSettings, hideMaintenance } from 'store/layout';
import { UserState } from 'store/user';

@Injectable()
export class MaintenanceGuard  {
    constructor(private router: Router, private store: Store<UserState>) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        if (route.queryParams && route.queryParams.debug) {
            this.store.dispatch(hideMaintenance());
            sessionStorage.setItem('isDebug', true.toString());
        }

        return this.store.pipe(
            select(getSettings),
            filter(settings => settings.loaded),
            map(({maintenance}) => {
                const now = new Date();
                const isDebug = sessionStorage.getItem('isDebug');
                const isDuringMaintenancePeriod = maintenance.active && maintenance.startDateTime <= now && maintenance.endDateTime >= now;

                if (!isDebug && isDuringMaintenancePeriod) {
                    return this.router.parseUrl('/wartungsarbeiten');
                }

                return true;

            })
        );
    }
}
