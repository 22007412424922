import { createReducer, on } from '@ngrx/store';

import { Dictionary, StoreHelper } from 'shared/helper';
import { BookModel, FilterStatus, FilterCategory, FilterProgress, BookDetailModel } from 'shared/models';
import * as BooksActions from 'store/books/books.actions';

export type BooksDict = Dictionary<BookModel>;
export type BooksDetailDict = Dictionary<BookDetailModel>;

export interface AllBooksState {
    dict: BooksDict;
    loaded: boolean;
}

interface RecommendedBookState {
    book: BookModel;
    loaded: boolean;
}

export interface BookSearchState {
    title: string;
    readingLevels: number[];
    genres: string[];
    progress: FilterProgress;
    statuses: FilterStatus[];
    exercises: string[];
    strategies: string[];
    series: string[];
    competences: string[];
    textbookPage: { title: string, page: number, relatedBookIds: number[] } | null;
}

export interface BooksState {
    all: AllBooksState;
    booksDetails: BooksDetailDict;
    recommended: RecommendedBookState;
    search: BookSearchState;
    filterOptions: { name: string; values: { key: string, value: string }[] }[];
}

const allBooksInitState: AllBooksState = {
    dict: {},
    loaded: false
};

const recommendedBookInitState: RecommendedBookState = {
    book: null,
    loaded: false
};

const bookSearchInitState: BookSearchState = {
    title: '',
    readingLevels: [],
    genres: [],
    progress: FilterProgress.All,
    statuses: [],
    exercises: [],
    strategies: [],
    series: [],
    competences: [],
    textbookPage: null
};

export const initialState: BooksState = {
    all: allBooksInitState,
    booksDetails: {},
    recommended: recommendedBookInitState,
    search: bookSearchInitState,
    filterOptions: []
};

const _booksReducer = createReducer(
    initialState,
    on(BooksActions.fetchBooksSuccess, (state, { books, filterOptions }) => {
        const dict = StoreHelper.arrayToDict(books);

        const newState: BooksState = {
            ...state,

            all: {
                dict,
                loaded: true
            },
            filterOptions: [
                ...filterOptions.filter((e,i) => i !== 6), // textbook option should be at the end of filterOptions
                {
                    name: 'Progress',
                    values: [
                        { key: 'Alle', value: 'Alle' },
                        { key: 'Angefangen', value: 'Angefangen' },
                        { key: 'Gelesen', value: 'Gelesen' },
                        { key: 'Ungelesen', value: 'Ungelesen' },
                    ]
                },
                {
                    name: 'Statuses',
                    values: [
                        { key: 'Favorit', value: 'Favorit' },
                        { key: 'Neuheit', value: 'Neuheit' },
                    ]
                },
                filterOptions[6] ?? { name: 'ConnectionToPrint', values: [] }
            ]
        };

        return newState;
    }),
    on(BooksActions.fetchBooksFail, state => {
        const newState: BooksState = {
            ...state,

            all: {
                ...state.all,
                loaded: true
            }
        };

        return newState;
    }),
    on(BooksActions.fetchBookDetailsSuccess, (state, { bookDetail }) => {
        return {
            ...state,
            booksDetails: {
                ...state.booksDetails,
                [bookDetail.id]: {
                    ...bookDetail
                }
            }
        };
    }),
    on(BooksActions.fetchRecommendedBook, state => {
        const newState: BooksState = {
            ...state,

            // reset recommended book
            recommended: {
                ...recommendedBookInitState
            }
        };

        return newState;
    }),
    on(BooksActions.fetchRecommendedBookSuccess, (state, { book }) => {
        const newState: BooksState = {
            ...state,
            recommended: {
                book,
                loaded: true
            }
        };

        return newState;
    }),
    on(BooksActions.fetchRecommendedBookFail, state => {
        const newState: BooksState = {
            ...state,

            recommended: {
                book: null,
                loaded: true
            }
        };

        return newState;
    }),
    on(BooksActions.setFavorite, (state, { bookId }) => {
        const allBooks: AllBooksState = {
            ...state.all,
            dict: {
                ...state.all.dict,

                [bookId]: {
                    ...state.all.dict[bookId],

                    isFavorite: true
                }
            }
        };

        let booksDetails: BooksDetailDict = {};

        if (state.booksDetails[bookId]) {
            booksDetails = {
                ...state.booksDetails,
                [bookId]: {
                    ...state.booksDetails[bookId],
                    isFavorite: true
                }
            };
        } else {
            booksDetails = state.booksDetails;
        }


        return {
            ...state,
            all: allBooks,
            booksDetails: booksDetails
        };
    }),
    on(BooksActions.removeFavorite, (state, { bookId }) => {
        const allBooks: AllBooksState = {
            ...state.all,

            dict: {
                ...state.all.dict,

                [bookId]: {
                    ...state.all.dict[bookId],
                    isFavorite: false
                }
            }
        };

        let booksDetails: BooksDetailDict = {};

        if (state.booksDetails[bookId]) {
            booksDetails = {
                ...state.booksDetails,
                [bookId]: {
                    ...state.booksDetails[bookId],
                    isFavorite: false
                }
            };
        } else {
            booksDetails = state.booksDetails;
        }

        const newState: BooksState = {
            ...state,

            all: allBooks,
            booksDetails: booksDetails
        };

        return newState;
    }),
    on(BooksActions.toggleFilter, (state, { filterCategory, filterValue }) => {
        let { title, readingLevels, genres, statuses, exercises, strategies, series, progress, competences, textbookPage } = state.search;

        switch (filterCategory) {
            case FilterCategory.Title:
                title = filterValue;
                break;

            case FilterCategory.ReadingLevel:
                if(Array.isArray(filterValue)) {
                    readingLevels = filterValue;
                    break;
                }
                readingLevels = toggleFilter(readingLevels, filterValue);
                break;

            case FilterCategory.Genre:
                genres = toggleFilter(genres, filterValue);
                break;

            case FilterCategory.Status:
                statuses = toggleFilter(statuses, filterValue);
                break;

            case FilterCategory.Exercise:
                exercises = toggleFilter(exercises, filterValue);
                break;

            case FilterCategory.Strategy:
                strategies = toggleFilter(strategies, filterValue);
                break;

            case FilterCategory.Serie:
                series = toggleFilter(series, filterValue);
                break;

            case FilterCategory.Competence:
                competences = toggleFilter(competences, filterValue);
                break;

            case FilterCategory.TextbookPage:
              if(textbookPage?.title && textbookPage?.page) {
                textbookPage = null;
              }
              else {
                textbookPage = {title: filterValue.title, page: filterValue.page, relatedBookIds: filterValue.relatedBookIds};
              }
              break;

            case FilterCategory.Progress:
                progress = filterValue;
                break;

            default:
                break;
        }
        return {
            ...state,
            search: {
                title, readingLevels, genres, statuses, exercises, strategies, series, progress, competences, textbookPage
            }
        };
    }),
    on(BooksActions.resetAllFilters, (state) => {
        return {
            ...state,
            search: {
                ...state.search,
                readingLevels: [],
                genres: [],
                statuses: [],
                exercises: [],
                strategies: [],
                series: [],
                competences: [],
                progress: FilterProgress.All,
                textbookPage: null
            }
        };
    }),
    on(BooksActions.resetTitleInput, (state) => {
        return {
            ...state,
            search: {
                ...state.search,
                title: ''
            }
        };
    }),
    on(BooksActions.setDefaultFiltersForStudent, (state, { readingLevels }) => {
        return {
            ...state,
            search: {
                ...state.search,
                readingLevels
            }
        };
    })
);

export function booksReducer(state, action) {
    return _booksReducer(state, action);
}

function toggleFilter(filterArray: any[], val: any): any[] {
    if (filterArray.includes(val)) {
        // item in list -> remove it
        return filterArray.filter(i => i !== val);
    }

    // item not in list -> add it & sort alphabetically / asc
    return [...filterArray, val].sort((a, b) => a < b ? -1 : 1);
}
