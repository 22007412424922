import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NavItemModel } from 'shared/models';

@Component({
    selector: 'app-cv-nav',
    templateUrl: './cv-nav.component.html',
    styleUrls: ['./cv-nav.component.scss']
})
export class CvNavComponent {
    @Input()
    items: NavItemModel[] = [];

    @Input()
    hideIconOnSmallScreens = true;

    @Input()
    hideLabelOnSmallScreens = false;

    @Output()
    selectedNavItem = new EventEmitter<NavItemModel>();

    constructor() {}

    pageTitle(item: NavItemModel): string {
        return item.label.replace(' ', '');
    }

    onSelectNavItem(val: NavItemModel) {
        this.selectedNavItem.emit(val);
    }
}
