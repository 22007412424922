import { createAction, props } from '@ngrx/store';

export const openUpdateMonsterPopup = createAction('[Profile page] open update monster popup');

export const cancelUpdateMonsterPopup = createAction('[Update monster comp] cancel popup');

export const openPromotionCodePopup = createAction('[Licence info comp] open promotion code popup');

export const openActivateLicenceCodePopup = createAction('[Licence info comp] open activate licence code popup');

export const openPromotionCodeSuccessPopup = createAction('[Licence effects] open promotion code success popup');

export const openGroupLimitInfoPopup = createAction('[Group limit info comp] open group limit info popup');
