import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class StudentAuthenticatedIdentity extends BaseTrackingEvent {
    constructor(identityTitle: string = '', identityId: number = 0, schoolId: string = '') {
        super({
            eventId: 'a294b7a4-34f9-4fbd-a4fa-ba80277c3f6f',
            eventVerb: TrackingVerbs.AUTHENTICATED,
            eventObject: TrackingObjects.IDENTITY,
            eventPayload: {
                identityTitle: identityTitle,
                identityId: identityId,
                identitySchoolId: schoolId
            },
        });
        this.sendUserId = true;
    }
}
