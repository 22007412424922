import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 010_Interaktive_uebungen_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-exercise',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
    <path
        d="M12.757,28.347H21.4l1.5,7.026-1.932-1.159a4.3,4.3,0,0,0-5.251,6.726L26,51.222A7.044,7.044,0,0,0,30.992,53.3a7.144,7.144,0,0,0,1.611-.185L42.7,50.786a7.077,7.077,0,0,0,5.379-8.172l-1.444-7.942a4.25,4.25,0,0,0-4.183-3.491H31.723l-.773-2.834h4.484a1.417,1.417,0,0,0,1.417-1.418V4.252a1.417,1.417,0,0,0-1.417-1.418H12.757A1.417,1.417,0,0,0,11.34,4.252V26.929A1.417,1.417,0,0,0,12.757,28.347Zm29.691,5.669a1.416,1.416,0,0,1,1.394,1.163l1.444,7.942a4.246,4.246,0,0,1-3.227,4.9l-10.093,2.33A4.231,4.231,0,0,1,28,49.217L17.722,38.936a1.464,1.464,0,0,1,1.789-2.291l4.731,2.838a1.418,1.418,0,0,0,2.115-1.512L22.689,20.852a1.458,1.458,0,0,1,1.077-1.728L24,19.067A1.473,1.473,0,0,1,25.763,20.1l3.51,12.868a1.417,1.417,0,0,0,1.367,1.045ZM14.174,5.669H34.017V25.512h-3.84L28.5,19.356a4.325,4.325,0,0,0-5.192-3.039l-.228.058a4.281,4.281,0,0,0-3.161,5.071l.871,4.066H14.174Z" />
    </svg>
  `
})
export class SvgIconExerciseComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'exercise';
    }
}
