import { ExerciseType, StrategyType } from 'shared/models';

export class TaskHelper {
    static getExerciseTypeFilename(exerciseType: ExerciseType): string {
        switch (exerciseType) {
            case ExerciseType.MultipleChoice:
                return 'multiple-choice.svg';

            case ExerciseType.Skimming:
                return 'skimming.svg';

            case ExerciseType.GapFill:
                return 'gap-fill.svg';

            case ExerciseType.Wordsnake:
                return 'wordsnake.svg';

            case ExerciseType.Matrix:
                return 'matrix.svg';

            case ExerciseType.FindThePairs:
                return 'find-the-pairs.svg';

            case ExerciseType.Hotspot:
                return 'hotspot.svg';

            case ExerciseType.Arrange:
                return 'arrange.svg';

            case ExerciseType.DragAndDrop:
                return 'drag-and-drop.svg';

            case ExerciseType.MarkTheWords:
                return 'mark-the-words.svg';

            default:
                return 'skimming.svg';
        }
    }

    static getStrategyTypeFilename(strategyType: StrategyType) {
        switch (strategyType) {
            case StrategyType.Expectations:
                return 'formulate-reading-expectations.svg';
            case StrategyType.PriorKnowledge:
                return 'activate-knowledge.svg';

            default:
                return 'skimming.svg';
        }
    }

    static getStrategyName(strategyType: StrategyType) {
        switch (strategyType) {
            case StrategyType.Expectations:
                return 'Leseerwartungen';
            case StrategyType.PriorKnowledge:
                return 'Vorwissen';

            default:
                return '';
        }
    }

    static getExerciseName(exerciseType: ExerciseType): string {
        switch (exerciseType) {
            case ExerciseType.MultipleChoice:
                return 'Die fünf/zehn Fragen';

            case ExerciseType.Skimming:
                return 'Die passenden Wörter';

            case ExerciseType.GapFill:
                return 'Der Lückentext';

            case ExerciseType.Wordsnake:
                return 'Die (verdrehte) Wortschlange';

            case ExerciseType.Matrix:
                return 'Das richtige Feld';

            case ExerciseType.FindThePairs:
                return 'Die richtigen Paare';

            case ExerciseType.Hotspot:
                return 'Das Suchbild';

            case ExerciseType.Arrange:
                return 'Die richtige Reihenfolge';

            case ExerciseType.DragAndDrop:
                return 'Die richtige Zuordnung';

            case ExerciseType.MarkTheWords:
                return 'Das falsche Wort';

            default:
                return '';
        }
    }
}
