import { Component, Input, EventEmitter, Output, HostBinding } from '@angular/core';

import { SelectableItemInput } from 'shared/models';

export interface SelectedItemEvent {
    id: number;
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[appCvSselectableItem]',
    templateUrl: './cv-selectable-item.component.html',
    styleUrls: ['./cv-selectable-item.component.scss']
})
export class CvSelectableItemComponent implements  SelectableItemInput {
    @Input()
    id: number;

    @Input()
    itemName: string;

    @Input()
    imageUrl: string;

    @Input()
    wrapperClass: string;

    @Input()
    isSelected: boolean;

    @Input()
    readingLevel: string;

    @Input()
    imageAlt: string;

    @Input()
    extraInfo: string;

    @Input()
    @HostBinding('class.disabled')
    isDisabled: boolean;

    @Output()
    toggleSelectedItem = new EventEmitter<SelectedItemEvent>();


    get cssClasses(): string {
        const cssClasses: String[] = [];

        if (this.wrapperClass !== undefined) {
            cssClasses.push(this.wrapperClass);
        }

        if (this.isDisabled) {
            cssClasses.push('disabled');
        } else if (this.isSelected === true) {
            cssClasses.push('selected');
        }

        return cssClasses.join(' ');
    }

    constructor() {}

    toggle() {
        if (this.isDisabled) {
            return;
        }

        this.toggleSelectedItem.emit({
            id: this.id
        });
    }
}
