import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'readingLevel'})
export class ReadingLevelPipe implements PipeTransform {
    static createMessage(readingLevel: number): string {
        if (readingLevel === null) { // no reading-level
            return 'keine Angabe zur Lesestufe';
        }

        return `Lesestufe ${readingLevel}`;
    }

    transform(readingLevel: number): string {
        return ReadingLevelPipe.createMessage(readingLevel);
    }
}
