import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SchoolModel } from 'shared/models';
import { EnvironmentService } from 'app/core/services/environment.service';

@Injectable()
export class SchoolService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) {}

    getSchool(): Observable<SchoolModel> {
        return this.http.get<SchoolModel>(`${this.baseApiUrl}/school`);
    }

    deleteTeacher(teacherId: number): Observable<void> {
        return this.http.delete<void>(
            `${this.baseApiUrl}/teachers/${teacherId}`
        );
    }
}
