import { AssignmentStatus, StudentAssignmentProgressStatus } from '.';
import { StudentDetailStrategy } from './student-details.model';
import { StudentModel } from './student.model';

export interface StudentAssignmentExercise {
    exerciseId: number;
    title: string;
    score: number;
    maxScore: number;
    status: AssignmentStatus;
    doneDate: Date;
}

export interface StudentAssignment {
    studentId: number;
    exercises: StudentAssignmentExercise[];
    strategies?: StudentDetailStrategy[];
}

export interface StudentAssignmentDetailModel extends StudentModel {
    score: number;
    maxScore: number;
    exercises: StudentAssignmentExercise[];
    strategies?: StudentDetailStrategy[];
}

export interface AssignmentDetailModel {
    id: number;
    exerciseIds: number[];
    bookId: number;
    schoolClassId: number;
    dueDate: Date;
    doneDate: Date;
    description: string;
    status: AssignmentStatus;
    studentsTotal: number;
    studentsDone: number;
    averageCorrect: number;
    studentAssignments: StudentAssignment[];
}

// todo: move to assignment.model.ts
export interface StudentAssignmentModel {
    id: number;
    dueDate: Date;
    status: AssignmentStatus;
    description: string;
    bookId: number;
    maxScore: number;
    score: number;
    changedDate: Date;
    progressStatus: StudentAssignmentProgressStatus,
}
