import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FilterCategory, GlobalNotification } from 'shared/models';
import { resetAllFilters, resetTitleInput, toggleFilter } from 'store/books';
import { selectGlobalNotifications } from 'store/layout';

@Component({
  selector: 'cv-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

  @Output()
  clickedItem = new EventEmitter<void>();

  notifications$: Observable<GlobalNotification[]>;
  defaultLinkText = 'Mehr erfahren';
  externalLinkPrefixSeparator = '://';

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.notifications$ = this.store.pipe(select(selectGlobalNotifications));
  }

  /**
  * @param url string in the format of "/lehrer/bibliothek"
  * if url contain query params, e.g. ?status=Neuheit, they will be removed
  * dispatches filter actions if there
  */
  handleInternalLink(url: string): void {
    if (url && url.includes('?')) {
      const query = url.split('?')[1];
      const params = query.split('=');

      if (params.length === 2 && params[0].length > 0 && params[1].length > 0) {
        const key = params[0];
        const val = params[1];

        this.store.dispatch(resetAllFilters());
        this.store.dispatch(resetTitleInput());
        this.store.dispatch(toggleFilter({ filterCategory: key as FilterCategory, filterValue: val }));
      }

    }
  }

  /**
  *
  * @param url string in the format of "/lehrer/bibliothek"
  * if url contain query params, e.g. ?status=Neuheit, they will be removed
  * @returns the path of the url
  */
  getRouterLink(url: string): string | string[] {
    if (url.includes('?')) {
      return url.split('?')[0].split('/');
    }

    return url;
  }

  clickNotification() {
    this.clickedItem.emit();
  }
}
