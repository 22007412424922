import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { ConsentService } from 'shared/consent/consent.service';
import * as ConsentActions from './consent.actions';

@Injectable()
export class ConsentEffects {
    constructor(
        private actions$: Actions,
        private consentService: ConsentService
    ) { }

    openConsentPage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ConsentActions.showConsentPage),
                map(() => this.consentService.show())
            ), { dispatch: false }
    );
}
