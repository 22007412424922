import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Colors } from 'shared/helper';

@Component({
    selector: 'app-reading-level-filter',
    templateUrl: './reading-level-filter.component.html',
    styleUrls: ['./reading-level-filter.component.scss']
})
export class ReadingLevelFilterComponent {

    @Input()
    showInfoLink = true;

    @Input()
    set selectableLevels(value: number[]) {
        if (value == null || value.length === 0) {
            this.selectedReadingLevels = [];
        } else {
            this.selectedReadingLevels = value;
        }
    }

    @Output()
    changed = new EventEmitter<number[]>();

    selectedReadingLevels: number[] = [];

    readonly colors = Colors;

    filter(level: number): void {
        this.selectedReadingLevels = this.toggleFilter(
            this.selectedReadingLevels || [],
            level
        );

        if (this.changed) {
            this.changed.emit(this.selectedReadingLevels);
        }
    }

    isActive(val: number) {
        return this.selectedReadingLevels.includes(val);
    }

    private toggleFilter(filterArray: any[], val: any): any[] {
        if (filterArray.includes(val)) {
            // item in list -> remove it
            return filterArray.filter(i => i !== val);
        }

        // item not in list -> add it
        return [...filterArray, val];
    }

}
