import { createAction, props } from '@ngrx/store';

import { StudentModel, StudentCreateModel, StudentDetails } from 'shared/models';
import { TrackingSource } from 'shared/services';

export const fetchStudents = createAction('[Students] Fetch Students',
    props<{ schoolClassId: number, firstTime?: boolean }>()
);

export const fetchStudentsSuccess = createAction('[Students] Fetch Students Success',
    props<{ students: StudentModel[] }>()
);

export const fetchStudentsFail = createAction('[Students] Fetch Students Fail',
    props<{ error: any }>()
);

export const addStudents = createAction('[Students] Add Students',
    props<{ schoolClassId: number; students: StudentCreateModel[] }>()
);

export const addStudentsSuccess = createAction('[Students] Add Students Success',
    props<{ schoolClassId: number; students: StudentModel[]; isSchoolAdmin?: boolean }>()
);

export const addStudentsFail = createAction('[Students] Add Students Fail',
    props<{ error: any }>()
);

export const deleteStudent = createAction('[Students] Delete Student',
    props<{ studentId: number, source: TrackingSource }>()
);

export const deleteStudentSuccess = createAction('[Students] Delete Student Success',
    props<{ student: StudentModel, source: TrackingSource }>()
);

export const deleteStudentFail = createAction('[Students] Delete Student',
    props<{ error: any }>()
);

export const resetStudentCode = createAction('[Students] Reset Student Code',
    props<{ student: StudentModel }>()
);

export const resetStudentCodeSuccess = createAction('[Students] Reset Student Code Success',
    props<{ student: StudentModel, newCode: string }>()
);

export const resetStudentCodeFail = createAction('[Students] Reset Student Code Fail',
    props<{ error: any }>()
);

export const changeStudent = createAction('[Students] Change Student',
    props<{ studentId: number, changeFields: any }>()
);

export const changeStudentSuccess = createAction('[Students] Change Student Success',
    props<{ student: StudentModel }>()
);

export const changeStudentFail = createAction('[Students] Change Student Fail',
    props<{ error: any }>()
);

export const fetchStudentDetail = createAction('[Students] Fetch Student Detail',
    props<{ studentId: number }>()
);

export const fetchStudentDetailSuccessful = createAction('[Students] Fetch Student Detail Success',
    props<{ studentId: number, details: StudentDetails }>()
);

export const fetchStudentDetailFail = createAction('[Students] Fetch Student Detail Fail',
    props<{ studentId: number, error: any }>()
);

export const setStudentDetailTeacherNote = createAction('[Note Comp] Set Student Detail Teacher Note',
    props<{ studentId: number, note: string }>()
);

export const setStudentDetailTeacherNoteSuccess = createAction('[StudentDetails API] Set Student Detail Teacher Note Success',
    props<{ studentId: number, note: string }>()
);

export const setStudentDetailTeacherNoteFail = createAction('[StudentDetails API] Set Student Detail Teacher Note Fail',
    props<{ studentId: number, error: any }>()
);
