import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { AuthService } from 'shared/services';
import * as LearnerActions from './learner.actions';
import * as UserActions from 'store/user/user.actions';
import { AccountType } from 'shared/models';
import { CustomErrorCodes } from 'app/core/enums/custom-error-codes';
import { HttpStatusCode } from '@angular/common/http';

@Injectable()
export class LearnerEffects {
    validateLearnerPasscode$ = createEffect(() => this.actions$.pipe(
        ofType(LearnerActions.validateLearnerPasscode),
        map(({ passcode }) => {
            const errorCode = this.authService.validateLearnerPasscode(passcode);

            if (errorCode) {
                return LearnerActions.validateLearnerPasscodeFail({ errorCode });
            }
            
            return LearnerActions.validateLearnerPasscodeSuccess({ passcode });
        }))
    );

    validateLearnerPasscodeSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(LearnerActions.validateLearnerPasscodeSuccess),
        exhaustMap(({ passcode }) => [UserActions.loginStudent({ passcode })]))
    );

    loginStudent$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loginStudent),
        exhaustMap(({ passcode }) => {
            return this.authService.studentlogin(passcode).pipe(
                map(({ accessToken, roles }) => UserActions.loginStudentSuccess({ accessToken, roles })),
                catchError((e: unknown) => of(UserActions.loginStudentFail({errorCode: this.getCustomErrorCodeFromError(e)})))
            );
        })
    ));

    loginStudentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loginStudentSuccess),
        map(({ accessToken, roles }) => UserActions.setUser({ accountType: AccountType.Student, accessToken, isTriggeredBySilentRefresh: false, roles }))
    ));

    constructor(private actions$: Actions, private authService: AuthService) { }

    getCustomErrorCodeFromError(error: any): CustomErrorCodes {
        if ((error as any)?.status === HttpStatusCode.Unauthorized) {
            return CustomErrorCodes.WrongStudentPassword;
        } else {
            return CustomErrorCodes.UnknownError;
        }
    }
}

