import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleDisplayNameService } from '../services/role-display-name.service';

@Pipe({ name: 'roleDisplayName$' })
export class RoleDisplayNamePipe implements PipeTransform {

    constructor(private textService: RoleDisplayNameService) { }

    transform(input: string | string[]): Observable<string | string[]> {
        return this.textService.getWordsBasedOnRoleContext().pipe(
            map(words => {
                Object.keys(words).forEach((key) => {
                    if (typeof input === 'string') {
                        input = input.replace(key, words[key]);
                    } else {
                        input = input.map(i => i.replace(key, words[key]));
                    }
                });

                return input;
            })
        );
    }
}
