import { AccountType } from 'shared/models';
import { createAction, props } from '@ngrx/store';
import { StudentProfileState, TeacherProfileState } from 'store/user';
import * as TrackingEvents from 'shared/tracking/models';

export const teacherActivatedLicence = createAction(
    '[User Effect] Teacher Activated Licence Tracking',
    props<{ event: TrackingEvents.TeacherActivatedLicence }>()
);

export const teacherAuthenticatedIdentity = createAction(
    '[Tracking Effect] track teacher login success',
    props<{ event: TrackingEvents.TeacherAuthenticatedIdentity }>()
);

export const studentAuthenticatedIdentity = createAction(
    '[Tracking Effect] track student login success',
    props<{ event: TrackingEvents.StudentAuthenticatedIdentity }>()
);

export const teacherClickedBanner = createAction(
    '[Banner Components] Teacher Clicked Banner Tracking',
    props<{ event: TrackingEvents.TeacherClickedBanner }>()
);

export const teacherClickedCompletedTasks = createAction(
    '[Students Details Assignments] Teacher Clicked Completed Assignments Tracking',
    props<{ event: TrackingEvents.TeacherClickedCompletedTasks }>()
);

export const teacherCreatedSchoolClass = createAction(
    '[School Classes Effect] Teacher Created School Class Tracking',
    props<{ event: TrackingEvents.TeacherCreatedSchoolClass }>()
);

export const teacherCreatedTask = createAction(
    '[User Effect] Teacher Created Task Tracking',
    props<{ event: TrackingEvents.TeacherCreatedTask }>()
);

export const teacherDeletedSchoolClass = createAction(
    '[SchoolClasses Effect] Teacher Deleted School Class Tracking',
    props<{ event: TrackingEvents.TeacherDeletedSchoolClass }>()
);

export const teacherOpenedResultPage = createAction(
    '[Cloubi Effect] Teacher Opens Result Page Tracking',
    props<{ event: TrackingEvents.TeacherOpenedResultPage }>()
);

export const teacherRegisteredAccount = createAction(
    '[User Effect] Teacher Registered Account Tracking',
    props<{ event: TrackingEvents.TeacherRegisteredAccount }>()
);

export const teacherUpdatedTask = createAction(
    '[Assignments Effect] Teacher Updated Task Tracking',
    props<{ event: TrackingEvents.TeacherUpdatedTask }>()
);

export const teacherUsedGlobalNotificationFeature = createAction(
    '[Layout Effect] Teacher Used Global Notification Tracking',
    props<{ event: TrackingEvents.TeacherUsedGlobalNotificationFeature }>()
);

export const teacherUsedReadingConfigurationFeature = createAction(
    '[SchoolClasses Effect] Teacher Used Reading Configuration Feature Tracking',
    props<{ event: TrackingEvents.TeacherUsedReadingConfigurationFeature }>()
);

export const teacherUsedReadingLevelFeature = createAction(
    '[Student Form Component] Teacher Used Reading Level Feature Tracking',
    props<{ event: TrackingEvents.TeacherUsedReadingLevelFeature }>()
);

export const teacherUpdateLearnersPerGroup = createAction(
    '[User Effect] Update learners per group',
    props<{ event: TrackingEvents.TeacherUpdatedLearnersGroup }>()
);

export const userAddedFavorite = createAction(
    '[Book Effect] User Added Favorite Tracking',
    props<{ event: TrackingEvents.UserAddedFavorite }>()
);

export const userDeletedFavorite = createAction(
    '[Book Effect] User Deleted Favorite Tracking',
    props<{ event: TrackingEvents.UserDeletedFavorite }>()
);

export const userOpenedBookViewer = createAction(
    '[Cloubi Effect] User Opened BookViewer Tracking',
    props<{ event: TrackingEvents.UserOpenedBookViewer }>()
);

export const userOpenedTask = createAction(
    '[Cloubi Effect] User Opened Task Tracking',
    props<{ event: TrackingEvents.UserOpenedTask }>()
);

export const userSendMessage = createAction(
    '[Messages Effect] User Send Message Tracking',
    props<{ event: TrackingEvents.UserSendMessage }>()
);

export const userViewedScreen = createAction(
    '[Router Effect] User Viewed Screen Tracking',
    props<{ event: TrackingEvents.UserViewedScreen }>()
);

export const studentReceivedMessage = createAction(
    '[Coversation Component] Student Received Message Tracking',
    props<{ event: TrackingEvents.StudentReceivedMessage }>()
);

export const studentUsedMonsterChangeFeature = createAction(
    '[Student Effect] Student Used Monster Change Feature Tracking',
    props<{ event: TrackingEvents.StudentUsedMonsterChangeFeature }>()
);

export const userClickedUIElement = createAction(
    '[User Effect] User Clicked UI Element Tracking',
    props<{ event: TrackingEvents.UserClickedUIElement }>()
);

export const teacherSavedStudentNoteFeature = createAction(
    '[Students (Details) Effect] Student Note Saved Feature Tracking',
    props<{ event: TrackingEvents.TeacherSavedStudentNoteFeature }>()
);

export const studentNavigatedOnboarding = createAction(
    '[Student Onboarding Component] Student Navigated Onboarding Step',
    props<{ event: TrackingEvents.StudentNavigatedOnboarding }>()
);

export const userUsedBookFilter = createAction(
    '[Book Effect] User Used Book Filter Tracking',
    props<{ event: TrackingEvents.UserUsedBookFilter }>()
);

export const trackingEventSentSuccessfully = createAction(
    '[CVPA] tracking event sent successfully',
    props<{
        event: TrackingEvents.BaseTrackingEvent,
        accountType: AccountType,
        profile: StudentProfileState | TeacherProfileState,
        schoolClassId: number
    }>()
);

