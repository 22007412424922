import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { filter, fromEvent, skip, Subscription } from 'rxjs';

@Directive({
  selector: '[cvClickOutside]'
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy{
  @Output() clickedOutside = new EventEmitter<void>();
  documentClickSubscription: Subscription | undefined;


  constructor(
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngAfterViewInit(): void {
      this.documentClickSubscription = fromEvent(this.document, 'click').pipe(
        skip(1), // should not emit when opening the component
        filter((event) => {
          return !((event.target as HTMLElement) === this.elementRef.nativeElement) &&
          !this.elementRef.nativeElement.contains((event.target as HTMLElement));
        })
      ).subscribe(() => {
        this.clickedOutside.emit();
      });
  }

  ngOnDestroy(): void {
    this.documentClickSubscription?.unsubscribe();
  }

}
