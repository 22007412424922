import { Component, Input } from '@angular/core';

import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 103_Stolperstelle_linear_CV_Uni_Icon     - circle shape
 * 103_Stolperstelle_b_linear_CV Uni Icon   - triangle shape
 */
@Component({
    selector: 'app-svg-icon-warning',
    template: `
        <svg *ngIf="type === 'circle'; else triangle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path d="M39.976,8.957q-.468-.285-.949-.547a22.538,22.538,0,0,0-10.8-2.741h0a22.683,22.683,0,0,0-3.449.262q-.562.086-1.115.2a22.68,22.68,0,0,0,0,44.431q.552.114,1.115.2a22.812,22.812,0,0,0,3.449.261h0a22.538,22.538,0,0,0,10.8-2.741c.321-.174.637-.357.949-.546a22.666,22.666,0,0,0,0-38.779ZM28.226,48.189a19.843,19.843,0,0,1,0-39.685c.342,0,.675.034,1.013.051a19.816,19.816,0,0,1,0,39.582C28.9,48.154,28.568,48.189,28.226,48.189ZM26.809,32.6V17.007a1.417,1.417,0,0,1,2.834,0V32.6a1.417,1.417,0,1,1-2.834,0Zm3.543,7.087a2.126,2.126,0,1,1-2.126-2.126A2.126,2.126,0,0,1,30.352,39.685Z" />
        </svg>

        <ng-template #triangle>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.69 56.69" enable-background="new 0 0 56.69 56.69" xml:space="preserve" class="icon">
                <path d="M30.17,6.35c-0.26-0.42-0.72-0.68-1.21-0.68h-1.42c-0.49,0-0.95,0.26-1.21,0.68L2.95,44.61c-0.14,0.22-0.21,0.48-0.21,0.74   v1.42c0,0.78,0.63,1.42,1.42,1.42h48.19c0.78,0,1.42-0.63,1.42-1.42v-1.42c0-0.26-0.07-0.52-0.21-0.74L30.17,6.35z M5.82,45.35   L28.25,8.65l22.43,36.71H5.82z M28.25,19.84c0.78,0,1.42,0.63,1.42,1.42V32.6c0,0.78-0.63,1.42-1.42,1.42s-1.42-0.63-1.42-1.42   V21.26C26.83,20.48,27.47,19.84,28.25,19.84z M28.25,37.56c1.17,0,2.13,0.95,2.13,2.13c0,1.17-0.95,2.13-2.13,2.13   s-2.13-0.95-2.13-2.13C26.12,38.51,27.08,37.56,28.25,37.56z" />
            </svg>
        </ng-template>

    `
})
export class SvgIconWarningComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'warning';
    }

    @Input()
    type: 'triangle' | 'circle' = 'circle';
}
