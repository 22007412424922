
export interface CreateMessageModel {
    subject?: string;
    body: string;
    recipientId: number;
    notificationId: number;
}

export interface MessageModel {
    id: number;
    createdAt: Date;
    subject?: string;
    body: string;
    messageType: number;
}

export interface Messages {
    total: number;
    items: MessageModel[];
}
