import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { SchoolService, TeacherService } from 'shared/services';
import { CvPopUpHelper, PopupChannels } from 'shared/helper';
import { SchoolModel } from 'shared/models';
import * as SchoolActions from './school.actions';
import { SchoolState, storeTeacherToTeacherLight } from './school.reducer';
import {
    getSchoolTeachersDict,
    getSchoolSchoolClassesDict
} from './school.selector';

@Injectable()
export class SchoolEffects {
    getSchool$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SchoolActions.getSchool),
            switchMap(() =>
                this.schoolService.getSchool().pipe(
                    map((school: SchoolModel) =>
                        SchoolActions.getSchoolSuccess({ school })
                    ),
                    catchError((error: unknown) =>
                        of(SchoolActions.getSchoolFail({ error }))
                    )
                    // todo: Error handling, e.g. logout and/or show msg to speak to CV support?
                )
            )
        )
    );

    getStudents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SchoolActions.getSchoolStudents),
            switchMap(({ schoolClassId }) =>
                this.teacherService
                    .getStudentsBySchoolClassId(schoolClassId)
                    .pipe(
                        map(students =>
                            SchoolActions.getSchoolStudentsSuccess({
                                schoolClassId,
                                students
                            })
                        ),
                        catchError((error: unknown) =>
                            of(SchoolActions.getSchoolStudentsFail({ error }))
                        )
                    )
            )
        )
    );

    deleteTeacher$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SchoolActions.deleteTeacher),
            withLatestFrom(
                this.store.pipe(select(getSchoolTeachersDict)),
                ({ teacherId, source }, teachersDict) => ({
                    teacherFromStore: teachersDict[teacherId],
                    source
                })
            ),
            // teacher type is internal model and have to transfer to real TeacherModel
            withLatestFrom(
                this.store.pipe(select(getSchoolSchoolClassesDict)),
                ({ teacherFromStore, source }, schoolClassesDict) => ({
                    teacher: storeTeacherToTeacherLight(
                        teacherFromStore,
                        schoolClassesDict
                    ),
                    source
                })
            ),
            map(({ teacher, source }) => {
                const message = `Möchten Sie ${teacher.firstName ||
                    ''} ${teacher.lastName || ''} wirklich löschen?`;
                const payload = {
                    teacher,
                    source
                };
                const openPopup = CvPopUpHelper.createOpenPopupActionForConfirmation(
                    PopupChannels.DeleteTeacherConfirmation,
                    message,
                    payload
                );

                return openPopup;
            })
        )
    );

    deleteTeacherSuccessful$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SchoolActions.deleteTeacherSuccess),
            map(({ teacher }) => {
                const message = `${teacher.firstName ||
                    ''} ${teacher.lastName || ''} wurde gelöscht.`;
                const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                    PopupChannels.DeleteTeacherSuccessful,
                    message
                );
                return openPopup;
            })
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<SchoolState>,
        private schoolService: SchoolService,
        private teacherService: TeacherService
    ) {}
}
