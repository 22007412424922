import { createAction, props } from '@ngrx/store';

export const updateLeseoName = createAction('[Tutor effects] update tutor leseo name',
    props<{ leseoName: string, oldLeseoName: string }>()
);

export const updateLeseoNameSuccess = createAction('[LeseoName API] update tutor leseo name success',
    props<{ leseoName: string }>()
);

export const updateLeseoNameFail = createAction('[LeseoName API] update tutor leseo name fail',
    props<{ oldLeseoName: string, error: any}>()
);
