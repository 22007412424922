import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AssignmentHelper, BookHelper, CvPopUpHelper, PopupChannels } from 'shared/helper';
import { AssignmentModel, AssignmentStatus, trackingEvents } from 'shared/models';
import { BooksService, ExerciseService } from 'shared/services';
import { getAssignmentsDict, getBookFromSelectedAssignment } from 'store/assignments/assignments.selector';
import { fetchBookDetailsForAssignmentPage } from 'store/books';
import { getAllBooksDict, getBookById } from 'store/books/books.selector';
import { closePopup, openPopup } from 'store/layout/layout.actions';
import { RootState } from 'store/root.reducer';
import { fetchStudentDetail } from 'store/students/students.actions';
import * as AssignmentsActions from './assignments.actions';
import * as TrackingActions from '../tracking/tracking.actions';
import { TeacherCreatedTask } from 'shared/tracking/models/teacher-created-task.model';
import { TeacherUpdatedTask } from 'shared/tracking/models/teacher-updated-task.model';
import { getStudentsCount } from 'store/students/students.selector';

@Injectable()
export class AssignmentsEffects {
    fetch$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.fetchAssignments),
        switchMap(action =>  this.exerciseService.getAssignments(action.schoolClassId, action.status, action.limit).pipe(
                map(assignments => {
                     const props: { assignments: AssignmentModel[], loadedCompletedAssignments?: boolean } = {
                        assignments
                    };

                    if (action.status === AssignmentStatus.Completed) {
                        props.loadedCompletedAssignments = true;
                    }

                    return AssignmentsActions.fetchAssignmentsSuccess(props);
                }),
                catchError((error: unknown) => of(AssignmentsActions.fetchAssignmentsFailure({ error })))
            )
        )
    ));

    fetchSelectedAssignment$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.FetchSelectedAssignment),
        map((action: AssignmentsActions.FetchSelectedAssignment) => action),
        switchMap(({ assignmentId }) =>
            this.exerciseService.getAssignment(assignmentId).pipe(
                map(
                    assignment => new AssignmentsActions.FetchSelectedAssignmentSuccess(assignment)
                ),
                catchError((error: unknown) => of(new AssignmentsActions.FetchSelectedAssignmentFail(error)))
            )
        )
    ));

    fetchSelectedAssifetchBookDetails$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.FetchSelectedAssignmentSuccess),
        map((action: AssignmentsActions.FetchSelectedAssignmentSuccess) => action),
        withLatestFrom(this.store.select(getBookFromSelectedAssignment)),
        filter(([_, b]) => !b),
        map(([a]) => fetchBookDetailsForAssignmentPage({ bookId: a.assignment.bookId })),
    ));

    addAssignment$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.AddAssignment),
        map((action: AssignmentsActions.AddAssignment) => action),
        switchMap(({ newAssignment, studentDetailIdToUpdate }) =>
            this.exerciseService.createAssignment(newAssignment).pipe(
                map((assignment) => new AssignmentsActions.AddAssignmentSuccess(assignment, studentDetailIdToUpdate)),
                catchError((error: unknown) => of(new AssignmentsActions.AddAssignmentFailure(error)))
            )
        )
    ));

    addAssignmentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.AddAssignmentSuccess),
        concatMap(({studentDetailIdToUpdate}) => {
            const openPopup = CvPopUpHelper.createOpenPopupActionForMonstersMessage(
                PopupChannels.AddAssignmentSuccess,
                '<h3>Die Aufgabe wurde erstellt.</h3>Sie können den aktuellen Status unter Aufgaben einsehen.'
            );

            const actions: any[] = [openPopup];

            // this is set when teacher creates assignment from student detail page
            if (studentDetailIdToUpdate) {
                actions.push(fetchStudentDetail({ studentId: studentDetailIdToUpdate }));
            }

            return actions;
        })
    ));

    addAssignmentSuccessTracking$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.AddAssignmentSuccess),
        map((action: AssignmentsActions.AddAssignmentSuccess) => action),
        withLatestFrom(this.store.select(getAllBooksDict), this.store.select(getStudentsCount)),
        map(([action, books, numOfStudentsInClass]) => {
            const selectedBook = books.dict[action.assignment.bookId];
            return TrackingActions.teacherCreatedTask({
                   event: new TeacherCreatedTask(selectedBook.title, numOfStudentsInClass, action.assignment.studentsTotal)
            });
        })
    ));

    addAssignmentFailure$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.AddAssignmentFailure),
        map((action: AssignmentsActions.AddAssignmentFailure) => {
            const message = 'Die Aufgabe konnte nicht gespeichert werden.';
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.AddAssignmentFailure,
                message
            );

            return openPopup;
        })
    ));

    updateAssignment$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.UpdateAssignment),
        map((action: AssignmentsActions.UpdateAssignment) => action),
        switchMap(({ updateModel }) =>
            this.exerciseService.updateAssignment(updateModel).pipe(
                withLatestFrom(this.store.pipe(select(getBookById(updateModel.bookId)))),
                map(([assignment]) => {
                    return new AssignmentsActions.UpdateAssignmentSuccess(assignment);
                }),
                catchError((error: unknown) => {
                    console.error(error);
                    if (error instanceof Error) {
                        return of(new AssignmentsActions.UpdateAssignmentFailure(error.message));
                    }
                })
            )
        )
    ));

    closePopupOnUpdateAssignmentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.UpdateAssignmentSuccess),
        map(() => closePopup())
    ));

    updateAssignmentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.UpdateAssignmentSuccess),
        map(() => {
            const message = '<h3>Die Aufgabe wurde gespeichert.</h3>Sie können den aktuellen Status unter Aufgaben einsehen.';
            const openPopup = CvPopUpHelper.createOpenPopupActionForMonstersMessage(
                PopupChannels.AddAssignmentSuccess,
                message
            );

            return openPopup;
        })
    ));

    updateAssignmentSuccessTrack$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.UpdateAssignmentSuccess),
        map((action: AssignmentsActions.UpdateAssignmentSuccess) => action),
        withLatestFrom(this.store.select(getAllBooksDict), this.store.select(getStudentsCount)),
        map(([action, books, numOfStudentsInClass]) => {
            const selectedBook = books.dict[action.assignment.bookId];
            return TrackingActions.teacherUpdatedTask({
                   event: new TeacherUpdatedTask(selectedBook.title, numOfStudentsInClass, action.assignment.studentsTotal)
            });
        })
    ));

    updateAssignmentFailure$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.UpdateAssignmentFailure),
        map((action: AssignmentsActions.UpdateAssignmentFailure) => {
            const message = 'Die Aufgabe konnte nicht gespeichert werden.';
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.AddAssignmentFailure,
                message
            );

            return openPopup;
        })
    ));

    deleteAssignment$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.DeleteAssignment),
        withLatestFrom(
            this.store.pipe(
                select(getAssignmentsDict),
                filter(assignments => assignments.loaded === true),
                map(assignments => assignments.dict)
            ),
            (action: AssignmentsActions.DeleteAssignment, assignmentsDict) => ({
                action,
                assignmentsDict
            })
        ),
        withLatestFrom(
            this.store.pipe(
                select(getAllBooksDict),
                filter(allBooks => allBooks.loaded === true),
                map(allBooks => allBooks.dict)
            ),
            (data, booksDict) => ({ ...data, booksDict })
        ),
        map(data => {
            const assignment = data.action.assignment;
            const bookId = assignment.bookId;
            const book = data.booksDict[bookId];
            const message = `Möchten Sie die Aufgabe zum Buch "${book.title}" wirklich löschen?`;

            const openPopup = CvPopUpHelper.createOpenPopupActionForConfirmation(
                PopupChannels.DeleteAssignmentConfirmation,
                message,
                assignment
            );
            return openPopup;
        })
    ));

    deleteAssignmentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.AssignmentsActionTypes.DeleteAssignmentSuccess),
        withLatestFrom(
            this.store
                .select(getAllBooksDict)
                .pipe(filter(allBooks => allBooks.loaded === true)),
            (action: AssignmentsActions.DeleteAssignmentSuccess, allBooks) => ({
                action,
                booksDict: allBooks.dict
            })
        ),
        tap(data => {
            const statusPart = AssignmentHelper.getUrlPartByStatus(
                data.action.assignment.status
            );
            this.router.navigate(['/lehrer', 'aufgaben', statusPart]);
        }),
        map(data => {
            const { assignment } = data.action;
            const bookId = assignment.bookId;
            const book = data.booksDict[bookId];
            const message = `Die Aufgabe zum Buch "${book.title}" wurde gelöscht.`;

            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.DeleteAssignmentSuccessful,
                message
            );
            return openPopup;
        })
    ));

    exerciseDetails$ = createEffect(() => this.actions$.pipe(
        ofType(openPopup),
        filter(({ settings }) => (settings.channel === PopupChannels.CreateAssignment || settings.channel === PopupChannels.EditAssignment) && settings.data.bookId != null),
        withLatestFrom(this.store.select(getBookFromSelectedAssignment)),
        map(([{ settings }, book]) => {
            if (settings.data) {
                // TODO @Anne: We only need the bookId here?
                const { bookId, assignment, studentIds } = settings.data;
                const exercises = book?.metadata?.exercises; // caused an error, just added a simple null check

                return AssignmentsActions.fetchExerciseDetails({ bookId, assignment, studentIds, exercises });
            }
            // handle when ids aren't set??
        })
    ));

    fetchExerciseDetailsForNewAssignment = createEffect(() => this.actions$.pipe(
        ofType(AssignmentsActions.fetchExerciseDetails),
        switchMap(({ bookId }) => this.booksService.getExerciseDetails(bookId).pipe(
            map((exerciseDetails) => AssignmentsActions.fetchExerciseDetailsSuccess({ bookId, exerciseDetails })),
            catchError((error: unknown) =>
                of(AssignmentsActions.fetchExerciseDetailsFail({ error }))
            )
        ))
    ));

    constructor(
        private actions$: Actions,
        private store: Store<RootState>,
        private exerciseService: ExerciseService,
        private router: Router,
        private booksService: BooksService
    ) { }
}
