import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AssignmentStatus } from 'shared/models';
import { RouterState } from '.';

export const getRouterState = createFeatureSelector<RouterState>('router');

export const getRouteParams = createSelector(getRouterState, router => {
    if (router == null || router.state == null) {
        return;
    }

    return router.state.params;
});

export const getRouteAssignmentId = createSelector(getRouteParams, params => {
    if (params == null) {
        return;
    }

    return +params.assignmentId as number;
});

export const getRouteBookId = createSelector(getRouteParams, params => {
    if (params == null) {
        return;
    }

    return +params.bookId as number;
});

export const getRouteSchoolClassId = createSelector(getRouteParams, params => {
    if (params == null) {
        return;
    }

    return +params.schoolClassId as number;
});

export const getRouteData = createSelector(getRouterState, router => {
    if (router == null || router.state == null) {
        return null;
    }

    return router.state.data;
});

export const getShowExclusive = createSelector(
    getRouterState,
    router => router.state.data.showExclusive
);

export const getHasChildHeader = createSelector(getRouterState, router =>
    router.state.data.hasChildHeader ? true : false
);

export const getAssignmentFilter = createSelector(getRouterState, router => {
    if (router == null || router.state == null || router.state.data == null) {
        return;
    }

    return router.state.data.assignmentFilter as AssignmentStatus;
});

export const getRouteTitle = createSelector(
    getRouterState,
    router => {
        if (router && router.state && router.state.title) {
            return router.state.title;
        }

        return '';
    }
);

export const isDashboard = createSelector(
    getRouteTitle,
    title => title.includes('Startseite')
);

export const isConversationPage = createSelector(
    getRouteTitle,
    title => title.includes('Nachrichten')
);

export const getRouteUrl = createSelector(getRouterState, router => {
    if (router == null || router.state == null) {
        return null;
    }

    return router.state.url;
});
