import { BaseTrackingEvent } from 'shared/tracking/models/base-tracking-event.model';
import { TrackingObjects, TrackingVerbs } from 'shared/tracking/tracking';

export class UserDeletedFavorite extends BaseTrackingEvent {
    constructor(favoriteTitle: string, favoriteId: string) {
        super({
            eventId: '2ed63198-355a-4324-bccd-b4713c1206a5',
            eventVerb: TrackingVerbs.DELETED,
            eventObject: TrackingObjects.FAVORITE,
            eventPayload: {
                favoriteTitle: favoriteTitle,
                favoriteId: favoriteId,
            },
        });
    }
}
