import { AuthConfig, OidcClientConfig } from 'shared/models';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    stage: 'dev',
    baseApiUrl: 'https://leseo-api.dev.aws.cornelsen.de',
    authService: <AuthConfig>{
        enableDebugMode: false,
        httpInterceptor: {
            sendAccessToken: true,
            allowedUrls: [
                'http://localhost:60856',
                'https://leseo-api.dev.aws.cornelsen.de',
                'https://leseo-api.dev.aws.cornelsen.de',
            ]
        },
        authConfig: <OidcClientConfig>{
            authority: 'https://id.stage.cornelsen.de',
            client_id:
                '@!DDD5.370D.8547.FFD9!0001!A1C9.92C1!0008!6C5A.51E7.5081.612C',
            redirect_uri: window.location.origin + '/login-callback',
            post_logout_redirect_uri: window.location.origin + '/logout-success.html',
            response_type: 'code',
            scope: 'openid profile email meta inum cv_sap_kdnr roles cv_schule gender postal_code',
            silent_redirect_uri:
                window.location.origin + '/silent-refresh.html',
            automaticSilentRenew: false,
            filterProtocolClaims: true,
            loadUserInfo: false,
            clockSkew: 600
        },
        berlinConfig: <OidcClientConfig>{
            authority: 'https://cornelsen.middleware.sdui.de/api/auth/6e23a80d-3c1f-4434-8711-9e7a80888163',
            client_id: 'leseo_dev',
            client_secret: 'hUJFDrbCFVuJrX9bunXW9dTyGTWkQUPFHjFjHtqv',
            redirect_uri: window.location.origin + '/login-callback-bsp',
            post_logout_redirect_uri: window.location.origin + '/logout-success.html',
            response_type: 'code',
            automaticSilentRenew: false,
            filterProtocolClaims: true,
            loadUserInfo: false,
            clockSkew: 600
        },
        eduPoolConfig: <OidcClientConfig>{
            authority: 'https://oidc.edupool.cloud',
            client_id: 'abf9167e-54cb-4ddb-9628-cc41df285cf8',
            scope: 'openid profile antares.context antares.classes antares.students antares.teachers email',
            redirect_uri: window.location.origin + '/login-callback-edupool',
            post_logout_redirect_uri: window.location.origin + '/logout-success.html',
            response_type: 'code',
            automaticSilentRenew: false,
            filterProtocolClaims: true,
            loadUserInfo: true,
            clockSkew: 600,
        },
        studentConfig: {
            validationPattern: /[a-zA-Z\d]{8,}/
        }
    },
    dateFormat: 'D. MMMM Y',
    cvConsentManager: {
        enable: false
    },
    cvTracking: {
        enable: true,
        url: 'https://cv-gateway.stage.cornelsen.de/firehose/firehose-a',
        appId: 'cornelsen_leseo',
        apiKey: '568dU0cAVy6FQVf59jiyY4N67znTrEs8wH30udRb',
        userSessionDurationInMinutes: 30
    },
    licenceInfoUrl: 'https://www.cornelsen.de/empfehlungen/lesen/lizenzen',
    imageService: {
        baseUrl: 'https://leseo-dev.obs-website.eu-de.otc.t-systems.com/resources',
        coversPath: 'covers',
        studentAvatarsPath: '/assets/student/avatars'
    },
    signUpUrl: 'https://qa.cornelsen.de/shop/registrierung/',
    apiHostUrl: 'https://leseo-api.dev.cornelsen.de/api/viewer',
    booksRoute: '/books',
    tasksRoute: '/tasks',
    statementsRoute: '/api/bookstatements',
    assetServer: 'https://leseo-dev.obs-website.eu-de.otc.t-systems.com/resources',
    berlinLogoutRedirectUrl: 'https://test.idm-bsp.de/start?swift_object=login&swift_action=view'
};
