import { AuthService, CvTracking, EnvironmentConfig, ImageService } from '../environment-config.model';

declare const window: {
    environmentConfig: EnvironmentConfig | null;
} & Window;

export class EnvironmentService {

    public static config: EnvironmentConfig = window.environmentConfig;

    static get isProduction(): boolean {
        return EnvironmentService.config.production;
    }

    static get stage(): string {
        return EnvironmentService.config.stage;
    }

    static get baseApiUrl(): string {
        return EnvironmentService.config.baseApiUrl;
    }

    static get baseCloubiUrl(): string {
        return EnvironmentService.config.baseCloubiUrl;
    }

    static get authService(): AuthService {
        return EnvironmentService.config.authService;
    }

    static get dateFormat(): string {
        return EnvironmentService.config.dateFormat;
    }

    static get enabledCvConsentManager(): boolean {
        return EnvironmentService.config.cvConsentManager.enable;
    }

    static get cvTracking(): CvTracking {
        return EnvironmentService.config.cvTracking;
    }

    static get licenceInfoUrl(): string {
        return EnvironmentService.config.licenceInfoUrl;
    }

    static get imageService(): ImageService {
        return EnvironmentService.config.imageService;
    }

    static get signUpUrl(): string {
        return EnvironmentService.config.signUpUrl;
    }

    static get apiHostUrl(): string {
        return EnvironmentService.config.apiHostUrl;
    }

    static get booksRoute(): string {
        return EnvironmentService.config.booksRoute;
    }

    static get tasksRoute(): string {
        return EnvironmentService.config.tasksRoute;
    }

    static get statementsRoute(): string {
        return EnvironmentService.config.statementsRoute;
    }

    static get assetServer(): string {
        return EnvironmentService.config.assetServer;
    }

    static get berlinLogoutRedirectUrl(): string {
        return EnvironmentService.config.berlinLogoutRedirectUrl;
    }

}
