import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { EnvironmentService } from 'app/core/services/environment.service';

if (EnvironmentService.isProduction) {
    enableProdMode();
    if (window) {
        window.console.log = function () { };
    }
}

platformBrowserDynamic().bootstrapModule(AppModule);

