import { ActionReducerMap } from '@ngrx/store';

import { RouterState, routerReducer } from './router';
import { LayoutState, layoutReducer } from './layout';
import { UserState, userReducer } from './user';
import { SchoolClassesState, schoolClassesReducer } from './school-classes';
import { BooksState, booksReducer } from './books';
import { teacherAssignmentsReducer, TeacherAssignmentsState } from './assignments';
import { StudentAssignmentsState, studentAssignmentsReducer } from './student';
import { SchoolState, schoolReducer } from './school/school.reducer';
import { studentsReducer, StudentsState } from './students/students.reducer';
import { notificationsReducer, NotificationsState } from './notifications/notifications.reducer';
import { PollingState, pollingReducer } from './polling/polling.reducer';
import { MessagesState, messagesReducer } from './messages/messages.reducer';

import { conversationsReducer, ConversationsState } from './conversations/conversations.reducer';
import { tutorOnboardingReducer, TutorOnboardingState } from './tutor-onboarding';
import { BookViewerState, bookViewerReducer } from './book-viewer';

export interface RootState {
    router: RouterState;
    layout: LayoutState;
    books: BooksState;
    user: UserState;
    schoolClass: SchoolClassesState;
    students: StudentsState;
    school: SchoolState;
    teacherAssignments: TeacherAssignmentsState;
    studentAssignments: StudentAssignmentsState;
    notifications: NotificationsState;
    messages: MessagesState;
    conversations: ConversationsState;
    polling: PollingState;
    tutorOnboarding: TutorOnboardingState;
    bookViewer: BookViewerState;
}

export const rootReducers: ActionReducerMap<RootState> = {
    router: routerReducer,
    layout: layoutReducer,
    user: userReducer,
    schoolClass: schoolClassesReducer,
    students: studentsReducer,
    books: booksReducer,
    teacherAssignments: teacherAssignmentsReducer,
    studentAssignments: studentAssignmentsReducer,
    school: schoolReducer,
    notifications: notificationsReducer,
    conversations: conversationsReducer,
    messages: messagesReducer,
    polling: pollingReducer,
    tutorOnboarding: tutorOnboardingReducer,
    bookViewer: bookViewerReducer,
};
