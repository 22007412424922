import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-cv-checkbox',
    templateUrl: './cv-checkbox.component.html',
    styleUrls: ['./cv-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CvCheckboxComponent),
            multi: true
        }
    ]
})
export class CvCheckboxComponent implements ControlValueAccessor {
    private _disabled: null | boolean = null;

    @Input()
    formControlName: string;

    @Input()
    elementId: string;

    @Input()
    @HostBinding('class.show-validation')
    showValidation = true;

    @Input()
    showOptionalFlag = false;

    get disabled(): boolean {
        return this._disabled;
    }

    private _value: string;

    get value(): string {
        return this._value;
    }

    set value(val: string) {
        this._value = val;
        this.onChange(val);
    }

    onChange = (value: string) => { };
    onTouched = () => { };

    constructor() { }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }
}
