import { Action, createAction, props } from '@ngrx/store';
import { UserRoles } from 'app/core/enums';
import { CustomErrorCodes } from 'app/core/enums/custom-error-codes';

import { User } from 'oidc-client-ts';

import {
    StudentModel,
    AccountType,
    TeacherModel,
    LicenceModel,
    ProfileUpdatesModel
} from 'shared/models';
import { UserData } from 'shared/models/user-data';


export enum UserActionTypes {
    OidcUserLoginSuccess = '[User] oidc user login success',
    ClearUser = '[User] clear user',
    FillStore = '[User] fill store',
    // @TODO: move to licence.actions.ts
    ActivateLicence = '[User] activate licence'
}
// @todo: move to learner.actions.ts
export const loginStudent = createAction('[User] student login',
    props<{ passcode: string }>()
);

// @todo: move to learner.actions.ts
export const loginStudentSuccess = createAction('[User] student login success',
    props<{ accessToken: string, roles: UserRoles[] }>()
);

export const trackLearnerLoginSuccess = createAction('[User] track learner login success',
    props<{ schoolId }>()
);

// @todo: move to learner.actions.ts
export const loginStudentFail = createAction('[User] student login fail',
    props<{ errorCode: CustomErrorCodes }>()
);

export const loginTeacher = createAction('[Tutor login comp] login cornelsen tutor');

export const loginBerlinSchoolPortalUser = createAction('[Login Comp] login Berlin School Portal user');

export const loginEdupoolUser = createAction('[Login Comp] Login Edupool user');

export const oidcUserLoginSuccess = createAction('[Start up effects] oidc user login success',
    props<{ user: User }>()
);

export const setUser = createAction('[User] set user',
    props<{ accountType: AccountType, accessToken: string, isTriggeredBySilentRefresh: boolean, oidcUser?: User, roles: UserRoles[] }>()
);

export const clearUser = createAction('[User] clear user',
    props<{ reloadPage?: boolean }>()
);

export const fillStore = createAction('[User] fill store',
    props<{ accountType: AccountType, profile: TeacherModel | StudentModel, firstTime?: boolean }>()
);

export const loadProfile = createAction('[User] load profile',
    props<{ accountType: AccountType, firstTime?: boolean }>()
);

export const loadProfileSuccess = createAction('[User] load profile successs',
    props<{ profile: TeacherModel | StudentModel, accountType: AccountType, firstTime?: boolean }>()
);

export const fetchProfileUpdates = createAction('[User] fetch profile updates',
    props<{ accountType: AccountType }>()
);

export const fetchProfileUpdatesSuccess = createAction('[User] fetch profile updates success',
    props<{ profileUpdates: ProfileUpdatesModel, accountType: AccountType, prevUnreadConversations: boolean }>()
);

export const fetchProfileUpdatesFail = createAction('[User] fetch profile updates fail',
    props<{ error: any }>()
);

export const loadProfileFail = createAction('[User] load profile fail',
    props<{ accountType: AccountType, error: any }>()
);

export const oidcUserCreateAccount = createAction('[User Effect] create new OIDC user');


export const validateEmail = createAction('[User Info comp] validate email for deletion',
    props<{ emailConfirm: string }>()
);

export const validateEmailFail = createAction('[User API] validate email for deletion failed',
    props<{ error: any }>()
);

export const deleteProfile = createAction('[User Effect] delete leseo user');

export const deleteProfileFail = createAction('[User API] delete profile failed',
    props<{ error: any }>()
);

export class ActivateLicence implements Action {
    readonly type = UserActionTypes.ActivateLicence;

    constructor(public code: string) { }
}

export const activateLicenceSuccess = createAction('[User API] activate licence success',
    props<{ licence: LicenceModel, isOldLicenceActive: boolean }>()
);

export const activateLicenceFail = createAction('[User API] activate licence fail',
    props<{ error: any, isOldLicenceActive: boolean }>()
);

export const registerTeacher = createAction('[User] Register teacher');

export const registerTeacherSuccess = createAction('[User] Register teacher success',
    props<{ teacher: UserData }>()
);

export const registerTeacherFail = createAction('[User] Register teacher fail',
    props<{ error: any }>()
);

export const logout = createAction('[User] Logout',
    props<{ redirectUri: string }>()
);

export const logoutSuccess = createAction('[User] Logout success');

export type UserActions =
    | ActivateLicence;
