import { Injectable } from '@angular/core';
import { BookDetailModel, BookModel, FilterCategory } from 'shared/models';

@Injectable()
export class BookHelper {
  static filterCategoryLabels = new Map<string, string>([
    [FilterCategory.Title, 'Titel'],
    [FilterCategory.ReadingLevel, 'Lesestufe'],
    [FilterCategory.Genre, 'Textart'],
    [FilterCategory.Serie, 'Reihe'],
    [FilterCategory.Exercise, 'Aufgabe'],
    [FilterCategory.Strategy, 'Lesestrategie'],
    [FilterCategory.Progress, 'Fortschritt'],
    [FilterCategory.Competence, 'Lesekompetenz'],
    [FilterCategory.TextbookPage, 'Lehrwerk'],
    [FilterCategory.Status, 'Status']
  ]);

    static hasGenre(genre: string, book: BookModel | BookDetailModel): boolean {
        if (book && genre && genre.trim().length > 0) {
            return book.genres.some(g => g.trim().toLowerCase() === genre.trim().toLowerCase());
        }
        return false;
    }

    static getIsbn(book: BookModel | BookDetailModel): string {
        const isbn = (book.isbn && book.isbn !== '') ? book.isbn : book.originalIsbn;

        return isbn;
    }

    static getFilterLabel(filterName: string): string {
      return this.filterCategoryLabels.get(filterName);
    }

    static getFilterCategory(filterLabel: string): string {
      for (const [key, value] of this.filterCategoryLabels) {
        if (value === filterLabel) {
          return key;
        }
      }
      return '';
    }

    constructor() { }

    getFormatedGenreInfo(book: BookModel | BookDetailModel): string {
        if (book == null) {
            return '';
        }

        const { genres } = book;

        if (genres == null || genres.length === 0) {
            return;
        }

        if (genres.length > 1) {
            return `${genres[0]} ● ${genres[1]}`;
        }

        return genres[0];
    }

}
