import { createAction, props } from '@ngrx/store';
import {
    ResetStudentCodesResultModel, SchoolClassDetailsModel, SchoolClassModel,
    StudentModel, StudentReadingConfigurationEnum, TeacherModel
} from 'shared/models';
import { TrackingSource } from 'shared/services';

export const fetchSchoolClasses = createAction('[SchoolClasses Effects] fetch school-classes');

export const fetchSchoolClassesSuccess = createAction('[SchoolClasses Effects] fetch school-classes Success',
    props<{ schoolClasses: SchoolClassModel[], activeSchoolClassId: number }>()
);

export const fetchSchoolClassesFail = createAction('[SchoolClasses Effects] fetch school-classes Fail',
    props<{ error: any }>()
);

export const selectSchoolClass = createAction('[SelectSchoolClass Comp] select active class id',
    props<{ schoolClassId: number}>()
);

export const getSchoolClassDetails = createAction('[SchoolClasses] get school-class details',
    props<{ schoolClassId: number }>()
);

export const getSchoolClassDetailsSuccess = createAction('[SchoolClasses] get school-class details success',
    props<{ schoolClassId: number, schoolClassDetails: SchoolClassDetailsModel }>()
);

export const getSchoolClassDetailsFail = createAction('[SchoolClasses] get school-class details fail',
    props<{ error: any }>()
);

export const addSchoolClass = createAction('[SchoolClasses] Add school-class',
    props<{ teacher: TeacherModel, schoolClassName: string }>()
);

export const addSchoolClassSuccess = createAction('[SchoolClasses] Add school-class Success',
    props<{ teacher: TeacherModel, schoolClass: SchoolClassModel }>()
);

export const addSchoolClassFail = createAction('[SchoolClasses] Add school-class Fail',
    props<{ error: any }>()
);

export const deleteSchoolClass = createAction('[SchoolClass] Delete SchoolClass',
    props<{ schoolClassId: number, source: TrackingSource }>()
);

export const deleteSchoolClassSuccess = createAction('[SchooolClass] Delete SchoolClass Success',
    props<{ schoolClass: SchoolClassModel, studentIds: number[] }>()
);

export const deleteSchoolClassFail = createAction('[SchooolClass] Delete SchoolClass Fail',
    props<{ error: any }>()
);

export const changeSchoolClass = createAction('[SchoolClass] Change SchoolClass',
    props<{ schoolClassId: number, changeFields: any }>()
);

export const changeSchoolClassSuccess = createAction('[SchoolClass] Change SchoolClass Success',
    props<{ schoolClass: SchoolClassModel }>()
);

export const changeSchoolClassFail = createAction('[SchoolClass] Change SchoolClass Fail',
    props<{ error: any }>()
);

export const resetSchoolClassCodes = createAction('[SchoolClass] Reset All Student Codes',
    props<{ schoolClass: SchoolClassModel }>()
);

export const resetSchoolClassCodesSuccess = createAction('[SchoolClass] Reset All Student Codes Success',
    props<{ schoolClassId: number, result: ResetStudentCodesResultModel }>()
);

export const resetSchoolClassCodesFail = createAction('[SchoolClass] Reset All Student Codes Fail',
    props<{ error: any }>()
);

export const setAllStudentReadingConfigurations = createAction('[SchoolClass] Set All Student Reading Configurations',
    props<{ schoolClass: SchoolClassModel, isHidden: boolean, studentReadingConfiguration: StudentReadingConfigurationEnum }>()
);

export const setAllStudentReadingConfigurationsSuccess = createAction('[SchoolClass] Set All Student Reading Configurations Success',
    props<{ schoolClass: SchoolClassModel, isHidden: boolean, studentReadingConfiguration: StudentReadingConfigurationEnum }>()
);

export const setAllStudentReadingConfigurationsFail = createAction('[SchoolClass] Set All Student Reading Configurations Fail',
    props<{ error: any }>()
);

export const setStudentReadingConfiguration = createAction('[SchoolClass] Set Student Reading Configuration',
    props<{ student: StudentModel, isHidden: boolean, studentReadingConfiguration: StudentReadingConfigurationEnum }>()
);

export const setStudentReadingConfigurationSuccess = createAction('[SchoolClass] Seto Student Reading Configuration Success',
    props<{ student: StudentModel, isHidden: boolean, studentReadingConfiguration: StudentReadingConfigurationEnum }>()
);

export const setStudentReadingConfigurationFail = createAction('[SchoolClass] Set Student Reading Options Fail',
    props<{ error: any }>()
);
