import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherUpdatedTask extends BaseTrackingEvent {
    constructor(taskBookTitle: string, numOfStudentsInClass: number, numOfAssignees: number, taskTitle: string = '', taskId: string = '') {
        super({
            eventId: '64c519b4-4870-46bb-9e13-3f65f7b5e270',
            eventObject: TrackingObjects.TASK,
            eventVerb: TrackingVerbs.UPDATED,
            eventPayload: {
                taskTitle: taskTitle,
                taskId: taskId,
                taskBookTitle: taskBookTitle,
                taskNumOfStudentsInClass: numOfStudentsInClass,
                taskNumOfAssignees: numOfAssignees
            },
        });
    }
}
