export enum StrategyType {
    Unknown = 0,

    /** Leseerwartungen */
    Expectations = 1,

    /** W-Fragen - not implemented yet */
    WhQuestions = 2,

    /** Unbekannte Wörter - not implemented yet */
    UnkownWords = 3,

    /** Vorwissen */
    PriorKnowledge = 4
}

export interface StrategyModel {
    id: number;
    title: string;
    strategyType: StrategyType;
}

export interface StrategyExplanationModel extends Pick<StrategyModel, 'title' | 'strategyType'> {
    imageUrl: string;
    description: string[]
}
