import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherUsedReadingLevelFeature extends BaseTrackingEvent {
    constructor(featureReadingLevel: number, featureTitle: string = '', featureId: string = '') {
        super({
            eventId: '6c06fadf-6883-4231-8593-b13edf714f65',
            eventVerb: TrackingVerbs.USED,
            eventObject: TrackingObjects.FEATURE,
            eventPayload: {
                featureTitle: featureTitle,
                featureId: featureId,
                featureReadingLevel: featureReadingLevel
            },
        });
    }
}
