import { createReducer, on } from '@ngrx/store';
import { nextOnboardingStep, previousOnboardingStep } from './tutor-onboarding.actions';

export interface TutorOnboardingState {
    currentOnboardingStep: number;
    error?: any;
}

export const initialState: TutorOnboardingState = {
    currentOnboardingStep: 0
};

export const tutorOnboardingReducer = createReducer(
    initialState,
    on(nextOnboardingStep, (state) => ({...state, currentOnboardingStep: state.currentOnboardingStep + 1})),
    on(previousOnboardingStep, (state) => ({...state, currentOnboardingStep: state.currentOnboardingStep - 1}))
);
