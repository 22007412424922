import { Component, OnInit, Input } from '@angular/core';

import { StudentModel } from 'shared/models';
import { ImageService } from 'shared/services';

// can we use the one from responsive image model
export enum StudentAvatarSize {
    XSmall,
    Small,
    Medium,
    Large,
    XLarge
}

@Component({
    selector: 'app-student-avatar',
    templateUrl: './student-avatar.component.html',
    styleUrls: ['./student-avatar.component.scss']
})
export class StudentAvatarComponent implements OnInit {
    @Input()
    student: StudentModel;

    @Input()
    size: StudentAvatarSize = StudentAvatarSize.Small;

    width: number;

    get avatarUrl(): string {
        return this.imageService.buildAvatarUrl(this.student);
    }

    constructor(private imageService: ImageService) { }

    ngOnInit() {
        this.width = this.getWidthFromSize();
    }

    private getWidthFromSize(): number {
        switch (this.size) {
            case StudentAvatarSize.XSmall:
                return 35;

            case StudentAvatarSize.Medium:
                return 110;

            case StudentAvatarSize.Large:
                return 220;

            case StudentAvatarSize.XLarge:
                return 320;

            case StudentAvatarSize.Small:
            default:
                return 70;
        }
    }
}
