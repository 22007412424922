import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, filter, withLatestFrom } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { UserState, getAccountType } from 'store/user';
import { getIsUiLocked } from 'store/layout';
import { RedirectionHelper } from 'shared/helper';

@Injectable()
export class UiLockedGuard  {
    constructor(private router: Router, private store: Store<UserState>) {}

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.store.pipe(
            select(getIsUiLocked),
            filter(isUiLocked => isUiLocked != null),
            withLatestFrom(
                this.store.pipe(select(getAccountType)),
                (isUiLocked, accountType) => ({ isUiLocked, accountType })
            ),
            map(data => {
                const { isUiLocked, accountType } = data;

                if (isUiLocked === true && ['/lehrer', '/schueler'].includes(state.url) === false) {
                    // redirect to teachers dashboard

                    const dashboardRoute = RedirectionHelper.getDashboardRoute(
                        accountType
                    );
                    this.router.navigate(dashboardRoute);
                    return false;
                }

                return true;
            })
        );
    }
}
