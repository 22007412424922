import { AssignmentStatus, StudentDetailAssignment } from 'shared/models';
import { AssignmentModel } from './../models/assignment.model';
import { CalendarHelper } from './calendar.helper';

export class AssignmentHelper {
    static getUrlPartByStatus(status: AssignmentStatus): string {
        if (status == null) {
            return;
        }

        switch (status) {
            case AssignmentStatus.Open:
                return 'aktive';
            case AssignmentStatus.Completed:
                return 'erledigte';
            default:
                throw new Error(`assignment-status "${status}" not supported.`);
        }
    }

    static createStatusMessageFromAssignmentStatus(
        status: AssignmentStatus
    ): string {
        switch (status) {
            case AssignmentStatus.Open:
                return 'aktive';
            case AssignmentStatus.Completed:
                return 'erledigte';
            default:
                throw new Error(`assignment-status "${status}" not supported`);
        }
    }

    static isDueDateExpired(dueDate: Date): boolean {
        if (dueDate == null) {
            return;
        }

        return CalendarHelper.isInPast(dueDate);
    }

    static isDueDateIn3Days(dueDate: Date): boolean {
        if (dueDate == null) {
            return;
        }

        return CalendarHelper.isIn3Days(dueDate);
    }

    /**
     * if status is completed sort by descending date
     * else sort ascending date
     */
    static sortAssignmentsByDate(assignmentList: AssignmentModel[], status: AssignmentStatus): AssignmentModel[] {
        if (status === AssignmentStatus.Completed) {
            return assignmentList.sort(AssignmentHelper.sortDescending);
        }

        return assignmentList.sort(AssignmentHelper.sortAscending);
    }

    /**
     * if status is completed sort by descending date
     * else sort ascending date
     */
    static sortStudentDetailAssignmentsByDate(assignmentList: StudentDetailAssignment[], status: AssignmentStatus): StudentDetailAssignment[] {
        if (status === AssignmentStatus.Completed) {
            return assignmentList.sort((a, b) => AssignmentHelper.sortByDoneDate(a, b, 'desc'));
        }

        return assignmentList.sort(AssignmentHelper.sortAscending);
    }

    private static sortByDoneDate(a: Partial<AssignmentModel>, b: Partial<AssignmentModel>, direction: string): number {
        if (direction === 'asc') {
            return a.doneDate.getTime() - b.doneDate.getTime();
        }
        return b.doneDate.getTime() - a.doneDate.getTime();
    }

    private static sortDescending(a: Partial<AssignmentModel>, b: Partial<AssignmentModel>): number {
        return b.dueDate.getTime() - a.dueDate.getTime();
    }

    private static sortAscending(a: Partial<AssignmentModel>, b: Partial<AssignmentModel>): number {
        return a.dueDate.getTime() - b.dueDate.getTime();
    }
}
