import { Component, Input } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

export enum TrophyFilling {
    Empty,
    HalfFull,
    Full
}

/**
 * 591_Erfolg_leer_linear_CV_Uni_Icon
 * 518_Erfolg_mittel_linear_CV_Uni_Icon
 * 519_Erfolg_hoch_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-trophy',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon" [ngSwitch]="trophyFilling">
            <path *ngSwitchCase="fillings.Empty" d="M38.376,36.545c8.165-2.083,12.647-10.454,12.647-23.789c0-0.783-0.634-1.417-1.417-1.417h-6.378V7.086 c0-0.783-0.634-1.417-1.417-1.417H14.882c-0.783,0-1.417,0.634-1.417,1.417v4.253H7.086c-0.783,0-1.417,0.634-1.417,1.417  c0,13.333,4.48,21.704,12.644,23.788c1.691,2.741,3.817,4.542,6.375,5.389l-0.427,3.421H21.26c-0.65,0-1.217,0.443-1.375,1.073  l-1.418,5.67c-0.105,0.423-0.011,0.872,0.258,1.216s0.681,0.545,1.117,0.545H36.85c0.437,0,0.849-0.201,1.117-0.545  s0.363-0.792,0.258-1.216l-1.417-5.669c-0.158-0.631-0.725-1.074-1.375-1.074h-3.001l-0.428-3.421  C34.561,41.085,36.685,39.285,38.376,36.545z M43.228,14.173h4.941c-0.174,6.277-1.622,15.217-7.976,18.635  C41.961,28.211,42.983,22.011,43.228,14.173z M8.523,14.173h4.942v0.044c0.244,7.813,1.265,13.999,3.029,18.588  C10.144,29.386,8.697,20.448,8.523,14.173z M29.93,48.188h4.396l0.708,2.836H21.658l0.709-2.836h4.396l0.715-5.726  c0.289,0.02,0.57,0.057,0.868,0.057s0.579-0.038,0.868-0.057L29.93,48.188z M28.346,39.685c-9.298,0-11.684-13.897-12.047-25.512  v-5.67h24.095v5.626C40.03,25.787,37.644,39.685,28.346,39.685z" />
            <path *ngSwitchCase="fillings.HalfFull" d="M49.606,11.339H43.228V7.086a1.416,1.416,0,0,0-1.417-1.417H14.881a1.416,1.416,0,0,0-1.417,1.417v4.253H7.087A1.416,1.416,0,0,0,5.67,12.756c0,13.333,4.48,21.7,12.643,23.788a11.941,11.941,0,0,0,6.358,5.382l-.388,2.72H21.259a1.417,1.417,0,0,0-1.383,1.109l-1.417,6.378a1.418,1.418,0,0,0,1.383,1.725H36.85a1.418,1.418,0,0,0,1.384-1.725l-1.417-6.378a1.419,1.419,0,0,0-1.384-1.109H32.411l-.389-2.721a11.928,11.928,0,0,0,6.354-5.38c8.165-2.083,12.647-10.455,12.647-23.789A1.416,1.416,0,0,0,49.606,11.339ZM16.3,8.5h24.1v5.626c-.058,1.869-.17,3.8-.352,5.713H16.648c-.181-1.9-.291-3.816-.35-5.669Zm-7.775,5.67h4.941v.044q.367,11.721,3.031,18.59C10.144,29.388,8.7,20.449,8.523,14.173ZM34.3,47.48l.788,3.544H21.609L22.4,47.48h4.346l.716-5.02c.3.021.584.059.888.059s.593-.038.888-.059l.718,5.02Zm5.9-14.67q2.652-6.9,3.035-18.637H48.17C48,20.45,46.548,29.392,40.193,32.81Z" />
            <path *ngSwitchCase="fillings.Full" d="M49.606,11.339H43.228V7.086a1.416,1.416,0,0,0-1.417-1.417H14.881a1.416,1.416,0,0,0-1.417,1.417v4.253H7.087A1.416,1.416,0,0,0,5.67,12.756c0,13.333,4.48,21.7,12.643,23.788a11.941,11.941,0,0,0,6.358,5.382l-.388,2.72H21.259a1.417,1.417,0,0,0-1.383,1.109l-1.417,6.378a1.418,1.418,0,0,0,1.383,1.725H36.85a1.418,1.418,0,0,0,1.384-1.725l-1.417-6.378a1.418,1.418,0,0,0-1.384-1.109H32.411l-.389-2.721a11.928,11.928,0,0,0,6.354-5.38c8.165-2.083,12.647-10.455,12.647-23.789A1.416,1.416,0,0,0,49.606,11.339ZM8.523,14.173h4.941v.044q.367,11.721,3.031,18.59C10.144,29.388,8.7,20.449,8.523,14.173ZM34.3,47.48l.787,3.544H21.609L22.4,47.48h4.346l.716-5.02c.3.021.584.059.888.059s.593-.038.889-.059l.717,5.02Zm5.9-14.67q2.652-6.9,3.035-18.637H48.17C48,20.45,46.548,29.392,40.193,32.81Z" />
        </svg>
    `
})
export class SvgIconTrophyComponent extends SvgIconBaseDirective {
    readonly fillings = TrophyFilling;

    @Input()
    trophyFilling: TrophyFilling;

    get iconName(): string {
        return 'trophy';
    }
}
