import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { SelectExerciseComponent } from '../select-exercise/select-exercise.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

enum Page {
    BookSelection,
    AssignmentForm
}

@Component({
    selector: 'app-create-assignment',
    templateUrl: './create-assignment.component.html',
    styleUrls: ['./create-assignment.component.scss']
})
export class CreateAssignmentComponent implements OnInit {
    readonly Pages = Page;

    bookId: number | null = null;
    studentIds: number[] = [];

    page: Page = Page.BookSelection;

    showBackButton = true;

    @ViewChild('createExerciseComponent')
    createExerciseComponent: SelectExerciseComponent;

    constructor(private element: ElementRef, private dialogRef: MatDialogRef<CreateAssignmentComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { bookId: number, studentIds: number[] }) {
            this.bookId = this.data?.bookId || null;
            this.studentIds = this.data?.studentIds || [];
        }

    ngOnInit() {
        if (this.bookId != null) {
            this.showBackButton = false;
            this.page = Page.AssignmentForm; // skip book selection page
        }
    }

    selectBook(bookId: number): void {
        this.bookId = bookId;
    }

    next(): void {
        this.page = Page.AssignmentForm;
        this.scrollToTop();
    }

    back(): void {
        this.page = Page.BookSelection;
        this.scrollToTop();
    }

    private scrollToTop(): void {
        // modal element consists of 3 parts: header, main, footer
        // get main section
        const mainSection = this.element.nativeElement.children[1];

        // scroll to the top of it
        mainSection.scrollTo(0, 0);
    }

    createAssignment(): void {
        if (this.createExerciseComponent) {
            // calls publish with student id if component was opened from student detail page
            const openedFromStudentDetailPage = this.studentIds.length === 1;
            const success = openedFromStudentDetailPage ? this.createExerciseComponent.publish(this.studentIds[0]) : this.createExerciseComponent.publish();

            if (success) {
                this.dialogRef.close();
            }
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
