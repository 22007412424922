import update from 'immutability-helper';

import {
    SchoolClassModel,
    StudentModel,
    SchoolClassDetailsModel
} from 'shared/models';
import { Dictionary, StoreHelper } from 'shared/helper';
import * as SchoolClassesActions from './school-classes.actions';
import { createReducer, on } from '@ngrx/store';

export type StudentsDict = Dictionary<StudentModel>;

export interface SchoolClassesDetailsState extends SchoolClassDetailsModel {
    loaded: boolean;
    loading: boolean;
}

export interface SchoolClassesState {
    all: Record<string, SchoolClassModel>;
    activeSchoolClassId: number;
    loaded: boolean;
    loading: boolean;
}

export const initialState: SchoolClassesState = {
    all: {},
    activeSchoolClassId: 0,
    loaded: false,
    loading: false
};

export const schoolClassesReducer = createReducer(
    initialState,
    on(SchoolClassesActions.fetchSchoolClasses, (state, action) => {
        return { ...state, loading: true };
    }),
    on(SchoolClassesActions.fetchSchoolClassesSuccess, (state, { schoolClasses, activeSchoolClassId}) => {
            if (schoolClasses == null) {
                return {...state, loading: false, loaded: true};
            }

            return {
                ...state,
                all: StoreHelper.arrayToRecord(schoolClasses, state.all),
                activeSchoolClassId: activeSchoolClassId,
                loading: false,
                loaded: true
            };
    }),
    on(SchoolClassesActions.fetchSchoolClassesFail, (state, action) => {
        return { ...state, loaded: true, loading: false };
    }),
    on(SchoolClassesActions.selectSchoolClass, (state, { schoolClassId }) => {
        return {
            ...state,
            activeSchoolClassId: schoolClassId
        };
    }),
    on(SchoolClassesActions.getSchoolClassDetails, (state, {schoolClassId}) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(SchoolClassesActions.getSchoolClassDetailsSuccess, (state, {schoolClassId, schoolClassDetails}) => {
        return {
            ...state,
            all: {
                ...state.all,
                [schoolClassId]: {
                    ...state.all[schoolClassId],
                    details: schoolClassDetails
                },
            },
            loading: false,
            loaded: true
        };
    }),
    on(SchoolClassesActions.addSchoolClass, (state, {teacher, schoolClassName}) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(SchoolClassesActions.addSchoolClassSuccess, (state, {teacher, schoolClass}) => {
        return {
            ...state,
            all: {
                ...state.all,
                [schoolClass.id]: schoolClass
            },
            activeSchoolClassId: schoolClass.id,
            loading: false,
            loaded: true
        };
    }),
    on(SchoolClassesActions.addSchoolClassFail, (state, action) => {
        return update(state, {
            loaded: { $set: true },
            loading: { $set: false }
        });
    }),
    on(SchoolClassesActions.deleteSchoolClassSuccess, (state, {schoolClass}) => {
        const updatedSchoolClasses: Record<string, SchoolClassModel> = {};

        for (let key in state.all) {
            // remove school class from record
            if (key !== schoolClass?.id?.toString()) {
                updatedSchoolClasses[key] = state.all[key];
            }
        }

        let updatedActiveSchoolClassId = 0;
        const updatedSchoolClassesValues = Object.values(updatedSchoolClasses);

        if (updatedSchoolClassesValues.length > 0) {
          // set alphabetically first school class as active school class
          updatedActiveSchoolClassId = updatedSchoolClassesValues.sort((groupA, groupB) => groupA.name.localeCompare(groupB.name))[0].id;
        }

        return {
            ...state,
            all: updatedSchoolClasses,
            activeSchoolClassId: updatedActiveSchoolClassId,
            loading: false,
            loaded: true
        };


    }),
    on(SchoolClassesActions.changeSchoolClassSuccess, (state, {schoolClass}) => {
        return {
            ...state,
            all: {
                ...state.all,
                [schoolClass.id]: {
                    ...state.all[schoolClass.id],
                    // explicitly need to only set the name, otherwise details property is reset to null
                    name: schoolClass.name
                }
            },
            loading: false,
            loaded: true
        };
    }),
    on(SchoolClassesActions.resetSchoolClassCodesSuccess, (state, { schoolClassId, result }) => {
        return {
            ...state,
            all: {
                ...state.all,
                [schoolClassId]: {
                    ...state.all[schoolClassId],
                    // explicitly need to only set the classCode, otherwise details property is reset to null
                    classCode: result.classCode
                }
            },
            loading: false,
            loaded: true
        };
    }),
);
