import { defaultMonsterKey } from './../models/student.model';
import { Injectable } from '@angular/core';

import { StudentModel, MonsterModel } from 'shared/models';
import { environment } from 'environments/environment';

export enum CoverImageMode  {
    Full = 'Full',
    Medium = 'Medium',
    Small = 'Small'
}

enum KeyType {
    Cover,
    StudentAvatar
}

@Injectable()
export class ImageService {
    buildAvatarUrl(student: StudentModel): string {
        let avatarKey = defaultMonsterKey;
        if (student && student.avatarKey) {
            avatarKey = student.avatarKey;
        }
        return this.buildUrl(KeyType.StudentAvatar, avatarKey);
    }

    buildAvatarUrlFromMonster(monster: MonsterModel): string {
        return this.buildUrl(KeyType.StudentAvatar, monster.key);
    }

    getAllStudentAvatars(): MonsterModel[] {
        return ['01', '02', '03', '04', '05', '06'].map(id => {
            const avatar: MonsterModel = {
                isDefault: false,
                key: id,
                url: this.buildUrl(KeyType.StudentAvatar, id),
                title: this.getMonsterTitle(id)
            };

            return avatar;
        });
    }

    private getMonsterTitle(key: string): string {
        switch (key) {
            case '01': return 'Blaues Monster mit zwei Antennen-Augen';
            case '02': return 'Lila Monster mit einer pinken Feder am Kopf';
            case '03': return 'Gelbes Monster mit gestreiften Hörnern';
            case '04': return 'Graues Monster mit besonders beweglichen Armen';
            case '05': return 'Grünes Monster mit lustiger Frisur';
            case '06': return 'Rotes Monster mit gepunkteten Hörnern';
        }
    }

    private buildUrl(type: KeyType, key: string): string {
        const { imageService } = environment;

        switch (type) {
            case KeyType.Cover:
                return `${imageService.baseUrl}/${imageService.coversPath
                    }/${key}`;

            case KeyType.StudentAvatar:
                return `${imageService.studentAvatarsPath}/${key}.svg`;

            default:
                throw new Error(`KeyType '${type}' not supported yet.`);
        }
    }
}
