import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import {
    AssignmentCreateModel,
    AssignmentDetailModel, AssignmentModel,
    AssignmentStatus,
    AssignmentUpdateModel, BookViewerAccessLinkModel, ExerciseModel
} from 'shared/models';

@Injectable()
export class ExerciseService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;

    constructor(private http: HttpClient) { }

    createAssignment(assignment: AssignmentCreateModel): Observable<AssignmentModel> {
        return this.http.post<AssignmentModel>(
            `${this.baseApiUrl}/assignments`,
            assignment
        );
    }

    updateAssignment(assignment: AssignmentUpdateModel): Observable<AssignmentModel> {
        return this.http.put<AssignmentModel>(
            `${this.baseApiUrl}/assignments`,
            assignment
        );
    }

    deleteAssignment(assignmentId: number): Observable<void> {
        return this.http.delete<void>(
            `${this.baseApiUrl}/assignments/${assignmentId}`
        );
    }

    /**
     * @todo not used - can probably be deleted
     */
    getExercises(bookId: number): Observable<ExerciseModel[]> {
        return this.http.get<ExerciseModel[]>(
            `${this.baseApiUrl}/books/${bookId}/exercises`
        );
    }

    /**
     * todo: move to teacher.service ?!
     */
    getAssignments(schoolClassId: Number, status: AssignmentStatus = AssignmentStatus.Open, limit: number = 0): Observable<AssignmentModel[]> {
        return this.http.get<AssignmentModel[]>(
            `${this.baseApiUrl}/schoolclasses/${schoolClassId}/assignments/${status}/${limit}`
        );
    }

    getAssignment(assignmentId: number): Observable<AssignmentDetailModel> {
        return this.http.get<AssignmentDetailModel>(
            `${this.baseApiUrl}/assignments/${assignmentId}`
        );
    }

    getExerciseResultPageLink(exerciseId: number, studentId: number): Observable<BookViewerAccessLinkModel> {
        return this.http
            .get<BookViewerAccessLinkModel>(
                `${this.baseApiUrl}/exercise/${exerciseId}/report/${studentId}`
            );
    }
}
