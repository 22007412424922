import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';


export class TeacherUpdatedLearnersGroup extends BaseTrackingEvent {
    constructor(learnersCounter: number) {
        super({
            eventId: '890278c5-7219-4bd6-9fa6-484f839d9512',
            eventObject: TrackingObjects.GROUP,
            eventVerb: TrackingVerbs.UPDATED,
            eventPayload: {
                learnersPerGroup: learnersCounter
            }
        });
    }
}