export enum TrackingVerbs {
    ACTIVATED = 'activated',
    ADDED = 'added',
    ASSIGNED = 'assigned',
    COMPLETED = 'completed',
    CONFIGURATED = 'configurated',
    CREATED = 'created',
    DELETED = 'deleted',
    DETECTED = 'detected',
    DOWNLOADED = 'downloaded',
    INSTALLED = 'installed',
    NONE = 'none',
    NAVIGATED = 'navigated',
    OPENED = 'opened',
    PURCHASED = 'purchased',
    RECORDED = 'recorded',
    RECOVERED = 'recovered',
    REGISTERED = 'registered',
    REPLACED = 'replaced',
    SELECTED = 'selected',
    SEND = 'send',
    SKIPPED = 'skipped',
    STARTED = 'started',
    SUBMITTED = 'submitted',
    UPDATED = 'updated',
    USED = 'used',
    VIEWED = 'viewed',
    CLICKED = 'clicked',
    AUTHENTICATED = 'authenticated'
}


export enum TrackingObjects {
    ACCOUNT = 'account',
    ANNOTATION = 'annotation',
    ASSET = 'asset',
    BANNER = 'banner',
    BEHAVIOUR = 'behaviour',
    BOOK_LIST = 'bookList',
    BOOK_VIEWER = 'bookViewer',
    CATALOG = 'catalog',
    CONTENT = 'content',
    DEVICE = 'device',
    ERROR_SCREEN = 'errorScreen',
    EVENT = 'event',
    FAVORITE = 'favorite',
    FEATURE = 'feature',
    FILTER = 'filter',
    GROUP = 'group',
    LEARNING_OBJECT = 'learningObject',
    LESSON_PLANNING = 'lessonPlanning',
    LICENCE = 'licence',
    MATERIAL_TOOLBAR = 'materialToolbar',
    MESSAGE = 'message',
    PROFILE = 'profile',
    SCREEN = 'screen',
    SEARCH = 'search',
    STATUS = 'status',
    TASK = 'task',
    UIELEMENT = 'uielement',
    IDENTITY = 'identity'
}
