export enum AssignmentStatus {
    Open = 1,
    Completed = 2,
    Expired = 3
}

export enum StudentAssignmentProgressStatus {
    Open = 0,
    InProgress = 1,
    Done = 2
}

export interface AssignmentModel {
    id: number;
    bookId: number;
    dueDate?: Date;
    doneDate?: Date;
    description: string;
    status?: AssignmentStatus;
    studentsTotal: number;
    studentsDone: number;
}

export interface AssignmentCreateModel {
    description?: string;
    dueDate?: Date;
    bookId: number;
    exerciseIds?: number[];
    studentIds?: number[];
}

export interface AssignmentUpdateModel extends AssignmentCreateModel {
    id: number;
}
