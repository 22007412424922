import {
    Component,
    OnInit,
    Input,
    forwardRef,
    HostBinding,
    HostListener,
    SkipSelf,
    Host,
    Optional,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    AbstractControl,
    ControlContainer
} from '@angular/forms';
import { NgxFloatUiContentComponent } from 'ngx-float-ui';

import { Colors } from 'shared/helper';

export enum InputTypes {
    TEXT = 'text',
    PASSWORD = 'password',
    NUMBER = 'number'
}

@Component({
    selector: 'app-cv-input',
    templateUrl: './cv-input.component.html',
    styleUrls: ['./cv-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CvInputComponent),
            multi: true
        }
    ]
})
export class CvInputComponent implements ControlValueAccessor, OnInit {
    private _disabled: null | boolean = null;
    private _control: AbstractControl;
    readonly colors = Colors;

    inputType: InputTypes;

    @Input()
    formControlName: string;

    @Input()
    elementId: string;

    @Input()
    label: string;

    @Input()
    placeholder: string;

    @Input()
    type: InputTypes;

    @Input()
    maxlength: number;

    @Input()
    @HostBinding('class.show-validation')
    showValidation = true;

    @Input()
    showOptionalFlag = false;

    @Input()
    showTooltip = false;

    @Input()
    inputStyles = '';

    @ViewChild('tooltipMessage')
    tooltip: NgxFloatUiContentComponent;

    clickedInsideOfTheInput: boolean;

    get disabled(): boolean {
        return this._disabled;
    }

    isEyeIconVisible = false;

    private _value: string;

    get value(): string {
        return this._value;
    }

    set value(val: string) {
        this._value = val;
        this.onChange(val);
    }

    get hasLabel(): boolean {
        return this.label != null && this.label !== '';
    }

    get showCheckIcon(): boolean {
        if (this._control != null) {
            if (this._control.pristine || this.value === '') {
                return false;
            }
        }

        return this.isPasswordInput && this.isEyeIconVisible ? false : true;
    }

    get showInvalidIcon(): boolean {
        if (this._control != null) {
            if (this._control.pristine) {
                return false;
            }
        }

        const invalid = this._control && this._control.invalid;

        return invalid && !this.isEyeIconVisible;
    }

    get showEyeIcon(): boolean {
        return this.isEyeIconVisible;
    }

    private get isPasswordInput(): boolean {
        if (this.elementId == null) {
            return false;
        }

        return this.elementId.indexOf('password') > -1;
    }

    @HostBinding('class.focused')
    isFocused = false;

    @HostBinding('class.show-password')
    showPassword = false;

    onChange = (value: string) => {};
    onTouched = () => {};

    // click on the input element
    @HostListener('click', ['$event'])
    @HostListener('touchstart', ['$event'])
    clickInside(event): void {
        this.clickedInsideOfTheInput = true;
    }

    // click outside of the input
    @HostListener('document:click')
    @HostListener('document:touchstart', ['$event'])
    clickout(event): void {
        if (!this.clickedInsideOfTheInput) {
            this.isEyeIconVisible = false;
            this.showPassword = false;
            if (this.isPasswordInput) {
                this.isEyeIconVisible = false;
                this.isFocused = false;

                if (event) {
                    event.target.blur();
                }
            }

            this.isFocused = false;
        }

        this.clickedInsideOfTheInput = false;
    }

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer
    ) {}

    ngOnInit() {
        this.inputType = this.type;

        // from https://stackoverflow.com/questions/44731894/get-access-to-formcontrol-from-the-custom-form-component-in-angular
        if (this.controlContainer) {
            if (this.formControlName != null) {
                this._control = this.controlContainer.control.get(
                    this.formControlName
                );
            } else {
                console.log(
                    'warning: Missing FormControlName directive from host element of the component'
                );
            }
        } else {
            console.log(`warning: Can't find parent FormGroup directive`);
        }
    }

    inputFocus(): void {
        this.isFocused = true;

        if (this.isPasswordInput) {
            this.isEyeIconVisible = true;
        }
    }

    inputFocusOut(e: Event): void {
        const inputElement = <HTMLInputElement>e.target;

        if (inputElement.type === InputTypes.PASSWORD) {
            e.preventDefault();
            return;
        }

        this.isFocused = false;
    }

    toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
        this.inputType = this.getInputType(this.showPassword);
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }

    private getInputType(showPassword: boolean) {
        return showPassword ? InputTypes.TEXT : InputTypes.PASSWORD;
    }
}
