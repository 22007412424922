import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { RootState } from 'store/root.reducer';
import { getRouteUrl } from 'store/router';
import { getAccountType, getHasTutorRolePrivateUser, getProfile, getStudentProfile, getUserState, hasSchoolClassManagementPermissions, isInPrivateUserOnboardingFlow, logout } from 'store/user';
import { AccountType, TeacherModel, StudentModel } from 'shared/models';
import { Colors } from 'shared/helper';
import { selectConversationsHasUnreadMessages, selectStudyGroupHasUnreadMessages } from 'store/conversations/conversations.selector';
import { closeUserSidebar, selectIsMenuSidebarOpen, toggleMenuSidebar, setSubmenuTitle, selectSubmenuTitle, isGlobalNotificationNew } from 'store/layout';
import { NavItemsHelper } from 'shared/helper/navItems.helper';
import { getSchoolClass } from 'store/school-classes';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    readonly colors = Colors;
    readonly userState$ = this.store.pipe(select(getUserState));
    readonly hasUnreadMessages$ = this.store.pipe(
        select(selectConversationsHasUnreadMessages)
    );
    readonly hasCurrentGroupNewMessages$ = this.store.pipe(select(selectStudyGroupHasUnreadMessages));
    readonly urlRoot = './lehrer';

    profile$: Observable<TeacherModel | StudentModel>;
    showLogoutButtonOnly$ = combineLatest([this.store.select(getHasTutorRolePrivateUser), this.store.select(getRouteUrl)]).pipe(
        map(([isPrivateUser, routeUrl]) => isPrivateUser && routeUrl?.includes('leseo-starten'))
    );
    isInOnboardingFlow$ = this.store.select(isInPrivateUserOnboardingFlow);
    isTeacher$ = this.store.pipe(select(getAccountType), map(account => account === AccountType.Teacher));

    studentState$ = this.store.pipe(select(getStudentProfile));
    hasRolePrivateUser$ = this.store.select(getHasTutorRolePrivateUser);
    showNavigation$ = this.store.pipe(select(isInPrivateUserOnboardingFlow), map(isInOnboardingFlow => !isInOnboardingFlow));
    isMenuSidebarOpen$ = this.store.select(selectIsMenuSidebarOpen);
    submenuTitle$ = this.store.select(selectSubmenuTitle);
    hasNotifications$ = combineLatest([
        this.hasUnreadMessages$,
        this.store.pipe(select(isGlobalNotificationNew))
    ]).pipe(
        map(([hasUnreadMessages, hasNotifications]) => hasUnreadMessages || hasNotifications)
    );
    routerUrl$ = this.store.pipe(select(getRouteUrl));
    schoolClass$ = this.store.select(getSchoolClass);
    isStudyGroupSelectorOpen = false;
    hasSchoolClassManagementpermissions$ = this.store.select(hasSchoolClassManagementPermissions);

    navItems$ = combineLatest([
        this.userState$,
        this.hasCurrentGroupNewMessages$,
        this.hasRolePrivateUser$
    ]).pipe(
        filter(([userState, _]) => userState.loaded && userState.teacher?.loaded),
        map(([user, hasCurrentGroupUnreadMessages, hasRolePrivateUser]) => [
            user.teacher?.isSchoolAdmin,
            user.teacher?.licence?.isExpired,
            hasCurrentGroupUnreadMessages,
            hasRolePrivateUser
        ]),
        map(([isSchoolAdmin, isLicenceExpired, hasCurrentGroupUnreadMessages, hasRolePrivateUser]) => isSchoolAdmin
            ? NavItemsHelper.getAdminNavItems(hasCurrentGroupUnreadMessages, isLicenceExpired, this.urlRoot)
            : NavItemsHelper.getTeacherNavItems(hasCurrentGroupUnreadMessages, hasRolePrivateUser, this.urlRoot)
        ));

    constructor(
        private store: Store<RootState>,
        private location: Location
    ) { }

    ngOnInit() {
        this.profile$ = this.store.pipe(select(getProfile));
    }

    back() {
        this.location.back();
    }

    logoutLearner(): void {
        this.store.dispatch(logout({ redirectUri: '/kinder-login' }));
    }

    logoutTutor(): void {
        this.store.dispatch(logout({ redirectUri: 'login' }));
    }

    toggleMenuSidebar() {
        this.store.dispatch(toggleMenuSidebar());
    }

    closeSubmenu() {
        this.submenuTitle$.pipe(
            take(1)
        ).subscribe(submenuTitle => {
            switch (submenuTitle) {
                case 'Profil':
                    this.store.dispatch(closeUserSidebar());
                    break;
            }
            this.store.dispatch(setSubmenuTitle({ submenuTitle: '' }));
        });
    }

    toggleStudyGroupSelector() {
        this.isStudyGroupSelectorOpen = !this.isStudyGroupSelectorOpen;
        if(this.isStudyGroupSelectorOpen) {
            this.store.dispatch(closeUserSidebar());
        }
    }
}
