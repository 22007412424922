import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { StudentNotificationModel } from 'shared/models';
import { markAsRead } from 'store/notifications/notifications.actions';
import { UserState } from 'store/user/user.reducer';
import { getStudentAvatarKey, getStudentReadingLevel } from 'store/user/user.selector';

@Component({
    selector: 'app-reading-level-rise',
    templateUrl: './reading-level-rise.component.html',
    styleUrls: ['./reading-level-rise.component.scss']
})
export class ReadingLevelRiseComponent {
    @Input()
    notification: StudentNotificationModel;

    isAnimationPlaying = true;

    animationPath$ = this.store.pipe(
        select(getStudentAvatarKey),
        map(avatarKey => `/assets/student/animations/avatars/${avatarKey}/data.json`)
    );

    readingLevel$ = this.store.select(getStudentReadingLevel);

    readingLevelBadgeFilename$ = this.readingLevel$.pipe(
        map(readingLevel => `/assets/student/reading_level_badges/reading_level_${readingLevel}.svg`)
    );

    constructor(private store: Store<UserState>) {}

    animationCompleted(): void {
        this.isAnimationPlaying = false;
    }

    next(): void {
        this.store.dispatch(markAsRead({ id: this.notification.id }));
    }
}
