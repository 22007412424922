import { HasId } from 'shared/helper';

export enum NotificationType {
    Message = 0,
    LevelUp = 1,
    LevelDown = 2,
    UpdateNote = 3,
    MaxScore = 4,
    PriorKnowledge = 5,
    Expectations = 6,
}

export interface StudentNotificationModel extends HasId {
    id: number;
    subject: string;
    message: string;
    typeOfNotification: NotificationType;
    isNew: boolean;
    createdAt: Date;
}

export interface TeacherNotificationModel extends HasId {
    id: number;
    typeOfNotification: NotificationType;
    createdAt: Date;
    data: NotificationDataLevelChangeModel;
}

export interface NotificationDataLevelChangeModel {
    readingLevel?: number;
    studentId?: number;
    assignmentId?: number;
    exerciseId?: number;
}
