import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    HostListener,
    HostBinding
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import {
    CalendarSettings,
    CalendarCurrentDate,
    SelectedDates,
    CalendarSelectionDisabled
} from 'shared/models';
import { CalendarHelper } from 'shared/helper';

@Component({
    selector: 'app-cv-calendar',
    templateUrl: './cv-calendar.component.html',
    styleUrls: ['./cv-calendar.component.scss']
})
export class CvCalendarComponent implements OnInit {
    private calCache = {};
    private calWrapperVisible = false;
    private dueDate: Date;

    @Input()
    date: Date;

    @Input()
    settings: CalendarSettings;

    @Input()
    selectedDates: SelectedDates = {};

    @HostBinding('class.selection-in-past-disabled')
    get isSelectionInPastDisabled(): boolean {
        return (
            this.settings.selectionDisabled ===
            CalendarSelectionDisabled.OnlyForThePast
        );
    }

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    readonly onSelectedDay: EventEmitter<Date> = new EventEmitter();

    cal = [];
    calendarWeeks: number[];
    cvCalDate: UntypedFormGroup;
    currentDate: CalendarCurrentDate;

    // click outside of the input
    @HostListener('document:click', ['$event.target', '$event.path'])
    clickout(target: any, path: any): void {
        if (target !== undefined &&
            path !== undefined &&
            !target.classList.contains('cv-cal-date-input') &&
            !target.classList.contains('button') &&
            !path.some(x => x.classList && x.classList.contains('cal-wrapper'))
        ) {
            this.calWrapperVisible = false;
            const inputValue = this.generateInputValue();
            if (inputValue !== undefined) {
                this.cvCalDate.get('date').setValue(inputValue);
            }
        }
    }

    get canShowPrevYear(): boolean {
        const tmpYear = this.generateMonthAndYear()[1];
        if (this.currentDate && this.currentDate.year > tmpYear) {
            return true;
        }
    }

    get canShowPrevMonth(): boolean {
        const tmpMonth = this.generateMonthAndYear()[0];
        if (this.currentDate) {
            if (this.currentDate.month > tmpMonth || this.canShowPrevYear) {
                return true;
            }
        } else {
            return false;
        }
    }

    get calendarVisible(): boolean {
        if (this.settings) {
            return this.settings.showOnFocus
                ? this.calWrapperVisible && this.cal.length > 0
                : this.cal.length > 0;
        } else {
            return false;
        }
    }

    get currentMonthLabel(): string {
        return this.settings.monthsLabels[this.currentDate.month];
    }

    constructor() { }

    ngOnInit() {

        this.setUpSettings();

        this.cvCalDate = new UntypedFormGroup({
            date: new UntypedFormControl(this.settings.inputLabel)
        });

        this.setUpCurrentDate(this.date || new Date());
        this.setCurrentDateObject();

        if (!('path' in MouseEvent.prototype) || !('path' in TouchEvent.prototype)) {
            Object.defineProperty(Event.prototype, 'path', {
                get: function () {
                    const path = [];
                    let currentElem = this.target;
                    while (currentElem) {
                        path.push(currentElem);
                        currentElem = currentElem.parentElement;
                    }
                    if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
                        path.push(document);
                    }
                    if (path.indexOf(window) === -1) {
                        path.push(window);
                    }
                    return path;
                }
            });
        }
    }

    onFocus(): void {
        this.calWrapperVisible = !this.calWrapperVisible;
    }

    setCurrentDateObject(): void {
        if (this.selectedDates == null) {
            return;
        }

        const currentYear = this.selectedDates[this.currentDate.year];

        if (!currentYear) {
            this.selectedDates[this.currentDate.year] = {};
        }

        if (
            !this.selectedDates[this.currentDate.year][this.currentDate.month]
        ) {
            this.selectedDates[this.currentDate.year][
                this.currentDate.month
            ] = {};
        }

        if (
            !this.selectedDates[this.currentDate.year][this.currentDate.month]
                .days
        ) {
            this.selectedDates[this.currentDate.year][
                this.currentDate.month
            ].days = [];
        }
    }

    switchMonth(next: boolean, month?: number, year?: number): void {
        const isDecember = (m: number) => m === 11;
        const isJanuary = (m: number) => m === 0;

        if (month == null) {
            month = next
                ? isDecember(this.currentDate.month)
                    ? 0 // get January as next month
                    : this.currentDate.month + 1
                : isJanuary(this.currentDate.month)
                    ? 11 // get December as previous month
                    : this.currentDate.month - 1;
        }

        const switchToNextYear = next && isJanuary(month);
        const switchToPrevYear = !next && isDecember(month);

        year =
            year ||
            (switchToNextYear
                ? this.currentDate.year + 1
                : switchToPrevYear
                    ? this.currentDate.year - 1
                    : this.currentDate.year);

        this.currentDate.month = month;
        this.currentDate.year = year;

        this.cal = this.createCal(month, year);
        this.calendarWeeks = this.generateCalendarWeeks();

        this.setCurrentDateObject();
    }

    createCal(month: number, year: number): any[] {
        const currentDate = new Date(year, month);

        let i = 0;
        let j = 0;
        let day = 1;
        let startDay = currentDate.getDay(); // get first day of the month
        let haveDays = true;

        const daysInMonths = [
            31,
            (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28,
            31,
            30,
            31,
            30,
            31,
            31,
            30,
            31,
            30,
            31
        ];
        const calendar: any[] = [];

        if (this.calCache[year]) {
            if (this.calCache[year][month]) {
                return this.calCache[year][month];
            }
        } else {
            this.calCache[year] = {};
        }

        i = 0;
        while (haveDays) {
            calendar[i] = [];
            for (j = 0; j < 7; j++) {
                // if first week
                if (i === 0) {
                    // start counting on the first day of the month
                    if (j === startDay) {
                        calendar[i][j] = day++;
                        startDay++;
                    } else {
                        calendar[i][j] = null;
                    }
                    // if not a first week and maximal number of days in month has not been reached
                } else if (day <= daysInMonths[month]) {
                    calendar[i][j] = day++;
                } else {
                    // no more days in this month
                    calendar[i][j] = null;
                    haveDays = false;
                }
                if (day > daysInMonths[month]) {
                    haveDays = false;
                }
            }

            // there might be empty arrays created in some cases which will be cleand up here
            if (calendar[i].length === 0) {
                calendar.splice(i, 1);
            }
            i++;
        }

        return calendar;
    }

    onSelectDay(day: number): void {
        if (!day || this.selectedDates == null || this.isSelectable(day) === false) {
            return;
        }

        const currentYear = this.selectedDates[this.currentDate.year];
        const currentDateDays = currentYear[this.currentDate.month].days;
        const dayIndex = currentDateDays.indexOf(day);

        this.dueDate = CalendarHelper.createDateFromCurrentDate(
            this.currentDate,
            day
        );

        if (day) {
            if (dayIndex < 0) {
                // currentDateDays.push(day);
            }
            this.onSelectedDay.emit(this.dueDate);
        }

        this.calWrapperVisible = false;
        const inputValue = this.generateInputValue();
        if (inputValue) {
            this.cvCalDate.get('date').setValue(inputValue);
        }
    }

    isWorkDay(dayOfTheWeek: number): boolean {
        return CalendarHelper.isWorkday(dayOfTheWeek);
    }

    isToday(day: number): boolean {
        return (
            this.settings.showTodaysDate &&
            CalendarHelper.isToday(this.currentDate, day)
        );
    }

    isSelectable(day: number): boolean {
        const { selectionDisabled } = this.settings;

        switch (selectionDisabled) {
            case null:
                return true;

            case CalendarSelectionDisabled.Always:
                return false;

            case CalendarSelectionDisabled.OnlyForThePast:
                return !this.isInPast(day);

            default:
                return true;
        }
    }

    isInPast(day: number): boolean {
        const date = CalendarHelper.createDateFromCurrentDate(
            this.currentDate,
            day
        );

        return CalendarHelper.isInPast(date);
    }

    isSelected(day: number): boolean {
        if (this.selectedDates == null) {
            return;
        }

        const currentYear = this.selectedDates[this.currentDate.year];

        if (currentYear == null) {
            return false;
        }

        const currentMonth = currentYear[this.currentDate.month];

        if (currentMonth == null) {
            return false;
        }

        const currentDays = currentMonth.days;

        if (currentDays == null) {
            return false;
        }

        return currentDays.indexOf(day) > -1;
    }

    private generateInputValue(): string {
        const label = this.settings.inputLabel;
        const inputValue = this.dueDate
            ? `${label}: ${this.getHumanRedableDate(this.dueDate)}`
            : `${label}`;
        return inputValue;
    }

    private setUpCurrentDate(date: Date): void {
        // const now = new Date();
        const now = date;
        this.currentDate = CalendarHelper.createCurrentDateFromDate(now);

        const { year, month } = this.currentDate;
        this.switchMonth(null, month, year);

        this.dueDate = now;

        const inputValue = this.generateInputValue();
        if (inputValue !== undefined) {
            this.cvCalDate.get('date').setValue(inputValue);
        }
    }

    private setUpSettings(): void {
        // merge settings with default
        this.settings = {
            ...CalendarHelper.settings.default,
            ...this.settings
        };
    }

    private generateMonthAndYear(): number[] {
        const now = new Date();
        return [now.getMonth(), now.getFullYear()];
    }

    // TODO use momentjs for weeknumber calculation
    private calendarWeek(year: number, month: number, day: number): number {
        // based on http://www.1ngo.de/web/kalenderwoche.html
        let date = new Date();
        if (!day) {
            year = date.getFullYear();
            if (1900 > year) {
                year += 1900;
            }
            month = date.getMonth();
            day = date.getDate();
        } else {
            month--;
        }
        date = new Date(year, month, day, 0, 0, 1);
        let tmpDay = date.getDay();
        if (tmpDay === 0) {
            tmpDay = 7;
        }
        const d = new Date(2004, 0, 1).getTimezoneOffset();
        const summerTime =
            (Date.UTC(year, month, day, 0, d, 1) - Number(date)) / 3600000;
        date.setTime(
            Number(date) + summerTime * 3600000 - (tmpDay - 1) * 86400000
        );
        let tmpYear = date.getFullYear();
        if (1900 > tmpYear) {
            tmpYear += 1900;
        }
        let calendarWeek = 1;
        if (new Date(tmpYear, 11, 29) > date) {
            let start: number | any = new Date(tmpYear, 0, 1);
            start = new Date(Number(start) + 86400000 * (8 - start.getDay()));
            if (start.getDate() > 4) {
                start.setTime(Number(start) - 604800000);
            }
            calendarWeek = Math.ceil((date.getTime() - start) / 604800000);
        }
        return calendarWeek;
    }

    private getHumanRedableDate(date: Date): string {
        const month = date.getMonth() + 1;
        const monthString = month < 10 ? `0${month}` : month;
        const dateString = `${date.getDate()}.${monthString}.${date.getFullYear()}`;
        return dateString;
    }

    private generateCalendarWeeks(): number[] {
        const callendarWeeks = this.cal.map(row => {
            const myRow = [...row];
            // splice weekends 0 index is sunday, 6 is saturday
            myRow.splice(0, 1);
            myRow.splice(5, 1);
            const first = myRow.filter(el => !!el)[0];
            if (first) {
                return this.calendarWeek(
                    this.currentDate.year,
                    this.currentDate.month + 1,
                    first
                );
            } else {
                return null;
            }
        });

        return callendarWeeks;
    }
}
