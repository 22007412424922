export enum CvPopUpSizes {
    'S' = 'cv-popup-size-s',
    'M' = 'cv-popup-size-m',
    'L' = 'cv-popup-size-l',
    'CUSTOM' = 'cv-popup-size-custom'
}

export interface RenderedComponentInputConfig {
    propName: string;
    data: any;
}

export interface RenderedComponentConfig {
    componentName: string;
}

export interface CvPopupButtons {
    confirm: string;
    cancel: string;
}

export interface CvPopupConfirmEvent {
    channel: string;
    data?: any;
    renderedComponentOutputData: any;
}

export interface CvPopUpSettings {
    channel: string;
    renderedComponentConfig?: RenderedComponentConfig;
    size?: CvPopUpSizes;
    title?: string;
    autoClose?: number;
    closable?: boolean;
    buttons?: CvPopupButtons;
    data?: any;
    overflow?: string;
    transparentBackground?: boolean;
    padding?: boolean;
}
