import { ActionReducer, MetaReducer, INIT } from '@ngrx/store';
import { logoutSuccess } from './user';

export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        if (action != null && action.type === logoutSuccess.type) {
            return reducer(undefined, { type: INIT });
        }

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = [ resetState ];
