import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 547_Chevron_unten_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-chevron',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M19.843,48.189a1.418,1.418,0,0,1-.965-2.456L37.6,28.346,18.878,10.96a1.418,1.418,0,0,1,1.93-2.078L40.65,27.307a1.42,1.42,0,0,1,0,2.078L20.808,47.811A1.414,1.414,0,0,1,19.843,48.189Z" />
        </svg>
    `
})
export class SvgIconChevronComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'chevron';
    }
}
