import { Component, Input } from '@angular/core';

import { NavItemModel } from 'shared/models';

@Component({
    selector: 'app-cv-filter',
    templateUrl: './cv-filter.component.html',
    styleUrls: ['./cv-filter.component.scss']
})
export class CvFilterComponent {
    @Input()
    items: NavItemModel[] = [];

    constructor() {}

    createOptions(item: NavItemModel): any {
        if (item.routing.isExact === true) {
            return { exact: true };
        }

        return {};
    }
}
