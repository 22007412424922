import { Component, forwardRef, Inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { CvStepperComponent } from '../cv-stepper.component';

@Component({
  selector: 'app-cv-step',
  templateUrl: './cv-step.component.html'
})
export class CvStepComponent {

  /** Template for step content. */
  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;

  @Input()
  stepName: string;

  @Input()
  get editable(): boolean {
    return this._editable;
  }
  set editable(value: boolean) {
    this._editable = !!value;
  }
  private _editable = true;

  @Input()
  get completed(): boolean {
    return this._completed;
  }
  set completed(value: boolean) {
    this._completed = !!value;
  }
  private _completed: boolean = true;

  interacted = false;

  constructor(@Inject(forwardRef(() => CvStepperComponent)) public _stepper: CvStepperComponent) { }

  markAsInteracted(): void {
    this.interacted = true;
  }
}
