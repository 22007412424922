import { createAction, props } from '@ngrx/store';
import { LicenceModel } from 'shared/models';

export const enterPromoCode = createAction('[Promotion Code Comp] enter promotion code',
    props<{code: string}>()
);

export const enterPromoCodeSuccess = createAction('[Promotion API] enter promotion code success',
    props<{licence: LicenceModel}>()
);

export const enterPromoCodeFail = createAction('[Promotion API] enter promotion code fail',
    props<{error: any}>()
);
