export const DEFAULT_DIALOG_CONFIG = {
    /**
     * Removes inline style "max-width" property
     */
    maxWidth: '',
    maxHeight: '',

    /**
     * Prevents the button to be focused when the popup is opened
     */
    autoFocus: false,

    /**
     * can be extended in local config, e.g.
     * panelClass: [...defaultDialogConfig.panelClass, 'modal-alert'],
     */
    panelClass: ['cv-modal'],

    hasBackdrop: true
}
