import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';

import { RouterStateSerializer } from '@ngrx/router-store';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
    url: string;
    queryParams: any;
    params: any;
    title: string;
    data: any;
}

@Injectable()
export class CustomRouterStateSerializer
    implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        const { url } = routerState;
        const { queryParamMap } = routerState.root;

        let title: string;
        let params = {};
        let data = {};

        let route = routerState.root;
        while (route !== null) {
            if (route.data['title'] !== undefined) {
                title = route.data['title'];
            }

            params = { ...params, ...route.params };
            data = { ...data, ...route.data };

            route = route.firstChild;
        }

        // delete title field from data
        delete data['title'];

        const queryParams = {};
        for (const key of queryParamMap.keys) {
            queryParams[key] = queryParamMap.get(key);
        }

        return { url, queryParams, params, title, data };
    }
}
