export enum LicenceType {
    Test = 1,
    Demo = 2,
    Paid = 3
}

export enum LicenceSubType {
  Default = 0,
  School = 1,
  Class = 2,
  Private = 3
}

export enum LicenceOwnerRole {
    Teacher = 'Teacher',
    PrivateUser = 'PrivateUser'
}

export interface LicenceModel {
    type: LicenceType;
    subType: LicenceSubType;
    validUntil: Date;
    usageProductId: string;
    salesProductId: string;
    isExpired: boolean;
    isActive: boolean;
    resetLicenceAt: Date;
    ownerRole: LicenceOwnerRole
}
