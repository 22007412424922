import { createReducer, on, Action } from '@ngrx/store';

import * as fromPollingActions from 'store/polling/polling.actions';

export interface PollingState {
    pollingAction: Action;
    isPollingActive: boolean;
    pollingErrorCount: number;
    isErrorModeActive: boolean;
}

export const initialState: PollingState = {
    pollingAction: null,
    isPollingActive: false,
    pollingErrorCount: 0,
    isErrorModeActive: false
};

const _pollingReducer = createReducer(
    initialState,
    on(fromPollingActions.startPolling, (state, action) => {
        const newState = {
            ...state,
            pollingAction: action.pollingAction,
            isPollingActive: true
        };

        return newState;
    }),
    on(fromPollingActions.failedPolling, (state, action) => {
        const newState = {
            ...state,

            pollingErrorCount: state.pollingErrorCount + 1
        };

        return newState;
    }),
    on(fromPollingActions.stopPolling, (state, action) => {
        const newState = {
            ...state,
            isPollingActive: false
        };

        return newState;
    }),
    on(fromPollingActions.switchToErrorMode, (state, action) => {
        const newState = {
            ...state,

            isErrorModeActive: true
        };

        return newState;
    }),
    on(fromPollingActions.restartPolling, (state, action) => {
        const newState = {
            ...state,
            // add polling action
            isPollingActive: false, // stop polling and restart later (with help of effect)
        };

        return newState;
    }),
    on(fromPollingActions.resetErrors, (state, action) => {
        const newState = {
            ...state,

            pollingErrorCount: 0,
            isErrorModeActive: false
        };

        return newState;
    })
);

export function pollingReducer(state, action) {
    return _pollingReducer(state, action);
}
