import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getHasTutorRolePrivateUser } from 'store/user';

@Injectable({providedIn: 'root'})
export class RoleDisplayNameService {
    private readonly hasRolePrivateUser$ = this.store.pipe(select(getHasTutorRolePrivateUser));

    private readonly privateUserDisplayNames: Record<string, string> = {
        'einzelnen Schüler/-innen': 'einzelnen Kindern', // must come first (dative case check)!!!1!!
        'Ihren Schüler/-innen': 'Ihren Kindern',
        'einer Schülerin oder eines Schülers': 'eines Kindes',
        'Schüler/-innen': 'Kinder',
        'Schülerinnen und Schüler': 'Kinder',
        'Schüler und Schülerinnen': 'Kinder',
        'Schüler/-in': 'Kind',
    };

    constructor (private store: Store) {}

    getWordsBasedOnRoleContext(): Observable<Record<string, string>> {
        return this.hasRolePrivateUser$.pipe(
            map(hasRolePrivateCustomer => hasRolePrivateCustomer
                ? this.privateUserDisplayNames
                : {}
            )
        );
    }
}
