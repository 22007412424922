import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 001_Audio_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-audio',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path
        d="M7.088,36.851h6.589L27.462,47.878a1.419,1.419,0,0,0,.886.311h1.417a1.418,1.418,0,0,0,1.417-1.418V9.921A1.418,1.418,0,0,0,29.765,8.5H28.348a1.424,1.424,0,0,0-.886.31L13.677,19.842H7.088A1.418,1.418,0,0,0,5.67,21.26V35.433A1.418,1.418,0,0,0,7.088,36.851Zm21.26-25.115V44.957l-12.757-10.2V21.941ZM8.505,22.677h4.252V34.016H8.505Zm32.6,5.669a8.442,8.442,0,0,0-3.4-6.8,1.417,1.417,0,1,1,1.7-2.266,11.34,11.34,0,0,1,0,18.141,1.418,1.418,0,1,1-1.7-2.267A8.44,8.44,0,0,0,41.1,28.346Zm.85-12.473a1.418,1.418,0,1,1,1.7-2.267,18.427,18.427,0,0,1,0,29.48,1.417,1.417,0,1,1-1.7-2.266,15.592,15.592,0,0,0,0-24.947Z" />
    </svg>
  `
})
export class SvgIconAudioComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'audio';
    }
}
