import { createAction, props } from '@ngrx/store';
import { CreateMessageModel, NotificationType, MessageModel, Sort } from 'shared/models';

export const postMessage = createAction(
    '[Messages] post teacher message',
    props<{ message: CreateMessageModel, userName: string, notificationType: NotificationType }>()
);

export const postMessageSuccess = createAction(
    '[Messages] post teacher message success',
    props<{ notificationId: number, userName: string, notificationType: NotificationType }>()
);

export const postMessageError = createAction(
    '[Messages] post teacher message fail',
    props<{ error: any }>()
);
