import { TrackingVerbs, TrackingObjects } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherSavedStudentNoteFeature extends BaseTrackingEvent{
    constructor(featureTitle: string = '', featureId: number = 0) {
        super({
            eventId: '13dfb732-8a88-4ba9-bb55-cc9d7b7b03a1',
            eventVerb: TrackingVerbs.USED,
            eventObject: TrackingObjects.FEATURE,
            eventPayload: {
                featureTitle: featureTitle,
                featureId: featureId
            },
        });
    }
}
