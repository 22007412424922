import {
    Component, HostBinding, Input, OnChanges, SimpleChanges
} from '@angular/core';

import { Store } from '@ngrx/store';

import { Router } from '@angular/router';
import { Colors } from 'shared/helper';
import { StudentModel } from 'shared/models';
import { TeacherService } from 'shared/services';
import { StudentAvatarSize } from 'shared/student-avatar';
import { changeStudent } from 'store/students/students.actions';

@Component({
    selector: 'app-student-name-link',
    templateUrl: './student-name-link.component.html',
    styleUrls: ['./student-name-link.component.scss']
})
export class StudentNameLinkComponent implements  OnChanges {
    readonly Colors = Colors;
    readonly AvatarSizes = StudentAvatarSize;

    @Input()
    student: StudentModel;

    @Input()
    @HostBinding('class.is-editable')
    isEditable = false;

    @Input() link = '';

    name: string;

    constructor(private store: Store, private teacherService: TeacherService, private router: Router) {}

    ngOnChanges(changes: SimpleChanges): void {
        const studentChange = changes.student;

        if (studentChange != null) {
            const student: StudentModel = studentChange.currentValue;

            this.name = student == null ? null : student.userName;
        }
    }

    changeName(newName: string): void {
        this.store.dispatch(
            changeStudent({studentId: this.student.id, changeFields: { userName: newName }})
        );
    }

    redirectToStudentDetails(student: StudentModel): Array<string | number> {
        return this.teacherService.getStudentDetailRoute(student);
    }
}
