import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { VERSION } from 'environments/version';
import * as moment from 'moment';
import { of } from 'rxjs';
import { ConsentService } from 'shared/consent/consent.service';
import { AccountType, LicenceOwnerRole } from 'shared/models';
import { WindowService } from 'shared/services/window.service';
import { BaseTrackingEvent } from 'shared/tracking/models/base-tracking-event.model';
import { StudentProfileState, TeacherProfileState } from 'store/user';

interface TrackingHead {
    created: string;
    appId: string;
    appVersion: string;
    appStage: string;
    usageProductId: string;
    salesProductId: string;
    userId: string;
    location: string;
    requestId: string;
    userAgent: string;
}

interface TrackingUser {
    userRole: string;
    userSessionId: string;
    userCohortId: string;
    userClient: string;
    userCountry: string;
}

interface TrackingGroup {
    groupId: number;
    groupTitle: string;
}

class UserSession {
    id: string;
    dateTime: string;

    constructor(id : string) {
        this.dateTime = moment().format();
        this.id = id;
    }
}

export enum TrackingSource {
    SchoolAdminDashboard = 'school-admin dashboard',
    MySchoolClassPage = 'my-school page',
    StudentDetailPageTeacher = 'student detail page teacher'
}

@Injectable({
    providedIn: 'root',
})
export class TrackingService {
    private readonly shouldSendEvents: boolean = EnvironmentService.cvTracking.enable;
    private readonly apiUrl: string = EnvironmentService.cvTracking.url;
    private readonly apiKey: string = EnvironmentService.cvTracking.apiKey;
    private readonly version: string = VERSION.raw;
    private readonly stage: string = EnvironmentService.stage;
    private readonly appId: string = EnvironmentService.cvTracking.appId;
    private readonly userSessionDurationInMinutes: number = EnvironmentService.cvTracking.userSessionDurationInMinutes;

    public static userSessionLabel: string = 'userSession';

    constructor(
        private http: HttpClient,
        private windowService: WindowService,
        private consentService: ConsentService
    ) {}

    public track(
        event: BaseTrackingEvent,
        accountType: AccountType,
        role: LicenceOwnerRole,
        profile: StudentProfileState | TeacherProfileState,
        schoolClassId: number,
        schoolId: string
    ) {
        if (!this.shouldSendEvents) {
            return of();
        }

        const trackingEvent = {
            head: this.getTrackingHead(profile, event.sendExternalUserId),
            user: this.getTrackingUser(accountType, this.getUserSessionId(), schoolId),
            event: this.getTrackingEvent(event),
            group: this.getTrackingGroup(schoolClassId, role)
        };

        return this.http.post<any>(
            this.apiUrl,
            {
                Record: {
                    Data: this.windowService.encodeString(
                        JSON.stringify(trackingEvent)
                    ),
                },
            },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'x-api-key': this.apiKey,
                }),
            }
        );
    }

    private getUserSessionId(): string {
        let oldUserSession = localStorage.getItem(TrackingService.userSessionLabel);

        let userSessionId: string;
        if (!oldUserSession) {
            userSessionId = this.createUUID();
        } else {
            const us: UserSession = JSON.parse(oldUserSession);
            const sessionDateTime = moment(us.dateTime);
            userSessionId = this.shouldCreateNewSessionId(sessionDateTime) ? this.createUUID() : us.id;
        }

         localStorage.setItem(TrackingService.userSessionLabel, JSON.stringify(new  UserSession(userSessionId)));

         return userSessionId;
    }

    private shouldCreateNewSessionId(sessionDateTime: moment.Moment) : boolean {
        const isUserSessionExpired = sessionDateTime.add(this.userSessionDurationInMinutes, 'minutes') < moment();

        return !sessionDateTime.isValid() || isUserSessionExpired;
    }

    private getTrackingEvent(event: BaseTrackingEvent) {
        return {
            eventId: event.eventId,
            eventObject: event.eventObject,
            eventPayload: event.eventPayload,
            eventSubject: event.eventSubject,
            eventVerb: event.eventVerb,
        };
    }

    private getTrackingHead(
        profile: StudentProfileState | TeacherProfileState,
        sendExternalUserId: boolean
    ): TrackingHead {
        return {
            created: this.getUTCWithTimezoneOffset(),
            appId: this.appId,
            appVersion: this.version,
            appStage: this.stage,
            usageProductId: profile?.licence?.usageProductId || 'unknown',
            salesProductId: profile?.licence?.salesProductId || 'unknown',
            userId: sendExternalUserId || this.isPrivateUserAndConsentGiven(profile) ? profile.externalUserId : '',
            location: this.windowService.getWindowLocationHref(),
            requestId: this.createUUID(),
            userAgent: this.windowService.getUserAgent(),
        };
    }

    private isPrivateUserAndConsentGiven(profile: StudentProfileState | TeacherProfileState): boolean {
        return this.isPrivateUser(profile) && this.consentService.isConsentGiven();
    }


    private isPrivateUser(profile: StudentProfileState | TeacherProfileState) {
        return profile?.licence?.ownerRole === LicenceOwnerRole.PrivateUser;
    }

    private getTrackingUser(accountType: AccountType, userSessionId: string, schoolId: string): TrackingUser {
        return {
            userRole: this.getAccountTypeString(accountType),
            userSessionId: userSessionId,
            userCohortId: '',
            userClient: schoolId,
            userCountry: ''
        };
    }

    private getTrackingGroup(schoolClassId: number, role: LicenceOwnerRole): TrackingGroup {
        return {
            groupId: schoolClassId || 0,
            groupTitle: this.setGroupTitle(role)
        };
    }

    private setGroupTitle(role: LicenceOwnerRole): string {
        if (role === LicenceOwnerRole.PrivateUser) {
            return 'Reading group';
        }
        else if (role === LicenceOwnerRole.Teacher) {
            return 'Class';
        }

        return '';
    }

    private getAccountTypeString(accountType: AccountType): string {
        if(accountType === null) return 'unknown';

        return accountType === AccountType.Teacher ? 'Z01' : 'S01';
    }


    private getUTCWithTimezoneOffset() {
        const formattedDate =
            moment.utc().format('YYYY-MM-DD[T]HH:mm:ss[ ]') +
            moment().format('ZZ');
        return formattedDate;
    }

    private createUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }
}
