import { User } from 'oidc-client-ts';
import { AccountType, defaultMonsterKey, StudentModel, TeacherModel } from 'shared/models';
import * as demoLoginActions from './demo-login.actions';
import * as userActions from 'store/user/user.actions';
import * as studentActions from 'store/student/student.actions';
import * as tutorActions from 'store/user/tutor/tutor.actions';
import * as learnerActions from 'store/user/learner/learner.actions';
import { enterPromoCode, enterPromoCodeFail, enterPromoCodeSuccess } from './licence.actions';
import { UserRoles } from 'app/core/enums';
import { CustomErrorCodes } from 'app/core/enums/custom-error-codes';
import { createReducer, on } from '@ngrx/store';
import * as tutorOnboardingActions from 'store/tutor-onboarding/tutor-onboarding.actions';

export interface UserState {
    isAuthorized: boolean;
    accountType: AccountType;
    roles: UserRoles[];
    accessToken: string;
    oidcUser?: User;
    teacher?: TeacherProfileState;
    student?: StudentProfileState;
    loaded: boolean;
    loading: boolean;
    error?: {
        errorCode?: CustomErrorCodes,
        error?: any
    };
}

export interface StudentProfileState extends StudentModel {
    loading: boolean;
    loaded: boolean;
}

export interface TeacherProfileState extends TeacherModel {
    loading: boolean;
    loaded: boolean;
}

export const initialLearnerProfileState = {
    loading: false,
    loaded: false,
    id: 0,
    userName: '',
    readingLevel: 0,
    readBooks: 0,
    openedBooks: 0,
    totalScore: 0,
    maxScore: 0,
    exerciseCount: 0,
    doneExerciseCount: 0,
    hasLicence: false,
    avatarKey: defaultMonsterKey,
    avatarUri: '',
    tutorLeseoName: '',
    hideAudio: false,
    hideSyllableColoring: false,
    schoolClassId: 0,
    externalUserId: '',
    accountType: AccountType.Student,
    accountOrigin: '',
    schoolId: ''
};

export const initialState: UserState = {
    isAuthorized: false,
    accountType: null,
    roles: null,
    accessToken: null,
    oidcUser: null,
    teacher: null,
    student: initialLearnerProfileState,
    loaded: false,
    loading: false,
    error: null
};

export const userReducer = createReducer(
    initialState,
    on(userActions.loginStudent, learnerActions.validateLearnerPasscode, (state, { passcode }) => {
        return {
            ...state,
            loading: true,
            error: null
        };
    }),
    on(userActions.loginStudentSuccess, learnerActions.validateLearnerPasscodeSuccess, (state, action) => {
        if (state.error) {
            return {
                ...state,
                loading: false,
            };
        }

        return state;
    }),
    on(
        userActions.loginStudentFail,
        demoLoginActions.loginFail,
        learnerActions.validateLearnerPasscodeFail, (state, { errorCode }) => {

            return {
                ...state,
                loading: false,
                error: {
                    errorCode: errorCode || CustomErrorCodes.UnknownError
                }
            };
        }),
    on(learnerActions.resetLearnerPasscodeError, demoLoginActions.login, (state) => {
        return {
            ...state,
            error: null
        };
    }),
    on(userActions.setUser, (state, action) => {
        const newState = {
            ...state,

            isAuthorized: true,
            accountType: action.accountType,
            roles: action.roles,
            accessToken: action.accessToken,

            // teacher only
            oidcUser: action.oidcUser,

            // don't override profiles if SetUser was triggered by silent-refresh (prevent reloading of profile and FillStore)
            // todo: refactore...
            teacher: action.isTriggeredBySilentRefresh ? state.teacher : undefined,

            loaded: true,
            error: null
        };

        return newState;
    }),
    on(userActions.clearUser, (state, action) => {
        return { ...initialState, loaded: true };
    }),
    on(userActions.loadProfile, (state, action) => {
        if (action.accountType === AccountType.Teacher) {
            // todo: create proper default
            const teacher = {} as TeacherProfileState;
            teacher.loading = true;

            return {
                ...state,
                teacher
            };
        }

        if (action.accountType === AccountType.Student) {
            return {
                ...state,
                student: {
                    ...state.student,
                    loading: true,
                }
            };
        }

        return state;
    }),
    on(userActions.loadProfileSuccess, (state, action) => {
        if (action.accountType === AccountType.Teacher) {
            return {
                ...state,
                loaded: true,
                accountType: action.accountType,
                teacher: {
                    ...<TeacherModel>action.profile,
                    loaded: true,
                    loading: false
                }
            };
        }

        if (action.accountType === AccountType.Student) {
            return {
                ...state,
                loaded: true,
                accountType: action.accountType,
                student: {
                    ...<StudentModel>action.profile,
                    loaded: true,
                    loading: false
                }
            };
        }

        return state;
    }),
    on(userActions.loadProfileFail, (state, action) => {
        if (action.accountType === AccountType.Teacher) {
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    loading: false,
                    loaded: false
                },
                error: {
                    error: action.error,
                    errorCode: action.error?.error?.errorCode || CustomErrorCodes.UnknownError // todo this should be done in the action/effect
                }
            };
        }

        if (action.accountType === AccountType.Student) {
            return {
                ...state,
                student: {
                    ...state.student,
                    loading: false,
                    loaded: false
                },
                error: action.error
            };
        }

        return state;
    }),
    on(userActions.fetchProfileUpdatesFail, (state, action) => {
        return {
            ...state,
            error: action.error
        };
    }),
    on(studentActions.updateMonster, (state, action) => {
        return {
            ...state,
            student: {
                ...state.student,
                loading: true
            }
        };
    }),
    on(studentActions.setMonsterSuccess, (state, action) => {
        return {
            ...state,
            student: {
                ...action.student,
                loading: false,
                loaded: true
            }
        };
    }),
    on(studentActions.setMonsterFail, (state, action) => {
        return {
            ...state,
            student: {
                ...state.student,
                loading: false
            },
            error: action.error
        };
    }),
    on(enterPromoCode, (state, action) => {
        if (state.accessToken && state.accountType === AccountType.Teacher) {
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    loading: true
                }
            };
        }

        return state;
    }),
    on(
        userActions.activateLicenceSuccess,
        enterPromoCodeSuccess,
        (state, action) => {
            if (state.accessToken && state.accountType === AccountType.Teacher) {
                return {
                    ...state,
                    teacher: {
                        ...state.teacher,
                        licence: action.licence,
                        loading: false
                    }
                };
            }

            return state;
        }),
    on(enterPromoCodeFail, (state, action) => {
        if (state.accessToken && state.accountType === AccountType.Teacher) {
            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    loading: false
                },
                error: action.error
            };
        }

        return state;
    }),
    on(userActions.registerTeacher, (state, action) => {
        return {
            ...state,
            teacher: {
                ...state.teacher,
                loaded: false,
                loading: true
            }
        };
    }),
    on(userActions.registerTeacherSuccess, (state, action) => {
        return {
            ...state,
            teacher: {
                ...action.teacher.profile as TeacherModel,
                loaded: true,
                loading: false
            }
        };
    }),
    on(userActions.registerTeacherFail, (state, action) => {
        return {
            ...state,
            teacher: {
                ...state.teacher,
                loading: false
            },
            error: action.error,
        };
    }),
    on(userActions.logoutSuccess, (state, action) => {
        return {
            ...initialState
        };
    }),
    on(tutorActions.updateLeseoName, (state, action) => {
        return {
            ...state,
            teacher: {
                ...state.teacher,
                loading: true
            }
        };
    }),
    on(tutorActions.updateLeseoNameSuccess, tutorOnboardingActions.addLeseoNameSuccess, (state, action) => {
        return {
            ...state,
            teacher: {
                ...state.teacher,
                leseoName: action.leseoName,
                loading: false
            }
        };
    }),
    on(tutorActions.updateLeseoNameFail, (state, action) => {
        return {
            ...state,
            teacher: {
                ...state.teacher,
                leseoName: action.oldLeseoName,
                loading: false
            },
            error: action.error,
        };
    }),
    on(tutorOnboardingActions.completeOnboardingSuccess, (state) => {
        return {
            ...state,
            teacher: {
                ...state.teacher,
                isOnboarded: true
            },
        };
    }),

);
