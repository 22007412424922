import { registerLocaleData, ViewportScroller } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Router, Scroll } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { DefaultRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from 'app/shared';
import { filter } from 'rxjs/operators';
import { CustomRouterStateSerializer } from 'shared/custom-router-state-serializer';
import { AssignmentsEffects } from 'store/assignments';
import { BooksEffects } from 'store/books';
import { ConversationsEffects } from 'store/conversations/conversations.effects';
import { LayoutEffects } from 'store/layout';
import { PopUpEffects } from 'store/layout/popup.effects';
import { MessagesEffects } from 'store/messages/messages.effects';
import { metaReducers } from 'store/meta.reducer';
import { NotificationsEffects } from 'store/notifications/notifications.effects';
import { rootReducers } from 'store/root.reducer';
import { RouterEffects } from 'store/router/router.effects';
import { SchoolClassesEffects } from 'store/school-classes';
import { SchoolEffects } from 'store/school/school.effects';
import { StudentEffects } from 'store/student';
import { StudentsEffects } from 'store/students/students.effects';
import { TrackingEffects } from 'store/tracking/tracking.effects';
import { StartUpEffects, UserEffects, TutorEffects } from 'store/user';
import { LicenceEffects } from 'store/user/licence.effects';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PollingEffects } from './store/polling/polling.effects';
import { DemoLoginEffects } from './store/user/demo-login.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoleDisplayNameModule } from './teacher/role-display-name-translation/role-display-name.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG } from 'environments/app-config';
import { LearnerEffects } from 'store/user/learner/learner.effects';
import { ConsentEffects } from 'store/consent/consent.effects';
import { TutorOnboardingEffects } from 'store/tutor-onboarding';
import { BookViewerEffect } from 'store/book-viewer';
import { EnvironmentService } from './core/services/environment.service';

const routerStateProvider = {
    provide: RouterStateSerializer,
    useClass: CustomRouterStateSerializer
};

registerLocaleData(localeDe);

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        SharedModule.forRoot(),
        AppRoutingModule,
        StoreModule.forRoot(rootReducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),
        MatDialogModule,
        StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
        EffectsModule.forRoot([
            AssignmentsEffects,
            BooksEffects,
            ConversationsEffects,
            DemoLoginEffects,
            LayoutEffects,
            LearnerEffects,
            LicenceEffects,
            MessagesEffects,
            NotificationsEffects,
            PollingEffects,
            PopUpEffects,
            RouterEffects,
            SchoolClassesEffects,
            SchoolEffects,
            StartUpEffects,
            StudentEffects,
            StudentsEffects,
            UserEffects,
            TrackingEffects,
            TutorEffects,
            TutorOnboardingEffects,
            ConsentEffects,
            BookViewerEffect
        ]),
        RoleDisplayNameModule,
        EnvironmentService.isProduction ? [] : StoreDevtoolsModule.instrument()
    ],
    declarations: [AppComponent],
    providers: [
        routerStateProvider,
        { provide: LOCALE_ID, useValue: 'de' },
        Title,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG },
        //{ provide: 'BOOK_VIEWER_CONFIG', useValue: environment }
        { provide: 'BOOK_VIEWER_CONFIG', useValue: (window as any).environmentConfig ?? environment }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(router: Router, viewportScroller: ViewportScroller) {
        router.events
            .pipe(filter(e => e instanceof Scroll))
            .subscribe((e: Scroll) => {
                if (e.position) {
                    // backward navigation
                    viewportScroller.scrollToPosition(e.position);
                } else if (e.anchor) {
                    // anchor navigation
                    viewportScroller.scrollToAnchor(e.anchor);
                } else {
                    // forward navigation
                    viewportScroller.scrollToPosition([0, 0]);
                }
            });
    }
}
