import { createAction, props } from '@ngrx/store';
import { UserRoles } from 'app/core/enums';
import { User } from 'oidc-client-ts';

export const startUp = createAction('[User] Start up');
export const startUpAppComp = createAction('[App Comp] Start up');
export const startUpProfileErrorComp = createAction('[Profile Error Comp] Start up');

export const startUpFoundExistingSession = createAction('[Startup Effects] found exisiting user session', props<{ accountType, accessToken, isTriggeredBySilentRefresh, oidcUser, roles: UserRoles[]}>());

export const startUpNoExisitingSession = createAction('[Startup Effects] no existing user session found');
export const startUpError = createAction('[Startup Effects] unknown error', props<{err: Error}>());

export const loginCallBack = createAction('[Login Callback comp] login callback oidc user');

export const loginCallbackSuccess = createAction('[Startup effects] Oidc Login Callback Success', props<{user: User}>());

export const loginCallBackFail = createAction('[User] Login Callback Fail', props<{err: any}>());

export const sessionExpired = createAction('[OIDC Interceptor] Session expired');

