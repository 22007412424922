import { AuthService } from '../../shared/services/auth.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as DemoLoginActions from './demo-login.actions';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { setUser } from './user.actions';
import { Router } from '@angular/router';
import { AccountType } from 'shared/models';
import { User } from 'oidc-client-ts';
import { UserRoles } from 'app/core/enums';
import { CustomErrorCodes } from 'app/core/enums/custom-error-codes';
import { HttpStatusCode } from '@angular/common/http';

@Injectable()
export class DemoLoginEffects {

    constructor(private actions$: Actions, private authService: AuthService, private router: Router) {
    }

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemoLoginActions.login),
            switchMap((action) =>
                this.authService.demoUserLogin(action.username, action.password).pipe(
                    map(accessToken => DemoLoginActions.loginSuccess({ token: accessToken })),
                    catchError((err: unknown) => of(DemoLoginActions.loginFail({ errorCode: DemoLoginEffects.getCustomErrorCodeFromError(err) })))
                )
            )
        )
    );

    loginDemoSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemoLoginActions.loginSuccess),
            map(
                ({ token }) => {
                    const decodedJwt = this.authService.decodeJwt(token);

                    const signinResponse = {
                        access_token: token,
                        expires_at: decodedJwt.exp,
                        id_token: token,
                        token_type: 'access_token',
                    };
                    const oidcUser = new User(signinResponse as any);
                    return setUser({accountType: AccountType.Teacher, accessToken: token, isTriggeredBySilentRefresh: false, oidcUser, roles: [UserRoles.Teacher]});
                }
            ),
            tap(() => this.router.navigate(['lehrer']))
        )
    );

    static getCustomErrorCodeFromError(error: any): CustomErrorCodes {
        if ((error as any)?.status === HttpStatusCode.Unauthorized) {
            return CustomErrorCodes.InvalidDemoAccount;
        } else {
            return CustomErrorCodes.UnknownError;
        }
    }
}
