import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CalendarHelper, Colors, LicenceHelper, LicenceName } from 'shared/helper';
import { AccountType, LicenceModel, TeacherModel } from 'shared/models';
import { UserService } from 'shared/services';
import { TeacherClickedBanner } from 'shared/tracking/models/teacher-clicked-banner.model';
import { openActivateLicenceCodePopup } from 'store/layout/popup.actions';
import { getAccountType, getProfile, UserState } from 'store/user';
import * as TrackingActions from '../../../store/tracking/tracking.actions';

@Component({
    selector: 'app-licence-banner',
    templateUrl: './licence-banner.component.html',
    styleUrls: ['./licence-banner.component.scss']
})
export class LicenceBannerComponent implements OnInit, OnDestroy {

    private readonly daysFirstInfo = 60;
    private readonly daysSecondInfo = 30;
    private readonly daysThirdInfo = 10;
    private readonly daysWarning = 5;
    private unsubscribe$ = new Subject<void>();

    readonly colors = Colors;

    public isWarning: boolean;
    public remainingDays: number = this.daysThirdInfo;

    public licenceName: LicenceName;
    public licenceButtonUrl = LicenceHelper.getLicenceInfoUrl();

    @HostBinding('class.isVisible') isBannerVisible = false;

    constructor(private store: Store<UserState>, private userService: UserService) { }

    ngOnInit(): void {
        this.store.pipe(
            select(getProfile),
            withLatestFrom(this.store.select(getAccountType)),
            filter(([profile, accountType]) => accountType === AccountType.Teacher && profile.loaded && !!profile.licence),
            takeUntil(this.unsubscribe$),
        )
        .subscribe(([profile]) => {
            const licence = profile.licence;
            this.remainingDays = CalendarHelper.daysDiff(licence.validUntil);
            this.licenceName = LicenceHelper.getLicenceName(licence.type);
            const isOutsideLicenceBannerPeriod = this.remainingDays > this.daysFirstInfo;

            // if licence is expired or new licence was activated, hide banner
            if (licence.isExpired === true || isOutsideLicenceBannerPeriod || !(profile as TeacherModel)?.isOnboarded)  {
                this.isBannerVisible = false;
                this.isWarning = false;
                return;
            }

            // licence is not expired
            this.isBannerVisible = this.showBanner(licence, this.remainingDays);
            this.isWarning = this.remainingDays <= this.daysWarning;
        });
    }

    private showBanner(licence: LicenceModel, remainingDays: number): boolean {
        const viewedAt = this.userService.getLicenceBannerViewedAtToken();
        const diffViewedAt = CalendarHelper.daysDiff(licence.validUntil, viewedAt);

        const isInFirstPeriod = remainingDays <= this.daysFirstInfo && remainingDays > this.daysSecondInfo;
        const showBannerFirstTime = isInFirstPeriod && !viewedAt;

        const isInSecondPeriod = remainingDays <= this.daysSecondInfo && remainingDays > this.daysThirdInfo;
        const wasViewedInFirstPeriod = diffViewedAt > this.daysSecondInfo;
        const showBannerSecondTime = isInSecondPeriod && (!viewedAt || wasViewedInFirstPeriod);

        const showBannerFinalTime = remainingDays <= this.daysThirdInfo;

        return showBannerFirstTime || showBannerSecondTime || showBannerFinalTime;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    trackBanner(): void {
        this.store.dispatch(TrackingActions.teacherClickedBanner({ event: new TeacherClickedBanner('Licence Banner', null, this.remainingDays) }));
    }

    close(): void {
        this.userService.setLicenceBannerViewedAt(new Date());
        this.isBannerVisible = false;
    }

    isClosable(): boolean {
        return this.remainingDays > this.daysThirdInfo;
    }

    openActivateLicence() {
        this.store.dispatch(openActivateLicenceCodePopup());
    }
}
