import { NotificationType } from 'shared/models';
import { LanguageHelper } from './language.helper';

export class NotificationHelper {
    static getNotificationStudentEvent(type: NotificationType, userName: string, data): string {
        switch (type) {
            case NotificationType.LevelUp:
                return `${userName} ist ${data ? 'in die Lesestufe ' + data.readingLevel : ' '} aufgestiegen.`;

            case NotificationType.LevelDown:
               return `${userName} ist ${data ? 'in die Lesestufe ' + data.readingLevel : ' '} abgestiegen.`;

            case NotificationType.MaxScore:
               return `${userName} hat volle Punktzahl.`;

            case NotificationType.PriorKnowledge:
               return  `${userName} hat Vorwissen aktiviert.`;


            case NotificationType.Expectations:
               return  `${userName} hat Leseerwartungen formuliert.`;

            default:
                return '';
            }
    }
    static getNotificationStudentEventSticker(type: NotificationType) {
        switch (type) {
            case NotificationType.LevelUp:
                return 'berg';

            case NotificationType.LevelDown:
                return 'pflaster';

            case NotificationType.PriorKnowledge:
                return 'vorwissen';

            case NotificationType.Expectations:
                return 'leseerwartungen';

            case NotificationType.MaxScore:
            default:
                return 'stern';
            }
    }

    static getNotificationTeacherInstruction(type: NotificationType) {
        switch (type) {
            case NotificationType.LevelUp:
                return 'Geben Sie Feedback.';

            case NotificationType.LevelDown:
                return 'Motivieren Sie durch Feedback!';

            case NotificationType.MaxScore:
                return 'Prüfen Sie die Ergebnisse und geben Sie danach Feedback.';

            case NotificationType.PriorKnowledge:
            case NotificationType.Expectations:
                return 'Hören Sie sich die Sprachnachricht an und geben Sie danach Feedback.';

            default:
                return '';
            }
    }

    static getNotificationTeacherAction(type: NotificationType, userName: string) {
        switch (type) {
            case NotificationType.LevelUp:
            case NotificationType.MaxScore:
                return `${userName} loben`;

            case NotificationType.LevelDown:
                return `${userName} ermutigen`;

            case NotificationType.PriorKnowledge:
            case NotificationType.Expectations:
            default:
                return 'Feedback geben';
            }
    }

    static getNotificationTeacherActionLabel(type: NotificationType, userName: string) {
        switch (type) {
            case NotificationType.LevelUp:
                return '';

            case NotificationType.LevelDown:
                return `${LanguageHelper.getGenitiveCase(userName)} Lernstand`;

            case NotificationType.MaxScore:
                return `${userName} loben`;



            case NotificationType.PriorKnowledge:
            case NotificationType.Expectations:
            default:
                return 'Feedback geben';
            }
    }

    static getNotificationTeacherActionLabelIcon(type: NotificationType) {
        switch (type) {
            case NotificationType.PriorKnowledge:
            case NotificationType.Expectations:
                return 'popup';

            default:
                break;
        }
    }
}
