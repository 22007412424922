import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherUsedGlobalNotificationFeature extends BaseTrackingEvent {
    constructor(featureTitle: string = '', featureId: string = '') {
        super({
            eventId: '63c60c3b-4558-4cb2-b181-2bcae82da64a',
            eventVerb: TrackingVerbs.USED,
            eventObject: TrackingObjects.FEATURE,
            eventPayload: {
                featureTitle: featureTitle,
                featureId: featureId,
            },
        });
    }
}
