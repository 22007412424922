import {createReducer, on} from '@ngrx/store';
import {Dictionary, StoreHelper} from 'shared/helper';
import {StudentModel, StudentReadingConfigurationEnum} from 'shared/models';
import * as SchoolClassesActions from 'store/school-classes/school-classes.actions';
import * as StudentsActions from 'store/students/students.actions';

export interface StudentsState {
    allDict: Dictionary<StudentModel>;
    loading: boolean;
    loaded: boolean;
    loadingDetails: boolean;
}

export const initialState: StudentsState = {
    allDict: {},
    loading: false,
    loaded: false,
    loadingDetails: false
};

const _studentsReducer = createReducer(
    initialState,
    on(StudentsActions.fetchStudents, (state, props) => {
        return { ...state, loading: true };
    }),
    on(StudentsActions.fetchStudentsSuccess, (state, action) => {
        const newStudentsDict = StoreHelper.arrayToDict(action.students);
        const { allDict } = state;

        const newDict = [...Object.keys(allDict), ...Object.keys(newStudentsDict)].reduce((a, b) => {
            a[b] = { ...allDict[b], ...newStudentsDict[b] };

            return a;
        }, {});

        const newState: StudentsState = {
            ...state,
            allDict: newDict,
            loading: false,
            loaded: true
        };

        return newState;
    }),
    on(SchoolClassesActions.selectSchoolClass, (state, props) => ({
        ...state,
        allDict: {}
    })),
    on(StudentsActions.fetchStudentsFail, (state, props) => {
        return { ...state, loading: false, loaded: true };
    }),
    on(StudentsActions.addStudents, (state, action) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(StudentsActions.addStudentsSuccess, (state, { students }) => {
        const newDict = StoreHelper.arrayToDict(students);
        const newState: StudentsState = {
            ...state,
            allDict: {
                ...state.allDict,
                ...newDict
            },
            loaded: true,
            loading: false
        };

        return newState;
    }),
    on(StudentsActions.addStudentsFail, (state, { error }) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(StudentsActions.deleteStudentSuccess, (state, { student }) => {
        const newStudentsDict = StoreHelper.removeKey(
            state.allDict,
            student.id.toString()
        );
        const newState: StudentsState = {
            ...state,
            allDict: newStudentsDict
        };

        return newState;
    }),
    on(SchoolClassesActions.deleteSchoolClassSuccess, (state, { studentIds }) => {
        const newState: StudentsState = {
            ...state,
            allDict: {}
        };

        return newState;
    }),
    on(StudentsActions.fetchStudentDetail, (state, { studentId }) => {
        return {
            ...state,
           loadingDetails: true
        };
    }),
    on(StudentsActions.fetchStudentDetailSuccessful, (state, { studentId, details }) => {
        if (!state.allDict[studentId]) {
            return state;
        }

        const studentWithDetails = {
            ...state.allDict[studentId],
            details: details
        };

        return {
            ...state,
            allDict: {
                ...state.allDict,
                [studentId]: studentWithDetails
            },
            loadingDetails: false
        };
    }),
    on(StudentsActions.fetchStudentDetailFail, (state, { studentId, error }) => {
        // does this even make sense?
        if (!state.allDict[studentId]) {
            return state;
        }

        return {
            ...state,
            allDict: {
                ...state.allDict,
                [studentId]: {
                    ...state.allDict[studentId],
                    details: null
                }
            },
            loading: false,
            loaded: true,
            loadingDetails: false,
            error

        };
    }),
    on(StudentsActions.changeStudent, (state, action) => {
        return { ...state, loading: true };
    }),
    on(StudentsActions.changeStudentSuccess, (state, { student }) => {
        return {
            ...state,
            loading: false,
            allDict: {
                ...state.allDict,
                [student.id]: {
                    ...state.allDict[student.id],
                    ...student
                }
            }
        };
    }),
    on(StudentsActions.changeStudentFail, (state, action) => {
        return { ...state, loading: false };
    }),
    on(StudentsActions.resetStudentCodeSuccess, (state, { student, newCode }) => {
        return {
            ...state,
            allDict: {
                ...state.allDict,
                [student.id]: {
                    ...state.allDict[student.id],
                    studentCode: newCode
                }
            }
        };
    }),
    on(SchoolClassesActions.resetSchoolClassCodesSuccess, (state, { result }) => {
        const allDict = result.studentCodes.reduce((dict, s) => {
            const { id, studentCode } = s;
            return {
                ...dict,
                [id]: {
                    ...dict[id],
                    studentCode
                }
            };
        }, state.allDict);

        return {
            ...state,
            allDict
        };
    }),
    on(StudentsActions.setStudentDetailTeacherNoteSuccess, (state, { studentId, note }) => {

        const studentWithDetails = {
            ...state.allDict[studentId]
        };

        const details = { ...studentWithDetails.details, teacherNote: note };
        studentWithDetails.details = details;

        return {
            ...state,
            allDict: {
                ...state.allDict,
                [studentId]: studentWithDetails
            }
        };
    }),
    on(SchoolClassesActions.setAllStudentReadingConfigurations, (state, props) => {
        return { ...state, loading: true };
    }),
    on(SchoolClassesActions.setAllStudentReadingConfigurationsSuccess, (state, {schoolClass, isHidden, studentReadingConfiguration}) => {
        const newStudents: Array<StudentModel> = StoreHelper.dictToArray(state.allDict).map(student => ({
            ...student,
            hideAudio: studentReadingConfiguration === StudentReadingConfigurationEnum.HideAudio ? isHidden : student.hideAudio,
            hideSyllableColoring: studentReadingConfiguration === StudentReadingConfigurationEnum.HideSyllableColoring ? isHidden : student.hideSyllableColoring
        }));
        const newStudentsDict: Dictionary<StudentModel> = StoreHelper.arrayToDict(newStudents);

        return {
            ...state,
            allDict: newStudentsDict,
            loading: false
        };
    }),
    on(SchoolClassesActions.setAllStudentReadingConfigurationsFail, (state, action) => {
        return { ...state, loading: false };
    }),
    on(SchoolClassesActions.setStudentReadingConfiguration, (state, props) => {
        return { ...state, loading: true };
    }),
    on(SchoolClassesActions.setStudentReadingConfigurationSuccess, (state, {student, isHidden, studentReadingConfiguration}) => {
        return {
            ...state,
            allDict: {
                ...state.allDict,
                [student.id]: {
                    ...state.allDict[student.id],
                    hideAudio: studentReadingConfiguration === StudentReadingConfigurationEnum.HideAudio ? isHidden : student.hideAudio,
                    hideSyllableColoring: studentReadingConfiguration === StudentReadingConfigurationEnum.HideSyllableColoring ? isHidden : student.hideSyllableColoring
                }
            }
        };
    }),
    on(SchoolClassesActions.setStudentReadingConfigurationFail, (state, action) => {
        return { ...state, loading: false };
    }),
);

export function studentsReducer(state, action) {
    return _studentsReducer(state, action);
}
