import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 034_Einzelarbeit_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-user',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path d="M28.347,24.1a9.922,9.922,0,1,0-9.922-9.922A9.933,9.933,0,0,0,28.347,24.1Zm0-17.008a7.087,7.087,0,1,1-7.087,7.086A7.094,7.094,0,0,1,28.347,7.087ZM9.921,39.685v9.921a1.418,1.418,0,0,0,1.418,1.418H45.354a1.418,1.418,0,0,0,1.418-1.418V39.685A11.09,11.09,0,0,0,35.433,28.346H21.26A11.09,11.09,0,0,0,9.921,39.685Zm11.339-8.5H35.433a8.317,8.317,0,0,1,8.5,8.5v8.5H39.686V41.1a1.418,1.418,0,1,0-2.835,0v7.086H19.844V41.1a1.418,1.418,0,1,0-2.835,0v7.086H12.756v-8.5A8.317,8.317,0,0,1,21.26,31.181Z" />
        </svg>
    `
})
export class SvgIconUserComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'user';
    }
}
