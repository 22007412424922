export type SortDirection = 'asc' | 'desc';

export interface SortEvent<T> {
    column: keyof T;
    direction: SortDirection;
}

export enum Sort {
    asc = 'asc',
    desc = 'desc'
}
