import { createAction, props } from '@ngrx/store';
import { CustomErrorCodes } from 'app/core/enums/custom-error-codes';

export const validateLearnerPasscode = createAction('[LearnerLogin Comp] validate passcode',
    props<{ passcode: string }>()
);

export const validateLearnerPasscodeSuccess = createAction('[Auth Service] validate passcode success',
    props<{ passcode: string }>()
);

export const validateLearnerPasscodeFail = createAction('[Auth Service] validate passcode fail',
    props<{ errorCode: CustomErrorCodes }>()
);


export const resetLearnerPasscodeError = createAction('[LearnerLogin Comp] reset passcode error');
