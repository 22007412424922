import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { AccountType, AssignmentStatus, StudentModel } from 'shared/models';
import { ImageService, StudentService } from 'shared/services';
import { StudentUsedMonsterChangeFeature } from 'shared/tracking/models/student-used-monster-change-feature.model';
import { startPolling } from 'store/polling/polling.actions';
import { loadProfile } from 'store/user';
import * as TrackingActions from '../tracking/tracking.actions';
import * as StudentActions from './student.actions';

@Injectable()
export class StudentEffects {

    fetchOpenAssignments$ = createEffect(() => this.actions$.pipe(
        ofType(StudentActions.fetchOpenAssignments),
        mergeMap(({limit, skip}) => this.studentService.getStudentAssignments(AssignmentStatus.Open, skip, limit).pipe(
            map(assignments => StudentActions.fetchOpenAssignmentsSuccess({ assignments })),
            catchError((error: unknown) => of(StudentActions.fetchAssignmentsFail({ error })))
        ))
    ));

    fetchCompletedAssignments$ = createEffect(() => this.actions$.pipe(
        ofType(StudentActions.fetchCompletedAssignments),
        mergeMap(({limit, skip}) => this.studentService.getStudentAssignments(AssignmentStatus.Completed, skip, limit).pipe(
            map(assignments => StudentActions.fetchCompletedAssignmentsSuccess({ assignments })),
            catchError((error: unknown) => of(StudentActions.fetchAssignmentsFail({ error })))
        ))
    ));

    fetchExpiredAssignments$ = createEffect(() => this.actions$.pipe(
        ofType(StudentActions.fetchExpiredAssignments),
        mergeMap(({limit, skip}) => this.studentService.getStudentAssignments(AssignmentStatus.Expired, skip, limit).pipe(
            map(assignments => StudentActions.fetchExpiredAssignmentsSuccess({ assignments })),
            catchError((error: unknown) => of(StudentActions.fetchAssignmentsFail({ error })))
        ))
    ));

    setMonster$ = createEffect(() => this.actions$.pipe(
        ofType(
            StudentActions.setMonster,
            StudentActions.updateMonster
        ),
        switchMap(({ monster }) => {
            return this.studentService.setMonster(monster).pipe(
                concatMap((userData) => {
                    const student = <StudentModel>userData.profile;

                    student.avatarUri = this.imageService.buildAvatarUrl(student);

                    return [
                        StudentActions.setMonsterSuccess({student}),
                        startPolling({
                            pollingAction: loadProfile({
                                accountType: AccountType.Student,
                                firstTime: false
                            }),
                            interval: 1 * 60 * 1000, // one minute
                            startImmediately: false
                        })
                    ];
                }),
                // @todo: error handling
                catchError((error: unknown) => of(StudentActions.setMonsterFail({ error })))
            );
        })
    ));

    trackUpdateMonster$ = createEffect(() => this.actions$.pipe(
        ofType(StudentActions.updateMonster),
        map(({monster}) => {
            return TrackingActions.studentUsedMonsterChangeFeature({ event: new StudentUsedMonsterChangeFeature(monster.key) });
        })
    ));

    constructor(
        private actions$: Actions,
        private imageService: ImageService,
        private studentService: StudentService
    ) { }
}
