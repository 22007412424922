import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 589_Dropdown_close_flaechig_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-carrot',
    template: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.693 56.693" enable-background="new 0 0 56.693 56.693" xml:space="preserve" class="icon">
        <g id="RZ_Kopie">
        </g>
        <path d="M28.815,40.57c-0.258,0.292-0.68,0.292-0.938,0L7.918,17.949c-0.457-0.518-0.266-0.942,0.425-0.942H48.35  c0.691,0,0.882,0.424,0.425,0.942L28.815,40.57z" />
    </svg>
  `
})
export class SvgIconCarrotComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'carrot';
    }
}
