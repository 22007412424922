import { createAction, props } from '@ngrx/store';
import { CustomErrorCodes } from 'app/core/enums/custom-error-codes';

export enum DemoLoginActionTypes {
    Login = '[Demo] login',
    LoginSuccess = '[Demo] login success',
    LoginFail = '[Demo] login fail',
}

export const login = createAction(DemoLoginActionTypes.Login, props<{ username: string, password: string }>());
export const loginSuccess = createAction(DemoLoginActionTypes.LoginSuccess, props<{ token: string }>());
export const loginFail = createAction(DemoLoginActionTypes.LoginFail, props<{ errorCode: CustomErrorCodes }>());
