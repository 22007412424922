import { TrackingVerbs, TrackingObjects } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export interface BookFilterTrackingPayload {
    filterReadingLevel: number[],
    filterTextType: string[],
    filterSeries: string[],
    filterBook: string[],
    filterProgress: string,
    filterCompetence: string[],
    filterTask: string[],
    filterReadingStrategy: string[],
    filterTextbookPage: [string, number] | []
}

export class UserUsedBookFilter extends BaseTrackingEvent {
    constructor(
        filterPayload: BookFilterTrackingPayload,
        filterId: string = '',
        filterTitle: string = 'UserBookFilter',
        ) {
        super({
            eventId: '8ba654ce-cf64-4a8d-a943-5c4291caedb3',
            eventVerb: TrackingVerbs.USED,
            eventObject: TrackingObjects.FILTER,
            eventPayload: {
                filterTitle: filterTitle,
                filterId: filterId,
                filterReadingLevel: filterPayload.filterReadingLevel,
                filterTextType: filterPayload.filterTextType,
                filterSeries: filterPayload.filterSeries,
                filterBook: filterPayload.filterBook,
                filterProgress: filterPayload.filterProgress,
                filterCompetence: filterPayload.filterCompetence,
                filterReadingStrategy: filterPayload.filterReadingStrategy,
                filterTask: filterPayload.filterTask,
                filterTextBookPage: filterPayload.filterTextbookPage ?? [],
            },
        });
    }
}
