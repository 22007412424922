import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherCreatedSchoolClass extends BaseTrackingEvent{
    constructor(groupTitle: string, groupId: number) {
        super({
            eventId: '38c60b21-602f-471c-aad2-70a6b01f1645',
            eventVerb: TrackingVerbs.CREATED,
            eventObject: TrackingObjects.GROUP,
            eventPayload: {
                groupTitle: groupTitle,
                groupId: groupId,
            },
        });
    }
}
