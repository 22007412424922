import { Component, Input } from '@angular/core';
import { ReadingLevelHelper } from 'shared/helper';

@Component({
    selector: 'app-reading-level-bookmark',
    templateUrl: './reading-level-bookmark.component.html',
    styleUrls: ['./reading-level-bookmark.component.scss']
})
export class ReadingLevelBookmarkComponent {
    @Input()
    readingLevel = 3;

    get colorClassNames(): string[] {
        return ReadingLevelHelper.getColorClassNames(this.readingLevel);
    }

    constructor() {}
}
