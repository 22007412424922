import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getSelectedStudentDetails } from 'store/students/students.selector';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-reading-level-distribution-chart',
    templateUrl: './reading-level-distribution-chart.component.html',
    styleUrls: ['./reading-level-distribution-chart.component.scss']
})
export class ReadingLevelDistributionChartComponent {
    readingLevelDetails$ = this.store.pipe(
        select(getSelectedStudentDetails),
        filter(details => details != null),
        map(details => details.readingLevels));

    constructor(private store: Store) {}
}
