import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 *
 */
@Component({
    selector: 'app-svg-icon-search',
    template: `
    <svg class="search-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
            d="M30.166 29.034l-7.311-7.311c1.705-1.959 2.745-4.536 2.745-7.356 0-6.202-5.027-11.229-11.229-11.229s-11.229 5.027-11.229 11.229c0 6.202 5.027 11.229 11.229 11.229 2.82 0 5.397-1.039 7.369-2.756l-0.014 0.012 7.309 7.314c0.147 0.166 0.361 0.271 0.6 0.271 0.442 0 0.8-0.358 0.8-0.8 0-0.238-0.104-0.453-0.27-0.599l-0.001-0.001zM4.801 14.4c0-5.302 4.298-9.6 9.6-9.6s9.6 4.298 9.6 9.6c0 5.302-4.298 9.6-9.6 9.6v0c-5.299-0.006-9.594-4.301-9.6-9.599v-0.001z">
        </path>
    </svg>
  `
})
export class SvgIconSearchComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'search';
    }
}
