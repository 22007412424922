import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookModel } from 'shared/models';
import { CoverImageMode } from 'shared/services';

@Component({
    selector: 'app-book-cover',
    templateUrl: './book-cover.component.html',
    styleUrls: ['./book-cover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookCoverComponent {

    private readonly imageTypes = ['png', 'jpg'];

    private readonly ImageSizes: Record<CoverImageMode | string, string> = { Small: '200', Medium: '400', Full: '' };

    @Input()
    book: BookModel;

    @Input()
    isReadinglevelEnabled = true;

    @Input()
    showFavorite = false;

    @Input()
    size: CoverImageMode = CoverImageMode.Small;

    get showReadingLevel(): boolean {
        if (this.isReadinglevelEnabled === false) {
            return false;
        }

        if (this.book == null) {
            return false;
        }

        const { readingLevel } = this.book;

        if (readingLevel == null) {
            return false;
        }

        if (readingLevel < 0 || readingLevel > 5) {
            return false;
        }

        return true;
    }



    coverUri(key: CoverImageMode | string): string {

        const uri = this.book.coverUri;

        const extension = uri.split('.').pop();

        if (this.imageTypes.includes(extension)) {
            return `${uri.substring(0, uri.length - (extension.length + 1))}-${this.ImageSizes[key]}.${extension}`;
        }

        return `${uri}_${this.ImageSizes[key]}`;
    }
}
