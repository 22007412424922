import {  Input, HostBinding, Directive } from '@angular/core';
import { Colors } from 'shared/helper';

@Directive()
export abstract class SvgIconBaseDirective {

    @Input()
    height: number;

    @Input()
    width: number;

    @Input()
    color = Colors.colorPrimary;

    @Input()
    isFilled = false;

    abstract get iconName(): string;

    @HostBinding('class')
    get classList(): string {
        const name = `svg-icon-${this.iconName}`;
        return ['svg-icon', name].join(' ');
    }
}
