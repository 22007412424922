import { Component, Input, HostBinding } from '@angular/core';

import { ReadingLevelHelper } from 'shared/helper';

@Component({
    selector: 'app-reading-level-box',
    templateUrl: './reading-level-box.component.html',
    styleUrls: ['./reading-level-box.component.scss']
})
export class ReadingLevelBoxComponent {
    @HostBinding('class')
    get hostClasses(): string {
        if (this.readingLevel == null) {
            return;
        }

        return ReadingLevelHelper.getColorClassNames(this.readingLevel).join(
            ' '
        );
    }

    @Input()
    readingLevel: number;

    constructor() {}

}
