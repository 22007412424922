import { TrackingObjects, TrackingVerbs } from 'shared/tracking/tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class StudentNavigatedOnboarding extends BaseTrackingEvent{
    constructor(slideName: string) {
        super({
            eventId: 'cec90ba7-7a3a-4d43-acb8-acd5cd3ed917',
            eventVerb: TrackingVerbs.NAVIGATED,
            eventObject: TrackingObjects.FEATURE,
            eventPayload: {
                featureId: '',
                featureTitle: 'student-onboarding',
                featureSlideName: slideName,
            },
        });
    }
}
