import { Component, ElementRef, Input, Output, ViewChild, EventEmitter, AfterViewInit, OnDestroy, ViewChildren, QueryList, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { NotificationHelper } from 'shared/helper/notification.helper';
import { NotificationType, StudentModel } from 'shared/models';
import { Conversation, ConversationMessage } from 'shared/models/conversation.model';

@Component({
    selector: 'app-conversation-thread',
    templateUrl: './conversation-thread.component.html',
    styleUrls: ['./conversation-thread.component.scss']
})
export class ConversationThreadComponent implements AfterViewInit, OnDestroy {

    @Input()
    activeConversation: {
        student: StudentModel;
        conversation: Conversation;
    };

    @ViewChild('scrollArea') scrollAreaSection: ElementRef;
    @ViewChildren('messages') messages: QueryList<any>;

    @Input()
    userId: number;

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    readonly onLoadMore = new EventEmitter<Conversation>();

    @HostListener('scroll', ['$event'])
    public onHostScroll(event: Event) {
        if (!this.loadMoreCalled && this.activeConversation?.conversation?.hasMoreMessages && (<HTMLElement>event.target).scrollTop <= 100) {
            this.loadMoreCalled = true;
            const scrolledElem = (<HTMLElement>event.target);
            this.currentScrollHeight = scrolledElem.scrollHeight;
            this.currentScrollTop = scrolledElem.scrollTop;

            // load more older messages
            this.onLoadMore.emit();
        }

    }

    public loadMoreCalled = false;

    private ngUnsubscribe = new Subject<void>();

    private currentScrollHeight = 0;
    private currentScrollTop = 0;

    constructor() { }

    ngAfterViewInit() {
        this.messages.changes
            .pipe(startWith(this.messages),takeUntil(this.ngUnsubscribe)) // we need this for the unit test
            .subscribe(() => this.setScrollPosition());

        // need this hack when navigating away from and back to the conversation page
        setTimeout(() => this.messages.notifyOnChanges(), 0);
    }

    private setScrollPosition(): void {
        if (!this.loadMoreCalled) {
            // scroll to bottom
            this.scrollAreaSection.nativeElement.parentElement.scrollTop = this.scrollAreaSection.nativeElement.parentElement.scrollHeight;
        } else {
            // keep previous scroll position
            this.scrollAreaSection.nativeElement.parentElement.scrollTop = this.scrollAreaSection.nativeElement.parentElement.scrollHeight - this.currentScrollHeight + this.currentScrollTop;
        }

        // reset scroll position
        this.currentScrollHeight = 0;
        this.currentScrollTop = 0;

        // load more call finished
        this.loadMoreCalled = false;
    }

    getStudentEvent(message: ConversationMessage): string {
        const { userName } = this.activeConversation.student;

        return NotificationHelper.getNotificationStudentEvent(message.messageType, userName, message.studentEventData);
    }

    getStickerName(type: NotificationType): string {
        return NotificationHelper.getNotificationStudentEventSticker(type);
    }

    isFirstNewMessage(index: number): boolean {
        const { thread } = this.activeConversation.conversation;
        const newMsgIndex = thread.findIndex(m => m.isNew);

        return index === newMsgIndex;
    }

    getNewMessagesHeadline(): string {
        const { thread } = this.activeConversation.conversation;
        const multipleNewMsg = thread.filter(m => m.isNew).length > 1;

        return multipleNewMsg ? 'Neue Nachrichten' : 'Neue Nachricht';
    }

    ngOnDestroy() {
        // this.messageSubscription.unsubscribe();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
