import { Dictionary, StoreHelper } from 'shared/helper';
import { MessageModel } from 'shared/models';
import { createReducer, on } from '@ngrx/store';

import * as MessagesActions from 'store/messages/messages.actions';

export interface MessagesState {
    items: Dictionary<MessageModel>;
    isLoading: boolean;
    isLoaded: boolean;
}

export const initialState: MessagesState = {
    items: {},
    isLoading: false,
    isLoaded: false
};

const _messagesReducer = createReducer(
    initialState
);

export function messagesReducer(state, action) {
    return _messagesReducer(state, action);
}
