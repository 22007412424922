import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 094_Schwingen_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-syllable',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M28.237,5.669A22.677,22.677,0,1,0,50.914,28.346,22.7,22.7,0,0,0,28.237,5.669Zm0,42.52A19.843,19.843,0,1,1,48.079,28.346,19.865,19.865,0,0,1,28.237,48.189ZM42.41,25.511a1.418,1.418,0,0,1,2.835,0c0,6.782-3.7,11.339-9.213,11.339a8.494,8.494,0,0,1-7.795-4.857,8.5,8.5,0,0,1-7.8,4.857c-5.51,0-9.212-4.557-9.212-11.339a1.417,1.417,0,0,1,2.834,0c0,3.923,1.671,8.5,6.378,8.5s6.378-4.581,6.378-8.5a1.418,1.418,0,0,1,2.835,0c0,3.923,1.67,8.5,6.378,8.5S42.41,29.434,42.41,25.511Z" />
    </svg>
  `
})
export class SvgIconSyllableComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'syllable';
    }
}
