// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin: 3rem 0 2rem;
  text-align: center;
}
:host app-svg-icon-chevron {
  transform: rotate(90deg);
}
:host app-svg-icon-chevron.flip {
  transform: rotate(-90deg);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYWQtbW9yZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGNBQUE7RUFDQSxtQkFBQTtFQUNBLGtCQUFBO0FBQ0o7QUFDSTtFQUNJLHdCQUFBO0FBQ1I7QUFDUTtFQUNJLHlCQUFBO0FBQ1oiLCJmaWxlIjoibG9hZC1tb3JlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIG1hcmdpbjogM3JlbSAwIDJyZW07XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuXG4gICAgYXBwLXN2Zy1pY29uLWNoZXZyb24ge1xuICAgICAgICB0cmFuc2Zvcm06IHJvdGF0ZSg5MGRlZyk7XG5cbiAgICAgICAgJi5mbGlwIHtcbiAgICAgICAgICAgIHRyYW5zZm9ybTogcm90YXRlKC05MGRlZyk7XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/widgets/load-more/load-more.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACI;EACI,wBAAA;AACR;AACQ;EACI,yBAAA;AACZ;AACA,ooBAAooB","sourcesContent":[":host {\n    display: block;\n    margin: 3rem 0 2rem;\n    text-align: center;\n\n    app-svg-icon-chevron {\n        transform: rotate(90deg);\n\n        &.flip {\n            transform: rotate(-90deg);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
