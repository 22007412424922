import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/core/services/environment.service';
import { Observable } from 'rxjs';
import {
    AccountType,
    LicenceModel,
    ProfileUpdatesModel,
    TeacherModel
} from 'shared/models';
import { UserData } from 'shared/models/user-data';

@Injectable()
export class UserService {
    private readonly baseApiUrl: string = EnvironmentService.baseApiUrl;
    private static readonly viewedLicenceBannerAt = 'viewed-licence-banner';

    constructor(private http: HttpClient) {}

    getProfile(): Observable<UserData> {
        return this.http.get<UserData>(`${this.baseApiUrl}/user/me`);
    }

    getProfileUpdates(): Observable<ProfileUpdatesModel> {
        return this.http.get<ProfileUpdatesModel>(`${this.baseApiUrl}/user/updates`);
    }

    registerTeacher(): Observable<UserData> {
        return this.http.post<UserData>(`${this.baseApiUrl}/register`, {});
    }

    validate(email: string): Observable<void> {
        return this.http.post<void>(`${this.baseApiUrl}/user/validate`, { email });
    }

    deleteProfile(teacherId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseApiUrl}/Teachers/${teacherId}`);
    }

    activateLicence(code: string): Observable<LicenceModel> {
        return this.http.post<LicenceModel>(`${this.baseApiUrl}/user/activate`, { code });
    }

    updateLeseoName(leseoname: string): Observable<string> {
        return this.http.patch(`${this.baseApiUrl}/user/leseoname`, `"${leseoname}"`,  {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'text'
        });
    }

    onboardPrivateUser(): Observable<TeacherModel> {
        return this.http.patch<TeacherModel>(`${this.baseApiUrl}/user/onboard`, {});
    }

    getLicenceBannerViewedAtToken(): Date {
        const token = localStorage.getItem(UserService.viewedLicenceBannerAt);

        if (token) {
            const date = JSON.parse(token).viewedAt;

            return new Date(date);
        }

        return null;
    }

    setLicenceBannerViewedAt(date: Date): void {
        const value = JSON.stringify({ viewedAt: date });

        localStorage.setItem(
            UserService.viewedLicenceBannerAt,
            value
        );
    }

    setAccountTypeInLocalStorage(accountType: AccountType): void {
        localStorage.setItem(
            'account-type',
            accountType.toString()
        );
    }

    getAccountTypeFromLocalStorage(): AccountType {
        const storedValue = parseInt(localStorage.getItem('account-type'));

        return storedValue as AccountType;
    }

    removeAccountTypeFromLocalStorage(): void {
        localStorage.removeItem('account-type');
    }

    usePromotionCode(code: string): Observable<LicenceModel> {
        return this.http.post<LicenceModel>(`${this.baseApiUrl}/promotion/`, { code });
    }
}
