export interface SchoolClassModel {
    id: number;
    name: string;
    classCode: string;
    details?: SchoolClassDetailsModel;
    isOwner?: boolean;
}

export interface SchoolClassDetailsModel {
    averageExerciseResult: number;
}

export interface SchoolClassLightModel {
    id: number;
    name: string;
    classCode: string;
}
