import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 160_externer_Link_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-new-window',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M42.521,29.764V46.771A1.417,1.417,0,0,1,41.1,48.188H9.922a1.416,1.416,0,0,1-1.417-1.417V15.591a1.416,1.416,0,0,1,1.417-1.417H26.93a1.417,1.417,0,1,1,0,2.834H11.34V45.354H39.686V29.764a1.418,1.418,0,0,1,2.835,0ZM46.773,8.5H32.6a1.417,1.417,0,1,0,0,2.834H43.352L20.258,34.431a1.417,1.417,0,0,0,2.005,2L45.355,13.343V24.1a1.418,1.418,0,0,0,2.835,0V9.921A1.417,1.417,0,0,0,46.773,8.5Z" />
    </svg>
    `
})
export class SvgIconNewWindowComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'new-window';
    }
}
