export interface NavItemRoutingModel {
    routerLink: string | (string | number)[];
    isExact?: boolean;
}

export interface NavItemModel {
    label: string;
    routing: NavItemRoutingModel;
    iconName?: string;
    isDisabled?: boolean;
    hasUpdates?: boolean;
}
