// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  align-items: center;
}
:host img.monsters {
  height: 140px;
}
:host div.message {
  margin-top: 20px;
  text-align: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN2LW1vbnN0ZXJzLW1lc3NhZ2UuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxtQkFBQTtBQUNKO0FBQ0k7RUFDSSxhQUFBO0FBQ1I7QUFFSTtFQUNJLGdCQUFBO0VBQ0Esa0JBQUE7QUFBUiIsImZpbGUiOiJjdi1tb25zdGVycy1tZXNzYWdlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuXG4gICAgaW1nLm1vbnN0ZXJzIHtcbiAgICAgICAgaGVpZ2h0OiAxNDBweDtcbiAgICB9XG5cbiAgICBkaXYubWVzc2FnZSB7XG4gICAgICAgIG1hcmdpbi10b3A6IDIwcHg7XG4gICAgICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/widgets/cv-monsters-message/cv-monsters-message.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;AACR;AAEI;EACI,gBAAA;EACA,kBAAA;AAAR;AACA,wqBAAwqB","sourcesContent":[":host {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    img.monsters {\n        height: 140px;\n    }\n\n    div.message {\n        margin-top: 20px;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
