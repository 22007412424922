import {
    Component,
    Input,
    ChangeDetectionStrategy
} from '@angular/core';
import { ReadingLevelChartDetail, ReadingLevelProgression, ReadingLevelHistory } from 'shared/models';
import * as moment from 'moment';

@Component({
    selector: 'app-reading-level-progression-chart',
    templateUrl: 'reading-level-progression-chart.component.html',
    styleUrls: ['reading-level-progression-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadingLevelProgressionChartComponent {
    private endDate: Date = this.getLastDateInYear(new Date());
    private _progression: ReadingLevelProgression;
    private tooltipElem: HTMLDivElement;
    private monthGridWidth: number;

    @Input()
    set progression(value: ReadingLevelProgression) {
        this._progression = value;
        this.updateChart();
    }

    get progression(): ReadingLevelProgression {
        return this._progression;
    }

    public chartSettings = {
        height: 180,
        barHeight: 18,
        barSpace: 9,
        fontSize: 14,
        gridDash: 8
    };
    public datesToDisplay: ReadingLevelChartDetail[] = [];
    public readingLevelHistory: ReadingLevelHistory[] = [];

    constructor() { }

    public mouseEnter(e: MouseEvent | KeyboardEvent, entry: ReadingLevelHistory) {
        if (!this.tooltipElem) {
            this.tooltipElem = document.createElement('div');
        }
        this.tooltipElem.style.position = 'absolute';
        this.tooltipElem.style.padding = '5px 10px';
        this.tooltipElem.style.backgroundColor = 'rgba(0, 0, 0, .7)';
        this.tooltipElem.style.color = '#f5f5f5';
        this.tooltipElem.style.fontSize = '.5em';
        this.tooltipElem.style.zIndex = '1';

        const readingLevelTitle = entry.readingLevel === 0 ? 'Lesestarter' : `Lesestufe ${entry.readingLevel}`;

        this.tooltipElem.innerText = `${readingLevelTitle}
            (${moment(entry.startDate).format('L')} - ${moment(
                entry.endDate
            ).format('L')})`;

        if (e.type === 'focus') { // user used Tab to focus element
          const containerElement = document.getElementById('readingLevelProgressionChartWrapper');
          const target = (e.target as HTMLElement);
          const xStart = containerElement.getBoundingClientRect().width * (Number((`${target.getAttribute('x')}`).slice(0,-1)) / 100) + 12;
          containerElement.appendChild(this.tooltipElem);
          this.tooltipElem.style.top = `${target.getAttribute('y')}px`;
          this.tooltipElem.style.left = xStart + 'px';      
        }
        else { // user hovered over element
          document.getElementsByTagName('body')[0].appendChild(this.tooltipElem);
        }
    }

    public mouseLeave(e: MouseEvent) {
        this.tooltipElem.remove();
        this.tooltipElem = null;
    }

    public mouseMove(e: MouseEvent) {
        if (this.tooltipElem) {
            this.tooltipElem.style.top = `${e.pageY -
                this.tooltipElem.clientHeight -
                5}px`;
            this.tooltipElem.style.left = `${e.pageX -
                this.tooltipElem.clientWidth / 2}px`;
        }
    }

    private getShortMonth(month: number): string {
        const d = new Date();

        moment.updateLocale('de', {
            monthsShort: [
                'Jan',
                'Feb',
                'Mär',
                'Apr',
                'Mai',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Okt',
                'Nov',
                'Dez'
            ]
        });

        return moment(new Date(d.getFullYear(), month, 1)).format('MMM');
    }

    private getXAxisLabelHeight(offset: number = 0): number {
        return this.chartSettings.height + 20 + offset;
    }

    private calcGridWidth(index: number, items: ReadingLevelChartDetail[]): number {
        let amountOfDatesWithYear = 0;

        for (let i = 0; i < index; i++) {
            if (items[i].hasYear) {
                amountOfDatesWithYear++;
            }
        }

        return this.monthGridWidth * (index + amountOfDatesWithYear);
    }

    private calcOffset(): number {
        const dayOffset = Math.round(
            (this.progression.startDate.getDate() / this.getDaysInMonth(this.progression.startDate)) *
            100
        );
        return Math.round((this.monthGridWidth * dayOffset) / 100);
    }

    // generate array of dates for rendering in svg chart
    private generateDates(studentStartDate: Date): ReadingLevelChartDetail[] {
        let yearsCounter = 1;
        let datesArray: ReadingLevelChartDetail[] = [];
        let startDate = moment(studentStartDate);
        const defaultStartDate = startDate;
        const endDate = moment(this.endDate);
        const monthDifference = moment(endDate).diff(startDate, 'months');


        //add initial (star date) to array
        datesArray = [
            ...datesArray,
            {
                formatedData: `${startDate.date()}. ${this.getShortMonth(startDate.month())} ${startDate.year()}`,
                gridLabelWidth: 0,
                gridLabelHeight: this.getXAxisLabelHeight(),
                hasYear: true,
                date: startDate.toDate()
            }
        ];

        //add date objects to array until end date
        while (startDate < endDate) {

            if (startDate.month() === 0 && startDate !== defaultStartDate) {
                yearsCounter++;
                datesArray = [
                    ...datesArray,
                    {
                        formatedData: `${this.getShortMonth(startDate.month())} ${startDate.year()}`,
                        gridLabelWidth: 0,
                        gridLabelHeight: this.getXAxisLabelHeight(),
                        hasYear: true,
                        date: startDate.toDate()
                    }
                ];
            } else if (startDate !== defaultStartDate) {
                if (monthDifference > 5 && startDate.month() % 3 === 0) {
                    datesArray = [
                        ...datesArray,
                        {
                            formatedData: `${this.getShortMonth(startDate.month())}`,
                            gridLabelWidth: 0,
                            gridLabelHeight: this.getXAxisLabelHeight(),
                            hasYear: false,
                            date: startDate.toDate()
                        }
                    ];
                } else if (monthDifference <= 5) {
                    datesArray = [
                        ...datesArray,
                        {
                            formatedData: `${this.getShortMonth(startDate.month())}`,
                            gridLabelWidth: 0,
                            gridLabelHeight: this.getXAxisLabelHeight(),
                            hasYear: false,
                            date: startDate.toDate()
                        }
                    ];
                }
            }
            startDate = moment(startDate).add(1, 'month');

        }

        //update font size because of overlapping labels when we have many months
        this.updateFontSize(yearsCounter);

        //calculate grid width between months
        const datesLenght = datesArray.length + yearsCounter;
        this.monthGridWidth = Math.floor(100 / datesLenght);

        return datesArray.map((item: ReadingLevelChartDetail, index: number) => {
            return {
                ...item,
                gridLabelWidth: this.calcGridWidth(index, datesArray)
            };
        });
    }

    private updateChart() {

        if (this.progression !== null) {
            this.datesToDisplay = this.generateDates(this.progression.startDate);
        }

        if (this.progression !== null && this.progression.data !== null) {
            const studentHistoryData = [...this.progression.data];

            studentHistoryData.unshift({
                startDate: this.progression.startDate,
                level: this.progression.startReadingLevel
            });

            const mergedHistory = this.mergeSameReadingLevels(
                studentHistoryData
            );

            this.readingLevelHistory = this.generateChartData(mergedHistory);
        }
    }

    private mergeSameReadingLevels(
        data: { startDate: Date; level: number }[]
    ): { startDate: Date; level: number }[] {
        return data.reduce(
            (
                prev: { startDate: Date; level: number }[],
                curr: { startDate: Date; level: number }
            ) => {
                if (prev.length === 0) {
                    prev.push(curr);
                    return prev;
                }

                if (prev[prev.length - 1].level !== curr.level) {
                    prev.push(curr);
                }

                return prev;
            },
            []
        );
    }

    private generateChartData(
        studentHistoryData: { startDate: Date; level: number }[]
    ): ReadingLevelHistory[] {
        const chartData: ReadingLevelHistory[] = [];

        const absStartDate = this.progression.startDate;
        const absEndDate = this.endDate;

        const dateRange = this.dateDiff(absStartDate, absEndDate);

        for (let i = 0; i < studentHistoryData.length; i++) {
            const curr = studentHistoryData[i];
            const next = studentHistoryData[i + 1];
            const nextDate = next ? next.startDate : moment(new Date()).toDate();

            const minDateDiff = this.dateDiff(curr.startDate, new Date(curr.startDate.getFullYear(), curr.startDate.getMonth(), curr.startDate.getDate() + 1));
            const minWidthInPercent = Math.round((minDateDiff / dateRange) * 100);

            const dateDiff = this.dateDiff(curr.startDate, nextDate);
            const widthInPercent = Math.max(minWidthInPercent, Math.round((dateDiff / dateRange) * 100));

            const offset = this.calcOffset();
            const width = Math.max(1, widthInPercent - (widthInPercent / 100) * offset);

            if (chartData.length === 0) {
                chartData.push({
                    readingLevel: curr.level,
                    x: 0,
                    w: width,
                    startDate: curr.startDate,
                    endDate: nextDate
                });
            } else {
                const prev = chartData[chartData.length - 1];
                chartData.push({
                    readingLevel: curr.level,
                    x: prev.x + prev.w,
                    w: width,
                    startDate: curr.startDate,
                    endDate: nextDate
                });
            }
        }

        return chartData;
    }

    private dateDiff(start: Date, end: Date): number {
        return Math.abs(new Date(end).getTime() - new Date(start).getTime());
    }

    private getLastDateInYear(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth() + (11 - date.getMonth()), 31);
    }

    private getDaysInMonth(date: Date): number {
        return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
    }

    private updateFontSize(yearsCount: number): void {
        if (yearsCount > 0 && yearsCount < 4) {
            this.chartSettings = {
                ...this.chartSettings,
                fontSize: 14
            };
        } else if (yearsCount === 4) {
            this.chartSettings = {
                ...this.chartSettings,
                fontSize: 12
            };
        } else if (yearsCount >= 5) {
            this.chartSettings = {
                ...this.chartSettings,
                fontSize: 9
            };
        }
    }
}
