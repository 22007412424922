import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    routerCancelAction,
    routerErrorAction, routerNavigatedAction, routerNavigationAction
} from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AccountType } from 'shared/models';
import { UserViewedScreen } from 'shared/tracking/models/user-viewed-screen.model';
import { fetchBookDetails } from 'store/books';
import {
    getLoadingIndicator, hideLoadingIndicator, showLoadingIndicator
} from 'store/layout';
import { RootState } from 'store/root.reducer';
import { getAccountType } from 'store/user/user.selector';
import * as TrackingActions from '../tracking/tracking.actions';
import { getRouteParams } from './router.selector';

@Injectable()
export class RouterEffects {
    private readonly _defaultLeseoPageName = 'Leseo';

    showLoadingIndicator$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigationAction),
            withLatestFrom(
                this.store.pipe(select(getLoadingIndicator)),
                this.store.pipe(select(getAccountType))
            ),
            filter(
                ([_, loadingIndicatior, accountType]) =>
                    !loadingIndicatior.isVisible && accountType === AccountType.Teacher
            ),
            map(
                ([action, loadingIndicatior, accountType]) =>
                    showLoadingIndicator(accountType, true)
            )
        )
    );

    hideLoadingIndicator$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                routerNavigatedAction,
                routerCancelAction,
                routerErrorAction
            ),
            withLatestFrom(this.store.pipe(select(getLoadingIndicator))),
            map(([_, loadingIndicatorState]) => loadingIndicatorState),
            filter(({isVisible, isTriggeredByRouter}) => isVisible && isTriggeredByRouter),
            map(({ isTriggeredByRouter }) => hideLoadingIndicator({ isTriggeredByRouter }))
        )
    );

    navigationDone$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigatedAction),
            tap((action) => {
                let state = action.payload.routerState;
                let title = state['title'] || this._defaultLeseoPageName;

                this.titleService.setTitle(title);
            }),
            filter((action) => {
                let state = action.payload.routerState;
                let route = state['url'];

                return this.shouldSendTrackingEvent(route);
                }
            ),
            map((action) => {
                let state = action.payload.routerState;
                let title = state['title'] || this._defaultLeseoPageName;
                let route = state['url'];

                return TrackingActions.userViewedScreen({
                    event: new UserViewedScreen(title, route),
                });
            })
        )
    );

    setBookIdByRouteParam$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                routerNavigatedAction
            ),
            withLatestFrom(
                this.store.select(getRouteParams)
            ),
            filter(([, routeParams]: [any, Params]) => routeParams && routeParams['bookId']),
            map(([, routeParams]: [any, Params]) => fetchBookDetails({ bookId: routeParams['bookId'] }))
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<RootState>,
        private titleService: Title
    ) {}

    private shouldSendTrackingEvent(route: string) { //todo consider adding a whitelist to check against
        return !route.startsWith('/login-callback'); //this is url for user authentication flow and we should not track it
    }
}
