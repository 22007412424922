import { Component } from '@angular/core';

import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 146_Bibliothek_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-library',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
            <path d="M51.025,22.678h1.417a1.417,1.417,0,1,0,0-2.834H52A16.925,16.925,0,0,1,52,8.5h.439a1.417,1.417,0,1,0,0-2.834h-41.1a1.416,1.416,0,0,0-1.313.884A19.894,19.894,0,0,0,8.5,14.173a19.539,19.539,0,0,0,.836,5.669H5.7a.353.353,0,0,0-.065,0H4.251a1.417,1.417,0,0,0,0,2.834H4.69a16.924,16.924,0,0,1,.978,5.67,16.911,16.911,0,0,1-.978,5.67H4.251a1.417,1.417,0,1,0,0,2.834H9.339A19.556,19.556,0,0,0,8.5,42.52a19.9,19.9,0,0,0,1.522,7.62,1.417,1.417,0,0,0,1.313.884h41.1a1.417,1.417,0,1,0,0-2.834H52a16.925,16.925,0,0,1,0-11.34h.439a1.417,1.417,0,1,0,0-2.834H51.056a.321.321,0,0,0-.062,0h-3.64a19.642,19.642,0,0,0,0-11.339h3.671ZM11.331,34.016H7.67a19.547,19.547,0,0,0,.833-5.67,19.562,19.562,0,0,0-.833-5.67h9.339v7.086a1.418,1.418,0,0,0,2.2,1.18l3.465-2.311,3.466,2.311a1.418,1.418,0,0,0,2.2-1.18V22.677H44.377a16.922,16.922,0,0,1,0,11.339H11.331ZM25.513,14.172V27.115L23.465,25.75a1.42,1.42,0,0,0-1.573,0l-2.048,1.365V14.172ZM49.023,36.85a19.714,19.714,0,0,0,0,11.34H12.316a16.925,16.925,0,0,1,0-11.34H49.023Zm0-17.007H28.348v-5.67h1.417a1.417,1.417,0,0,0,0-2.834H15.591a1.417,1.417,0,0,0,0,2.834h1.418v5.67H12.316a16.922,16.922,0,0,1,0-11.339H49.023a19.714,19.714,0,0,0,0,11.34Z" />
        </svg>
  `
})
export class SvgIconLibraryComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'library';
    }
}
