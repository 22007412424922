import { createAction, props } from '@ngrx/store';
import { StudentModel } from 'shared/models';
import { Conversation, ConversationMessage, ConversationMessageDraft } from 'shared/models/conversation.model';

export const fetchConversations = createAction('[Conversations] fetch all conversations');

export const fetchConversationsSuccess = createAction('[Conversations] fetch all conversations success',
    props<{conversations: Conversation[]}>()
);
export const fetchConversationsFail = createAction('[Conversations] fetch all conversations fail',
    props<{error: any}>()
);

export const fetchConversationThread = createAction('[Conversations] fetch conversation thread',
    props<{conversationId: number, participantId: number, skip?: number, limit?: number}>()
);

export const createConversationDraft = createAction('[Conversations] create new conversation',
    props<{student: StudentModel}>()
);

export const fetchConversationThreadSuccess = createAction('[Conversations] fetch conversation thread success',
    props<{conversationThread: ConversationMessage[], hasMore: boolean}>()
);

export const fetchConversationThreadFail = createAction('[Conversations] fetch conversation thread fail',
    props<{ error: any }>()
);

export const setActiveConversation = createAction('[Conversations] set active conversation',
    props<{participantId?: number, conversationId?: number}>()
);

export const updateActiveConversation = createAction('[Conversations] update active conversation',
    props<{limit?: number}>()
);

export const loadFirstConversationThread = createAction('[Conversations] load first conversation thread');

export const draftConversationMessage = createAction('[Conversations] draft message',
    props<{content: string}>()
);

export const sendConversationMessage = createAction('[Conversations] send message');

export const sendConversationMessageSuccess = createAction('[Conversations] send message success',
    props<{message: ConversationMessage, participants: number[]}>()
);

export const sendConversationMessageFail = createAction('[Conversations] send message fail',
    props<{error: any}>()
);

export const setConversationAsRead = createAction('[Conversations] set conversation as read',
    props<{ conversationId: number, participantId: number }>()
    );

export const setConversationAsReadSuccess = createAction('[Conversations] set conversation as read success',
    props<{ conversationId: number, participantId: number }>()
);

export const setConversationAsReadFail = createAction('[Conversations] set conversation as read fail',
    props<{error: any}>()
);

export const markConversationThreadAsRead = createAction('[Conversations] mark conversation thread as read',
    props<{ conversationId: number, participantId: number }>()
);

export const fetchConversationsForGroup = createAction('[Conversations] fetch conversations for group',
    props<{ groupId: number }>()
);
