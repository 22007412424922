
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WidgetsModule } from 'app/widgets/widgets.module';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { BackdropComponent } from './backdrop/backdrop.component';
import { BookListComponent } from './book-list/book-list.component';
import { ConversationThreadComponent } from './conversation-thread/conversation-thread.component';
import { CorrectAnswersComponent } from './correct-answers/correct-answers.component';
import { DonutProgressComponent } from './donut-progress/donut-progress.component';
import { DueDateComponent } from './due-date/due-date.component';
import { GlobalNotificationsComponent } from './global-notifications/global-notifications.component';
import {
    MaintenanceGuard, StudentDetailsPreloadingGuard, UiLockedGuard
} from './guards';
import { LandingPageGuard } from './guards/landing-page.guard';
import { HeaderComponent } from './header/header.component';
import { BookHelper } from './helper';
import { DateObjectInterceptor, OidcInterceptor } from './interceptors';
import { LoadingContainerComponent } from './loading-container/loading-container.component';
import { LottieComponent } from './lottie/lottie.component';
import { MaintenanceInfoComponent } from './maintenance-info/maintenance-info.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FriendlyDatePipe, GenderPipe, ReadingLevelPipe, ReplacePipe, SlugPipe, SortByPipe } from './pipes';
import { ReadingLevelBoxComponent } from './reading-level-box/reading-level-box.component';
import { ReadingLevelFilterComponent } from './reading-level-filter/reading-level-filter.component';
import { ReadingLevelProgressionChartComponent } from './reading-level-progression-chart/reading-level-progression-chart.component';
import {
    AuthService,
    BooksService,
    CookieService,
    ExerciseService,
    HotjarService,
    ImageService,
    MessageService,
    NotificationService,
    SchoolService,
    SettingsService,
    SortingService,
    StudentDetailsService,
    StudentService,
    TeacherService,
    TrackingService,
    UserService,
    WindowService
} from './services';
import { ConversationService } from './services/conversation.service';
import { GlobalNotificationService } from './services/global-notification.service';
import { StudentAvatarComponent } from './student-avatar/student-avatar.component';
import { StudentNameLinkComponent } from './student-name-link/student-name-link.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { AlertModule } from './_alert';
import { CoreModule } from 'app/core/core.module';
import { ConsentService } from './consent/consent.service';
import {
    CvCommonButtonComponent,
    CvCommonIconComponent,
    CvLoaderComponent,
    CvNavigationLinkComponent,
    CvNotificationBubbleComponent,
    CvMenuButtonComponent,
    CvListComponent,
    CvListItemComponent
} from '@cornelsen/cv-common';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { NotificationsListComponent } from './global-notifications/notifications-list/notifications-list.component';
import { CvStudyGroupSelectorComponent } from 'shared/cv-study-group-selector/cv-study-group-selector.component';

const oidcInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: OidcInterceptor,
    multi: true
};

const dateObjectInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: DateObjectInterceptor,
    multi: true
};

const tooltipDefaultOptions: TooltipOptions = {
    'hide-delay': 50,
    'theme': 'light'
};

@NgModule({
    imports: [
        TooltipModule.forRoot(tooltipDefaultOptions),
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SvgIconsModule,
        WidgetsModule,
        RouterModule,
        NgxFloatUiModule,
        AlertModule,
        CoreModule,
        CvCommonButtonComponent,
        CvCommonIconComponent,
        CvLoaderComponent,
        CvNavigationLinkComponent,
        CvNotificationBubbleComponent,
        CvMenuButtonComponent,
        CvListComponent,
        CvListItemComponent,
    ],
    declarations: [
        BackdropComponent,
        BookListComponent,
        ConversationThreadComponent,
        CorrectAnswersComponent,
        DonutProgressComponent,
        DueDateComponent,
        FriendlyDatePipe,
        GenderPipe,
        GlobalNotificationsComponent,
        HeaderComponent,
        LoadingContainerComponent,
        LottieComponent,
        MaintenanceInfoComponent,
        PageNotFoundComponent,
        ReadingLevelBoxComponent,
        ReadingLevelFilterComponent,
        ReadingLevelPipe,
        ReadingLevelProgressionChartComponent,
        ReplacePipe,
        SlugPipe,
        SortByPipe,
        StudentAvatarComponent,
        StudentNameLinkComponent,
        UserInfoComponent,
        NotificationsListComponent,
        CvStudyGroupSelectorComponent,
    ],
    exports: [
        AlertModule,
        BackdropComponent,
        BookListComponent,
        CommonModule,
        ConversationThreadComponent,
        CorrectAnswersComponent,
        DonutProgressComponent,
        DueDateComponent,
        FormsModule,
        FriendlyDatePipe,
        GenderPipe,
        NotificationsListComponent,
        HeaderComponent,
        HttpClientModule,
        LoadingContainerComponent,
        LottieComponent,
        MaintenanceInfoComponent,
        PageNotFoundComponent,
        ReactiveFormsModule,
        ReadingLevelBoxComponent,
        ReadingLevelFilterComponent,
        ReadingLevelPipe,
        ReadingLevelProgressionChartComponent,
        ReplacePipe,
        SlugPipe,
        SortByPipe,
        StudentAvatarComponent,
        StudentNameLinkComponent,
        SvgIconsModule,
        TooltipModule,
        UserInfoComponent,
        WidgetsModule,
        CvStudyGroupSelectorComponent,
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AuthService,
                BookHelper,
                BooksService,
                ExerciseService,
                ConversationService,
                dateObjectInterceptor,
                GlobalNotificationService,
                ImageService,
                LandingPageGuard,
                MaintenanceGuard,
                MessageService,
                NotificationService,
                oidcInterceptor,
                SchoolService,
                SettingsService,
                SortingService,
                StudentDetailsService,
                StudentDetailsPreloadingGuard,
                StudentService,
                TeacherService,
                TrackingService,
                UiLockedGuard,
                UserService,
                WindowService,
                HotjarService,
                ConsentService,
                CookieService
            ]
        };
    }
}
