import { createAction, props } from '@ngrx/store';
import { BookDetailModel, BookModel, FilterCategory } from 'shared/models';

export const fetchBooks = createAction('[Books] Fetch books');
export const fetchBooksSuccess = createAction(
    '[Books] Fetch books success',
    props<{ books: BookModel[], filterOptions: any }>()
);
export const fetchBooksFail = createAction(
    '[Books] Fetch books fail',
    props<{ error: any }>()
);

export const fetchBookDetails = createAction(
    '[Book details page] Fetch book details',
    props<{ bookId: number }>()
);

export const fetchBookDetailsForAssignmentPage = createAction(
    '[Assignment effects] Fetch book details',
    props<{ bookId: number }>()
);
export const fetchBookDetailsSuccess = createAction(
    '[Books API] Fetch book details success',
    props<{ bookDetail: BookDetailModel }>()
);
export const fetchBookDetailsFail = createAction(
    '[Books API] Fetch book details fail',
    props<{ bookId: number; error: any }>()
);

export const fetchRecommendedBook = createAction(
    '[Book] Fetch recommended book'
);
export const fetchRecommendedBookSuccess = createAction(
    '[Book] Fetch recommended book success',
    props<{ book: BookModel }>()
);
export const fetchRecommendedBookFail = createAction(
    '[Book] Fetch recommended book fail',
    props<{ error: any }>()
);

export const setFavorite = createAction('[Book details page] set favorite', props<{ bookId: number }>());
export const setFavoriteSuccess = createAction('[Books API] set favorite succes', props<{ bookId: number }>());
export const setFavoriteFail = createAction('[Books API] set favorite fail', props<{ error: any }>());

export const removeFavorite = createAction('[Book details page] remove favorite', props<{ bookId: number }>());
export const removeFavoriteSuccess = createAction('[Books API] remove favorite success', props<{ bookId: number }>());
export const removeFavoriteFail = createAction('[Books API] remove favorite fail', props<{ error: any }>());

export const toggleFilter = createAction('[Book Search] toggle filter',
    props<{ filterCategory: FilterCategory, filterValue: any }>()
);

// any is used because the value can be a string or a number and we need to refactor this
export const setDefaultFiltersForStudent = createAction('[Book Search] apply filter for student', props<{ readingLevels: any[] }>());

export const resetAllFilters = createAction('[Book Search] reset all filters');
export const resetTitleInput = createAction('[Book Search] reset title input');
