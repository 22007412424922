import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { MonsterModel } from 'shared/models';
import { ImageService } from 'shared/services';
import { cancelUpdateMonsterPopup } from 'store/layout/popup.actions';
import { updateMonster } from 'store/student';
import { getStudentAvatarKey, getStudentProfile } from 'store/user';

@Component({
    selector: 'app-update-monster',
    templateUrl: './update-monster.component.html',
    styleUrls: ['./update-monster.component.scss']
})
export class UpdateMonsterComponent implements OnInit{
    monsters: MonsterModel[];
    selectedMonsterKey: string;
    currentMonsterKey: string;
    isSaving$ = this.store.pipe(select(getStudentProfile), map(s => s.loading));

    constructor(private imageService: ImageService, private store: Store) {}

    ngOnInit(): void {
        this.monsters = this.imageService.getAllStudentAvatars();
        this.store.pipe(select(getStudentAvatarKey))
            .subscribe((key) => {
                this.currentMonsterKey = key;
                this.selectedMonsterKey = this.currentMonsterKey;
            })
            .unsubscribe();
    }

    save(): void {
        this.selectedMonsterKey === this.currentMonsterKey
            ? this.cancel()
            : this.store.dispatch(updateMonster({ monster: this.monsters.find(m => m.key === this.selectedMonsterKey) }));
    }

    cancel(): void {
        this.store.dispatch(cancelUpdateMonsterPopup());
    }
}
