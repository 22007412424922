import { Component } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 069_Schwierigkeit_1_von_4a_flaechig_CV_Uni_Icon
 * 069_Schwierigkeit_1_von_4a_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-star',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
    <path *ngIf="isFilled"
        d="M51.661,21.749a.708.708,0,0,0-.674-.489H35.008L29.289,5.532a.708.708,0,0,0-.666-.467H28.07a.708.708,0,0,0-.666.467L21.685,21.26H5.706a.708.708,0,0,0-.674.489l-.138.426a.708.708,0,0,0,.239.778l12.985,10.1L10.532,51.121a.709.709,0,0,0,.337.908l.433.217a.711.711,0,0,0,.741-.066l16.3-12.16,16.3,12.16a.711.711,0,0,0,.741.066l.433-.217a.709.709,0,0,0,.337-.908L38.575,33.053l12.985-10.1a.708.708,0,0,0,.239-.778Z" />
    <path *ngIf="!isFilled"
        d="M5.746,22.217a1.415,1.415,0,0,0,.47,1.578l11.9,9.257-6.67,16.009a1.417,1.417,0,0,0,2.142,1.691L28.346,40.02,43.1,50.752a1.417,1.417,0,0,0,2.142-1.691l-6.67-16.009,11.9-9.257a1.417,1.417,0,0,0-.87-2.536h-14.6L29.679,6.6a1.418,1.418,0,0,0-2.665,0L21.685,21.259H7.087A1.418,1.418,0,0,0,5.746,22.217Zm16.931,1.877a1.418,1.418,0,0,0,1.332-.933l4.337-11.927,4.338,11.927a1.417,1.417,0,0,0,1.332.933h11.46l-9.5,7.386a1.416,1.416,0,0,0-.438,1.663l5.144,12.346L29.18,37.121a1.415,1.415,0,0,0-1.667,0L16.007,45.489l5.144-12.346a1.416,1.416,0,0,0-.438-1.663l-9.5-7.386Z" />
    </svg>
  `
})
export class SvgIconStarComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'star';
    }
}
