export interface TrackingEvent {
    eventId: string;
    category: EventCategory;
    action: string;
}

enum EventCategory {
    UserInteractions = 'userInteractions',
    Application = 'application',
    Licences = 'licences'
}

export enum TrackingEventName {
    openBook = 'openBook',
    openExercise = 'openExercise',
    createTask = 'createTask',
    editTask = 'editTask',
    setFavorite = 'setFavorite',
    removeFavorite = 'removeFavorite',
    deleteStudent = 'deleteStudent',
    deleteSchoolClass = 'deleteSchoolClass',
    deleteTeacher = 'deleteTeacher',
    activatedLicence = 'activatedLicence',
    changeClassReadingLevel = 'changeClassReadingLevel',
    createSchoolClass = 'createSchoolClass',
    sendMessage = 'sendMessage',
    openStudentResultPage = 'openStudentResultPage',
    registerAsTeacher = 'registerAsTeacher',
    clickSalesBanner = 'clickSalesBanner',
    clickGlobalNotifications = 'clickGlobalNotifications',
    clickConfigAudio = 'clickConfigAudio',
    clickConfigSyllableColoring = 'clickConfigSyllableColoring',
    updateMonster = 'updateMonster',
    updateLearnersPerGroup = 'updateLearnersPerGroup',
}

export const trackingEvents: { [key in TrackingEventName]: TrackingEvent } = {
    openBook: {
        eventId: 'd38dff29-ecfc-4cf9-bdad-93a53c9bf449',
        category: EventCategory.UserInteractions,
        action: 'open-book'
    },
    openExercise: {
        eventId: '5b4020f3-a82f-419f-b01a-ea73f0987678',
        category: EventCategory.UserInteractions,
        action: 'open-exercise'
    },
    createTask: {
        eventId: '4810902d-d12e-4304-ba15-88af08f64cb2',
        category: EventCategory.UserInteractions,
        action: 'create-task'
    },
    editTask: {
        eventId: '64c519b4-4870-46bb-9e13-3f65f7b5e270',
        category: EventCategory.UserInteractions,
        action: 'edit-task'
    },
    setFavorite: {
        eventId: '97871223-9a3c-4445-ac69-294bcefaa13f',
        category: EventCategory.UserInteractions,
        action: 'set favorite'
    },
    removeFavorite: {
        eventId: '2ed63198-355a-4324-bccd-b4713c1206a5',
        category: EventCategory.UserInteractions,
        action: 'remove favorite'
    },
    deleteStudent: {
        eventId: '2d1f41ae-367a-48e1-b5e7-b59aacfaa543',
        category: EventCategory.UserInteractions,
        action: 'delete student'
    },
    deleteSchoolClass: {
        eventId: '83dafb8d-cb3d-4a97-9769-b234c7682faf',
        category: EventCategory.UserInteractions,
        action: 'delete school-class'
    },
    deleteTeacher: {
        eventId: '33f322b6-1309-4cc0-99a0-610d15fce0a2',
        category: EventCategory.UserInteractions,
        action: 'delete teacher'
    },
    activatedLicence: {
        eventId: 'aa50727d-11a7-4ecb-a52b-7cc0496f92ee',
        category: EventCategory.Licences,
        action: 'activated'
    },
    changeClassReadingLevel: {
        eventId: '6c06fadf-6883-4231-8593-b13edf714f65',
        category: EventCategory.UserInteractions,
        action: 'change-class-reading-level'
    },
    createSchoolClass: {
        eventId: '38c60b21-602f-471c-aad2-70a6b01f1645',
        category: EventCategory.UserInteractions,
        action: 'create-schoolclass'
    },
    sendMessage: {
        eventId: 'd3550886-cdfa-4155-88c4-8eb8738d8317',
        category: EventCategory.UserInteractions,
        action: 'send-message'
    },
    openStudentResultPage: {
        eventId: '37c1396d-7dfe-42c8-93ba-42f0a670b142',
        category: EventCategory.UserInteractions,
        action: 'viewed'
    },
    registerAsTeacher: {
        eventId: '151d0885-9d51-4aad-83ee-668dc50f18c4',
        category: EventCategory.Application,
        action: 'register-teacher'
    },
    clickSalesBanner: {
        eventId: '2a17e314-9ac0-4499-8318-2bd3180deb24',
        category: EventCategory.UserInteractions,
        action: 'clicked'
    },
    clickGlobalNotifications: {
        eventId: '63c60c3b-4558-4cb2-b181-2bcae82da64a',
        category: EventCategory.UserInteractions,
        action: 'clicked'
    },
    clickConfigAudio: {
        eventId: 'a7d010be-34e8-4087-b2ec-25fbcba33ecb',
        category: EventCategory.UserInteractions,
        action: 'click-config-audio'
    },
    clickConfigSyllableColoring: {
        eventId: 'ztRe10be-34e8-4087-b2ec-25fbcba14opQ',
        category: EventCategory.UserInteractions,
        action: 'click-config-syllable-coloring'
    },
    updateMonster: {
        eventId: 'b63fe8c5-d6e1-409d-8bc4-c0ecab83ca83',
        category: EventCategory.UserInteractions,
        action: 'updated'
    },
    updateLearnersPerGroup: {
        eventId: '890278c5-7219-4bd6-9fa6-484f839d9512',
        category: EventCategory.UserInteractions,
        action: 'updated'
    }
};

