import { Component } from '@angular/core';

import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 585_Login_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-logout',
    template: `
    <!-- 585_Login_linear_CV_Uni_Icon-->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon">
        <path d="M28.348,46.772a1.417,1.417,0,0,1-1.418,1.417H9.922a1.416,1.416,0,0,1-1.417-1.417V9.921A1.416,1.416,0,0,1,9.922,8.5H26.93a1.417,1.417,0,1,1,0,2.834H11.34V45.355H26.93A1.417,1.417,0,0,1,28.348,46.772Zm25.4-17.882,0-.006a1.406,1.406,0,0,0,0-1.076l0,0a1.428,1.428,0,0,0-.31-.462L40.688,14.588a1.417,1.417,0,1,0-2,2L49.021,26.929H17.009a1.417,1.417,0,1,0,0,2.834H49.021L38.684,40.1a1.417,1.417,0,0,0,2,2L53.441,29.351A1.424,1.424,0,0,0,53.751,28.89Z" />
    </svg>
  `
})
export class SvgIconLogoutComponent extends SvgIconBaseDirective {
    get iconName(): string {
        return 'logout';
    }
}
