import { Component, Input } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

/**
 * 128_Korrekt_linear_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-correct',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" *ngIf="!filled">
        <path d="M21.261,45.354a1.417,1.417,0,0,1-1-.416L3.251,27.931a1.417,1.417,0,1,1,2-2L21.261,41.932,51.439,11.753a1.417,1.417,0,1,1,2.005,2L22.264,44.938A1.421,1.421,0,0,1,21.261,45.354Z" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon" *ngIf="filled">
        <path d="M20.76,47.444,4.167,30.851a.708.708,0,0,1,0-1l3.007-3.007a.708.708,0,0,1,1,0L21.261,39.928,48.519,12.67a.708.708,0,0,1,1,0l3.007,3.007a.708.708,0,0,1,0,1L21.762,47.444A.708.708,0,0,1,20.76,47.444Z" />
    </svg>`
})
export class SvgIconCorrectComponent extends SvgIconBaseDirective {
    @Input()
    public filled = false;
    
    get iconName(): string {
        return 'correct';
    }
}
