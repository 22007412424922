import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { NgxFloatUiContentComponent } from 'ngx-float-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getTeacherProfileLoading } from 'store/user';
import { enterPromoCode, enterPromoCodeFail } from 'store/user/licence.actions';

@Component({
    selector: 'app-promotion-code',
    templateUrl: './promotion-code.component.html',
    styleUrls: ['./promotion-code.component.scss']
})
export class PromotionCodeComponent implements AfterViewInit{
    private readonly ngUnsubscribe = new Subject<void>();

    @Output()
    readonly dismissPopup: EventEmitter<void> = new EventEmitter();

    @ViewChild('errorTooltip')
    tooltip: NgxFloatUiContentComponent;

    isLoading$ = this.store.select(getTeacherProfileLoading);
    code = '';

    get isEmpty(): boolean {
        return this.code.length === 0;
    }

    constructor(private store: Store, private actionSubject$: ActionsSubject) { }

    ngAfterViewInit() {
        this.actionSubject$
            .pipe(
                ofType(enterPromoCodeFail),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(action => {
                const statusCode = action.error.status;
                this.tooltip.text = this.getErrorMessage(statusCode);;
                this.tooltip.show();
            });
    }


    getErrorMessage(statusCode: number): string {
        switch (statusCode) {
            case 404:
                return 'Der Code ist ungültig.';
            case 409:
                return 'Der Code wurde bereits eingelöst.';
            default:
                return `Beim Aktivieren des Codes ist ein Fehler (${statusCode}) aufgetreten `;
        }
    }

    licenceCodeChange(value: string): void {
        this.code = value;
        this.tooltip.hide();
    }

    enterPromoCode() {
        if (!this.isEmpty) {
            this.store.dispatch(enterPromoCode({code: this.code.toUpperCase()}));
        }
    }

    cancel(): void {
        this.dismissPopup.emit();
    }
}
