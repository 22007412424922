import { TrackingObjects, TrackingVerbs } from 'shared/tracking/tracking';

interface TrackingEventData {
    eventId: string;
    eventObject: TrackingObjects;
    eventPayload: {};
    eventVerb: TrackingVerbs;
}

export abstract class BaseTrackingEvent implements TrackingEventData {
    readonly eventId: string;
    readonly eventObject: TrackingObjects;
    readonly eventPayload: {};
    readonly eventSubject = 'user';
    readonly eventVerb: TrackingVerbs;
    protected sendUserId: boolean = false;

    protected constructor(trackingEvent: TrackingEventData) {
        this.eventId = trackingEvent.eventId;
        this.eventObject = trackingEvent.eventObject;
        this.eventPayload = trackingEvent.eventPayload;
        this.eventVerb = trackingEvent.eventVerb;
    }

    public get sendExternalUserId() {
        return this.sendUserId;
    }
}
