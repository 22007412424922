import update from 'immutability-helper';

import { AssignmentModel, AssignmentDetailModel, ExerciseDetailModel, ExerciseModel, StudentCompletedExerciseModel, CvPopUpSettings, StrategyModel } from 'shared/models';
import { Dictionary, StoreHelper, PopupChannels } from 'shared/helper';
import * as SchoolClassesActions from 'store/school-classes/school-classes.actions';
import { openPopup } from 'store/layout';
import * as AssignmentsActions from './assignments.actions';

export type AssignmentsDict = Dictionary<AssignmentModel>;

export interface TeacherAssignmentsState {
    all: {
        dict: AssignmentsDict;
        loading: boolean;
        loaded: boolean;
        loadedCompletedAssignments: boolean;
    };
    selected: {
        item: AssignmentDetailModel;
        loading: boolean;
        loaded: boolean;
    };
    editor: AssignmentEditorState;
}

export interface AssignmentEditorState {
    bookId: number;
    studentIds: number[];
    exercises: ExerciseModel[];
    strategies: StrategyModel[];
    completedExercises: StudentCompletedExerciseModel[];
    loading: boolean;
    loaded: boolean;
}

const allDictInitValue = {};
const selectedItemInitValue = null;

export const initialState: TeacherAssignmentsState = {
    all: {
        dict: allDictInitValue,
        loading: false,
        loaded: false,
        loadedCompletedAssignments: false
    },
    selected: {
        item: selectedItemInitValue,
        loading: false,
        loaded: false
    },
    editor: {
        bookId: 0,
        studentIds: [],
        exercises: [],
        completedExercises: [],
        strategies: [],
        loading: false,
        loaded: false
    }
};

export function teacherAssignmentsReducer(state: TeacherAssignmentsState = initialState, action: any): TeacherAssignmentsState {
    switch (action.type) {
        case AssignmentsActions.AssignmentsActionTypes.FetchSelectedAssignment:
            return update(state, {
                selected: {
                    item: {
                        $set: selectedItemInitValue
                    },
                    loading: {
                        $set: true
                    },
                    loaded: {
                        $set: false
                    }
                }
            });

        case AssignmentsActions.AssignmentsActionTypes.FetchSelectedAssignmentSuccess:
            return update(state, {
                selected: {
                    item: {
                        $set: action.assignment
                    },
                    loading: {
                        $set: false
                    },
                    loaded: {
                        $set: true
                    }
                }
            });

        case AssignmentsActions.AssignmentsActionTypes.FetchSelectedAssignmentFail:
            return update(state, {
                selected: {
                    loading: {
                        $set: false
                    },
                    loaded: {
                        $set: true
                    }
                }
            });

        case AssignmentsActions.fetchAssignments.type: {
            return update(state, {
                all: {
                    loading: {
                        $set: true
                    },
                    loaded: {
                        $set: false
                    }
                }
            });
        }

        case AssignmentsActions.fetchAssignmentsSuccess.type: {
            const assignments = action.assignments as AssignmentModel[];
            const assignmentsDict = StoreHelper.arrayToDict(assignments);

            return {
                ...state,
                all: {
                    ...state.all,
                    dict: {
                        ...state.all.dict,
                        ...assignmentsDict
                    },
                    loaded: true,
                    loading: false,
                    loadedCompletedAssignments: action.loadedCompletedAssignments || state.all.loadedCompletedAssignments
                }
            };
        }

        case AssignmentsActions.fetchAssignmentsFailure.type: {
            return update(state, {
                all: {
                    loading: {
                        $set: false
                    },
                    loaded: {
                        $set: true
                    }
                }
            });
        }

        case AssignmentsActions.AssignmentsActionTypes.AddAssignmentSuccess: {
            const { assignment } = action;

            return update(state, {
                all: {
                    dict: {
                        $merge: {
                            [assignment.id]: assignment
                        }
                    }
                }
            });
        }

        case AssignmentsActions.AssignmentsActionTypes.UpdateAssignmentSuccess: {
            const { assignment } = action;

            return update(state, {
                selected: {
                    item: {
                        $set: assignment
                    },
                    loading: {
                        $set: false
                    },
                    loaded: {
                        $set: true
                    }
                }
            });
        }

        case AssignmentsActions.AssignmentsActionTypes.DeleteAssignmentSuccess: {
            return update(state, {
                all: {
                    dict: {
                        $unset: [action.assignment.id]
                    }
                }
            });
        }

        case SchoolClassesActions.selectSchoolClass.type:
        case SchoolClassesActions.deleteSchoolClassSuccess.type: {
            // clear all assignments
            const newState: TeacherAssignmentsState = {
                all: {
                    dict: {},
                    loading: false,
                    loaded: true,
                    loadedCompletedAssignments: true
                },
                selected: {
                    item: null,
                    loading: false,
                    loaded: false
                },
                editor: {
                    bookId: 0,
                    studentIds: [],
                    exercises: [],
                    completedExercises: [],
                    strategies: [],
                    loading: false,
                    loaded: false
                }
            };

            return newState;
        }

        case openPopup.type: {
            const settings = action.settings as CvPopUpSettings;
            const { channel } = settings;
            const isEditor = channel === PopupChannels.CreateAssignment || channel === PopupChannels.EditAssignment;

            if (isEditor && settings.data) {
                return {
                    ...state,
                    editor: {
                        ...state.editor,
                        bookId: settings.data.bookId || 0,
                        studentIds: settings.data.studentIds,
                    }
                };
            }

            return state;
        }

        case AssignmentsActions.fetchExerciseDetails.type: {
            return {
                ...state,
                editor: {
                    ...state.editor,
                    bookId: action.bookId,
                    exercises: action.exercises,
                    loading: true,
                    loaded: false,
                }
            };
        }

        case AssignmentsActions.fetchExerciseDetailsSuccess.type: {
            const exerciseDetails = action.exerciseDetails as ExerciseDetailModel;

            return {
                ...state,
                editor: {
                    ...state.editor,
                    exercises: exerciseDetails.exercises,
                    completedExercises: exerciseDetails.completedExercises,
                    strategies: exerciseDetails.strategies,
                    loading: false,
                    loaded: true,
                }
            };
        }

        case AssignmentsActions.fetchExerciseDetailsFail.type: {
            return {
                ...state,
                editor: {
                    ...state.editor,
                    loading: false,
                    loaded: false,
                }
            };
        }

        default:
            return state;
    }
}
