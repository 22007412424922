import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class UserOpenedTask extends BaseTrackingEvent{
    constructor(taskTitle: string = '', taskId: number = 0, taskAssignment: string = '') {
        super({
            eventId: '5b4020f3-a82f-419f-b01a-ea73f0987678',
            eventVerb: TrackingVerbs.OPENED,
            eventObject: TrackingObjects.TASK,
            eventPayload: {
                taskTitle: taskTitle,
                taskId: taskId,
                taskAssignment: taskAssignment
            },
        });
    }
}
