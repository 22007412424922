import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { WindowService } from 'shared/services';
import { openActivateLicenceCodePopup } from 'store/layout/popup.actions';
import { RootState } from 'store/root.reducer';

@Component({
  selector: 'app-cv-group-limit-info',
  templateUrl: './cv-group-limit-info.component.html',
  styleUrls: ['./cv-group-limit-info.component.scss']
})
export class CvGroupLimitInfoComponent {
  @Output()
  readonly dismissPopup: EventEmitter<void> = new EventEmitter();

  private readonly shopUrl = 'https://www.cornelsen.de/produkte/leseo-schullizenz-1-6-schuljahr-9783060845637';
  private readonly faqUrl = 'https://www.cornelsen.de/service/kundenservice/digitale-produkte/leseo';

  constructor(
    private store: Store<RootState>,
    private windowService: WindowService,
  ) { }

  closePopup() {
    this.dismissPopup.emit();
  }

  navigateTo(url: string) {
    this.windowService.openInNewTab(url);
    this.closePopup();
  }

  openLicensePopup() {
    this.closePopup();
    this.store.dispatch(openActivateLicenceCodePopup());
  }
}
