import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CvPopUpHelper } from 'shared/helper';
import { NotificationType, trackingEvents } from 'shared/models';
import { MessageService } from 'shared/services';
import { closePopup } from 'store/layout';
import { postMessage, postMessageError, postMessageSuccess } from './messages.actions';
import * as TrackingActions from '../tracking/tracking.actions';
import { UserSendMessage } from 'shared/tracking/models/user-send-message.model';

@Injectable()
export class MessagesEffects {
    // @todo log when fetchMessagesFromTeacherFail
    sendMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(postMessage),
            switchMap(({ message, notificationType, userName }) =>
                this.messageService.postMessage(message).pipe(
                    map(() => {
                        const props = {
                            notificationId: message.notificationId,
                            userName: userName,
                            notificationType: notificationType
                        };

                        return postMessageSuccess(props);
                    }),
                    catchError((error: unknown) =>
                        of(postMessageError({ error }))
                    )
                )
            )
        )
    );

    sendMessageSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(postMessageSuccess),
            tap(() => closePopup()),
            map((data) => CvPopUpHelper.createOpenPopupActionForSendMessageConfirmation(data.userName, data.notificationType, 2500))
        )
    );

    sendMessageSuccessTrack$ = createEffect(() =>
        this.actions$.pipe(
            ofType(postMessageSuccess),
            map((data) => {
                const messageTypeString = this.getMessageTypeStringForTracking(data.notificationType);
                return TrackingActions.userSendMessage({ event: new UserSendMessage(messageTypeString) });
            })
        )
    );

    sendMessageFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(postMessageError),
            tap(() => closePopup()),
            map((_) => {
                const message = 'Die Meldung konnte nicht gesendet werden.';
                const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                    null,
                    message,
                    2500
                );

                return openPopup;
            })
        )
    );

    constructor(
        private actions$: Actions,
        private messageService: MessageService
    ) { }


    private getMessageTypeNumberForTracking(notificationType: NotificationType): number {
        switch (notificationType) {
            case NotificationType.LevelUp:
                return 1;
            case NotificationType.LevelDown:
                return 2;
            case NotificationType.UpdateNote:
                return 3;
            case NotificationType.MaxScore:
                return 4;
            case NotificationType.Message:
            default:
                return 0;
        }
    }

    private getMessageTypeStringForTracking(notificationType: NotificationType): string {
        switch (notificationType) {
            case NotificationType.LevelUp:
                return 'LevelUp';
            case NotificationType.LevelDown:
                return 'LevelDown';
            case NotificationType.UpdateNote:
                return 'UpdateNote';
            case NotificationType.MaxScore:
                return 'MaxScore';
            case NotificationType.Message:
            default:
                return 'Message';
        }
    }
}

