import { Component, Input } from '@angular/core';
import { SvgIconBaseDirective } from './svg-icon-base.component';

export enum RatingFilling {
    Empty,
    HalfFull,
    Full
}

/**
 * 534_Rating_none_CV_Uni_Icon
 * 535_Rating_half_CV_Uni_Icon
 * 536_Rating_full_CV_Uni_Icon
 */
@Component({
    selector: 'app-svg-icon-rating',
    template: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.693 56.693" class="icon" [ngSwitch]="filling">
        <path *ngSwitchCase="fillings.Empty" d="M18.116,33.052l-6.67,16.009a1.417,1.417,0,0,0,2.142,1.691L28.345,40.019,43.1,50.752a1.418,1.418,0,0,0,2.142-1.691L38.573,33.052l11.9-9.257a1.418,1.418,0,0,0-.871-2.536h-14.6L29.677,6.6a1.417,1.417,0,0,0-2.664,0l-5.33,14.657H7.085a1.417,1.417,0,0,0-.87,2.536Zm4.559-8.958a1.417,1.417,0,0,0,1.332-.933l4.338-11.927,4.337,11.927a1.418,1.418,0,0,0,1.332.933h11.46l-9.5,7.385a1.418,1.418,0,0,0-.438,1.664l5.145,12.346L29.178,37.121a1.415,1.415,0,0,0-1.667,0L16.005,45.489l5.144-12.346a1.418,1.418,0,0,0-.438-1.664l-9.495-7.385Z"/>
        <path *ngSwitchCase="fillings.HalfFull" d="M18.116,33.052l-6.67,16.009a1.417,1.417,0,0,0,2.142,1.691L28.345,40.019,43.1,50.752a1.418,1.418,0,0,0,2.143-1.691L38.573,33.052l11.9-9.257a1.417,1.417,0,0,0-.87-2.536h-14.6L29.677,6.6a1.417,1.417,0,0,0-2.664,0l-5.33,14.657H7.085a1.417,1.417,0,0,0-.87,2.536Zm14.566-9.891a1.418,1.418,0,0,0,1.332.933h11.46l-9.5,7.385a1.419,1.419,0,0,0-.438,1.664l5.144,12.346L28.347,36.516l0,0V11.234Z"/>
        <path *ngSwitchCase="fillings.Full" d="M18.116,33.052l-6.67,16.009a1.417,1.417,0,0,0,2.142,1.691L28.345,40.019,43.1,50.752a1.417,1.417,0,0,0,2.142-1.691l-6.67-16.009,11.9-9.257a1.417,1.417,0,0,0-.87-2.536h-14.6L29.677,6.6a1.417,1.417,0,0,0-2.664,0l-5.33,14.657H7.085a1.417,1.417,0,0,0-.87,2.536Z"/>
    </svg>
    `
})
export class SvgIconRatingComponent extends SvgIconBaseDirective {
    readonly fillings = RatingFilling;

    @Input()
    filling: RatingFilling;

    get iconName(): string {
        return 'rating';
    }
}
