import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { CvPopUpHelper } from 'shared/helper';
import { CvPopUpSettings, CvPopUpSizes } from 'shared/models';
import * as StudentActions from 'store/student/student.actions';
import { closePopup, openPopup } from '.';
import * as PopupActions from './popup.actions';

@Injectable()
export class PopUpEffects {
    openUpdateMonsterPopup$ = createEffect(() => this.actions$.pipe(
        ofType(PopupActions.openUpdateMonsterPopup),
        map((action) => {
            const settings: CvPopUpSettings = {
                channel: action.type,
                renderedComponentConfig: {
                    componentName: 'UpdateMonsterComponent'
                },
                size: CvPopUpSizes.L,
                closable: true,
                overflow: 'visible',
                padding: false,
            };

            return openPopup({ settings });
        })
    ));

    openPromotionCodePopup$ = createEffect(() => this.actions$.pipe(
        ofType(PopupActions.openPromotionCodePopup),
        map((action) => {
            const settings: CvPopUpSettings = {
                channel: action.type,
                renderedComponentConfig: {
                    componentName: 'PromotionCodeComponent'
                },
                size: CvPopUpSizes.M,
                closable: true,
                overflow: 'visible',
                padding: false,
            };

            return openPopup({ settings });
        })
    ));

    openActivateLicenceCodePopup$ = createEffect(() => this.actions$.pipe(
        ofType(PopupActions.openActivateLicenceCodePopup),
        map((action) => {
            const settings: CvPopUpSettings = {
                channel: action.type,
                renderedComponentConfig: {
                    componentName: 'ActivateLicencePopupComponent'
                },
                size: CvPopUpSizes.M,
                closable: true,
                overflow: 'visible',
                padding: false,
            };

            return openPopup({ settings });
        })
    ));

    openGroupLimitInfoPopup$ = createEffect(() => this.actions$.pipe(
      ofType(PopupActions.openGroupLimitInfoPopup),
      map((action) => {
          const settings: CvPopUpSettings = {
              channel: action.type,
              renderedComponentConfig: {
                  componentName: 'GroupLimitInfoComponent'
              },
              size: CvPopUpSizes.CUSTOM,
              closable: true,
              overflow: 'visible',
              padding: false,
          };

          return openPopup({ settings });
      })
    ));

    // todo: use activatelicencesuccess action in this effect as well
    promotionCodeSuccessPopup$ = createEffect(() => this.actions$.pipe(
        ofType(PopupActions.openPromotionCodeSuccessPopup),
        map((action) => {
            const message = 'Ihre Lizenz wurde aktiviert';
            const settings: CvPopUpSettings = {
                channel: action.type,
                size: CvPopUpSizes.S,
                title: message,
                autoClose: CvPopUpHelper.defaultAutoCloseTime,
                closable: true
            };

            return openPopup({ settings });
        })
    ));

    closePopup$ = createEffect(() => this.actions$.pipe(
        ofType(
            PopupActions.cancelUpdateMonsterPopup,
            StudentActions.setMonsterSuccess
        ),
        map(() => closePopup())
    ));

    constructor(
        private actions$: Actions,
    ) { }
}
