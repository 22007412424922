import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TutorOnboardingState } from './tutor-onboarding.reducer';

export const getTutorOnboardingState = createFeatureSelector<TutorOnboardingState>('tutorOnboarding');

export const getCurrentOnboardingStep = createSelector(
    getTutorOnboardingState,
    state => state.currentOnboardingStep
);


