import { createReducer, on } from '@ngrx/store';
import { StudentAssignmentModel } from 'shared/models';
import * as StudentActions from './student.actions';
import { fetchAssignmentsFail } from './student.actions';

// check if this can be deleted
export interface StudentAssignmentsState {
    loading: boolean;
    loaded: boolean;
    openAssignments: StudentAssignmentModel[];
    expiredAssignments: StudentAssignmentModel[];
    completedAssignments: StudentAssignmentModel[];
}

export const initialState: StudentAssignmentsState = {
    loaded: false,
    loading: false,
    openAssignments: [],
    expiredAssignments: [],
    completedAssignments: []
};

const _studentAssignmentsReducer = createReducer(
    initialState,
    on(
        StudentActions.fetchOpenAssignments,
        StudentActions.fetchExpiredAssignments,
        StudentActions.fetchCompletedAssignments, (state) => {
            return { ...state, loading: true };
    }),
    on(StudentActions.fetchOpenAssignmentsSuccess, (state, {assignments}) => {
        return {
            ...state,
            openAssignments: assignments,
            loaded: true,
            loading: false
        };
    }),
    on(StudentActions.fetchExpiredAssignmentsSuccess, (state, {assignments}) => {
        return {
            ...state,
            expiredAssignments: assignments,
            loaded: true,
            loading: false
        };
    }),
    on(StudentActions.fetchCompletedAssignmentsSuccess, (state, {assignments}) => {
        return {
            ...state,
            completedAssignments: assignments,
            loaded: true,
            loading: false
        };
    }),
    on(fetchAssignmentsFail, (state) => {
        return {
            ...state,
            loading: false
        };
    })
);

export function studentAssignmentsReducer(state, action) {
    return _studentAssignmentsReducer(state, action);
}
