import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { CustomErrorCodes } from 'app/core/enums/custom-error-codes';
import { NgxFloatUiContentComponent } from 'ngx-float-ui';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LicenceType } from 'shared/models';
import { getLicence } from 'store/user';
import {
    ActivateLicence,
    activateLicenceFail
} from 'store/user/user.actions';
import { UserState } from 'store/user/user.reducer';

@Component({
    selector: 'app-activate-licence-popup',
    templateUrl: './activate-licence-popup.component.html',
    styleUrls: ['./activate-licence-popup.component.scss']
})
export class ActivateLicencePopupComponent implements AfterViewInit, OnDestroy {
    private readonly ngUnsubscribe = new Subject<void>();

    @Output()
    readonly dismissPopup: EventEmitter<void> = new EventEmitter();

    @ViewChild('errorTooltip', { static: true })
    tooltip: NgxFloatUiContentComponent;

    licenceCode: string;
    buttonLabel = 'Lizenz aktivieren';
    isBusy = false;
    licence$ = this.store.pipe(select(getLicence));

    public get warning(): Observable<string> {
        return this.licence$.pipe(
            map(licence => {
                const warning = 'Mit der Aktivierung der Lizenz endet ';

                if (licence.type === LicenceType.Paid) {
                    return warning + 'die Laufzeit Ihrer aktuellen Lizenz.';
                }

                return warning + 'Ihre kostenlose Testphase.';
            })
        );
    }


    constructor(
        private store: Store<UserState>,
        private actionSubject$: ActionsSubject,
    ) { }

    ngAfterViewInit() {
        this.actionSubject$
            .pipe(
                ofType(activateLicenceFail),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(action => {
                const status = action.error.status as number;
                const errorCodeResults = Number(action.error.errorCodeResults as string | null);

                if (errorCodeResults && CustomErrorCodes.EmsServiceNotAvailableCode === errorCodeResults && status === 503) {
                    this.tooltip.text = 'Diese Aktion ist aktuell nicht möglich. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
                } else {
                    this.tooltip.text = this.getErrorMessage(status);
                }

                this.tooltip.show();
                this.isBusy = false;
                this.buttonLabel = 'Lizenz aktivieren';
            });
    }

    getErrorMessage(statusCode: number): string {
        switch (statusCode) {
            case 404:
                return 'Der Code ist ungültig.';
            case 409:
                return 'Der Code wurde bereits eingelöst.';
            default:
                return `Beim Aktivieren des Codes ist ein Fehler (${statusCode}) aufgetreten `;
        }
    }

    activateLicence(): void {
        this.isBusy = true;
        this.buttonLabel = 'Bitte warten...';

        this.store.dispatch(new ActivateLicence(this.licenceCode));
    }

    cancel(): void {
        this.dismissPopup.emit();
    }

    licenceCodeChange(value: string): void {
        this.licenceCode = value;
        this.tooltip.hide();
    }

    isButtonDisabled() {
        return this.isBusy || this.licenceCode == null || this.licenceCode.includes('_');
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
