import { createAction, props } from '@ngrx/store';

export const addLeseoName = createAction('[SetLeseoName comp] set leseo name',
    props<{ leseoName: string }>()
);

export const addLeseoNameSuccess = createAction('[LeseoName API] set leseo name success',
    props<{ leseoName: string }>()
);

export const addLeseoNameFail = createAction('[LeseoName API] set leseo name fail',
    props<{ error: any }>()
);

export const nextOnboardingStep = createAction('[Tutor Onboarding Effects] go to next onboarding step');

export const previousOnboardingStep = createAction('[Onboarding comp] go to previous onboarding step');

export const completeOnboarding = createAction('[Finish Onboarding comp] finish onboarding flow');

export const completeOnboardingSuccess = createAction('[Finish Onboarding comp] finish onboarding flow success');

export const completeOnboardingFail = createAction('[Finish Onboarding comp] finish onboarding flow fail',
    props<{ error: any }>()
);

export const skipOnboarding = createAction('[Onboarding comp] skip onboarding flow');
