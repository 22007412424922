import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-cv-switch',
    templateUrl: 'cv-switch.component.html',
    styleUrls: ['cv-switch.component.scss']
})
export class CvSwitchComponent {

    private _items: { id: any, label: string }[] = [];

    @Input()
    set items(value: { id: any, label: string }[]) {
        if (value && value.length > 0) {
            if (!this.activeId) {
                this.activeId = value[0].id;
            }
            this._items = value.reverse();
        }
    }

    get items(): { id: any, label: string }[] {
        return this._items;
    }

    @Input()
    activeId: any;

    @Output() active: EventEmitter<any> = new EventEmitter();

    constructor() { }

    select(id: any) {
        this.activeId = id;

        if (this.active) {
            this.active.emit(id);
        }
    }
}
