import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherRegisteredAccount extends BaseTrackingEvent {
    constructor(accountTitle: string = '', accountId: string = '') {
        super({
            eventId: '151d0885-9d51-4aad-83ee-668dc50f18c4',
            eventVerb: TrackingVerbs.REGISTERED,
            eventObject: TrackingObjects.ACCOUNT,
            eventPayload: {
                accountTitle: accountTitle,
                accountId: accountId,
            },
        });
    }
}
