import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-leseo-name-error-popup',
    templateUrl: './leseo-name-error-popup.component.html',
    styleUrls: ['./leseo-name-error-popup.component.scss']
})
export class LeseoNameErrorPopupComponent {

    constructor(public dialogRef: MatDialogRef<LeseoNameErrorPopupComponent>) { }

    close(): void {
        this.dialogRef.close();
    }

}
