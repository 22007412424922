import { TrackingObjects, TrackingVerbs } from '../tracking';
import { BaseTrackingEvent } from './base-tracking-event.model';

export class TeacherClickedBanner extends BaseTrackingEvent{
    constructor(bannerTitle: string, bannerId: number = 0, numOfDaysLicenceActive?: number) {
        super({
            eventId: '2a17e314-9ac0-4499-8318-2bd3180deb24',
            eventVerb: TrackingVerbs.CLICKED,
            eventObject: TrackingObjects.BANNER,
            eventPayload: {
                bannerTitle,
                bannerId,
                numOfDaysLicenceActive
            },
        });
    }
}
