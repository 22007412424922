import { EnvironmentService } from 'app/core/services/environment.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { LicenceModel, LicenceType } from 'shared/models';

export interface LicenceName {
    salutation: string;
    name: string;
}

export class LicenceHelper {
    static getExpiredMessage(licence: LicenceModel): string {
        if (licence == null) {
            throw new Error('licence required');
        }

        if (licence.isExpired === false) {
            throw new Error('licence is not expired');
        }

        const licenceName = this.getLicenceName(licence.type);
        const message = `${licenceName.salutation} ${licenceName.name} ist abgelaufen.`;

        return message;
    }

    static getWarningMessage(licence: LicenceModel): string {
        if (licence == null) {
            throw new Error('licence required');
        }

        if (licence.isExpired === true) {
            throw new Error('licence is expired');
        }

        const licenceName = this.getLicenceName(licence.type);

        if (this.isInfiniteDemoLicence(licence) === true) {
            return `Sie besitzen eine ${licenceName.name}.`;
        }

        const validUntilFormated = LicenceHelper.getValidUntilFormated(licence);

        return `${licenceName.salutation} ${licenceName.name} läuft noch bis zum ${validUntilFormated}.`;
    }

    static getValidUntilFormated(licence: LicenceModel):string {
        return moment(licence.validUntil).format(EnvironmentService.dateFormat);
    }

    static isInfiniteDemoLicence(licence: LicenceModel): boolean {
        if (licence == null) {
            return false;
        }

        return (
            licence.type === LicenceType.Test &&
            licence.validUntil > new Date(2100, 12, 31, 0, 0, 0, 0)
        );
    }

    static getLicenceInfoUrl(): string {
        return EnvironmentService.licenceInfoUrl;
    }

    static getLicenceName(type: LicenceType): LicenceName {
        switch (type) {
            case LicenceType.Test:
                return {
                    salutation: 'Ihre',
                    name: 'Testlizenz'
                };
            case LicenceType.Demo:
                return {
                    salutation: 'Ihre',
                    name: 'Demo-Lizenz'
                };

            case LicenceType.Paid:
                return {
                    salutation: 'Ihre',
                    name: 'Lizenz'
                };
            default:
                throw new Error(`licence-type '${type} isn't supported yet.'`);
        }
    }
}
