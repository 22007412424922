import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RoleDisplayNamePipe } from 'app/teacher/role-display-name-translation/pipes/role-display-name.pipe';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CvPopUpHelper, PopupChannels } from 'shared/helper';
import { AccountType, StudentReadingConfigurationEnum } from 'shared/models';
import { TeacherService } from 'shared/services';
import { TeacherCreatedSchoolClass } from 'shared/tracking/models/teacher-created-school-class.model';
import { TeacherDeletedSchoolClass } from 'shared/tracking/models/teacher-deleted-school-class.model';
import { TeacherUsedReadingConfigurationFeature } from 'shared/tracking/models/teacher-used-reading-configuration-feature.model';
import { getSchoolSchoolClassesDict } from 'store/school/school.selector';
import * as StudentsActions from 'store/students/students.actions';
import { fillStore } from 'store/user';
import * as TrackingActions from '../tracking/tracking.actions';
import * as SchoolClassesActions from './school-classes.actions';
import { SchoolClassesState } from './school-classes.reducer';
import { getSchoolClass, getSchoolClassesId } from './school-classes.selector';
import {
    allStudentsHaveAudioHidden,
    allStudentsHaveSyllableColoringHidden
} from 'store/students/students.selector';
import { TeacherUpdatedLearnersGroup } from 'shared/tracking/models';
import { fetchConversationsForGroup } from 'store/conversations/conversations.actions';

@Injectable()
export class SchoolClassesEffects {

    loadSchoolClassOnInitialPageLoad$ = createEffect(() => this.actions$.pipe(
        ofType(fillStore),
        filter(({ accountType, firstTime }) => accountType === AccountType.Teacher && firstTime),
        map((_) => SchoolClassesActions.fetchSchoolClasses())
    ));

    fetchSchoolClasses$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.fetchSchoolClasses),
        switchMap(() =>
            this.teacherService.getSchoolClasses().pipe(
                map(schoolClasses => {

                    // reset active school class id if user doesn't have any classes
                    if (schoolClasses.length === 0) {
                        TeacherService.activeSchoolClassId = 0;
                    }
                    else if (!schoolClasses.some(sc => sc.id === TeacherService.activeSchoolClassId)) {
                        // if user doesn't own active school class
                        // reset to first one of their classes (alphabetically)
                        TeacherService.activeSchoolClassId = schoolClasses.sort((a, b) => a.name.localeCompare(b.name))[0]?.id;
                    }

                    return SchoolClassesActions.fetchSchoolClassesSuccess({ schoolClasses, activeSchoolClassId: TeacherService.activeSchoolClassId });
                }
                ),
                catchError((error: unknown) => of(SchoolClassesActions.fetchSchoolClassesFail({ error })))
            )
        )
    ));

    fetchSchoolClassesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.fetchSchoolClassesSuccess),
        filter(({ schoolClasses, activeSchoolClassId }) => schoolClasses != null && !!activeSchoolClassId),
        map(({ activeSchoolClassId }) => StudentsActions.fetchStudents({ schoolClassId: activeSchoolClassId }))
    ));

    selectSchoolClass$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.selectSchoolClass),
        tap(({ schoolClassId }) => TeacherService.activeSchoolClassId = schoolClassId),
        tap(() => {
          const studentDetailPageUrl = /lerngruppe\/entwicklung\/[0-9]+/;
          const exerciseDetailPageUrl = /aufgaben\/[0-9]+/;
          // should leave student detail page when selecting different group
          if (studentDetailPageUrl.test(this.router.url)) {
            this.router.navigate(['lehrer', 'lerngruppe', 'entwicklung']);
          }
          // should leave exercise detail page when selecting different group
          else if (exerciseDetailPageUrl.test(this.router.url)) {
            this.router.navigate(['lehrer', 'aufgaben']);
          }
        }),
        map(({ schoolClassId }) => StudentsActions.fetchStudents({ schoolClassId }))
    ));

    loadConversationsOnSchoolClassChange$ = createEffect(() => this.actions$.pipe(
      ofType(SchoolClassesActions.selectSchoolClass),
      map(({ schoolClassId }) => fetchConversationsForGroup({ groupId: schoolClassId }))
    ));

    /**
     * @todo: show error popup on fail
     **/
    fetchSchoolClassesFail$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.fetchSchoolClassesFail),
        tap(({ error }) => console.error(error))
    ), { dispatch: false });

    fetchSchoolClassDetails$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.getSchoolClassDetails),
        switchMap(({ schoolClassId }) =>
            this.teacherService
                .getSchoolClassDetails(schoolClassId)
                .pipe(
                    map(schoolClassDetails => SchoolClassesActions.getSchoolClassDetailsSuccess({ schoolClassId, schoolClassDetails })),
                    catchError((error: unknown) =>
                        of(SchoolClassesActions.getSchoolClassDetailsFail({ error }))
                    )
                )
        )
    ));

    addSchoolClass$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.addSchoolClass),
        switchMap(({ teacher, schoolClassName }) =>
            this.teacherService.addSchoolClass(schoolClassName).pipe(
                map(schoolClass =>
                    SchoolClassesActions.addSchoolClassSuccess({ teacher, schoolClass })
                ),
                catchError((error: unknown) =>
                    of(SchoolClassesActions.addSchoolClassFail({ error }))
                )
            )
        )
    ));

    addSchoolClassSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.addSchoolClassSuccess),
        map(({ schoolClass }) =>
            TrackingActions.teacherCreatedSchoolClass({
                event: new TeacherCreatedSchoolClass(schoolClass.name, schoolClass.id)
            })
        )
    ));

    viewNewSchoolClass$ = createEffect(() => this.actions$.pipe(
      ofType(SchoolClassesActions.addSchoolClassSuccess),
      tap(() => this.router.navigate(['lehrer', 'lerngruppe', 'entwicklung'])),
      map(({ schoolClass }) =>
        SchoolClassesActions.selectSchoolClass({schoolClassId: schoolClass.id})
      )
    ));

    deleteSchoolClass$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.deleteSchoolClass),
        withLatestFrom(
            this.store.pipe(select(getSchoolClass)),
            this.store.pipe(select(getSchoolSchoolClassesDict)),
            ({ schoolClassId, source }, schoolClass, schoolClassesDict) => ({
                schoolClassId,
                schoolClass,
                schoolClassesDict,
                source
            })
        ),
        switchMap(({ schoolClassId, schoolClass, schoolClassesDict, source }) => this.roleContextPipe.transform('Lerngruppe').pipe(map(groupLabel => (
            {
                schoolClassId,
                schoolClass,
                schoolClassesDict,
                source,
                groupLabel
            })))),
        map(({ schoolClassId, schoolClass, schoolClassesDict, source, groupLabel }) => {
            if (schoolClassId !== schoolClass.id && schoolClassesDict != null && Object.keys(schoolClassesDict).length > 0) {
                schoolClass = schoolClassesDict[schoolClassId];
            }

            const message = `Möchten Sie Ihre ${groupLabel} ${schoolClass.name} wirklich löschen?`;
            const payload = {
                schoolClass,
                source
            };
            const openPopup = CvPopUpHelper.createOpenPopupActionForConfirmation(
                PopupChannels.DeleteSchoolClassConfirmation,
                message,
                payload
            );
            return openPopup;
        })
    ));

    deleteSchoolClassSuccessful$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.deleteSchoolClassSuccess),
        withLatestFrom(this.store.select(getSchoolClassesId)),
        switchMap(([{ schoolClass }, schoolClassId]) => this.roleContextPipe.transform('Lerngruppe').pipe(map(groupLabel => ({ schoolClass, groupLabel, schoolClassId })))),
        concatMap(({ schoolClass, groupLabel, schoolClassId }) => {
            const message = `Die ${groupLabel} "${schoolClass.name}" wurde gelöscht.`;
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.DeleteSchoolClassSuccessful,
                message
            );

            if (schoolClassId === 0) {
                return [openPopup];
            }

            return [openPopup, SchoolClassesActions.selectSchoolClass({schoolClassId})];
        })
    ));

    deleteSchoolClassTracking$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.deleteSchoolClassSuccess),
        map((schoolClass) => TrackingActions.teacherDeletedSchoolClass({
            event: new TeacherDeletedSchoolClass(schoolClass.schoolClass.name, schoolClass.schoolClass.id)
        }))
    ));

    updateLearnesPerGroupTracking$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.deleteSchoolClassSuccess),
        map(() => TrackingActions.teacherUpdateLearnersPerGroup({ event: new TeacherUpdatedLearnersGroup(0) })
    )));

    changeSchoolClass$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.changeSchoolClass),
        switchMap(action =>
            this.teacherService
                .changeSchoolClass(action.schoolClassId, action.changeFields)
                .pipe(
                    map(
                        schoolClass => SchoolClassesActions.changeSchoolClassSuccess({ schoolClass })
                    ),
                    catchError((error: unknown) => of(SchoolClassesActions.changeSchoolClassFail({ error })))
                )
        )
    ));

    resetSchoolClassCodesCodes$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.resetSchoolClassCodes),
        map(({ schoolClass }) => {
            const message =
                'Möchten Sie wirklich alle Passwörter zurücksetzen?';
            const openPopup = CvPopUpHelper.createOpenPopupActionForConfirmation(
                PopupChannels.ResetAllCodesConfirmation,
                message,
                schoolClass
            );
            return openPopup;
        })
    ));

    resetSchoolClassCodesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.resetSchoolClassCodesSuccess),
        map(({ result }) => {
            const openPopup = CvPopUpHelper.createOpenPopupActionForMessage(
                PopupChannels.ResetAllCodesSuccessful,
                'Alle Passwörter wurden zurückgesetzt.'
            );
            return openPopup;
        })
    ));

    /**
     * @todo: add error handling resetSchoolClassCodesFail
     */

    setAllStudentConfigurations$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.setAllStudentReadingConfigurations),
        withLatestFrom(this.store.select(allStudentsHaveAudioHidden), this.store.select(allStudentsHaveSyllableColoringHidden)),
        map(([{ schoolClass, studentReadingConfiguration }, allAudiosHidden, allSyllableColoringHidden]) => {
            // todo refactor: this should be two different actions
            let isHidden: boolean;

            // flip current state
            if (studentReadingConfiguration === StudentReadingConfigurationEnum.HideAudio) {
                isHidden = !allAudiosHidden;
            } else {
                isHidden = !allSyllableColoringHidden;
            }

            return {
                schoolClass,
                isHidden,
                studentReadingConfiguration
            };
        }),
        switchMap(({ schoolClass, isHidden, studentReadingConfiguration }) =>

            this.teacherService.changeSchoolClassStudentReadingConfiguration(schoolClass.id, isHidden, studentReadingConfiguration).pipe(
                map(() =>
                    SchoolClassesActions.setAllStudentReadingConfigurationsSuccess({ schoolClass, isHidden, studentReadingConfiguration })
                ),
                catchError((error: unknown) =>
                    of(SchoolClassesActions.setAllStudentReadingConfigurationsFail({ error }))
                )
            )
        )
    ));

    setAllStudentConfigurationsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SchoolClassesActions.setAllStudentReadingConfigurationsSuccess),
                map(({ schoolClass, isHidden, studentReadingConfiguration }) => {
                    if (studentReadingConfiguration === StudentReadingConfigurationEnum.HideSyllableColoring) {
                        return TrackingActions.teacherUsedReadingConfigurationFeature({ event: new TeacherUsedReadingConfigurationFeature('school-class', 'syllable-coloring') });
                    } else {
                        return TrackingActions.teacherUsedReadingConfigurationFeature({ event: new TeacherUsedReadingConfigurationFeature('school-class', 'audio') });
                    }
                })
            )
    );

    setStudentReadingConfigurations$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolClassesActions.setStudentReadingConfiguration),
        switchMap(({ student, isHidden, studentReadingConfiguration }) =>
            this.teacherService.changeStudentReadingConfiguration(student, isHidden, studentReadingConfiguration).pipe(
                map(() =>
                    SchoolClassesActions.setStudentReadingConfigurationSuccess({ student, isHidden, studentReadingConfiguration })
                ),
                catchError((error: unknown) =>
                    of(SchoolClassesActions.setStudentReadingConfigurationFail({ error }))
                )
            )
        )
    ));

    setStudentConfigurationsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SchoolClassesActions.setStudentReadingConfigurationSuccess),
                map(({ student, isHidden, studentReadingConfiguration }) => {
                    if (studentReadingConfiguration === StudentReadingConfigurationEnum.HideSyllableColoring) {
                        return TrackingActions.teacherUsedReadingConfigurationFeature({ event: new TeacherUsedReadingConfigurationFeature('student', 'syllable-coloring') });
                    } else {
                        return TrackingActions.teacherUsedReadingConfigurationFeature({ event: new TeacherUsedReadingConfigurationFeature('student', 'audio') });
                    }
                })
            )
    );

    constructor(
        private actions$: Actions,
        private store: Store<SchoolClassesState>,
        private teacherService: TeacherService,
        private roleContextPipe: RoleDisplayNamePipe,
        private router: Router
    ) { }
}
