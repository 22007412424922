import {
    CvPopUpSettings,
    CvPopUpSizes,
    StudentNotificationModel,
    NotificationType,
    AssignmentDetailModel
} from 'shared/models';

import { openPopup } from 'store/layout/layout.actions';

// why do we need this?
export enum PopupChannels {
    AddAssignmentFailure = 'AddAssignmentFailure',
    AddAssignmentSuccess = 'AddAssignmentSuccess',

    DeleteExerciseAssignmentDraft = 'DeleteExerciseAssignmentDraft',
    DeleteSchoolClassConfirmation = 'DeleteSchoolClassConfirmation',
    DeleteSchoolClassSuccessful = 'DeleteSchoolClassSuccessful',

    DeleteStudentConfirmation = 'DeleteStudentConfirmation',
    DeleteStudentSuccessful = 'DeleteStudentSuccessful',

    ResetAllCodesConfirmation = 'ResetAllCodesConfirmation',
    ResetAllCodesSuccessful = 'ResetAllCodesSuccessful',

    ResetStudentCodeConfirmation = 'ResetStudentCodeConfirmation',
    ResetStudentCodeSuccessful = 'ResetStudentCodeSuccessful',

    DeleteAssignmentConfirmation = 'DeleteAssignmentConfirmation',
    DeleteAssignmentSuccessful = 'DeleteAssignmentSuccessful',

    StudentLoginFail = 'StudentLoginFail',

    CreateAssignmentNoSchoolClassOrNoStudents = 'CreateAssignmentNoSchoolClassOrNoStudents',
    CreateAssignment = 'CreateAssignment',
    EditAssignment = 'EditAssignment',

    ActivateLicence = 'ActivateLicence',
    ActivateLicenceSuccess = 'ActivateLicenceSuccess',

    AddStudentsSuccessful = 'AddStudentsSuccessful',
    AddStudentsFail = 'AddStudentsFail',

    TeacherSignedOut = 'TeacherSignedOut',

    DeleteTeacherConfirmation = 'DeleteTeacherConfirmation',
    DeleteTeacherSuccessful = 'DeleteTeacherSuccessful',
    DeleteTeacherFail = 'DeleteTeacherFail',

    Notification = 'ReadingLevelRise',

    SendTeacherMessage = 'SendTeacherMessage',
    SendTeacherMessageConfirmation = 'SendTeacherMessageConfirmation',
    SendTeacherMessageFail = 'SendTeacherMessageFail'
}

export class CvPopUpHelper {
    static readonly defaultAutoCloseTime = 3000;

    static createOpenPopupActionForConfirmation(
        channel: PopupChannels,
        message: string,
        payload: any
    ) {
        const settings: CvPopUpSettings = {
            size: CvPopUpSizes.S,
            title: message,
            buttons: {
                confirm: 'Ja',
                cancel: 'Nein'
            },
            data: payload,
            channel,
            closable: true
        };

        return openPopup({settings});
    }

    static createOpenPopupActionForMessage(
        channel: PopupChannels,
        message: string,
        timeout = this.defaultAutoCloseTime
    ) {
        const settings: CvPopUpSettings = {
            size: CvPopUpSizes.S,
            title: message,
            autoClose: timeout,
            channel,
            closable: true
        };

        return openPopup({settings});
    }

    static createOpenPopupActionForMonstersMessage(
        channel: PopupChannels,
        message: string
    ) {
        const settings: CvPopUpSettings = {
            renderedComponentConfig: {
                componentName: 'CvMonstersMessageComponent'
            },
            size: CvPopUpSizes.M,
            data: { message },
            title: '',
            autoClose: this.defaultAutoCloseTime,
            channel,
            closable: true
        };

        return openPopup({settings});
    }

    static createOpenPopupActionForCustomView(
        channel: PopupChannels,
        componentName: string,
        size: CvPopUpSizes = CvPopUpSizes.M,
        closable: boolean = true
    ) {
        const settings: CvPopUpSettings = {
            channel,
            size,
            renderedComponentConfig: {
                componentName
            },
            closable
        };

        return openPopup({settings});
    }

    static createOpenPopupActionForCreateAssignment(
        bookId?: number,
        studentIds: number[] = []
    ) {
        const settings: CvPopUpSettings = {
            channel: PopupChannels.CreateAssignment,
            size: CvPopUpSizes.L,
            overflow: 'visible',
            padding: false,
            data: { bookId, studentIds },
            renderedComponentConfig: {
                componentName: 'CreateAssignmentComponent'
            },
            closable: true
        };

        return openPopup({settings});
    }

    static createOpenPopupActionForEditAssignment(
        bookId: number,
        assignment: AssignmentDetailModel
    ) {
        const settings: CvPopUpSettings = {
            channel: PopupChannels.EditAssignment,
            size: CvPopUpSizes.L,
            overflow: 'visible',
            padding: false,
            data: { bookId, assignment },
            renderedComponentConfig: {
                componentName: 'EditAssignmentComponent'
            },
            closable: true
        };

        return openPopup({settings});
    }

    static createOpenPopupActionForSendMessage(
        notificationId: number,
        studentId: number,
        typeOfNotification: NotificationType,
        userName: string
    ) {
        const settings: CvPopUpSettings = {
            channel: PopupChannels.SendTeacherMessage,
            size: CvPopUpSizes.M,
            overflow: 'visible',
            padding: false,
            data: { notificationId, studentId, typeOfNotification, userName },
            renderedComponentConfig: {
                componentName: 'SendMessageComponent'
            },
            closable: true
        };

        return openPopup({settings});
    }

    static createOpenPopupActionForSendMessageConfirmation(userName: string, typeOfNotification: NotificationType, autoClose?: number) {
        const settings: CvPopUpSettings = {
            renderedComponentConfig: {
                componentName: 'SendMessageConfirmationComponent'
            },
            size: CvPopUpSizes.M,
            data: { userName, typeOfNotification },
            autoClose: autoClose || this.defaultAutoCloseTime,
            channel: PopupChannels.SendTeacherMessageConfirmation,
            closable: true
        };

        return openPopup({settings});
    }

    static createOpenPopupActionForNotification(
        notification: StudentNotificationModel
    ) {
        const settings: CvPopUpSettings = {
            channel: PopupChannels.Notification,
            size: CvPopUpSizes.L,
            data: {
                notification
            },
            renderedComponentConfig: {
                componentName: 'ReadingLevelRiseComponent'
            },
            closable: false,
            padding: false,
            overflow: 'visible'
        };

        return openPopup({settings});
    }
}
